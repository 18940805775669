import {
  Button,
  Box,
  Flex,
  Text,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  useToast,
  InputRightElement,
  InputGroup,
} from "@chakra-ui/react";
import { EyeOpenIcon } from "evergreen-ui";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import "./index.scss";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";

import { UPDATE_PATIENT_PROFILE } from "src/graphql/mutations/update-patient-profile.mutation";

import "./index.scss";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "src/providers/AuthProvider/context";

const Password = () => {
  const toast = useToast();
  const [showMore, setShowMore] = useState<boolean>(false);
  const [state, setState] = useState<any>({});
  const [show, setShow] = useState<boolean>(false);
  const [newState, setNewState] = useState<any>({});
  const { changePassword } = useAuthContext();
  const { t, i18n } = useTranslation();
  // const data = useMemo(() => patient?.patient || null, [patient]);
  const schema = yup.object({
    password1: yup
      .string()
      .min(8, t("La password deve avere almeno 8 caratteri"))
      .matches(/[a-z]+/, t("La password deve contenere uno minuscolo"))
      .matches(/[A-Z]+/, t("La password deve contenere uno maiuscolo"))
      .matches(
        /[@$!%*#?&_]+/,
        t("La password deve contenere un carattere speciale")
      )
      .matches(/\d+/, t("La password deve contenere un numero"))
      .required(t("Inserisci la tua password")),
    password2: yup
      .string()
      .min(8, t("La password deve avere almeno 8 caratteri"))
      .oneOf([yup.ref("password1")], t("La password non corrisponde"))
      .required(t("Inserisci la tua password")),
  });
  const {
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const togglePassword = () => {
    setShow((show) => !show);
  };

  const onSubmit = async () => {
    console.log(newState.password, newState.password1);
    try {
      await changePassword(newState.password, newState.password1);
      setNewState({
        ...newState,
        password: "",
        password1: "",
        password2: "",
      });
      reset({
        ...newState,
        password: "",
        password1: "",
        password2: "",
      });
      toast({
        title: t("La password è stata aggiornata"),
        status: "success",
        isClosable: true,
      });
    } catch (e) {
      toast({
        title: e,
        status: "error",
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Box pb="24px" px="32px" bg="#FFF" className="main-section-patient">
        <Box borderBottom={"solid 1px #EAECF0"}>
          <Flex>
            <Box w="360px" paddingRight={"24px"}>
              <Text
                fontWeight="700"
                fontSize="18px"
                lineHeight={"30px"}
                mb={"4px"}
              >
                {t("Password")}
              </Text>
            </Box>
            <Box w="596px">
              <form id="password-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-pat">
                  {/* password */}
                  <Flex
                    justifyContent="space-between"
                    mb={"24px"}
                    width={"50%"}
                    alignItems="flex-start"
                  >
                    <FormControl mt="16px" marginRight={"6px"}>
                      {newState.password?.length > 0 && (
                        <FormLabel
                          marginTop={"4px"}
                          htmlFor="password"
                          fontSize="14px"
                          fontWeight={"bold"}
                          color="brand.700"
                        >
                          {t("Password")}
                        </FormLabel>
                      )}
                      <InputGroup>
                        <Input
                          type={!show ? "password" : "text"}
                          id="password"
                          placeholder={t("Password attuale")}
                          {...register("password", {
                            onChange: (e) => {
                              setNewState({
                                ...newState,
                                password: e.target.value,
                              });
                            },
                          })}
                        />
                        <InputRightElement width="4.5rem">
                          <EyeOpenIcon
                            style={{
                              height: "80px",
                              width: "25px",
                              cursor: "pointer",
                            }}
                            onClick={togglePassword}
                          >
                            {show ? "Hide" : "Show"}
                          </EyeOpenIcon>
                        </InputRightElement>
                      </InputGroup>
                    </FormControl>
                  </Flex>
                  {!showMore && (
                    <Text
                      className="link-psw"
                      mb={12}
                      onClick={() => setShowMore((showMore) => !showMore)}
                    >
                      <span style={{ fontSize: "20px" }}>+</span>
                      &nbsp;&nbsp; <span>{t("Modifica password")}</span>{" "}
                    </Text>
                  )}

                  {showMore && (
                    <Flex
                      justifyContent="space-between"
                      alignItems="flex-start"
                      mb={8}
                    >
                      <FormControl mr="16px" isInvalid={!!errors.password1}>
                        {newState.password1?.length > 0 && (
                          <FormLabel
                            marginTop={"4px"}
                            htmlFor="password1"
                            fontSize="14px"
                            fontWeight={"bold"}
                            color="brand.700"
                          >
                            {t("Nuova password")}
                          </FormLabel>
                        )}
                        {newState.password2?.length > 0 &&
                          (newState.password1?.length <= 0 ||
                            !newState.password1) && (
                            <Box height={"22px"} marginTop={"4px"} />
                          )}
                        <Input
                          id="password1"
                          variant="outline"
                          type={!show ? "password" : "text"}
                          placeholder={t("Nuova password")}
                          {...register("password1", {
                            onChange: (e) => {
                              setNewState({
                                ...newState,
                                password1: e.target.value,
                              });
                            },
                          })}
                        />
                        <FormErrorMessage>
                          {errors.password1 && errors.password1?.message}
                        </FormErrorMessage>
                      </FormControl>

                      <FormControl isInvalid={!!errors.password2}>
                        {newState.password2?.length > 0 && (
                          <FormLabel
                            marginTop={"4px"}
                            htmlFor="password2"
                            fontSize="14px"
                            fontWeight={"bold"}
                            color="brand.700"
                          >
                            {t("Conferma nuova password")}
                          </FormLabel>
                        )}
                        {newState.password1?.length > 0 &&
                          (newState.password2?.length <= 0 ||
                            !newState.password2) && (
                            <Box height={"22px"} marginTop={"4px"} />
                          )}
                        <Input
                          id="password2"
                          type={!show ? "password" : "text"}
                          placeholder={t("Conferma nuova password")}
                          {...register("password2", {
                            onChange: (e) =>
                              setNewState({
                                ...newState,
                                password2: e.target.value,
                              }),
                          })}
                        />
                        <FormErrorMessage>
                          {errors.password2 && errors.password2?.message}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                  )}
                </div>
              </form>
            </Box>
          </Flex>
        </Box>

        <Box mt={"24px"}>
          <Flex justifyContent={"flex-end"} alignItems={"center"}>
            <Button
              type="submit"
              form="password-form"
              colorScheme="teal"
              variant="solid"
              backgroundColor="brand.500"
              isDisabled={
                !newState.password1?.length || !newState.password2?.length
              }
            >
              {t("Salva modifiche")}
            </Button>
          </Flex>
        </Box>
      </Box>
      <Box pb="24px" px="32px" bg="none" h={"46px"}></Box>
    </>
  );
};
export default Password;
