import { gql } from "@apollo/client";

export const GET_SERVICES_CATEGORIES_BYANGECY = gql`
  query GetMedicalServiceCategoriesByAgency {
    getMedicalServiceCategoriesByAgency {
      acronyms {
        id
        name
      }
      types {
        id
        name
      }
      priceList {
        id
        name
      }
    }
  }
`;
