import { SVGProps } from 'react';

export const ShareScreenIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={25}
    height={19}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.7 11.888V6.764m0 0 2.154 2.725M12.7 6.764 10.354 9.49m-5.341 8.164h15.372a3.202 3.202 0 0 0 3.202-3.203V4.202A3.202 3.202 0 0 0 20.385 1H5.013a3.202 3.202 0 0 0-3.202 3.202V14.45a3.202 3.202 0 0 0 3.202 3.203Z"
      stroke="#fff"
      strokeWidth={1.921}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
