import { QuestionnaireRelatedToType } from "src/interfaces/IQuestionnaires";
import { Select } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type RelatedToTypeProp = {
  setSelectedRelatedTo: (relatedTo: string) => void;
};

const RelatedToSelector: React.FC<RelatedToTypeProp> = ({
  setSelectedRelatedTo,
}) => {
  
  const { t, i18n } = useTranslation();
  return (
    <>
      <Select
        placeholder={t("Rivolto a")}
        border="gray.600"
        color="GrayText"
        boxShadow="0px 2px 24px rgba(9, 107, 114, 0.08)"
        onChange={(e) => {
          e.target.value === QuestionnaireRelatedToType.Doctor
            ? setSelectedRelatedTo(QuestionnaireRelatedToType.Doctor)
            : setSelectedRelatedTo(QuestionnaireRelatedToType.Patient);
        }}
      >
        <option>{t(QuestionnaireRelatedToType.Doctor)}</option>
        <option>{t(QuestionnaireRelatedToType.Patient)}</option>
      </Select>
    </>
  );
};

export { RelatedToSelector };
