import {
  Button,
  Box,
  Flex,
  Text,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  useToast,
  Spinner,
  Select,
} from "@chakra-ui/react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import "./index.scss";
import { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import { emailRegex, phoneRegex } from "src/regex";
import "./index.scss";
import _ from "lodash";
import { Avatar } from "evergreen-ui";

import { useTranslation } from "react-i18next";
import { UPDATE_USER } from "src/graphql/mutations/update-user.mutation";
import { GET_USER } from "src/graphql/queries/user.query";
import { GET_AVATAR_UPLOAD_URL } from "src/graphql/queries/get-avatar-upload-url.query";
import axios from "axios";

const Review = () => {
  const toast = useToast();
  const { t, i18n } = useTranslation();
  const languages = [
    {
      label: "it",
      language: "Italiano",
    },
    {
      label: "en",
      language: "Inglese",
    },
    {
      label: "fr",
      language: "Francese",
    },
    {
      label: "de",
      language: "Tedesco",
    },
    {
      label: "es",
      language: "Spagnolo",
    },
    {
      label: "pt",
      language: "Portoghese",
    },
    {
      label: "ar",
      language: "Arabo",
    },
  ];
  const [state, setState] = useState<any>({});
  const [newState, setNewState] = useState<any>({});
  const [file, setFile] = useState<any | null>();
  const query = useQuery(GET_AVATAR_UPLOAD_URL);
  const avatarUrl = useMemo(
    () => query.data?.getAvatarUploadUrl?.avatarUrl || null,
    [query]
  );
  const uploadUrl = useMemo(
    () => query.data?.getAvatarUploadUrl?.uploadUrl || null,
    [query]
  );
  const [selectedImage, setSelectedImage] = useState<any | null>(null);
  const { data, loading: userLoading } = useQuery(GET_USER);
  const [updateUser, { loading }] = useMutation(UPDATE_USER, {
    refetchQueries: [{ query: GET_USER }],
  });
  const schema = yup.object({
    name: yup
      .string()
      .test(
        "isNameValid",
        t("Il nome non è valido"),
        (value: any) => value.trim().length !== 0
      )
      .required(t("Inserisci un nome")),
    surname: yup
      .string()
      .test(
        "isSurnameValid",
        t("Il cognome non è valido"),
        (value: any) => value.trim().length !== 0
      )
      .required(t("Inserisci un cognome")),

    email: yup
      .string()
      .transform((value) => (!value ? null : value))
      .email(t("L'email non è valida"))
      .matches(emailRegex, t("L'email non è valida"))
      .nullable(),
    phone: yup
      .string()
      .transform((value) => (!value ? null : value))
      .matches(phoneRegex, t("Il numero di telefono non è valido"))
      .nullable(),
  });
  const user: any = useMemo(() => data?.me || null, [data]);
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const handleImageChange = (e: any) => {
    setFile(e.target.files[0]);
    if (e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target?.result) {
          setSelectedImage(e.target?.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const handleDisable = () => !selectedImage && _.isEqual(state, newState);

  useEffect(() => {
    if (user) {
      setState({
        ...state,
        ...user,
      });
      setNewState({
        ...state,
        ...user,
      });
      reset({
        ...state,
        ...user,
      });
    }
  }, [user]);

  const onSubmit = async (data: any) => {
    try {
      if (selectedImage) {
        await axios.put(uploadUrl, file, {
          headers: {
            "Content-Type": "image/*",
          },
        });

        await updateUser({
          variables: {
            input: {
              profilePhoto: avatarUrl,
              name: data.name,
              surname: data.surname,
              phone: data.phone,
              email: data.email,
              language: data.language,
            },
          },
        });
        window.location.reload();
      } else {
        await updateUser({
          variables: {
            input: {
              name: data.name,
              surname: data.surname,
              phone: data.phone,
              email: data.email,
              language: data.language,
            },
          },
        });
      }
      setState({ ...newState });
      setSelectedImage(null);
      setFile(null);
      toast({
        title: t("Il profilo è stato aggiornato"),
        status: "success",
        isClosable: true,
      });
    } catch (error: any) {
      toast({
        title: t("Errore"),
        description: error.message,
        status: "error",
        isClosable: true,
      });
    }
  };

  if (userLoading) {
    return (
      <Flex alignItems="center" justifyContent="center" height="100%">
        <Spinner size="xl" color="brand.500" thickness="4px" speed="0.65s" />
      </Flex>
    );
  }
  return (
    <>
      <Box pb="24px" px="32px" bg="#FFF" className="main-section-patient">
        <Box borderBottom={"solid 1px #EAECF0"}>
          <Flex>
            <Box w="360px" paddingRight={"24px"}>
              <Text
                fontWeight="700"
                fontSize="18px"
                lineHeight={"30px"}
                mb={"4px"}
              >
                {t("Le tue informazioni personali")}
              </Text>
            </Box>
            <Box w="596px">
              <Box position={"relative"}>
                <Flex alignItems={"center"} mb={6}>
                  <Avatar
                    src={selectedImage ? selectedImage : user.profilePhoto}
                    name={user ? `${user.name}` : ""}
                    size={50}
                  />
                  <Text ml={6}>
                    <label htmlFor="profilePhoto" className="label-upload">
                      {t("Clicca qui")}{" "}
                    </label>
                    <input
                      hidden
                      type="file"
                      id="profilePhoto"
                      accept="image/*"
                      onChange={handleImageChange}
                    />

                    {t("per cambiare l'immagine")}
                  </Text>
                </Flex>
              </Box>
              <form id="patient-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-pat">
                  {/* name and surname */}
                  <Flex justifyContent="space-between" alignItems="flex-start">
                    <FormControl mr="16px" isInvalid={!!errors.name}>
                      {newState.name?.length > 0 && (
                        <FormLabel
                          marginTop={"4px"}
                          htmlFor="name"
                          fontSize="14px"
                          fontWeight={"bold"}
                          color="brand.700"
                        >
                          {t("Nome")}
                        </FormLabel>
                      )}
                      {newState.surname?.length > 0 &&
                        (newState.name?.length <= 0 || !newState.name) && (
                          <Box height={"22px"} marginTop={"4px"} />
                        )}
                      <Input
                        id="name"
                        variant="outline"
                        placeholder={t("Nome")}
                        {...register("name", {
                          onChange: (e) => {
                            setNewState({ ...newState, name: e.target.value });
                            console.log(newState);
                          },
                        })}
                      />
                      <FormErrorMessage>
                        {errors.name?.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!errors.surname}>
                      {newState.surname?.length > 0 && (
                        <FormLabel
                          marginTop={"4px"}
                          htmlFor="surname"
                          fontSize="14px"
                          fontWeight={"bold"}
                          color="brand.700"
                        >
                          {t("Cognome")}
                        </FormLabel>
                      )}
                      {newState.name?.length > 0 &&
                        (newState.surname?.length <= 0 ||
                          !newState.surname) && (
                          <Box height={"22px"} marginTop={"4px"} />
                        )}
                      <Input
                        id="surname"
                        placeholder={t("Cognome")}
                        {...register("surname", {
                          onChange: (e) =>
                            setNewState({
                              ...newState,
                              surname: e.target.value,
                            }),
                        })}
                      />
                      <FormErrorMessage>
                        {errors.surname?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>
                  {/* ruolo */}
                  <Flex
                    justifyContent="space-between"
                    mb={"24px"}
                    width={"50%"}
                    alignItems="flex-start"
                  >
                    <FormControl mt="16px" marginRight={"6px"}>
                      <FormLabel
                        marginTop={"4px"}
                        htmlFor="role"
                        fontSize="14px"
                        fontWeight={"bold"}
                        color="brand.700"
                      >
                        {t("Ruolo")}
                      </FormLabel>
                      <Input
                        id="role"
                        disabled={true}
                        placeholder={t("Amministratore")}
                      />
                    </FormControl>
                  </Flex>
                </div>
              </form>
            </Box>
          </Flex>
        </Box>
        <Box borderBottom={"solid 1px #EAECF0"} mt={"24px"}>
          <Flex>
            <Box w="360px" paddingRight={"24px"}>
              <Text
                fontWeight="700"
                fontSize="18px"
                lineHeight={"30px"}
                mb={"4px"}
              >
                {t("Contatti")}
              </Text>
            </Box>
            <Box w="596px">
              <form id="patient-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-pat">
                  {/* email e telefono */}
                  <Flex
                    justifyContent="space-between"
                    alignItems="flex-start"
                    mb={"24px"}
                  >
                    <FormControl mr="16px" mt="16px" isInvalid={!!errors.email}>
                      {newState.email?.length > 0 && (
                        <FormLabel
                          marginTop={"4px"}
                          htmlFor="email"
                          fontSize="14px"
                          fontWeight={"bold"}
                          color="brand.700"
                        >
                          {t("Email")}
                        </FormLabel>
                      )}
                      {newState.phone?.length > 0 &&
                        (newState.email?.length <= 0 || !newState.email) && (
                          <Box height={"22px"} marginTop={"4px"} />
                        )}
                      <Input
                        id="email"
                        placeholder={t("Email")}
                        {...register("email", {
                          onChange: (e) =>
                            setNewState({ ...newState, email: e.target.value }),
                        })}
                      />
                      <FormErrorMessage>
                        {errors.email?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl mt="16px" isInvalid={!!errors.phone}>
                      {newState.phone?.length > 0 && (
                        <FormLabel
                          marginTop={"4px"}
                          htmlFor="phone"
                          fontSize="14px"
                          fontWeight={"bold"}
                          color="brand.700"
                        >
                          {t("Telefono")}
                        </FormLabel>
                      )}
                      {newState.email?.length > 0 &&
                        (newState.phone?.length <= 0 || !newState.phone) && (
                          <Box height={"22px"} marginTop={"4px"} />
                        )}
                      <Input
                        id="phone"
                        type="phone"
                        placeholder={t("Telefono")}
                        {...register("phone", {
                          onChange: (e) =>
                            setNewState({ ...newState, phone: e.target.value }),
                        })}
                      />
                      <FormErrorMessage>
                        {errors.phone?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>
                </div>
              </form>
            </Box>
          </Flex>
        </Box>
        <Box borderBottom={"solid 1px #EAECF0"} mt={"24px"}>
          <Flex>
            <Box w="360px" paddingRight={"24px"}>
              <Text
                fontWeight="700"
                fontSize="18px"
                lineHeight={"30px"}
                mb={"4px"}
              >
                {t("Lingua")}
              </Text>
            </Box>
            <Box w="596px">
              <form id="patient-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-pat">
                  {/* lingua */}
                  <Flex
                    justifyContent="space-between"
                    mb={"24px"}
                    width={"50%"}
                    alignItems="flex-start"
                  >
                    <FormControl mt="16px" marginRight={"6px"}>
                      <Select
                        {...register("language", {
                          onChange: (e) =>
                            setNewState({
                              ...newState,
                              language: e.target.value,
                            }),
                        })}
                        id="language"
                      >
                        {languages?.map((lang: any, index: number) => {
                          return (
                            <option key={index} value={lang.label}>
                              {t(lang.language)}
                            </option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Flex>
                </div>
              </form>
            </Box>
          </Flex>
        </Box>
        <Box mt={"24px"}>
          <Flex justifyContent={"flex-end"} alignItems={"center"}>
            <Button
              type="submit"
              form="patient-form"
              colorScheme="teal"
              variant="solid"
              backgroundColor="brand.500"
              isLoading={loading}
              isDisabled={handleDisable()}
            >
              {t("Salva modifiche")}
            </Button>
          </Flex>
        </Box>
      </Box>
      <Box pb="24px" px="32px" bg="none" h={"46px"}></Box>
    </>
  );
};
export default Review;
