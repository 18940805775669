import {
  Box,
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Image,
  Flex,
  Spinner,
} from "@chakra-ui/react";
import { IModal } from "../../../../../interfaces/IModal";
import { ArrowLeftIcon } from "evergreen-ui";
import { ICardModalInfo } from "../../../../../interfaces/ICardModalInfo";
import { RowsQuestionnaires } from "./RowsQuestionnaires";
import { useTranslation } from "react-i18next";

const CardInfoModal = ({
  data,
  isOpen,
  loading,
  onClose,
  onSave,
}: IModal & { data: ICardModalInfo | undefined }) => {
  const { t, i18n } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      isCentered={true}
      onClose={onClose}
      scrollBehavior="inside"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />

      <ModalContent
        backgroundColor="brand.300"
        maxWidth="1185px"
        h="855px"
        borderRadius="12px"
      >
        <ModalHeader pt="30px" alignItems="center">
          <Button
            variant="link"
            backgroundColor="transparent"
            color="brand.500"
            fontSize="16px"
            leftIcon={<ArrowLeftIcon />}
            onClick={onClose}
          >
            <Text>{t("Ritorna ai modelli")}</Text>
          </Button>
          <ModalCloseButton borderRadius="23px" mt="17px" />
        </ModalHeader>

        <Divider color="#EAECF0" />

        <ModalBody pb={6} mt="16px">
          {loading ? (
            <Flex alignItems="center" justifyContent="center" height="100%">
              <Spinner
                size="xl"
                color="brand.500"
                thickness="4px"
                speed="0.65s"
              />
            </Flex>
          ) : (
            <Box h="100%" mr="18px" ml="18px">
              <Text fontSize="24px" fontWeight="semibold" pt="8px" pb="24px">
                {data?.name}
              </Text>
              <Box display="flex" justifyContent="space-between" gap="48px">
                <Text
                  pt="16px"
                  width="678px"
                  height="216px"
                  fontFamily="Nunito"
                  fontSize="16px"
                  lineHeight="150%"
                  fontWeight="500"
                  verticalAlign="top"
                  color="#667085"
                >
                  {data?.description}
                </Text>
                <Image
                  src={data?.image}
                  width="367px"
                  height="253px"
                  alt="icon"
                  borderRadius="lg"
                />
              </Box>
              <Text fontSize="20px" fontWeight="semibold" pt="32px" pb="4px">
                {t("Questionari")} ({data?.questionnaires.length ?? 0})
              </Text>
              <Text
                h="24px"
                fontSize="16px"
                fontWeight="500"
                pb="52px"
                color="#667085"
              >
                {t(
                  "Vedi l'elenco dei questionari proposti per questo percorso"
                )}
              </Text>
              {data?.questionnaires?.map((x) => (
                <RowsQuestionnaires
                  icon={x.questionnaire?.icon}
                  name={x.questionnaire?.name}
                />
              ))}

              <Flex pt="64px" pb="24px" justifyContent="center">
                <Button
                  colorScheme="teal"
                  variant="solid"
                  //onClick={() => setIsAddClinic(true)}
                  //   onClick={handleClick}
                  width="165px"
                  height="48px"
                  backgroundColor="brand.500"
                >
                  {t("Prosegui")}
                </Button>
              </Flex>
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CardInfoModal;
