import { useState } from "react";
import * as yup from "yup";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Grid,
  Heading,
  Input,
  VStack,
  Text,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useSearchParams } from "react-router-dom";

import { User } from "../../../types/User";
import { text } from "../../../utils/text";
import LogoIcon from "../../../components/Icons/LogoIcon";
import { useTranslation } from "react-i18next";

const EmailVerificationForm = ({ isLoading, onSubmit }: any) => {
  const [searchParams] = useSearchParams();
  const type = (searchParams.get("type") as User) || "patient";
  const { features } = text[type];

  const { t, i18n } = useTranslation();
  const schema = yup.object({
    email: yup
      .string()
      .email(
        t(
          `L’indirizzo email fornito non è associato a nessun account attivo. Inserire un indirizzo email valido.`
        )
      )
      .required(t("Inserisci la tua email")),
  });
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <Grid height="100vh" gridAutoColumns="1fr" gridAutoFlow="column">
      <Box backgroundColor="brand.600">
        <Flex alignItems="center" justifyContent="space-between" p="24px">
          <LogoIcon />
        </Flex>
        <Flex
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          mt="10%"
        >
          <Box>
            <Heading as="h1" size="2xl">
              {t("Reimposta password")}
            </Heading>
            <Flex gap="10px" alignItems="center" mt="32px">
              <Text>
                {t("Riceverai per EMAIL un codice di autenticazione")}
              </Text>
            </Flex>
            <form onSubmit={handleSubmit(onSubmit)}>
              <VStack alignItems="flex-start" spacing="20px" mt="32px">
                <FormControl isInvalid={!!errors.email}>
                  <Input
                    size="lg"
                    id="email"
                    type="email"
                    placeholder={t("Email")}
                    {...register("email")}
                    w="327px"
                    boxShadow="0px 1px 24px rgba(9, 107, 114, 0.08)"
                  />
                  <FormErrorMessage>
                    {errors.email && errors.email.message}
                  </FormErrorMessage>
                </FormControl>
                <Button
                  size="lg"
                  type="submit"
                  variant="solid"
                  colorScheme="teal"
                  backgroundColor="brand.500"
                  isLoading={isLoading}
                >
                  {t("Conferma")}
                </Button>
              </VStack>
            </form>
          </Box>
        </Flex>
      </Box>
    </Grid>
  );
};

export default EmailVerificationForm;
