import { useCallback } from "react";
import { DefaultModal } from "../DefaultModal";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

type DismissModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
};

const DismissModal: React.FC<DismissModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  onCancel,
}) => {
  const navigate = useNavigate();
  const onConfirmDismiss = useCallback(() => {
    navigate("/paths");
    onConfirm?.();
  }, [navigate, onConfirm]);
  const { t, i18n } = useTranslation();
  return (
    <DefaultModal
      isOpen={isOpen}
      onClose={onClose}
      title={t("Annulla")}
      description={t(
        "Sei sicuro di voler annullare la creazione del percorso? Tutti i tuoi progressi andranno persi"
      )}
      confirmText={t("Si, conferma")}
      cancelText={t("No")}
      onConfirm={onConfirmDismiss}
      onCancel={onCancel}
    />
  );
};

export { DismissModal };
