import { SVGProps } from "react";

const DoctorsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.916 14.083a2.667 2.667 0 1 0 0-5.333 2.667 2.667 0 0 0 0 5.333Zm0 0v1.334c0 2.209 2 3.958 4.209 3.958 2.209 0 4.375-1.25 4.375-3.958V10m0 0c4.404 0 5.362-4.234 5.146-7.493-.048-.73-.682-1.257-1.413-1.257h-1.858M12.5 10c-4.404 0-5.188-4.234-4.972-7.493.048-.73.682-1.257 1.413-1.257H10.8m3.576 0V0m0 1.25V2.5M10.8 1.25V0m0 1.25V2.5"
      stroke={props.color ?? "#096b72"}
      strokeWidth={1.2}
    />
  </svg>
);

export default DoctorsIcon;
