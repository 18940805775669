import { gql } from "@apollo/client";

export const GET_PATH_QUESTIONNAIRES = gql`
  query GetPathQuestionnaires($getPathQuestionnairesId: ID!) {
    getPathQuestionnaires(id: $getPathQuestionnairesId) {
      id
      questionnaire {
        id
        name
        description
        shortName
        relatedTo
        icon
        landBotUrl
        patientQuestionnaire {
          id
          status
        }
      }
      activationDate
      repetition
    }
  }
`;
