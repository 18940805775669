import { ChangeEvent, useMemo, useRef, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { ChevronRightIcon, MoreIcon, PlusIcon, SearchIcon } from "evergreen-ui";
import AddClinicModal from "../../../components/Modals/AddClinicModal";
import SuccessModal from "../../../components/Modals/SuccessModal";
import { IClinic } from "../../../interfaces/IClinic";
import ClinicsTable from "./ClinicsTable";
import { useMutation, useQuery } from "@apollo/client";
import { GET_CLINICS } from "../../../graphql/queries/clinics.query";

import { CREATE_CLINIC } from "../../../graphql/mutations/create-clinic";
import React from "react";
import { ClinicsFilter } from "./ClinicsFilter";
import { GET_USER } from "src/graphql/queries/user.query";
import { useTranslation } from "react-i18next";

const Clinics = () => {
  const clinicModalRef = useRef<{ resetForm: () => void }>();
  const { t, i18n } = useTranslation();
  const toast = useToast();

  // const [status, setStatus] = useState("");
  const [globalFilter, setGlobalFilter] = useState("");
  const [isAddClinic, setIsAddClinic] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  // const clinicsQuery = useQuery(GET_CLINICS);
  const clinicsQuery = {
    loading: false,
    data: {
      clinics: Array.from({ length: 10 }, (_, i) => ({
        id: i,
        name: `Clinica ${i}`,
        doctorsQuantity: 10,
        address: "Via Roma 1",
        tags: [],
        email: "d@test.com",
        clinicManager: {
          name: "Mario",
          surname: "Rossi",
          email: "d@test.com",
          pec: "d@test.com",
        },
      })) as IClinic[],
    },
  };
  const [filteredClinics, setFilteredClinics] = useState<IClinic[]>(
    clinicsQuery.data.clinics ?? []
  );

  const [createClinic, { loading }] = useMutation(CREATE_CLINIC, {
    refetchQueries: [{ query: GET_CLINICS }],
  });

  const {
    data: { me: user },
    loading: userLoading,
  } = useQuery(GET_USER);

  console.log(user);

  const handleSaveClinics = async (data: any) => {
    try {
      await createClinic({
        variables: {
          input: {
            name: data.name,
            doctorsQuantity: parseInt(data.doctorsQuantity),
            address: data.address,
            pec: data.pec,
            clinicManager: {
              name: data.managerName,
              surname: data.managerSurname,
              email: data.email,
            },
          },
        },
      });
      setIsAddClinic(false);
      clinicModalRef.current?.resetForm();
    } catch (error: any) {
      toast({
        title:t( "Errore durante la creazione della clinica."),
        description: error.message,
        status: "error",
        isClosable: true,
      });
    }
  };

  if (clinicsQuery.loading) {
    return (
      <Flex alignItems="center" justifyContent="center" height="100%">
        <Spinner size="xl" color="brand.500" thickness="4px" speed="0.65s" />
      </Flex>
    );
  }
  return (
    <Box h="100%" mr="91px" ml="91px">
      <AddClinicModal
        ref={clinicModalRef}
        isOpen={isAddClinic}
        onClose={() => setIsAddClinic(false)}
        loading={loading}
        onSave={handleSaveClinics}
      />
      <SuccessModal isOpen={isSuccess} setIsOpen={() => setIsSuccess(false)} />

      <Flex alignItems="center" justifyContent="space-between">
        <Flex flexDirection="column">
          <Text fontSize="40px" fontWeight="semibold" pt="40px" pb="8px">
            {t("Cliniche")}
          </Text>

          <Text fontSize="16px" fontWeight="500" pb="30px" color={"GrayText"}>
            {t("Vedi l'elenco delle cliniche gestite da")}
            {userLoading ? "..." : " " + user.name + " " + user.surname}
          </Text>
        </Flex>
        <Button
          colorScheme="teal"
          variant="solid"
          onClick={() => setIsAddClinic(true)}
          leftIcon={<PlusIcon />}
          width="188px"
          height="48px"
          backgroundColor="brand.500"
        >
          {t("Crea nuova clinica")}
        </Button>
      </Flex>

      <Flex
        height="76px"
        paddingInlineEnd="24px"
        alignItems="center"
        justifyContent="space-between"
      >
        <Flex alignItems="center">
          <InputGroup border="2px" borderRadius="10px" borderColor="gray.200">
            <InputLeftElement
              pointerEvents="none"
              children={<SearchIcon color="gray" />}
            />
            <Input
              width="360px"
              placeholder={t("Cerca per nome o tag")}
              value={globalFilter ?? ""}
              background="white"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const filtered = clinicsQuery.data.clinics.filter((clinic) =>
                  clinic.name
                    .toLowerCase()
                    .replaceAll(" ", "")
                    .includes(e.target.value.toLowerCase().replaceAll(" ", ""))
                );

                setFilteredClinics(filtered);
                setGlobalFilter(e.target.value);
              }}
            />
          </InputGroup>
          <ClinicsFilter />
        </Flex>
      </Flex>

      <Box backgroundColor="brand.300"></Box>
      <ClinicsList data={filteredClinics} searchText={globalFilter} />
    </Box>
  );
};

type ClinicsListProps = {
  data: IClinic[];
  searchText?: string;
};

const ClinicsList: React.FC<ClinicsListProps> = React.memo(
  ({ data, searchText }) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const columnsDef: ColumnDef<IClinic>[] = useMemo(
      () => [
        {
          id: "name",
          header: t("Nome"),
          accessorFn: (row) => row.name,
          cell: ({ row, getValue }) => {
            const id = row.original.id;
            const name = getValue() as string;

            const handleClick = () => {
              navigate(`/clinics/${id}`);
            };

            return (
              <Flex alignItems="center" gap="10px">
                <Avatar name={name} />
                <Button
                  variant="link"
                  colorScheme="teal"
                  fontSize="15px"
                  fontWeight="bold"
                  color="brand.700"
                  onClick={handleClick}
                  whiteSpace="break-spaces"
                  textAlign="start"
                >
                  {name}
                </Button>
              </Flex>
            );
          },
        },
        {
          header: t("Reparti associati"),
          accessorFn: (row) => ({ email: row.clinicManager.email }),
          id: "departments",
          cell: () => {
            return (
              <Flex gap="10px" alignItems="center">
                <Button
                  backgroundColor="brand.300"
                  marginLeft="-14px"
                  color="#096B72"
                  fontSize="16px"
                  rightIcon={<ChevronRightIcon />}
                >
                  <Text>{t("Vedi reparti")}</Text>
                </Button>
              </Flex>
            );
          },
        },
        {
          header: "",
          id: "more",
          cell: () => {
            return (
              <Flex justifyContent={"flex-end"}>
                <Button
                  backgroundColor="brand.300"
                  color="gray.300"
                  justifyContent={"flex-end"}
                >
                  <MoreIcon style={{ transform: "rotate(90deg)" }} />
                </Button>
              </Flex>
            );
          },
        },
      ],
      [navigate]
    );

    const columns = useMemo(() => columnsDef, [columnsDef]);

    const table = useReactTable({
      data,
      columns,
      state: {
        globalFilter: searchText,
      },
      // globalFilterFn: globalFilterFn,
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
    });

    return <ClinicsTable table={table} />;
  }
);

export default Clinics;
