import {
  Box,
  Button,
  Checkbox,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "evergreen-ui";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

type ClinicsFilterProps = {
  //
};

enum ClinicFilters {
  ACTIVE = "Attive",
  INACTIVE = "Inattive",
}

const ClinicsFilter: React.FC<ClinicsFilterProps> = React.memo(() => {
  const [currentFilter, setCurrentFilter] = useState<ClinicFilters[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const toggleFilter = useCallback(
    (filter: ClinicFilters) => {
      if (currentFilter.includes(filter)) {
        setCurrentFilter(currentFilter.filter((f) => f !== filter));
      } else {
        setCurrentFilter([...currentFilter, filter]);
      }
    },
    [currentFilter]
  );



  return (
    <Popover onClose={() => setIsOpen(false)} isOpen={isOpen}>
      <PopoverTrigger>
        <Button
          backgroundColor="white"
          height="44px"
          rightIcon={<ChevronDownIcon />}
          width="100%"
          marginLeft="4"
          border="2px"
          borderRadius="10px"
          borderColor="gray.200"
          onClick={() => setIsOpen(true)}
        >
          <Box flexDirection="row" display="flex">
            <Text display="flex" marginRight="1">
              {t('Filtri attivi')}:
            </Text>
            <Text
              display="flex"
              color="white"
              marginRight="1"
              marginLeft="1"
              backgroundColor="brand.500"
              borderRadius="full"
              height="20px"
              width="20px"
              alignItems="center"
              justifyContent="center"
              fontSize="12px"
            >
              {currentFilter.length}
            </Text>
          </Box>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        borderColor="gray.200"
        border="2px"
        borderRadius="14px"
        left="-8"
        w={120}
        maxH="600px"
        overflow="scroll"
      >
        <PopoverBody>
          {Object.values(ClinicFilters).map((filter: any, index: number) => {
            console.log({
              filter,
              currentFilter,
              checked: filter === currentFilter,
            });
            return (
              <Box
                key={index}
                display="flex"
                w="100%"
                paddingY="2"
                paddingX="1"
              >
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Checkbox
                    checked={currentFilter.includes(filter)}
                    onChange={() => toggleFilter(filter)}
                  />
                </Box>
                <Box
                  marginLeft={2}
                  fontWeight="500"
                  fontSize="16px"
                  fontFamily="Nunito"
                >
                  {t(filter)}
                </Box>
              </Box>
            );
          })}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
});

export { ClinicsFilter, ClinicFilters };
