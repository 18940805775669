import * as yup from "yup";
import { useState, forwardRef, useImperativeHandle } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { phoneRegex } from "../../../regex";
import GreenTagInput from "../../Inputs/GreenTagInput";
import { IModal } from "../../../interfaces/IModal";
import { validateTaxCode } from "./validateTaxCode";
import "./index.scss";
import AddPatientPhotoIcon from "src/components/Icons/AddPatientPhotoIcon";
import { useTranslation } from "react-i18next";

const AddPatientModal = forwardRef(
  ({ isOpen, loading, onClose, onSave }: IModal, ref) => {
    const { t, i18n } = useTranslation();
    const [tags, setTags] = useState<string[]>([]);
    const schema = yup.object({
      name: yup
        .string()
        .test(
          "isNameValid",
          t("Il nome non è valido"),
          (value: any) => value.trim().length !== 0
        )
        .required(t("Inserisci un nome")),
      surname: yup
        .string()
        .test(
          "isSurnameValid",
          t("Il cognome non è valido"),
          (value: any) => value.trim().length !== 0
        )
        .required(t("Inserisci un cognome")),
      fiscalCode: yup
        .string()
        .test("isFiscalCodeValid", t("Il codice fiscale non è valido"), (value) =>
          validateTaxCode(value)
        )
        .required(t("Inserisci il tuo codice fiscale")),
      email: yup.string().email(t("L'indirizzo email inserito non è valido")),
      phone: yup
        .string()
        .transform((value) => (!value ? null : value))
        .matches(phoneRegex, t("Il numero di telefono non è valido"))
        .nullable(),
      note: yup.string(),
    });

    const {
      handleSubmit,
      register,
      reset,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(schema),
    });

    useImperativeHandle(ref, () => ({
      resetForm: () => {
        reset();
        setTags([]);
      },
    }));

    const onSubmit = (data: any) => {
      onSave({ ...data, tags });
    };

    const nameError = errors.name?.message;
    const surnameError = errors.surname?.message;
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [fiscalCode, setFiscalCode] = useState("");
    const [email, setEmail] = useState("");
    const [tel, setTel] = useState("");

    const handleCloseModal = () => {
      reset();
      setName("");
      setSurname("");
      setEmail("");
      setTel("");
      setFiscalCode("");
      setTags([]);
      onClose();
    };
    return (
      <Modal
        id="mymodal"
        isOpen={isOpen}
        onClose={handleCloseModal}
        scrollBehavior="inside"
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent backgroundColor="white">
          <ModalHeader>
            <Box
              padding={"11px"}
              width={"fit-content"}
              mb={"16px"}
              className="photo-box"
            >
              <AddPatientPhotoIcon />
            </Box>
            <Text
              fontSize="24px"
              fontWeight={700}
              color="#272B30"
              style={{ lineHeight: "48px" }}
            >
              {t('Nuovo paziente')}
            </Text>
            <Text
              fontSize="16px"
              fontWeight={400}
              color="brand.800"
              style={{ lineHeight: "150%" }}
            >
              {t('Invieremo al paziente un’email di invito alla piattaforma.')}
            </Text>
            <Text
              fontSize="16px"
              fontWeight={400}
              color="brand.800"
              style={{ lineHeight: "150%" }}
            >
              {t('Le informazioni che specificherai qui non verranno condivise con il paziente.')}
            </Text>
          </ModalHeader>
          <ModalCloseButton
            borderRadius="23px"
            marginTop={2}
            onClick={handleCloseModal}
          />
          <ModalBody pb={6}>
            <form id="patient-form" onSubmit={handleSubmit(onSubmit)}>
              {/* FIXME: why space between? */}
              {/* FIXME: add vstack */}
              <div className="form-pat">
                <Flex
                  justifyContent="space-between"
                  alignItems="flex-start"
                  mt={"16px"}
                >
                  <FormControl mr="16px" isInvalid={!!errors.name}>
                    {name.length > 0 && (
                      <FormLabel
                        marginTop={"4px"}
                        htmlFor="name"
                        fontSize="14px"
                        fontWeight={"bold"}
                        color="brand.700"
                      >
                        {t("Nome")}
                      </FormLabel>
                    )}
                    {name.length <= 0 && surname.length > 0 && (
                      <Box height={"22px"} marginTop={"4px"} />
                    )}
                    <Input
                      id="name"
                      variant="outline"
                      placeholder={t("Nome")}
                      {...register("name", {
                        onChange: (e) => setName(e.target.value),
                      })}
                    />
                    <FormErrorMessage>{nameError}</FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={!!errors.surname}>
                    {surname.length > 0 && (
                      <FormLabel
                        marginTop={"4px"}
                        htmlFor="surname"
                        fontSize="14px"
                        fontWeight={"bold"}
                        color="brand.700"
                      >
                        {surname.length > 0 && t("Cognome")}
                      </FormLabel>
                    )}
                    {surname.length <= 0 && name.length > 0 && (
                      <Box height={"22px"} marginTop={"4px"} />
                    )}
                    <Input
                      id="surname"
                      placeholder={t("Cognome")}
                      {...register("surname", {
                        onChange: (e) => setSurname(e.target.value),
                      })}
                    />
                    <FormErrorMessage>{surnameError}</FormErrorMessage>
                  </FormControl>
                </Flex>
                <FormControl mt="16px" isInvalid={!!errors.fiscalCode}>
                  {fiscalCode.length > 0 && (
                    <FormLabel
                      marginTop={"4px"}
                      htmlFor="fiscalCode"
                      fontSize="14px"
                      fontWeight={"bold"}
                      color="brand.700"
                    >
                      {t('Codice Fiscale')}
                    </FormLabel>
                  )}
                  <Input
                    id="fiscalCode"
                    placeholder={t("Codice Fiscale")}
                    {...register("fiscalCode", {
                      onChange: (e) => setFiscalCode(e.target.value),
                    })}
                  />
                  <FormErrorMessage>
                    {errors.fiscalCode?.message}
                  </FormErrorMessage>
                </FormControl>
                <Flex justifyContent="space-between" alignItems="flex-start">
                  <FormControl mr="16px" mt="16px" isInvalid={!!errors.email}>
                    {email.length > 0 && (
                      <FormLabel
                        marginTop={"4px"}
                        htmlFor="email"
                        fontSize="14px"
                        fontWeight={"bold"}
                        color="brand.700"
                      >
                        {t('Email')}
                      </FormLabel>
                    )}
                    {email.length <= 0 && tel.length > 0 && (
                      <Box height={"22px"} marginTop={"4px"} />
                    )}
                    <Input
                      id="email"
                      placeholder={t("Email")}
                      {...register("email", {
                        onChange: (e) => setEmail(e.target.value),
                      })}
                    />
                    <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                  </FormControl>
                  <FormControl mt="16px" isInvalid={!!errors.phone}>
                    {tel.length > 0 && (
                      <FormLabel
                        marginTop={"4px"}
                        htmlFor="phone"
                        fontSize="14px"
                        fontWeight={"bold"}
                        color="brand.700"
                      >
                        {t('Telefono')}
                      </FormLabel>
                    )}
                    {tel.length <= 0 && email.length > 0 && (
                      <Box height={"22px"} marginTop={"4px"} />
                    )}
                    <Input
                      id="phone"
                      type="phone"
                      placeholder={t("Telefono")}
                      {...register("phone", {
                        onChange: (e) => setTel(e.target.value),
                      })}
                    />
                    <FormErrorMessage>{errors.phone?.message}</FormErrorMessage>
                  </FormControl>
                </Flex>
              </div>
              <GreenTagInput
                maxTags={100}
                label={t("Tags")}
                values={tags}
                placeholder={t("Tags")}
                onChange={(values) => {
                  setTags(values);
                }}
              />
            </form>
          </ModalBody>
          <ModalFooter className="modal-footer">
            <Button
              color="#667085"
              onClick={handleCloseModal}
              backgroundColor="white"
              disabled={loading}
              variant="solid"
            >
              {t("Annulla")}
            </Button>
            <Button
              className="button-save"
              type="submit"
              form="patient-form"
              colorScheme="teal"
              variant="solid"
              backgroundColor="brand.500"
              isLoading={loading}
            >
              {t("Aggiungi paziente")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
);

export default AddPatientModal;
