import { useQuery, useMutation } from "@apollo/client";
import {
  Box,
  Flex,
  Text,
  Tabs,
  TabList,
  Tab,
  Divider,
  TabIndicator,
  TabPanels,
  TabPanel,
  Button,
  Badge,
  Spinner,
} from "@chakra-ui/react";
import { ArrowLeftIcon, PlusIcon } from "evergreen-ui";
import { useParams } from "react-router-dom";
import { useState, useEffect, useCallback, useMemo } from "react";
import QuestionnairesPathList from "./QuestionnairesPath/QuestionnairesPathList";
import {
  ActivationDate,
  Repetition,
  QuestionnaireRelatedToType,
} from "../../../interfaces/IQuestionnaires";
import ServicesPathList from "./ServicesPath/ServicesPathList";
import { useNavigate } from "react-router-dom";
import { PathSearchbar } from "./PathSearchbar";
import { AssociateHealthServiceModal } from "src/components/Modals/AssociateHealthServiceModal";
import { AssociateQuestionnairesModal } from "src/components/Modals/AssociateQuestionnairesModal";
import { IServiceHealth } from "src/interfaces/IServiceHealth";
import { IQuestionnaires } from "../../../interfaces/IQuestionnaires";
import { PathQuestionnairesFilter } from "./QuestionnairesPath/PathQuestionnairesFilter";
import IPath, { PathStatus } from "../../../interfaces/IPath";
import { GET_PATHS } from "../../../graphql/queries/paths.query";
import { GET_PATH_QUESTIONNAIRES } from "src/graphql/queries/get-path-questionnaires.query";
import { PathServicesFilter } from "./ServicesPath/PathServicesFilter";
import { ASSOCIATE_QUESTIONNAIRE_TO_PATH } from "src/graphql/mutations/associate-Questionnaire-ToPath.mutation";
import { useTranslation } from "react-i18next";

export const servicesDetails = [
  {
    acronym: "ABTK",
    description: "(US) Allungamento di corona clinico",
    id: "1",
    priceList: "Listino unisalute",
    step: "",
    type: "ABT Promo",
  },
  {
    acronym: "ABTK",
    description: "(US) Asportazione corona o pilastro di ponte",
    id: "1",
    priceList: "Listino 2",
    step: "",
    type: "ABT Promo",
  },
  {
    acronym: "ABTK",
    description: "(US) Consulto ortodontico",
    id: "1",
    priceList: "Listino unisalute",
    step: "",
    type: "ABT Promo",
  },
];

const PathDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [tabIndex, setTabIndex] = useState(0);
  const [globalFilter, setGlobalFilter] = useState("");

  const { t, i18n } = useTranslation();
  const { data, loading } = useQuery<{ paths: IPath[] }>(GET_PATHS);

  const { data: pathQuestionnairesData, loading: pathQuestionnairesLoading } =
    useQuery(GET_PATH_QUESTIONNAIRES, {
      variables: { getPathQuestionnairesId: params?.pathId },
    });

  const [associateQuestionnaire, { loading: questionnairesLoading }] =
    useMutation(ASSOCIATE_QUESTIONNAIRE_TO_PATH, {
      refetchQueries: [
        { query: GET_PATH_QUESTIONNAIRES },
        { query: GET_PATHS },
      ],
    });

  const [isQuestionnairesModalOpen, setIsQuestionnairesModalOpen] =
    useState(false);

  const [selectedQuestionnaires, setSelectedQuestionnaires] = useState(
    pathQuestionnairesData?.getPathQuestionnaires || []
  );

  const [selectedServices, setSelectedServices] = useState<IServiceHealth[]>(
    servicesDetails || []
  );

  const [filteredServices, setFilteredServices] = useState(
    selectedServices || []
  );
  const PATH_TABS = [
    {
      label: t("Questionari"),
      field: "questionario",
    },
    {
      label: t("Prestazioni"),
      field: "prestazione",
    },
    {
      label: t("Metriche"),
      field: t("metrica"),
    },
  ];
  useEffect(() => {
    setFilteredServices(filteredServices);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedServices]);

  const onToggleServicesFilter = useCallback(
    (filters: any) => {
      const filtered = selectedServices?.filter((service) => {
        return (
          filters.length === 0 ||
          filters.includes(service.acronym) ||
          filters.includes(service.priceList) ||
          filters.includes(service.type)
        );
      });
      setFilteredServices(filtered ?? []);
    },
    [selectedServices]
  );

  useEffect(() => {
    setSelectedQuestionnaires(pathQuestionnairesData?.getPathQuestionnaires);
  }, [pathQuestionnairesData]);

  const [filteredQuestionnaires, setFilteredQuestionnaires] = useState(
    selectedQuestionnaires
  );

  useEffect(() => {
    setFilteredQuestionnaires(filteredQuestionnaires);
    // esling-disable-next-line react-hooks/exhaustive-deps
  }, [selectedQuestionnaires]);

  const [isServicesModalOpen, setIsServicesModalOpen] = useState(false);

  const onToggleFilter = useCallback(
    (filters) => {
      const filtered = selectedQuestionnaires.filter((questionnaire: any) => {
        return (
          filters?.length === 0 ||
          filters?.includes(
            QuestionnaireRelatedToType[
              questionnaire?.questionnaire
                ?.relatedTo as string as keyof typeof QuestionnaireRelatedToType
            ]
          ) ||
          filters?.includes(
            ActivationDate[
              questionnaire?.activationDate as string as keyof typeof ActivationDate
            ]
          ) ||
          filters?.includes(
            Repetition[
              questionnaire?.repetition as string as keyof typeof Repetition
            ]
          )
        );
      });

      setFilteredQuestionnaires(filtered ?? []);
    },
    [selectedQuestionnaires]
  );

  if (pathQuestionnairesLoading || pathQuestionnairesData == undefined) {
    return (
      <Flex alignItems="center" justifyContent="center" height="100%">
        <Spinner size="xl" color="brand.500" thickness="4px" speed="0.65s" />
      </Flex>
    );
  }

  return (
    <>
      <div className="path-container">
        <Box h="100%" mr="32px" ml="32px" mt="32px">
          <Flex alignItems="center" justifyContent="space-between">
            <Flex flexDirection="column" width="100%">
              <Button
                variant="link"
                backgroundColor="transparent"
                color="brand.500"
                fontSize="16px"
                width="fit-content"
                leftIcon={<ArrowLeftIcon />}
                onClick={() => {
                  navigate("/paths");
                }}
              >
                <Text>{t("Ritorna a percorsi")}</Text>
              </Button>

              {data?.paths
                .filter((p) => p.id.toString() === params.pathId)
                .map((p, index) => (
                  <Flex
                    justifyContent="space-between"
                    alignItems="flexStart"
                    pt="40px"
                    minWidth="100%"
                    key={p.id}
                  >
                    <Flex direction="column">
                      <Text fontSize="14px" fontWeight="semibold" key={p.id}>
                        {p.category?.name}
                      </Text>
                      <Text
                        fontSize="24px"
                        fontWeight="semibold"
                        pt="10px"
                        pb="8px"
                        key={p.id}
                      >
                        {p.name}
                      </Text>
                      <Text
                        fontSize="16px"
                        fontWeight="500"
                        pb="30px"
                        color={"GrayText"}
                      >
                        {t(
                          `Vedi l'elenco dei questionari e delle prestazioni previsti per questo percorso`
                        )}
                      </Text>
                    </Flex>
                    {p.status === PathStatus.ACTIVE ? (
                      <Badge
                        colorScheme="green"
                        width="fitContent"
                        pr="15px"
                        pl="15px"
                        height="40px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="16px"
                      >
                        <Text
                          display="flex"
                          color="white"
                          marginRight="1"
                          marginLeft="1"
                          backgroundColor="green"
                          borderRadius="full"
                          height="10px"
                          width="10px"
                          alignItems="center"
                          justifyContent="center"
                          fontSize="12px"
                        ></Text>
                        <Text
                          fontSize="16px"
                          fontWeight="bold"
                          textTransform="none"
                        >
                          {t("Percorso Attivo")}
                        </Text>
                      </Badge>
                    ) : (
                      <Badge
                        colorScheme="red"
                        width="fitContent"
                        pr="15px"
                        pl="15px"
                        height="40px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="16px"
                      >
                        <Text
                          display="flex"
                          color="white"
                          marginRight="1"
                          marginLeft="1"
                          backgroundColor="red"
                          borderRadius="full"
                          height="10px"
                          width="10px"
                          alignItems="center"
                          justifyContent="center"
                          fontSize="12px"
                        ></Text>
                        <Text
                          fontSize="16px"
                          fontWeight="bold"
                          textTransform="none"
                        >
                          {t("Percorso Non attivo")}
                        </Text>
                      </Badge>
                    )}
                  </Flex>
                ))}
            </Flex>
          </Flex>

          <Tabs
            marginTop="24px"
            variant="unstyled"
            position="relative"
            onChange={(index) => setTabIndex(index)}
          >
            <TabList>
              {PATH_TABS.map((tab, index) => (
                <Tab
                  key={index}
                  fontWeight="semibold"
                  _selected={{ color: "brand.500", bg: "brand.50" }}
                >
                  {tab.label}
                  <Text key={index}>
                    {tab.label === t("Questionari") && selectedQuestionnaires
                      ? ` (${selectedQuestionnaires?.length})`
                      : tab.label === t("Prestazioni")
                      ? ` (${selectedServices?.length})`
                      : ""}
                  </Text>
                </Tab>
              ))}
            </TabList>
            <Divider
              mt="-1px"
              height="0.5px"
              borderRadius="1px"
              backgroundColor="#D9DFE9"
              color="#D9DFE9"
            />
            <TabIndicator
              mt="-1.5px"
              height="2px"
              bg="brand.500"
              borderRadius="1px"
            />
            <Flex
              mt="20px"
              height="76px"
              alignItems="center"
              justifyContent="space-between"
            >
              <Flex alignItems="center">
                {tabIndex === 0 && selectedQuestionnaires ? (
                  <>
                    <PathSearchbar
                      text={t("Cerca Questionari...")}
                      filteredItems={selectedQuestionnaires}
                      setFilteredItems={setSelectedQuestionnaires}
                      globalFilter={globalFilter}
                      setGlobalFilter={setGlobalFilter}
                      defaultItems={selectedQuestionnaires}
                    />
                    <PathQuestionnairesFilter
                      onToggleFilter={onToggleFilter}
                      filteredItems={selectedQuestionnaires}
                      setFilteredItems={setSelectedQuestionnaires}
                    />
                  </>
                ) : tabIndex === 1 ? (
                  <>
                    <PathSearchbar
                      text={t("Cerca Prestazioni...")}
                      filteredItems={selectedServices}
                      setFilteredItems={setSelectedServices}
                      globalFilter={globalFilter}
                      setGlobalFilter={setGlobalFilter}
                      defaultItems={selectedServices}
                    />
                    <PathServicesFilter
                      onToggleServicesFilter={onToggleServicesFilter}
                      filteredItems={selectedServices}
                      setFilteredItems={setSelectedServices}
                    />
                  </>
                ) : (
                  ""
                )}
              </Flex>
              {tabIndex === 0 && selectedQuestionnaires ? (
                <Button
                  colorScheme="teal"
                  variant="solid"
                  leftIcon={<PlusIcon />}
                  width="200px"
                  height="48px"
                  backgroundColor="brand.500"
                  onClick={() => setIsQuestionnairesModalOpen(true)}
                >
                  {t("Associa Questionario")}
                </Button>
              ) : tabIndex === 1 ? (
                <Button
                  colorScheme="teal"
                  variant="solid"
                  leftIcon={<PlusIcon />}
                  width="200px"
                  height="48px"
                  backgroundColor="brand.500"
                  onClick={() => setIsServicesModalOpen(true)}
                >
                  {t("Associa Prestazione")}
                </Button>
              ) : (
                ""
              )}
            </Flex>
            <TabPanels>
              {PATH_TABS.map((tab, index) => (
                <TabPanel pl="0" pr="0" key={index}>
                  <Flex flexWrap="wrap" gap="24px" key={index}>
                    {tabIndex === 0 && selectedQuestionnaires ? (
                      <QuestionnairesPathList
                        data={selectedQuestionnaires}
                        searchText={globalFilter}
                        selectedQuestionnaires={selectedQuestionnaires}
                        setSelectedQuestionnaires={setSelectedQuestionnaires}
                        pathId={params?.pathId}
                      />
                    ) : tabIndex === 1 && selectedServices ? (
                      <ServicesPathList
                        data={selectedServices}
                        searchText={globalFilter}
                        key={Math.floor(Math.random() * 10)}
                      />
                    ) : (
                      ""
                    )}
                  </Flex>
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </Box>
      </div>

      <AssociateHealthServiceModal
        isOpen={isServicesModalOpen}
        onClose={() => setIsServicesModalOpen(false)}
        currentData={selectedServices}
        onSave={(services) =>
          setSelectedServices(
            (prev) =>
              [...prev, ...(services as IServiceHealth[])] as IServiceHealth[]
          )
        }
      />

      <AssociateQuestionnairesModal
        isOpen={isQuestionnairesModalOpen}
        onClose={() => setIsQuestionnairesModalOpen(false)}
        currentData={selectedQuestionnaires ? selectedQuestionnaires : []}
        onSave={async (questionnaires: any) => {
          setSelectedQuestionnaires(
            (prev: any) =>
              [
                ...prev,
                ...(questionnaires as IQuestionnaires[]),
              ] as IQuestionnaires[]
          );
          try {
            await associateQuestionnaire({
              variables: {
                input: {
                  activationDate: ActivationDate.NONE,
                  pathId: parseInt(params?.pathId!),
                  questionnairesId: questionnaires.map((q: any) =>
                    parseInt(q?.id)
                  ),
                  repetition: Repetition.NONE,
                },
              },
            });
          } catch (error: any) {
            console.error({
              title: t("Errore durante la creazione del percorso."),
              description: error.message,
              status: "error",
              isClosable: true,
            });
          }
        }}
      />
    </>
  );
};

export default PathDetails;
