import { gql } from "@apollo/client";

export const GET_PATIENT_QUESTIONNAIRES = gql`
query GetPatientQuestionnaires($patientId: ID!) {
  getPatientQuestionnaires(patientId: $patientId) {
    id
    questionnaire {
      id
      name
      description
    }
    status
  }
}
`;
