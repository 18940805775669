import { gql } from "@apollo/client";

export const GET_CLINICS = gql`
  query getClinics {
    clinics {
      id
      name
      doctorsQuantity
      address
      pec
      clinicManager {
        email
        name
        surname
      }
    }
  }
`;

export const GET_CLINIC = gql`
  query getClinic($id: ID!) {
    clinic(id: $id) {
      id
      name
      pec
      address
      doctorsQuantity
      clinicManager {
        email
        name
        surname
      }
    }
  }
`;
