import "./index.scss";

interface IProps {
  label: string;
  element: JSX.Element | JSX.Element[] | null | string;
  placeholder: string;
}

const InfoRow = ({ label, element, placeholder, ...props }: IProps) => {
  return (
    <div className="info-row" {...props}>
      <div className="info-text info-text__label" color="#6F767E">
        {label}
      </div>
      {element || (
        <div
          className="info-text info-text__placeholder"
          color="rgba(111, 118, 126, 0.5)"
        >
          {placeholder}
        </div>
      )}
    </div>
  );
};

export default InfoRow;
