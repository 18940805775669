import { gql } from "@apollo/client";

export const CREATE_QUESTIONNAIRES_BY_AGENCY = gql`
  mutation CreateQuestionnaireByAgency($input: questionnaireByAgencyInput!) {
    createQuestionnaireByAgency(input: $input) {
      id
      name
      description
    }
  }
`;
