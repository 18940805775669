import {
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Menu,
  IconButton,
} from "@chakra-ui/react";
import { MoreIcon } from "evergreen-ui";
import ActiveIcon from "../Icons/ActiveIcon";
import ArchiveIcon from "../Icons/ArchiveIcon";
import BlackCopyIcon from "../Icons/BlackCopyIcon";
import ModifyIcon from "../Icons/ModifyIcon";
import { PathStatus } from "../../interfaces/IPath";
import { useMutation } from "@apollo/client";
import { DUPLICATE_PATH } from "../../graphql/mutations/duplicate-path.mutation";
import { GET_PATHS } from "../../graphql/queries/paths.query";
import { UPDATE_PATH_STATUS } from "src/graphql/mutations/update-path-status.mutation";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface IProps {
  activeTabStatus: PathStatus;
  cardId: string;
}

const MenuBottonPath = ({ activeTabStatus, cardId }: IProps) => {
  const navigate = useNavigate();
  const [duplicatePath] = useMutation(DUPLICATE_PATH, {
    refetchQueries: [{ query: GET_PATHS }],
  });

  const { t, i18n } = useTranslation();
  const [activatePath] = useMutation(UPDATE_PATH_STATUS, {
    refetchQueries: [{ query: GET_PATHS }],
  });

  const handleActivatePath = async () => {
    try {
      await activatePath({
        variables: {
          id: cardId,
          status: PathStatus.ACTIVE,
        },
      });
    } catch (error: any) {
      console.error("Error while activating a path:", error);
    }
  };

  const handleArchivePath = async () => {
    try {
      await activatePath({
        variables: {
          id: cardId,
          status: PathStatus.ARCHIVED,
        },
      });
    } catch (error: any) {
      console.error("Error while activating a path:", error);
    }
  };

  const handleDuplicate = async () => {
    try {
      await duplicatePath({
        variables: {
          id: cardId,
        },
      });
    } catch (error: any) {
      console.error("Error duplicating component:", error);
    }
  };

  const items: {
    [key: string]: {
      title: string;
      icon: JSX.Element;
      onClick: any;
    }[];
  } = {
    [PathStatus.ACTIVE]: [
      {
        title: t("Archivia"),
        icon: <ArchiveIcon />,
        onClick: () => {
          handleArchivePath();
        },
      },
      {
        title: t("Duplica"),
        icon: <BlackCopyIcon />,
        onClick: handleDuplicate,
      },
      {
        title: t("Modifica"),
        icon: <ModifyIcon />,
        onClick: () => {
          navigate(`/paths/${cardId}/edit`);
        },
      },
    ],
    [PathStatus.ARCHIVED]: [
      {
        title: t("Attiva"),
        icon: <ActiveIcon />,
        onClick: () => {
          handleActivatePath();
        },
      },
      {
        title: t("Modifica"),
        icon: <ModifyIcon />,
        onClick: () => {
          navigate(`/paths/${cardId}/edit`);
        },
      },
    ],
    [PathStatus.DRAFT]: [
      {
        title: t("Modifica"),
        icon: <ModifyIcon />,
        onClick: () => {
          navigate(`/paths/${cardId}/edit`);
        },
      },
    ],
  };

  return (
    <Menu autoSelect={false}>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<MoreIcon style={{ transform: "rotate(90deg)" }} />}
        variant="outline"
        border="none"
        color="#667085"
      />
      <MenuList p={0} minW="0" w={"130px"} borderRadius="12px">
        {items[activeTabStatus].map((x) => (
          <>
            <MenuItem
              borderRadius="12px"
              h={"40px"}
              icon={x.icon}
              onClick={x.onClick}
            >
              {x.title}
            </MenuItem>
            <MenuDivider color="#EDEFF5" mt="0" mb="0" />
          </>
        ))}
      </MenuList>
    </Menu>
  );
};

export default MenuBottonPath;
