import { gql } from "@apollo/client";

export const GET_AVATAR_UPLOAD_URL = gql`
query GetAvatarUploadUrl {
    getAvatarUploadUrl {
      avatarUrl
      uploadUrl
    }
  }
`;

