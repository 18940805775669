
import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  VStack,
} from "@chakra-ui/react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { User } from "../../../types/User";
import { text } from "../../../utils/text";
import UpdatePasswordIcon from "../../../components/Icons/UpdatePasswordIcon";

import { useTranslation } from "react-i18next";

const PasswordUpdateForm = ({ isLoading, onSubmit }: any) => {
  const [searchParams] = useSearchParams();
  const type = (searchParams.get("type") as User) || "patient";
  const { features } = text[type];
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  return (
    <Grid height="100vh" gridAutoColumns="1fr" gridAutoFlow="column">
      <Box backgroundColor="brand.600">
        <Flex alignItems="center" justifyContent="space-between" p="24px">
          <span>{t("Logo")}</span>
        </Flex>
        <Flex
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          mt="10%"
        >
          <Box>
            <Flex
              alignItems="center"
              flexDirection="column"
              justifyContent="center"
            >
              <UpdatePasswordIcon />
            </Flex>
            <Heading as="h1" size="2xl">
              <Flex mt="20px">{t("Password aggiornata")}</Flex>
            </Heading>
            <Flex gap="10px" alignItems="center" mt="32px"></Flex>
            <form>
              <VStack alignItems="center" spacing="20px" mt="20px">
                <Button
                  size="lg"
                  type="submit"
                  variant="solid"
                  colorScheme="teal"
                  backgroundColor="brand.500"
                  isLoading={isLoading}
                  onClick={() => navigate("/signin")}
                >
                  {t("Fine")}
                </Button>
              </VStack>
            </form>
          </Box>
        </Flex>
      </Box>
    </Grid>
  );
};

export default PasswordUpdateForm;
