import { gql } from "@apollo/client";

export const GET_QUESTIONNAIRES = gql`
query getQuestionnaires($status: [String]) {
  questionnaires(status: $status) {
    id
    name
    description
    shortName
    relatedTo
    icon
    landBotUrl
    patientQuestionnaire {
      id
      status
    }
  }
}
`;


