// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ControlBarButton,
  Modal,
  ModalBody,
  ModalHeader,
  ModalButton,
  ModalButtonGroup,
  useLogger,
} from 'amazon-chime-sdk-component-library-react';
import { DealIcon } from '../Icons/DealIcon';
import { useTranslation } from 'react-i18next';

const EndMeetingControl: React.FC = () => {
  const logger = useLogger();
  const { t, i18n } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const toggleModal = (): void => setShowModal(!showModal);
  const navigate = useNavigate();

  const leaveMeeting = async (): Promise<void> => {
    navigate('/');
  };

  const endMeetingForAll = async (): Promise<void> => {
    try {
      // if (meetingId) {
      // await endMeeting(meetingId); api call
      navigate('/');
      // }
    } catch (e) {
      logger.error(`Could not end meeting: ${e}`);
    }
  };

  return (
    <>
      <ControlBarButton
        className="end-call-button"
        icon={<DealIcon />}
        onClick={toggleModal}
        label={t("Esci")}
      />
      {showModal && (
        <Modal size="md" onClose={toggleModal} rootId="modal-root">
          <ModalHeader title="End Meeting" />
          <ModalBody>
            {t("Leave meeting or you can end the meeting for all. The meeting cannot be used once it ends.")}
          </ModalBody>
          <ModalButtonGroup
            primaryButtons={[
              <ModalButton
                key="end-meeting-for-all"
                onClick={endMeetingForAll}
                variant="primary"
                label={t("End meeting for all")}
                closesModal
              />,
              <ModalButton
                key="leave-meeting"
                onClick={leaveMeeting}
                variant="primary"
                label={t("Leave Meeting")}
                closesModal
              />,
              <ModalButton
                key="cancel-meeting-ending"
                variant="secondary"
                label={t("Cancel")}
                closesModal
              />,
            ]}
          />
        </Modal>
      )}
    </>
  );
};

export default EndMeetingControl;
