import { gql } from "@apollo/client";

export const GET_QUESTIONNAIRES_BY_DOCTOR = gql`
  query GetDoctorQuestionnaires {
    getDoctorQuestionnaires {
      id
      name
      description
      shortName
      relatedTo
      icon
      landBotUrl
      patientQuestionnaire {
        id
        status
      }
    }
  }
`;
