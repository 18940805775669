import {
  Box,
  Flex,
  FormErrorMessage,
  Input,
  Switch,
  Text,
} from "@chakra-ui/react";

import React from "react";
import { PathStatus } from "src/interfaces/IPath";
import { EditCategory } from "./EditCategory";
import { useTranslation } from "react-i18next";

const GeneralInformationsField = {
  title: "title",
  description: "description",
  category: `category`,
  status: "status",
};

type GeneralInformationsProps = {
  formik: any;
  onCreateCategory?: () => void;
  singlePath: any;
};

const EditGeneralInformations: React.FC<GeneralInformationsProps> = React.memo(
  ({ formik, onCreateCategory, singlePath }) => {
    const { t, i18n } = useTranslation();
    return (
      <Flex p={0} flexDir={"column"}>
        <Flex>
          <Box flex={2}>
            <Text color={"gray.600"}>
              {t(
                "Aggiungi una categoria, un titolo e una descrizione per il tuo percorso."
              )}
            </Text>
          </Box>
          <Box flexDir={"column"} flex={3}>
            <Box flex={1}>
              <EditCategory
                GeneralInformationsField={GeneralInformationsField}
                onCreateCategory={onCreateCategory}
                singlePath={singlePath}
              />
              <Input
                boxShadow="0px 2px 24px rgba(9, 107, 114, 0.08)"
                marginTop={"15px"}
                variant="outline"
                errorBorderColor="red.300"
                id={GeneralInformationsField.title}
                name={GeneralInformationsField.title}
                onChange={formik.handleChange}
                value={formik.values.title}
              />
              {Boolean(formik?.errors?.title) && (
                <FormErrorMessage>{formik.errors.title}</FormErrorMessage>
              )}
              <Input
                boxShadow="0px 2px 24px rgba(9, 107, 114, 0.08)"
                marginTop={"15px"}
                height={"100px"}
                variant="outline"
                textAlign={"left"}
                id={GeneralInformationsField.description}
                name={GeneralInformationsField.description}
                onChange={formik.handleChange}
                value={formik.values.description}
              />
              {Boolean(formik?.errors?.description) && (
                <FormErrorMessage>{formik.errors.description}</FormErrorMessage>
              )}
            </Box>
          </Box>
        </Flex>

        <Flex marginTop={"20px"}>
          <Flex flex={2}>
            <Text color="gray.600">
              {t("Decidi tu se vuoi attivare o archiviare il tuo percorso.")}
            </Text>
          </Flex>
          <Flex flex={3} flexDir={"row"}>
            <Switch
              size="md"
              colorScheme="brand"
              marginRight={"10px"}
              onChange={(e) => {
                const newStatus = e.target.checked
                  ? PathStatus.ACTIVE
                  : PathStatus.ARCHIVED;
                formik.setFieldValue(
                  GeneralInformationsField.status,
                  newStatus
                );
              }}
            />
            <Flex flexDir={"column"}>
              <Text color="gray.800">{t("Attiva Percorso")}</Text>
              <Text color="gray.600">
                {t(
                  "Questo ti consente di attivare e vedere il percorso nella home"
                )}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    );
  }
);

export { EditGeneralInformations, GeneralInformationsField };
