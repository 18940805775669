import { gql } from '@apollo/client';

export const GET_PATHS = gql`
query paths ($status: String, $category: String) {
    paths(status: $status, category: $category) {
    id
    name
    status
    description
    agency {
      id
    }
    category {
      id
      name
    }
    questionnaires {
      id
    }
    }
  }
  `;