import { ChangeEvent, useEffect, useState } from "react";
import {
  Flex,
  Text,
  Box,
  Input,
  InputLeftElement,
  InputGroup,
} from "@chakra-ui/react";
import { useLazyQuery } from "@apollo/client";
import { SearchIcon } from "evergreen-ui";
import Card from "./card";
import CardInfoModal from "./CardInfoModal";
import { Paths } from "../Paths";
import { ICard } from "./ICard";
import { ICardModalInfo } from "../../../../interfaces/ICardModalInfo";
import { GET_PATH_INFO } from "../../../../graphql/queries/get-path-info.query";
import { useTranslation } from "react-i18next";

interface IProps {
  category: Paths;
  cards: ICard[];
}

const PathModelsContent = (props: IProps) => {
  const [globalFilter, setGlobalFilter] = useState("");
  const [filteredData, setFilteredData] = useState<ICard[] | null>(null);
  const [isModalOpened, setModalOpened] = useState(false);

  const { t, i18n } = useTranslation();
  const [getPathInfo, { loading, data }] = useLazyQuery<{
    getPathInfoById: ICardModalInfo;
  }>(GET_PATH_INFO);
  const content: {
    [key: string]: {
      name: string;
      description: string;
    };
  } = {
    [Paths.CARDIOMETABOLIC]: {
      name: t("Cardiometabolico"),
      description: t(
        t("Seleziona uno dei modelli seguenti inerenti al percorso di cardiometabolico.")
      ),
    },
    [Paths.ONCOLOGY]: {
      name: t("Oncologia"),
      description: t(
        t("Seleziona uno dei modelli seguenti inerenti al percorso di oncologia.")
      ),
    },
    [Paths.LIFE_COURSE]: {
      name: t("Corso della vita"),
      description: t(
        t("Seleziona uno dei modelli seguenti inerenti al percorso di corso della vita.")
      ),
    },
    [Paths.NEUROLOGY]: {
      name: t("Neurologia"),
      description: t(
        t("Seleziona uno dei modelli seguenti inerenti al percorso di neurologia.")
      ),
    },
    [Paths.MENTAL_HEALTH]: {
      name: t("Salute mentale"),
      description: t(
        t("Seleziona uno dei modelli seguenti inerenti al percorso di salute mentale.")
      ),
    },
  };

  useEffect(() => {
    setFilteredData(null);
    setGlobalFilter("");
  }, [props.cards]);

  const onClick = (id: string) => {
    setModalOpened(true);
    getPathInfo({ variables: { id: id } });
  };

  return (
    <Box h="100%">
      <CardInfoModal
        data={data?.getPathInfoById}
        loading={loading}
        isOpen={isModalOpened}
        onClose={() => setModalOpened(false)}
        onSave={() => {}}
      />

      <Flex justifyContent="space-between" alignItems="center" pb="30px">
        <InputGroup
          width="576px"
          border="2px"
          borderRadius="10px"
          borderColor="gray.200"
        >
          <InputLeftElement
            pointerEvents="none"
            children={<SearchIcon color="gray" />}
          />
          <Input
            width="576px"
            placeholder={t("Cerca i modelli per nome, categoria, ecc..")}
            value={globalFilter ?? ""}
            background="white"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const filtered = props.cards.filter((card: ICard) =>
                card.name
                  .toLowerCase()
                  .replaceAll(" ", "")
                  .includes(e.target.value.toLowerCase().replaceAll(" ", ""))
              );
              setFilteredData(filtered ?? null);
              setGlobalFilter(e.target.value);
            }}
          />
        </InputGroup>
      </Flex>
      <Text fontSize="24px" fontWeight="semibold" pt="8px" pb="24px">
        {content[props.category].name}
      </Text>
      <Text fontSize="16px" fontWeight="500" pb="30px" color={"GrayText"}>
        {content[props.category].description}
      </Text>
      <Flex flexWrap="wrap" gap="24px">
        {(filteredData || props.cards)?.map((x: ICard) => (
          <Card
            key={x.id}
            id={x.id}
            image={x.image}
            name={x.name}
            questionnaires={x.questionnaires}
            onClick={onClick}
          />
        ))}
      </Flex>
    </Box>
  );
};

export default PathModelsContent;
