import { SVGProps } from 'react';

export const SharedScreenIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={25}
    height={19}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.845 11.9V6.776m0 0L15 9.501m-2.155-2.725L10.5 9.501m-5.341 8.163h15.372a3.202 3.202 0 0 0 3.202-3.202V4.214a3.202 3.202 0 0 0-3.202-3.202H5.159a3.202 3.202 0 0 0-3.202 3.202v10.248a3.202 3.202 0 0 0 3.202 3.202Z"
      stroke="#66E3C3"
      strokeWidth={1.921}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
