import { SVGProps } from 'react';

export const DealIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={31}
    height={13}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.218 11.893.744 9.418A2.333 2.333 0 0 1 .892 5.97a23.086 23.086 0 0 1 9.651-4.586 22.747 22.747 0 0 1 9.899 0 23.086 23.086 0 0 1 9.684 4.62 2.332 2.332 0 0 1 .156 3.439l-2.474 2.475a2.333 2.333 0 0 1-3.069.23 14.979 14.979 0 0 0-2.895-1.74 2.333 2.333 0 0 1-1.37-2.112V6.201c-3.239-.89-6.658-.89-9.898 0v2.095a2.333 2.333 0 0 1-1.369 2.112 14.977 14.977 0 0 0-2.895 1.74 2.333 2.333 0 0 1-3.094-.255Z"
      fill="#fff"
    />
  </svg>
);
