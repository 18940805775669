import { SVGProps } from "react";

const Logotipe = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="124"
    height="27"
    fill="none"
    viewBox="0 0 124 27"
  >
    <path
      fill="#096B72"
      d="M53.836 11.383c0-2.21 1.33-3.612 3.137-3.612 1.806 0 3.042 1.378 3.042 3.589 0 2.21-1.26 3.707-3.066 3.707-1.877 0-3.113-1.474-3.113-3.684zM62.676 0h-2.685v6.987c-.357-.737-1.379-1.664-3.398-1.664-3.256 0-5.514 2.733-5.514 6.06 0 3.494 2.234 6.108 5.561 6.108 1.64 0 2.852-.808 3.398-1.783 0 .476.072 1.236.12 1.498h2.637c-.024-.19-.119-1.07-.119-2.115V0zM71.601 15.09c-1.687 0-3.232-1.283-3.232-3.683s1.545-3.636 3.232-3.636c1.711 0 3.232 1.236 3.232 3.636 0 2.424-1.52 3.684-3.232 3.684zm0-9.814c-3.445 0-5.988 2.59-5.988 6.131 0 3.565 2.543 6.155 5.988 6.155 3.47 0 6.013-2.59 6.013-6.155 0-3.54-2.543-6.131-6.013-6.131zM85.554 7.819c1.758 0 2.542 1.14 2.804 2.162l2.471-.903c-.475-1.854-2.186-3.802-5.3-3.802-3.326 0-5.916 2.566-5.916 6.131 0 3.517 2.59 6.155 5.988 6.155 3.042 0 4.777-1.972 5.323-3.779l-2.424-.879c-.261.88-1.093 2.139-2.899 2.139-1.735 0-3.232-1.307-3.232-3.636s1.497-3.588 3.185-3.588zM97.104 2.091h-2.496v1.735c0 1.022-.546 1.806-1.782 1.806h-.594V8.08h2.139v5.822c0 2.163 1.33 3.446 3.47 3.446.997 0 1.52-.19 1.687-.261v-2.282c-.12.024-.57.095-.951.095-1.022 0-1.473-.427-1.473-1.45V8.08h2.4V5.632h-2.4v-3.54zM107.544 15.09c-1.688 0-3.232-1.283-3.232-3.683s1.544-3.636 3.232-3.636c1.711 0 3.231 1.236 3.231 3.636 0 2.424-1.52 3.684-3.231 3.684zm0-9.814c-3.446 0-5.989 2.59-5.989 6.131 0 3.565 2.543 6.155 5.989 6.155 3.469 0 6.012-2.59 6.012-6.155 0-3.54-2.543-6.131-6.012-6.131zM123.231 5.56a3.95 3.95 0 00-.737-.07c-1.521 0-2.804.736-3.351 1.996V5.632h-2.685v11.574h2.756v-5.514c0-2.162.975-3.398 3.114-3.398.285 0 .594.024.903.071V5.561z"
    ></path>
    <path
      stroke="#66E3C3"
      strokeLinejoin="round"
      strokeWidth="2.753"
      d="M33.992 20.049c0 1.65 1.609 4.95 6.436 4.95s6.436-3.3 6.436-4.95"
    ></path>
    <path
      fill="#66E3C3"
      d="M2.828 10.147c.095-1.236 1.093-2.566 2.828-2.566 1.901 0 2.804 1.212 2.852 2.566h-5.68zm5.989 3.018c-.38 1.141-1.236 2.02-2.9 2.02-1.71 0-3.089-1.26-3.16-3.018h8.46c.024-.047.071-.428.071-.88 0-3.659-2.091-6.012-5.656-6.012C2.71 5.275 0 7.7 0 11.36c0 3.921 2.78 6.203 5.917 6.203 2.78 0 4.61-1.64 5.229-3.66l-2.33-.737zM13.447 14.068c0 1.83 1.497 3.494 3.921 3.494 1.878 0 3.019-.95 3.565-1.83 0 .927.095 1.402.119 1.473h2.567c-.024-.119-.143-.808-.143-1.853V9.6c0-2.306-1.355-4.326-4.92-4.326-2.851 0-4.657 1.783-4.871 3.779l2.519.57c.119-1.164.927-2.091 2.376-2.091 1.521 0 2.187.784 2.187 1.759 0 .404-.19.736-.856.831l-2.97.452c-1.973.285-3.494 1.426-3.494 3.493zm4.444 1.331c-1.093 0-1.687-.713-1.687-1.497 0-.95.69-1.426 1.545-1.568l3.018-.452v.523c0 2.257-1.331 2.994-2.876 2.994zM26.024 14.068c.143 1.307 1.426 3.494 4.777 3.494 2.947 0 4.372-1.949 4.372-3.708 0-1.71-1.14-3.041-3.327-3.517l-1.758-.356c-.713-.143-1.165-.594-1.165-1.212 0-.713.69-1.331 1.664-1.331 1.545 0 2.044 1.07 2.139 1.735l2.329-.666c-.19-1.14-1.236-3.232-4.468-3.232-2.4 0-4.254 1.711-4.254 3.731 0 1.593 1.07 2.924 3.113 3.375l1.711.38c.927.19 1.355.666 1.355 1.284 0 .713-.594 1.33-1.735 1.33-1.473 0-2.258-.927-2.353-1.972l-2.4.665zM42.996 15.066c-1.664 0-2.33-1.212-2.33-2.638V5.632H37.91v7.296c0 2.566 1.616 4.61 4.373 4.61.239 0 .478-.018.713-.053v-2.419zM48.176 12.476V5.632H45.42v6.844h2.757z"
    ></path>
  </svg>
);

export default Logotipe;
