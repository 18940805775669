import { gql } from "@apollo/client";

export const CREATE_SERVICE = gql`
  mutation createService($input: ServiceInput!) {
    createService(input: $input) {
      name
      cost
      duration
    }
  }
`;