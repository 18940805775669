import { useState, forwardRef } from "react";
import * as yup from "yup";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TitleSmall from "../../TitleSmall";
import { IModal } from "../../../interfaces/IModal";
import { colors } from "../../../colors";
import "./index.scss";
import { useTranslation } from "react-i18next";

const AddServicesModal = forwardRef(
  ({ isOpen, loading, onClose, onSave }: IModal, ref) => {
    const [tags, setTags] = useState<string[]>([]);

    const { t, i18n } = useTranslation();
    const schema = yup.object({
      name: yup.string().required(t("Inserisci il tuo nome")),
      cost: yup.string().required(t("Inserisci il costo")),
      duration: yup.number().required(t("Inserisci la durata")),
    });
    const {
      handleSubmit,
      register,
      reset,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(schema),
    });

    // useImperativeHandle(ref, () => ({
    //   resetForm: () => {
    //     reset();
    //     setTags([]);
    //   },
    // }));

    const onSubmit = (data: any) => {
      onSave(data);
    };

    return (
      <Modal
        size="2xl"
        isOpen={isOpen}
        isCentered={true}
        onClose={onClose}
        scrollBehavior="inside"
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent backgroundColor="brand.600">
          <ModalHeader pt="24px">
            <TitleSmall text={t("Nuovo servizio")} color={colors[200]} />
          </ModalHeader>
          <ModalCloseButton mt="8px" mr="8px" borderRadius="23px" />

          <ModalBody pb={6} mt="16px">
            <form id="service-form" onSubmit={handleSubmit(onSubmit)}>
              <VStack spacing="32px" alignItems="unset">
                <FormControl isInvalid={!!errors.cost}>
                  <FormLabel htmlFor="cost" fontSize="14px">
                    {t('Costo')}
                  </FormLabel>
                  <Input
                    id="cost"
                    placeholder="€"
                    {...register("cost")}
                    variant="solid"
                  />
                  <FormErrorMessage>{errors.cost?.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.name}>
                  <FormLabel htmlFor="name" fontSize="14px">
                    {t('Visita')}
                  </FormLabel>
                  <Input
                    id="name"
                    placeholder={t("Nome")}
                    {...register("name")}
                    variant="solid"
                  />
                  <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.duration}>
                  <FormLabel htmlFor="duration" fontSize="14px">
                    Durata
                  </FormLabel>
                  <Input
                    id="duration"
                    placeholder={t("Min.")}
                    {...register("duration")}
                    variant="solid"
                  />
                  <FormErrorMessage>
                    {errors.duration?.message}
                  </FormErrorMessage>
                </FormControl>
                {/* <GreenTagInput
                label="Servizi associati"
                values={tags}
                onChange={(values) => setTags(values)}
              /> */}
              </VStack>
            </form>
          </ModalBody>

          <ModalFooter pb="24px" justifyContent="space-between">
            <Button
              colorScheme="gray"
              variant="outline"
              onClick={onClose}
              backgroundColor="white"
              disabled={loading}
            >
              {t("Annulla")}
            </Button>
            <Button
              type="submit"
              form="service-form"
              colorScheme="teal"
              variant="solid"
              backgroundColor="brand.500"
              isLoading={loading}
            >
              {t('Aggiungi')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
);

export default AddServicesModal;
