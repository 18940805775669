import { Avatar, Box, Button, Divider, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface IRowsQuestionnairesProps {
  icon: string;
  name: string;
}

export const RowsQuestionnaires = (props: IRowsQuestionnairesProps) => {
  const { t, i18n } = useTranslation();
  return (
    <Box pb="16px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Flex gap="12px" alignItems="center">
          <Avatar size="md" src={props.icon} />
          <Text
            fontFamily="Nunito"
            fontWeight="600"
            fontSize="16px"
            color="#101828"
          >
            {props.name}
          </Text>
        </Flex>
        <Box pr="50px">
          <Button
            w="173px"
            h="24px"
            borderRadius="12px"
            fontWeight={700}
            backgroundColor="brand.300"
            color="#096B72"
            fontSize="16px"
            // onClick={handleClick}
          >
            {t("Visualizza questionario")}
          </Button>
        </Box>
      </Box>
      <Divider w="1093px" pt="16px" color="#EAECF0" />
    </Box>
  );
};
