import { format } from "date-fns";
import { forwardRef, useState, useEffect } from "react";
import { CrossIcon, Dialog, Pane } from "evergreen-ui";
import { IconButton, Box, Button } from "@chakra-ui/react";
import TitleSmall from "../../TitleSmall";
import VideoWhiteIcon from "../../Icons/VideoWhiteIcon";
import ModalInfoRow from "../../../components/ModalInfoRow";
import BucketIcon from "../../../components/Icons/BucketIcon";
import ToggleInput from "../../../components/Inputs/ToggleInput";
import { IEvent } from "../../../interfaces/IEvent";
import GreenCalendarIcon from "../../../components/Icons/GreenCalendarIcon";
import EditGrayIcon from "../../../components/Icons/EditGrayIcon";
import { useTranslation } from "react-i18next";
import { useMeeting } from "src/hooks/useMeeting";

const serviceList: any = [
  {
    id: 1,
    hospital_id: 1,
    name: "Visita introduttiva",
    price: "20.00",
    duration: 30,
  },
  {
    id: 2,
    hospital_id: 1,
    name: "Visita di follow-up",
    price: "10.00",
    duration: 20,
  },
];

const ViewAppointmentModal = ({
  event,
  onClose,
  isOpen,
  editEvent,
  deleteEvent,
}: {
  event: (IEvent & { id: string }) | null;
  isOpen: boolean;
  onClose: () => void;
  editEvent: (event: (IEvent & { id: string }) | null) => void;
  deleteEvent: (id: string) => void;
}) => {
  const [serviceType, setServiceType] = useState<string>("");
  const { isJoining, joinHandler } = useMeeting(event);

  const { t } = useTranslation();
  useEffect(() => {
    if (event?.serviceId) {
      const service = serviceList.find(
        (service: any) => service.id == event.serviceId
      );
      setServiceType(`${service.name} - ${service.duration} minuti`);
    }
  }, [event]);

  const ModalHeader = () => {
    return (
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        w="100%"
      >
        <TitleSmall text={t("Appuntamento in programma")} color="#FFBC99" />
        <IconButton
          border="none"
          aria-label="close"
          icon={<CrossIcon style={{ height: "20px", width: "20px" }} />}
          onClick={onClose}
          background="#EFEFEF"
          variant="circle"
        />
      </Box>
    );
  };

  const isPastEvent = () => {
    if (!event?.end) return true;
    if (isJoining) return false;
    return new Date(event?.end) < new Date();
  };

  return (
    <Dialog
      isShown={isOpen}
      hasHeader={false}
      hasFooter={false}
      header={ModalHeader}
      containerProps={{ background: "#F2F5FA" }}
      width={400}
      onCloseComplete={onClose}
    >
      <Pane>
        <ModalInfoRow
          label={t("Invitati")}
          placeholder=""
          element={event?.title}
        />
        <ModalInfoRow
          label={t("Tipo di appuntamento")}
          placeholder=""
          element={serviceType}
          marginTop={32}
        />
        <ToggleInput
          marginTop={32}
          title={t("Appuntamento online")}
          checked={!!event?.online}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onChange={(checked) => {}}
        />
        <ModalInfoRow
          label={t("Data")}
          placeholder=""
          element={
            event?.start
              ? format(new Date(event.start), "EEEE d MMMM yyyy")
              : null
          }
          marginTop={32}
          iconAfter={<GreenCalendarIcon />}
        />
        <ModalInfoRow
          label={t("Ora")}
          placeholder=""
          element={event?.start ? format(new Date(event.start), "HH:mm") : null}
          marginTop={32}
          iconAfter={<GreenCalendarIcon />}
        />
        <ModalInfoRow
          label={t("NOTE")}
          placeholder=""
          element={event?.note}
          marginTop={32}
        />
        <Pane
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginTop={16}
        >
          {!isPastEvent() && event && (
            <>
              <Box>
                <IconButton
                  variant="ghost"
                  background="#FFFFFF"
                  borderRadius="12px"
                  colorScheme="gray"
                  aria-label="edit"
                  icon={<EditGrayIcon />}
                  mr="16px"
                  onClick={() => editEvent(event)}
                />
                <IconButton
                  variant="ghost"
                  background="#FFFFFF"
                  borderRadius="12px"
                  colorScheme="gray"
                  aria-label="bucket"
                  icon={<BucketIcon />}
                  onClick={() => deleteEvent(event.id)}
                />
              </Box>
              {Boolean(event?.online) ? <Button
                colorScheme="teal"
                variant="solid"
                leftIcon={<VideoWhiteIcon />}
                backgroundColor="brand.500"
                onClick={joinHandler}
                isLoading={isJoining}
              >
                {t("Partecipa")}
              </Button> : null}
            </>
          )}
        </Pane>
      </Pane>
    </Dialog>
  );
};

export default forwardRef(ViewAppointmentModal);
