import { SVGProps } from "react"

const EditIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.4 7.828 14.227 5l4.95 4.95-2.829 2.828m-4.95-4.95-5.606 5.607a1 1 0 0 0-.293.707v4.536h4.536a1 1 0 0 0 .707-.293l5.606-5.607m-4.95-4.95 4.95 4.95"
      stroke="#696969"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EditIcon;
