import { gql } from "@apollo/client";

export const GET_QUESTIONNAIRES_BY_AGENCY = gql`
  query getQuestionnairesByAgency {
    getQuestionnairesByAgency {
      id
      name
      description
      shortName
      relatedTo
      icon
      landBotUrl
      patientQuestionnaire {
        id
        status
      }
    }
  }
`;
