import { SVGProps } from "react";

const ServicesIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.333.666H2c-.737 0-1.333.597-1.333 1.333v5.334c0 .736.596 1.333 1.333 1.333h5.333c.737 0 1.333-.597 1.333-1.333V1.999c0-.736-.596-1.333-1.333-1.333ZM18 .666h-5.334c-.736 0-1.333.597-1.333 1.333v5.334c0 .736.597 1.333 1.333 1.333H18c.736 0 1.333-.597 1.333-1.333V1.999c0-.736-.597-1.333-1.333-1.333ZM18 11.333h-5.334c-.736 0-1.333.597-1.333 1.333v5.333c0 .737.597 1.334 1.333 1.334H18c.736 0 1.333-.597 1.333-1.334v-5.333c0-.736-.597-1.333-1.333-1.333ZM7.333 11.333H2c-.737 0-1.333.597-1.333 1.333v5.333c0 .737.596 1.334 1.333 1.334h5.333c.737 0 1.333-.597 1.333-1.334v-5.333c0-.736-.596-1.333-1.333-1.333Z"
      stroke={props.color ?? "#096b72"}
      strokeWidth={1.2}
    />
  </svg>
);

export default ServicesIcon;
