import { createContext, useContext } from 'react';

interface IAuthContextData {
  isAuthenticated: boolean;
  signIn: (username: string, password: string) => Promise<void> | null;
  signUp: (username: string, password: string) => Promise<void> | null;
  confirmSignUp: (username: string, code: string) => Promise<void> | null;
  forgotPassword: (username: string) => Promise<void> | null;
  forgotPasswordSubmit: (username: string, code: string, newPassword: string) => Promise<void> | null;
  resendConfirmationCode: (username: string) => Promise<void> | null;
  changePassword: (currPassword: string, newPassword: string) => Promise<void> | null;
  checkIsAuthenticated: () => Promise<void> | null;
  signOut: () => Promise<void> | null;
  inProgress: boolean;
  error: Error | null;
}

export const AuthContext = createContext<IAuthContextData>({
  isAuthenticated: false,
  signIn: () => null,
  signOut: () => null,
  signUp: () => null,
  confirmSignUp: () => null,
  resendConfirmationCode: () => null,
  forgotPassword: () => null,
  forgotPasswordSubmit: () => null,
  checkIsAuthenticated: () => null,
  changePassword: () => null,
  inProgress: false,
  error: null,
});

export const useAuthContext = () => useContext(AuthContext);
