import {
  Box,
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "evergreen-ui";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

type QuestionnaireDeliveryChoiceProps = {
  choices: string[];
  onChoose: (choice: string) => void;
  selectedChoice?: string;
  choicePlaceholder: string;
};

const QuestionnaireDeliveryChoice: React.FC<QuestionnaireDeliveryChoiceProps> =
  React.memo(({ choices, onChoose, selectedChoice, choicePlaceholder }) => {
    const [isOpen, setIsOpen] = useState(false);

    const { t, i18n } = useTranslation();
    return (
      <Popover onClose={() => setIsOpen(false)} isOpen={isOpen}>
        <PopoverTrigger>
          <Button
            backgroundColor="white"
            height="44px"
            rightIcon={<ChevronDownIcon color="#667085" />}
            width="100%"
            border="1px"
            borderRadius="10px"
            borderColor="gray.200"
            onClick={() => setIsOpen(true)}
            justifyContent={"start"}
          >
            <Text
              fontWeight="500"
              fontSize={16}
              display="flex"
              marginRight="1"
              color={"brand.800"}
            >
              {selectedChoice ?? choicePlaceholder}
            </Text>
          </Button>
        </PopoverTrigger>
        <PopoverContent
          border="1px"
          borderRadius="14px"
          overflow="scroll"
          style={{
            borderColor: "#E2E8F0",
          }}
        >
          <PopoverBody>
            {choices.map((filter: any, index: number) => {
              return (
                <Button
                  margin={2}
                  fontWeight="500"
                  fontSize="15px"
                  fontFamily="Nunito"
                  key={index}
                  onClick={() => {
                    onChoose(filter);
                    setIsOpen(false);
                  }}
                >
                  {t(filter)}
                </Button>
              );
            })}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  });

export { QuestionnaireDeliveryChoice };
