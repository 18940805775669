import { gql } from '@apollo/client';

export const GET_APPOINTMENTS = gql`
  query getAppointments {
    appointments {
      id
      startDateTime
      endDateTime
      attendees {
        user {
          id
          email
          displayName
        }
      }
      serviceId
      meetingId
      online
      note
      patient {
        id
        name
        surname
      }
      status
      roomId
      roomName
      roomUrl
    }
  }
`;
