import { SVGProps } from 'react'

export const MutedMicrophoneIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m3 3 18 18M9 9a5 5 0 0 0 5 5v0m1-3.5V5a3 3 0 0 0-6 0v.5"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 10v1a7 7 0 1 0 14 0v-1m-7 8v4m0 0H9m3 0h3"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
