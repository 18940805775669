import {
  Box,
  Button,
  Flex,
  InputGroup,
  Input,
  InputLeftElement,
} from '@chakra-ui/react';
import TitleSmall from '../../../../components/TitleSmall';
import { SearchIcon } from 'evergreen-ui';

import { colors } from '../../../../colors';
import { PlusIcon } from 'evergreen-ui';
import { useTranslation } from 'react-i18next';

const ClinicalHistory = () => {
  const { t, i18n } = useTranslation();
  return (
    <Box p="24px" borderRadius="8px" bg="#FCFCFC">
      <Flex
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex alignItems="center">
          <TitleSmall
            text={t("Storia clinica")}
            color={colors[100]}
            style={{ marginRight: 24 }}
          />
          <Flex>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<SearchIcon color="gray" />}
              />
              <Input
                width="360px"
                placeholder={t("Cerca per data, reparto, nome ecc...")}
                // value={globalFilter ?? ''}
                background="brand.400 !important"
                // onChange={(e: ChangeEvent<HTMLInputElement>) =>
                //   setGlobalFilter(e.target.value)
                // }
              />
            </InputGroup>
          </Flex>
        </Flex>
        <Button
          colorScheme="teal"
          variant="solid"
          //   onClick={() => setIsAddAppointment(true)}
          leftIcon={<PlusIcon />}
          height="48px"
          backgroundColor="brand.500"
        >
          {t('Aggiungi appuntamento')}
        </Button>
      </Flex>
      
      
      <Row />
    </Box>
  );
};

const Row = () => {
  
  const { t, i18n } = useTranslation();
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      pt="24px"
      pb="24px"
    >
      <Box fontWeight="bold" p="10px" bg="brand.200" borderRadius="8px">
        {t('Follow Up')}
      </Box>

      <Box>
        <Box fontWeight="semibold" pb="10px">{t('Clinica')} "La clinica"</Box>
        <Box>{t('Cardiologia')}</Box>
      </Box>

      <Box>
        <Box fontWeight="bold" pb="10px">{t('Controllo')}</Box>
        <Box>Mauris quis pellentesque blandit pulvinar...</Box>
      </Box>

      <Button variant="outline" height="48px">
        {t('Vedi referto')}
      </Button>
    </Flex>
  );
};

export default ClinicalHistory;
