import { useState } from "react";
import * as yup from "yup";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Grid,
  Heading,
  Input,
  VStack,
  Text,
  useToast,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useSearchParams } from "react-router-dom";

import { User } from "../../types/User";
import { text } from "../../utils/text";
import FeaturePoint from "../../components/FeaturePoint";
import DoctorIcon from "../../components/Icons/DoctorIcon";
import { useAuthContext } from "../../providers/AuthProvider/context";
import { useTranslation } from "react-i18next";



const SignIn = () => {
  const [searchParams] = useSearchParams();
  const type = (searchParams.get("type") as User) || "patient";
  const { features } = text[type];
  const { t, i18n } = useTranslation();
  const schema = yup.object({
    email: yup
      .string()
      .email(t("L'indirizzo email inserito non è valido"))
      .required(t("Inserisci la tua email")),
    password: yup
      .string()
      // .min(8, 'La password deve avere almeno 8 caratteri')
      .required(t("Inserisci la tua password")),
  });
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();
  const toast = useToast();

  const [show, setShow] = useState(false);
  const { signIn, inProgress } = useAuthContext();

  const handleClick = () => setShow(!show);

  const onSubmit = async ({ email, password }: any) => {
    try {
      await signIn(email, password);
      navigate("/patients");
    } catch (error) {
      toast({
        title: t("Username o password errati."),
        status: "error",
        isClosable: true,
      });
    }
  };

  return (
    <Grid height="100vh" gridAutoColumns="1fr" gridAutoFlow="column">
      <Box backgroundColor="brand.600">
        <Flex alignItems="center" justifyContent="space-between" p="24px">
          <span>{t("Logo")}</span>
          <Flex gap="10px" alignItems="center">
            <Text>{t("Non sei ancora registrato?")}</Text>
            <Button
              variant="link"
              colorScheme="teal"
              onClick={() => navigate("/signup")}
            >
              {t("Sign Up")}
            </Button>
          </Flex>
        </Flex>
        <Flex
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          mt="25%"
        >
          <Box>
            <Heading as="h1" size="2xl">
              {t("Accedi")}
            </Heading>
            <form onSubmit={handleSubmit(onSubmit)}>
              <VStack alignItems="flex-start" spacing="20px" mt="32px">
                <FormControl isInvalid={!!errors.email}>
                  <Input
                    size="lg"
                    id="email"
                    type="email"
                    placeholder={t("Email")}
                    {...register("email")}
                    w="327px"
                    boxShadow="0px 1px 24px rgba(9, 107, 114, 0.08)"
                  />
                  <FormErrorMessage>
                    {errors.email && errors.email.message}
                  </FormErrorMessage>
                </FormControl>

                <Box>
                  <FormControl isInvalid={!!errors.password}>
                    <InputGroup size="lg">
                      <Input
                        size="lg"
                        id="password"
                        type={show ? "text" : "password"}
                        placeholder={t("Password")}
                        autoComplete="current-password"
                        {...register("password")}
                        w="327px"
                        boxShadow="0px 1px 24px rgba(9, 107, 114, 0.08)"
                      />
                      <InputRightElement width="6rem">
                        <Button
                          size="xs"
                          onClick={handleClick}
                          borderRadius="5px"
                          mr="1.6rem"
                          height="30px"
                        >
                          {show ? t("Nascondi") : t("Mostra")}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>
                      {errors.password && errors.password.message}
                    </FormErrorMessage>
                  </FormControl>

                  <Button
                    mt="5px"
                    variant="link"
                    colorScheme="teal"
                    onClick={() => navigate("/resetpassword")}
                  >
                    {t("Non ricordi la password? Impostane una nuova")}
                  </Button>
                </Box>
                <Button
                  size="lg"
                  type="submit"
                  variant="solid"
                  colorScheme="teal"
                  backgroundColor="brand.500"
                  isLoading={inProgress}
                >
                  {t("Accedi")}
                </Button>
              </VStack>
            </form>
          </Box>
        </Flex>
      </Box>

      <Flex alignItems="center" flexDirection="column" justifyContent="center">
        <DoctorIcon />
        <Heading as="h2" size="lg" mt="32px" mb="24px">
          {t("Cos'è Easydoctor")}
        </Heading>
        <VStack spacing="20px" alignItems="flex-start" ml={20} mr={20}>
          {features.map((x, idx) => (
            <FeaturePoint key={idx} text={t(x)} />
          ))}
        </VStack>
      </Flex>
    </Grid>
  );
};

export default SignIn;
