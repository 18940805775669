import { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import { useQuery } from "@apollo/client";
import * as yup from "yup";
import format from "date-fns/format";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Textarea,
  VStack,
  Spinner,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TitleSmall from "../../TitleSmall";
import { IModal } from "../../../interfaces/IModal";
import { colors } from "../../../colors";
import { emailRegex, phoneRegex } from "../../../regex";
import Label from "../../Label";
import FullCalendar from "@fullcalendar/react";
import itLocale from "@fullcalendar/core/locales/it";
import enLocale from "@fullcalendar/core/locales/en-gb";
import arLocale from "@fullcalendar/core/locales/ar-sa";
import frLocale from "@fullcalendar/core/locales/fr";
import deLocale from "@fullcalendar/core/locales/de";
import esLocale from "@fullcalendar/core/locales/es";
import ptLocale from "@fullcalendar/core/locales/pt";


import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { GET_AVAILABILITY } from "../../../graphql/queries/availability.query";
import GreenTagInput from "../../Inputs/GreenTagInput";
import { IDoctor } from "../../../interfaces/IDoctor";
import "./index.scss";
import { useTranslation } from "react-i18next";


interface ISlot {
  dow: number[];
  start: string;
  end: string;
}

const initialState: {
  slots: ISlot[];
  tags: string[];
  services: any;
} = {
  slots: [],
  tags: [],
  services: {
    cardiology: false,
    electrogramma: false,
    holter: false,
    stressTest: false,
    tilting: false,
    ecocardiogramma: false,
    pacemaker: false,
    myocardialScintigraphy: false,
  },
};

const EditDoctorModal = forwardRef(
  (
    { isOpen, onClose, loading, onSave, doctor }: IModal & { doctor: IDoctor },
    ref
  ) => {
    const doctorId = doctor?.id;
    const [calendarLanguage, setCalendarLanguage] = useState(enLocale);
    const [state, setState] = useState(initialState);

    const { t, i18n } = useTranslation();
    const schema = yup.object({
      name: yup.string().required(t("Inserisci il tuo nome")),
      specializations: yup
        .string()
        .required(t("Inserisci la tua specializzazione")), // specializations Array<string>
      phone: yup
        .string()
        .transform((value) => (!value ? null : value))
        .matches(phoneRegex, t("Il numero di telefono non è valido"))
        .nullable(),
      email: yup
        .string()
        .transform((value) => (!value ? null : value))
        .email(t("L'email non è valida"))
        .matches(emailRegex, t("L'email non è valida"))
        .nullable(),
      note: yup.string(),
    });
    const languageToLocaleMap: any = {
      it: itLocale,
      ar: arLocale,
      fr: frLocale,
      de: deLocale,
      pt: ptLocale,
      es: esLocale,
    };
    useEffect(() => {
      const defaultLocale = enLocale;
      const selectedLocale = languageToLocaleMap[i18n.language] || defaultLocale;
      setCalendarLanguage(selectedLocale);
    }, [i18n.language]);
    const { loading: availabilityLoading } = useQuery(GET_AVAILABILITY, {
      skip: !doctorId,
      variables: { doctorId },
      onCompleted(data) {
        const slots = data?.availability?.slots.map(
          ({ dow, start, end }: ISlot) => ({
            dow,
            start,
            end,
          })
        );
        setState((prevState) => ({ ...prevState, slots }));
      },
    });

    const {
      handleSubmit,
      register,
      reset,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(schema),
    });

    useImperativeHandle(ref, () => ({
      resetForm: () => {
        reset();
        setState((prevState) => ({ ...prevState, slots: [], tags: [] }));
      },
    }));

    useEffect(() => {
      if (doctor) {
        reset({
          name: doctor.name,
          specializations: doctor.specializations?.length
            ? doctor.specializations[0].name
            : "",
          phone: doctor.phone,
          email: doctor.email,
          note: doctor.note,
        });
      }

      if (doctor?.tags) {
        setState((prevState) => ({ ...prevState, tags: doctor.tags }));
      }
    }, [doctor, reset]);

    const onSubmit = (data: any) => {
      onSave({ ...data, tags: state.tags, slots: state.slots });
    };

    const handleDateClick = async ({ date }: any) => {
      const slot = {
        dow: [date.getDay()],
        start: format(date, "HH:mm"),
        end: format(new Date(date.setHours(date.getHours() + 1)), "HH:mm"),
      };

      const slotsAvailability = state.slots.some(
        (s: ISlot) => s.dow[0] === slot.dow[0] && s.start === slot.start
      )
        ? (state.slots.filter(
            (s: ISlot) => !(s.dow[0] === slot.dow[0] && s.start === slot.start)
          ) as any)
        : [...state.slots, slot];

      setState((prevState) => ({ ...prevState, slots: slotsAvailability }));
    };

    if (loading) {
      return (
        <Flex alignItems="center" justifyContent="center" height="100%">
          <Spinner size="xl" color="brand.500" thickness="4px" speed="0.65s" />
        </Flex>
      );
    }

    return (
      <Modal
        size="2xl"
        isOpen={isOpen}
        isCentered={true}
        onClose={onClose}
        scrollBehavior="inside"
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent backgroundColor="brand.600">
          <ModalHeader pt="24px">
            <TitleSmall
              text={t("Modifica dettagli dottore")}
              color={colors[200]}
            />
          </ModalHeader>
          <ModalCloseButton mt="8px" mr="8px" borderRadius="23px" />

          <ModalBody pb={6} mt="16px">
            <form id="doctor-form" onSubmit={handleSubmit(onSubmit)}>
              <VStack spacing="32px" alignItems="unset">
                <FormControl isInvalid={!!errors.name}>
                  <FormLabel htmlFor="name" fontSize="14px">
                    {t("Nome")}*
                  </FormLabel>
                  <Input
                    id="name"
                    placeholder={t("Nome")}
                    {...register("name")}
                    variant="solid"
                  />
                  <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.specializations}>
                  <FormLabel htmlFor="specializations" fontSize="14px">
                    {t("Specializzazione")}*
                  </FormLabel>
                  <Input
                    id="specializations"
                    placeholder={t("Specializzazione")}
                    {...register("specializations")}
                    variant="solid"
                  />
                  <FormErrorMessage>
                    {errors.specializations?.message}
                  </FormErrorMessage>
                </FormControl>
                {/* FIXME: Tags to save */}
                <GreenTagInput
                  maxTags={12}
                  label={t("Tags")}
                  values={state.tags}
                  placeholder={t(
                    "Inserisci i tag per poter cercare il dotore per parole chiave"
                  )}
                  onChange={(values) =>
                    setState((prevState) => ({ ...prevState, tags: values }))
                  }
                />
                <Flex justifyContent="space-between" alignItems="flex-end">
                  <FormControl mr="16px" isInvalid={!!errors.phone}>
                    <FormLabel htmlFor="phone" fontSize="14px">
                      {t("Informazioni di contatto")}*
                    </FormLabel>
                    <Input
                      id="phone"
                      placeholder={t("Cellulare del referente")}
                      {...register("phone")}
                      variant="solid"
                    />
                    <FormErrorMessage>
                      {errors.phone?.message || null}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!errors.email}>
                    <Input
                      id="email"
                      placeholder={t("Email del referente")}
                      {...register("email")}
                      variant="solid"
                      readOnly={true}
                    />
                    <FormErrorMessage>
                      {errors.email?.message || null}
                    </FormErrorMessage>
                  </FormControl>
                </Flex>
                <Box>
                  <FormLabel htmlFor="name" fontSize="14px">
                    {t("Servizi svolti")}
                  </FormLabel>

                  <SimpleGrid columns={[2, null, 3]} spacing="20px">
                    {/* FIXME: checkboxes can be reusable components. -> AddDoctorModal */}
                    <Checkbox
                      colorScheme="teal"
                      defaultChecked
                      onChange={(e) =>
                        setState({
                          ...state,
                          services: {
                            ...state.services,
                            cardiology: !state.services.cardiology,
                          },
                        })
                      }
                    >
                      {t("Visita Cardiologica")}
                    </Checkbox>
                    <Checkbox
                      colorScheme="teal"
                      defaultChecked
                      onChange={(e) =>
                        setState({
                          ...state,
                          services: {
                            ...state.services,
                            stressTest: !state.services.stressTest,
                          },
                        })
                      }
                    >
                      {t("Prove da sforzo")}
                    </Checkbox>
                    <Checkbox
                      colorScheme="teal"
                      defaultChecked
                      onChange={(e) =>
                        setState({
                          ...state,
                          services: {
                            ...state.services,
                            electrogramma: !state.services.electrogramma,
                          },
                        })
                      }
                    >
                      {t("Controlli pace-maker")}
                    </Checkbox>
                    <Checkbox
                      colorScheme="teal"
                      defaultChecked
                      onChange={(e) =>
                        setState({
                          ...state,
                          services: {
                            ...state.services,
                            pacemaker: !state.services.pacemaker,
                          },
                        })
                      }
                    >
                      {t("Elettrocardiogramma")}
                    </Checkbox>
                    <Checkbox
                      colorScheme="teal"
                      defaultChecked
                      onChange={(e) =>
                        setState({
                          ...state,
                          services: {
                            ...state.services,
                            tilting: !state.services.tilting,
                          },
                        })
                      }
                    >
                      {t("Tilting test")}
                    </Checkbox>
                    <Checkbox
                      colorScheme="teal"
                      defaultChecked
                      onChange={(e) =>
                        setState({
                          ...state,
                          services: {
                            ...state.services,
                            myocardialScintigraphy:
                              !state.services.myocardialScintigraphy,
                          },
                        })
                      }
                    >
                      {t("Scintigrafie miocardiche")}
                    </Checkbox>
                    <Checkbox
                      colorScheme="teal"
                      defaultChecked
                      onChange={(e) =>
                        setState({
                          ...state,
                          services: {
                            ...state.services,
                            holter: !state.services.holter,
                          },
                        })
                      }
                    >
                      {t("Holter")}
                    </Checkbox>
                    <Checkbox
                      colorScheme="teal"
                      defaultChecked
                      onChange={(e) =>
                        setState({
                          ...state,
                          services: {
                            ...state.services,
                            ecocardiogramma: !state.services.ecocardiogramma,
                          },
                        })
                      }
                    >
                      {t("Ecocardiogramma")}
                    </Checkbox>
                  </SimpleGrid>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box>
                    <Label
                      label={t("Disponibilità generali")}
                      style={{ marginTop: "32px" }}
                    />
                    <Label
                      className="label__gray"
                      style={{ marginTop: "12px" }}
                      label={t(
                        "Fai tap su uno slot per switchare da disponibile a non disponbile"
                      )}
                    />
                  </Box>
                  <Box display="flex">
                    <Box
                      display="flex"
                      alignItems="center"
                      h="28px"
                      p="8px"
                      backgroundColor="#F4F4F4"
                      borderRadius="8px"
                      fontSize="12px"
                      mr="4px"
                    >
                      <Box
                        w="12px"
                        h="12px"
                        background="#FFFFFF"
                        borderRadius="4px"
                      />
                      &nbsp;= {t("Disponibile")}
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      h="28px"
                      p="8px"
                      borderRadius="8px"
                      fontSize="12px"
                    >
                      <Box
                        w="12px"
                        h="12px"
                        background="#F4F4F4"
                        borderRadius="4px"
                      />
                      &nbsp;= {t("Non disponibile")}
                    </Box>
                  </Box>
                </Box>
                {availabilityLoading ? (
                  <Flex alignItems="center" justifyContent="center">
                    <Spinner
                      size="xl"
                      color="brand.500"
                      thickness="4px"
                      speed="0.65s"
                    />
                  </Flex>
                ) : (
                  <FullCalendar
                    height="auto"
                    events={[]}
                    selectable={false}
                    selectMirror={true}
                    selectOverlap={false}
                    locale={calendarLanguage}
                    contentHeight={600}
                    plugins={[timeGridPlugin, interactionPlugin]}
                    initialView="timeGridWeek"
                    businessHours={[
                      ...(state.slots.length
                        ? state.slots
                        : [
                            {
                              dow: [1, 2, 3, 4, 5],
                              start: "23:00",
                              end: "23:00",
                            },
                          ]
                      ).map(
                        (slot: {
                          dow: number[];
                          start: string;
                          end: string;
                        }) => ({
                          daysOfWeek: slot.dow,
                          startTime: slot.start,
                          endTime: slot.end,
                        })
                      ),
                    ]}
                    hiddenDays={[0, 6]}
                    slotMinTime={"08:00:00"}
                    slotMaxTime={"21:00:00"}
                    selectConstraint="businessHours"
                    eventConstraint="businessHours"
                    headerToolbar={false}
                    buttonText={{
                      day: t("Giorno"),
                      week: t("Settimana"),
                      month: t("Mese"),
                    }}
                    slotDuration="01:00"
                    slotLabelFormat={[
                      {
                        hour: "2-digit",
                        minute: "2-digit",
                      },
                    ]}
                    datesSet={(arg) => {
                      // setState({
                      //   from: arg.start.toISOString().substring(0, 10),
                      //   to: arg.end.toISOString().substring(0, 10),
                      // });
                    }}
                    dayHeaderFormat={{ weekday: "short" }}
                    allDaySlot={false}
                    dateClick={handleDateClick}
                    select={(info) => {
                      // setIsAddAppointment(true);
                      console.log(info);
                    }}
                  />
                )}
                <FormControl mt="32px">
                  <FormLabel htmlFor="note" fontSize="14px">
                    {t("Note")}
                  </FormLabel>
                  <Textarea
                    id="note"
                    resize="vertical"
                    backgroundColor="white"
                    border="none"
                    {...register("note")}
                  />
                </FormControl>
              </VStack>
            </form>
          </ModalBody>
          <ModalFooter pb="24px" justifyContent="space-between">
            <Button
              colorScheme="gray"
              variant="outline"
              onClick={onClose}
              backgroundColor="white"
              disabled={loading}
            >
              {t("Annulla")}
            </Button>
            <Button
              type="submit"
              form="doctor-form"
              colorScheme="teal"
              variant="solid"
              backgroundColor="brand.500"
              isLoading={loading}
            >
              {t("Salva")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
);

export default EditDoctorModal;
