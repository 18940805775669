import { gql } from "@apollo/client";

export const DUPLICATE_PATH  = gql`
  mutation duplicatePath($id: ID!) {
    duplicatePath(id: $id) {
    id
    name
    status
    description
    agency_id
    category {
      id
      name
    }
    questionnaires {
      id
    }
    }
  }
`;