import React from "react";
import {
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";
import {
  Avatar,
  Button,
  Flex,
  Text,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Divider,
  IconButton,
} from "@chakra-ui/react";
import { MoreIcon, ExpandAllIcon, ArrowDownIcon } from "evergreen-ui";
import ServicesPathTable from "./ServicesPathTable";
import { IServiceHealth } from "src/interfaces/IServiceHealth";

import { useMemo } from "react";
import { useTranslation } from "react-i18next";

type ServicesPathTableProps = {
  data: IServiceHealth[];
  searchText?: string;
};

export const ServicesPathList: React.FC<ServicesPathTableProps> = ({
  data,
  searchText,
}) => {
  const { t, i18n } = useTranslation();
  const columnsDef: ColumnDef<IServiceHealth>[] = useMemo(
    () => [
      {
        id: "title",
        header: () => (
          <Text fontSize="16px">
            <Flex gap="6px">
              {t("Nome")} <ArrowDownIcon />
            </Flex>
          </Text>
        ),
        accessorFn: (row) => row.description,
        cell: ({ row, getValue }) => {
          const description = row.original.description;

          return (
            <Flex alignItems="center" gap="10px">
              <Avatar name={description} />
              <Button
                variant="link"
                colorScheme="teal"
                fontSize="15px"
                fontWeight="bold"
                color="brand.700"
                whiteSpace="break-spaces"
                textAlign="start"
              >
                {description}
              </Button>
            </Flex>
          );
        },
      },
      {
        header: () => (
          <Text fontSize="16px">
            <Flex gap="6px">
              {t("Listino")} <ExpandAllIcon />
            </Flex>
          </Text>
        ),
        id: "priceList",
        accessorFn: (row) => row.priceList,
        cell: ({ row, getValue }) => {
          const priceList = row.original.priceList;
          return (
            <Flex gap="10px" alignItems="center">
              <Text
                colorScheme="teal"
                fontSize="15px"
                fontWeight="500"
                color={"GrayText"}
                whiteSpace="break-spaces"
                textAlign="start"
              >
                {priceList}
              </Text>
            </Flex>
          );
        },
      },
      {
        header: () => (
          <Text fontSize="16px">
            <Flex gap="6px">
              {t("Sigla")} <ExpandAllIcon />
            </Flex>
          </Text>
        ),
        id: "abbr",
        accessorFn: (row) => row.acronym,
        cell: ({ row }) => {
          const acronym = row.original.acronym;
          return (
            <Flex gap="10px" alignItems="center">
              <Text
                colorScheme="teal"
                fontSize="15px"
                fontWeight="500"
                color={"GrayText"}
                whiteSpace="break-spaces"
                textAlign="start"
              >
                {acronym}
              </Text>
            </Flex>
          );
        },
      },
      {
        header: () => (
          <Text fontSize="16px">
            <Flex gap="6px">
              {t("Genere")} <ExpandAllIcon />
            </Flex>
          </Text>
        ),
        id: "type",
        accessorFn: (row) => row.type,
        cell: ({ row }) => {
          const type = row.original.type;
          return (
            <Flex gap="10px" alignItems="center">
              <Text
                colorScheme="teal"
                fontSize="15px"
                fontWeight="500"
                color={"GrayText"}
                whiteSpace="break-spaces"
                textAlign="start"
              >
                {type}
              </Text>
            </Flex>
          );
        },
      },
      {
        header: "",
        id: "delete",
        cell: () => {
          return (
            <>
              <Box>
                <Menu>
                  <MenuButton
                    as={IconButton}
                    aria-label="Options"
                    icon={<MoreIcon style={{ transform: "rotate(90deg)" }} />}
                    variant="outline"
                    border="none"
                  />
                  <MenuList p={0} minW="0" w={"130px"} borderRadius="12px">
                    <MenuItem borderRadius="12px" h={"40px"}>
                      <Text
                        colorScheme="teal"
                        fontSize="15px"
                        fontWeight="500"
                        color={"GrayText"}
                        whiteSpace="break-spaces"
                      >
                        {t("Modifica")}
                      </Text>
                    </MenuItem>
                    <Divider color="#EDEFF5" />
                    <MenuItem h={"40px"}>
                      <Text
                        colorScheme="teal"
                        fontSize="15px"
                        fontWeight="500"
                        color={"GrayText"}
                        whiteSpace="break-spaces"
                      >
                        {t("Elimina")}
                      </Text>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Box>
            </>
          );
        },
      },
    ],
    []
  );

  const columns = useMemo(() => columnsDef, [columnsDef]);

  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter: searchText,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  return <ServicesPathTable table={table} />;
};

export default ServicesPathList;
