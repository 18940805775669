import { useState, forwardRef, useImperativeHandle } from "react";
import * as yup from "yup";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TitleSmall from "../../TitleSmall";
import { IModal } from "../../../interfaces/IModal";
import { colors } from "../../../colors";
import "./index.scss";
import { emailRegex } from "../../../regex";
import { useTranslation } from "react-i18next";

const AddClinicModal = forwardRef(
  ({ isOpen, loading, onClose, onSave }: IModal, ref) => {
    const [tags, setTags] = useState<string[]>([]);
    const { t, i18n } = useTranslation();

    const schema = yup.object({
      name: yup.string().required(t("Inserisci il tuo nome")),
      managerSurname: yup.string().required(t("Inserisci il tuo cognome")),
      doctorsQuantity: yup
        .number()
        .required(t("Inserisci il numero dei medici")),
      address: yup.string().required(t("Inserisci l'indirizzo")),
      managerName: yup.string().required(t("Inserisci il tuo nome")),
      email: yup.string().email(t("L'indirizzo email inserito non è valido")),
      pec: yup
        .string()
        .email(t("L'email non è valida"))
        .matches(emailRegex, t("L'email non è valida"))
        .nullable(),
    });
    const {
      handleSubmit,
      register,
      reset,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(schema),
    });

    useImperativeHandle(ref, () => ({
      resetForm: () => {
        reset();
        setTags([]);
      },
    }));

    const onSubmit = (data: any) => {
      onSave(data);
    };

    return (
      <Modal
        size="2xl"
        isOpen={isOpen}
        isCentered={true}
        onClose={onClose}
        scrollBehavior="inside"
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent backgroundColor="brand.600">
          <ModalHeader pt="24px">
            <TitleSmall text={t("Nuova clinica")} color={colors[200]} />
          </ModalHeader>
          <ModalCloseButton mt="8px" mr="8px" borderRadius="23px" />

          <ModalBody pb={6} mt="16px">
            <form id="clinic-form" onSubmit={handleSubmit(onSubmit)}>
              <VStack spacing="32px" alignItems="unset">
                <FormControl isInvalid={!!errors.name}>
                  <FormLabel htmlFor="name" fontSize="14px">
                    {t("Nome ambulatorio o centro medico")}*
                  </FormLabel>
                  <Input
                    id="name"
                    placeholder={t("Nome")}
                    {...register("name")}
                    variant="solid"
                  />
                  <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.doctorsQuantity}>
                  <FormLabel htmlFor="doctorsQuantity" fontSize="14px">
                    {t("Numero medici presenti")}*
                  </FormLabel>
                  <Input
                    id="doctorsQuantity"
                    placeholder={t("Inserisci una quantità")}
                    {...register("doctorsQuantity")}
                    variant="solid"
                  />
                  <FormErrorMessage>
                    {errors.doctorsQuantity?.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.address}>
                  <FormLabel htmlFor="address" fontSize="14px">
                    {t("Indirizzo")}*
                  </FormLabel>
                  <Input
                    id="address"
                    placeholder={t("Inserisci indirizzo")}
                    {...register("address")}
                    variant="solid"
                  />
                  <FormErrorMessage>{errors.address?.message}</FormErrorMessage>
                </FormControl>
                {/* <GreenTagInput
                label="Servizi associati"
                values={tags}
                onChange={(values) => setTags(values)}
              /> */}
                <Flex justifyContent="space-between" alignItems="flex-end">
                  <FormControl mr="16px" isInvalid={!!errors.managerName}>
                    <FormLabel htmlFor="managerName" fontSize="14px">
                      {t("Nome completo del referente")}*
                    </FormLabel>
                    <Input
                      id="managerName"
                      placeholder={t("Nome")}
                      {...register("managerName")}
                      variant="solid"
                    />
                    <FormErrorMessage>
                      {errors.managerName?.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={!!errors.managerSurname}>
                    <Input
                      id="managerSurname"
                      placeholder={t("Cognome")}
                      {...register("managerSurname")}
                      variant="solid"
                    />
                    <FormErrorMessage>
                      {errors.managerSurname?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Flex>
                <Flex justifyContent="space-between" alignItems="flex-end">
                  <FormControl mr="16px" isInvalid={!!errors.email}>
                    <FormLabel htmlFor="email" fontSize="14px">
                      {t("Informazioni di contatto del referente")}*
                    </FormLabel>
                    <Input
                      id="email"
                      placeholder={t("Email del referente")}
                      {...register("email")}
                      variant="solid"
                    />
                    <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={!!errors.pec}>
                    <Input
                      id="pec"
                      placeholder={t("PEC")}
                      {...register("pec")}
                      variant="solid"
                    />
                    <FormErrorMessage>{errors.pec?.message}</FormErrorMessage>
                  </FormControl>
                </Flex>
              </VStack>
            </form>
          </ModalBody>

          <ModalFooter pb="24px" justifyContent="space-between">
            <Button
              colorScheme="gray"
              variant="outline"
              onClick={onClose}
              backgroundColor="white"
              disabled={loading}
            >
              {t("Annulla")}
            </Button>
            <Button
              type="submit"
              form="clinic-form"
              colorScheme="teal"
              variant="solid"
              backgroundColor="brand.500"
              isLoading={loading}
            >
              {t("Aggiungi")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
);

export default AddClinicModal;
