import {
  Button,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
  Divider,
  useDisclosure,
  Box,
} from "@chakra-ui/react";
import { ArrowLeftIcon } from "evergreen-ui";
import React, { useEffect, useState } from "react";
import "./index.scss";
import { Questionnaires } from "./Questionnaires";
import {
  GeneralInformations,
  GeneralInformationsField,
} from "./GeneralInformations";
import { useMutation } from "@apollo/client";
import { DismissModal } from "src/components/Modals/DismissModal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CreateCategoryModal } from "src/components/Modals/CreateCategory";
import { useNavigate } from "react-router-dom";
import { HealthServices } from "./HealthServices";
import { CREATE_PATH } from "src/graphql/mutations/create-path.mutation";
import { PathStatus } from "src/interfaces/IPath";
import IPathQuestionnaire from "src/interfaces/IPathQuestionnaire";
import { GET_PATHS } from "src/graphql/queries/paths.query";
import { useTranslation } from "react-i18next";

const CreateNewPath = React.memo(() => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { t, i18n } = useTranslation();
  const {
    isOpen: isCreateCategoryModalOpen,
    onOpen: onOpenCreateCategoryModal,
    onClose: onCloseCreateCategoryModal,
  } = useDisclosure();
  const navigate = useNavigate();

  const [mainQuestionnaires, setMainQuestionnaires] = useState<
    IPathQuestionnaire[]
  >([]);
  const [mainHealthServices, setMainHealthServices] = useState<number[]>([]);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [createPath, { loading }] = useMutation(CREATE_PATH, {
    refetchQueries: [{ query: GET_PATHS }],
  });

  const handleSavePath = async (data: any) => {
    try {
      await createPath({
        variables: {
          input: {
            name: data.name,
            description: data.description,
            category: data.category,
            status: data.status,
            questionnaires: data.questionnaires,
            medicalServices: data.healthServices,
          },
        },
      });
    } catch (error: any) {
      console.error({
        title: t("Errore durante la creazione del percorso."),
        description: error.message,
        status: "error",
        isClosable: true,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      [GeneralInformationsField.title]: "",
      [GeneralInformationsField.description]: "",
      [GeneralInformationsField.status]: PathStatus.ARCHIVED,
    },
    validationSchema: Yup.object({
      [GeneralInformationsField.title]: Yup.string().required(
        t("Aggiungi un titolo")
      ),
      [GeneralInformationsField.description]: Yup.string()
        .max(40, t("Sono ammessi al più 40 caratteri"))
        .required(t("Aggiungi una descrizione")),
    }),
    validateOnChange: true,
    onSubmit: (values, actions) => {
      console.log({
        values,
      });
    },
  });

  const PATH_TABS = [
    {
      label: t("Informazioni generali"),
      component: (
        <GeneralInformations
          formik={formik}
          onCreateCategory={onOpenCreateCategoryModal}
          showAlert={showAlert} setShowAlert={setShowAlert}
        />
      ),
    },
    {
      label: t("Questionari"),
      component: (
        <Questionnaires setMainQuestionnaires={setMainQuestionnaires} />
      ),
    },
    {
      label: t("Prestazioni"),
      component: (
        <HealthServices setMainHealthServices={setMainHealthServices} />
      ),
    },
  ];

  return (
    <div className="main-container">
      <Box ml="16px" mt="30px" mb="32px">
        <Button
          variant="link"
          backgroundColor="transparent"
          color="brand.500"
          fontSize="16px"
          leftIcon={<ArrowLeftIcon />}
          onClick={() => {
            navigate("/paths");
          }}
        >
          <Text>{t("Ritorna a percorsi")}</Text>
        </Button>
      </Box>

      <div className="container">
        <Text fontSize="24px" fontWeight="bold" color="brand.700">
          {t("Crea un nuovo percorso")}
        </Text>
        <Text fontSize="16px" color="brand.800" marginTop="5">
          {t(
            "Compila i campi e inserisci tutte le informazioni necessarie per creare il tuo nuovo percorso."
          )}
        </Text>
        <form onSubmit={formik.handleSubmit}>
          <Tabs marginTop="24px" variant="unstyled" position="relative">
            <TabList>
              {PATH_TABS.map((tab, index) => (
                <Tab
                  key={index}
                  _selected={{
                    color: "brand.500",
                    bg: "#f1f6f7",
                    fontWeight: "bold",
                  }}
                  height={"48px"}
                  minWidth={"200px"}
                  color="GrayText"
                >
                  {tab.label}
                </Tab>
              ))}
            </TabList>
            <Divider
              mt="-1px"
              height="0.5px"
              borderRadius="1px"
              backgroundColor="#D9DFE9"
              color="#D9DFE9"
            />
            <TabIndicator
              mt="-1.5px"
              height="2px"
              bg="brand.500"
              borderRadius="1px"
            />
            <TabPanels>
              {PATH_TABS.map((tab, index) => (
                <TabPanel key={index}>{tab.component}</TabPanel>
              ))}
            </TabPanels>
          </Tabs>
          <Divider
            mt="24px"
            mb="24px"
            height="1.5px"
            borderRadius="1px"
            backgroundColor="#D9DFE9"
            color="#D9DFE9"
          />
          <div
            style={{
              justifyContent: "space-between",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Button
              backgroundColor="transparent"
              color="#667085"
              fontSize="16px"
              onClick={onOpen}
            >
              <Text> {t("Annulla")}</Text>
            </Button>

            <div>
              <Button
                colorScheme="gray"
                variant="outline"
                backgroundColor="white"
                minWidth="188px"
                height="48px"
                marginRight="16px"
                onClick={async () => {
                  await handleSavePath({
                    name: formik.values[GeneralInformationsField.title],
                    description:
                      formik.values[GeneralInformationsField.description],
                    status: PathStatus.DRAFT,
                    category: GeneralInformationsField.category,
                    questionnaires: mainQuestionnaires,
                    medicalServices: mainHealthServices,
                  });
                  navigate("/paths");
                }}
              >
                {t("Salva come bozza")}
              </Button>
              <Button
                colorScheme="teal"
                variant="solid"
                width="188px"
                height="48px"
                backgroundColor="brand.500"
                type="submit"
                onClick={async () => {
                  if (
                    typeof GeneralInformationsField.category == "number" &&
                    GeneralInformationsField.category >= 0
                  ) {
                    await handleSavePath({
                      name: formik.values[GeneralInformationsField.title],
                      description:
                        formik.values[GeneralInformationsField.description],
                      status: formik.values[GeneralInformationsField.status],
                      category: GeneralInformationsField.category,
                      questionnaires: mainQuestionnaires,
                      medicalServices: mainHealthServices,
                    });
                    navigate("/paths");
                  } else {
                    setShowAlert(true)
                  }
                }}
              >
                {t("Pubblica percorso")}
              </Button>
            </div>
          </div>
        </form>
      </div>

      <CreateCategoryModal
        onClose={onCloseCreateCategoryModal}
        isOpen={isCreateCategoryModalOpen}
      />
      <DismissModal onClose={onClose} isOpen={isOpen} />
    </div>
  );
});

export { CreateNewPath };
