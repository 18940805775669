import React, { SetStateAction, useCallback, useState } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "evergreen-ui";
import { useQuery } from "@apollo/client";
import { GET_SERVICES_CATEGORIES_BYANGECY } from "src/graphql/queries/getMedicalServiceCategoriesByAgency.query";
import { useTranslation } from "react-i18next";

enum ServicesFiltersType {
  priceList = "Listino",
  acronym = "Sigla",
  type = "Genere",
}

type ServiceFilters = {
  name: string;
  checked: boolean;
};

type ServicesFilterProps = {
  filteredItems: any[];
  setFilteredItems: React.Dispatch<SetStateAction<any[]>>;
  onToggleServicesFilter: (filters: any[]) => void;
};

const PathServicesFilter: React.FC<ServicesFilterProps> = React.memo(
  ({ filteredItems, setFilteredItems, onToggleServicesFilter }) => {
    const [currentFilter, setCurrentFilter] = useState<any[]>(filteredItems);
    const [isOpen, setIsOpen] = useState(false);
    const [isChecked, setIsChecked] = useState<boolean>(true);

    const { t, i18n } = useTranslation();
    const { data, loading } = useQuery(GET_SERVICES_CATEGORIES_BYANGECY);

    const toggleFilter = useCallback(
      (filter: any) => {
        if (isChecked === true) {
          const newFilter = currentFilter?.filter(
            (f) =>
              f.acronym.toLowerCase() === filter?.toLowerCase() ||
              f.priceList.toLowerCase() === filter?.toLowerCase() ||
              f.type.toLowerCase() === filter?.toLowerCase()
          );
          setFilteredItems(newFilter);
        }
        if (isChecked === false) {
          const newFilter = currentFilter;
          setFilteredItems(newFilter);
        }
      },
      [isChecked]
    );
    return (
      <Popover onClose={() => setIsOpen(false)} isOpen={isOpen}>
        <PopoverTrigger>
          <Button
            backgroundColor="white"
            height="44px"
            rightIcon={<ChevronDownIcon />}
            width="100%"
            marginLeft="4"
            border="2px"
            borderRadius="10px"
            borderColor="gray.200"
            onClick={() => setIsOpen(true)}
          >
            <Text display="flex" marginRight="1">
              {t("Filtra per")}
            </Text>
          </Button>
        </PopoverTrigger>
        <PopoverContent
          border="2px"
          borderRadius="14px"
          maxH="fit-content"
          style={{
            borderColor: "#E2E8F0",
          }}
        >
          <PopoverBody>
            <Accordion allowToggle>
              {Object.entries(ServicesFiltersType).map(([key, values]) => {
                return (
                  <AccordionItem>
                    <h2>
                      {" "}
                      <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                          {
                            ServicesFiltersType[
                              key as keyof typeof ServicesFiltersType
                            ]
                          }
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      {key === "priceList"
                        ? data?.getMedicalServiceCategoriesByAgency?.priceList?.map(
                            (filter: any, index: number) => {
                              return (
                                <Box
                                  key={index}
                                  display="flex"
                                  w="100%"
                                  paddingY="2"
                                  paddingX="1"
                                >
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                  >
                                    <Checkbox
                                      onChange={() => {
                                        toggleFilter(filter?.name);
                                        setIsChecked(!isChecked);
                                      }}
                                    />
                                  </Box>
                                  <Box
                                    marginLeft={2}
                                    fontWeight="500"
                                    fontSize="16px"
                                    fontFamily="Nunito"
                                  >
                                    {filter?.name}
                                  </Box>
                                </Box>
                              );
                            }
                          )
                        : key === "acronym"
                        ? data?.getMedicalServiceCategoriesByAgency?.acronyms?.map(
                            (filter: any, index: number) => {
                              return (
                                <Box
                                  key={index}
                                  display="flex"
                                  w="100%"
                                  paddingY="2"
                                  paddingX="1"
                                >
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                  >
                                    <Checkbox
                                      onChange={() => {
                                        toggleFilter(filter?.name);
                                        setIsChecked(!isChecked);
                                      }}
                                    />
                                  </Box>
                                  <Box
                                    marginLeft={2}
                                    fontWeight="500"
                                    fontSize="16px"
                                    fontFamily="Nunito"
                                  >
                                    {filter?.name}
                                  </Box>
                                </Box>
                              );
                            }
                          )
                        : data?.getMedicalServiceCategoriesByAgency?.types?.map(
                            (filter: any, index: number) => {
                              return (
                                <Box
                                  key={index}
                                  display="flex"
                                  w="100%"
                                  paddingY="2"
                                  paddingX="1"
                                >
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                  >
                                    <Checkbox
                                      onChange={() => {
                                        toggleFilter(filter?.name);
                                        setIsChecked(!isChecked);
                                      }}
                                    />
                                  </Box>
                                  <Box
                                    marginLeft={2}
                                    fontWeight="500"
                                    fontSize="16px"
                                    fontFamily="Nunito"
                                  >
                                    {filter?.name}
                                  </Box>
                                </Box>
                              );
                            }
                          )}
                    </AccordionPanel>
                  </AccordionItem>
                );
              })}
            </Accordion>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  }
);

export { PathServicesFilter };
export type { ServiceFilters };
