import { gql } from "@apollo/client";

export const GET_PATH_INFO = gql`
  query getPathInfoByIdId($id: ID!) {
    getPathInfoById(id: $id) {
      id
      name
      description
      image
      status
      category {
        id
        name
        type
      }
      questionnaires {
        id
        activationDate
        repetition
        questionnaire {
          id
          name
          icon
        }
      }
    }
  }
`;
