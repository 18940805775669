import { useQuery } from "@apollo/client";
import { useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Tablist, Tab } from "evergreen-ui";
import { Box, Flex, Text, Spinner } from "@chakra-ui/react";

import Review from "./Review";
import { GET_PATIENT } from "src/graphql/queries/patients.query";
import "./index.scss";
import Password from "./Password";
import { useTranslation } from "react-i18next";
import { GET_USER } from "src/graphql/queries/user.query";

const Settings = () => {
  const params = useParams();

  const { t, i18n } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const tabs = [
    {
      title: "Informazioni generali",
      view: (props: any = {}) => <Review {...props} />,
    },
    {
      title: "Password",
      view: (props: any = {}) => <Password {...props} />,
    },
  ];
  const {
    data: { me: user },
    loading: userLoading,
  } = useQuery(GET_USER);

  // const data = useMemo(() => patient?.patient || null, [patient]);

  if (userLoading) {
    return (
      <Flex alignItems="center" justifyContent="center" height="100%">
        <Spinner size="xl" color="brand.500" thickness="4px" speed="0.65s" />
      </Flex>
    );
  }

  return (
    <>
      <Box className="settings-title">
        <Text ml={3}>{t('Impostazioni account')}</Text>
      </Box>
      <Box className="patient-container">
        <Box className="patient-header">
          <Box borderBottom={"solid 1px #D9DFE9"}>
            <Tablist mb="16px" flexBasis={240} mr="24px" h="100%">
              {tabs.map(({ title }, index) => (
                <Tab
                  className="nav-tab-settings"
                  key={title}
                  id={title}
                  onSelect={() => setSelectedIndex(index)}
                  isSelected={index === selectedIndex}
                  aria-controls={`Boxl-${title}`}
                  height={48}
                  backgroundColor={
                    index === selectedIndex
                      ? "#F1F6F7 !important"
                      : "#FFF !important"
                  }
                  borderBottom={
                    index === selectedIndex ? " 2px solid #096B72" : ""
                  }
                  color={
                    index === selectedIndex
                      ? "#096B72 !important"
                      : "#667085 !important"
                  }
                  boxShadow="none !important"
                >
                  {t(title)}
                </Tab>
              ))}
            </Tablist>
          </Box>
          {/* <Box border="1px solid #EFEFEF" w="100%" mt="40px" /> */}
        </Box>
        <Box>
          {tabs.map(({ title, view }, index) => (
            <Box
              key={title}
              id={`Boxl-${title}`}
              role="tabBoxl"
              aria-labelledby={title}
              aria-hidden={index !== selectedIndex}
              display={index === selectedIndex ? "block" : "none"}
            >
              {view({ user })}
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default Settings;
