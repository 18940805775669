

export const text = {
  patient: {
    consentText: 'Ho capito e acconsento al ',
    consentLinkText: 'Trattamento dei dati',
    features: [
      'Ti aiutiamo a prenderti cura di te con facilità. In un’unica app puoi gestire i tuoi referti, condividerli con i medici e seguire percorsi di prevenzione su misura per te.',
      'Il nostro assistente intelligente ti aiuta a monitorare il tuo stato di salute in pochi minuti, a ricordarti le scadenze e tenere in ordine i tuoi documenti.',
      'Prenota velocemente in base alle tue esigenze di data e luogo.',
      'Ti troviamo la clinica e il medico.',
      'Effettua le visite tramite videoconsulto.',
    ],
  },
  doctor: {
    consentText: 'Ho letto e accetto i ',
    consentLinkText: 'Termini e le condizioni',
    features: [
      'Crea la tua lista dei pazienti: invita, aggiungi e  aggiorna la cartella clinica di ciascuno con le terapie, le diagnosi e gli esami direttamente sulla piattaforma.',
      'Gestisci tutti i tuoi appuntamenti: crea visite o televisite, sposta o annulla i tuoi appuntamenti.',
      'Crea momenti di teleconsulto per i casi clinici più complessi dove è necessario la collaborazione con un’équipe.',
      'Visualizza le cartelle cliniche complete dei pazienti che le condividono con te.',
      'Edita il tuo profilo professionale e le tue disponibilità per le visite e televisite.',
    ],
  },
};
