import { Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { flexRender, Table as T } from "@tanstack/react-table";
import { IClinic } from "../../../interfaces/IClinic";

const ClinicsTable = ({ table }: { table: T<IClinic> }) => {
  return (
    <TableContainer
      border="1px solid"
      borderColor="gray.100"
      borderRadius="md"
      shadow="lg"
    >
      <Table variant="stripped" backgroundColor="brand.300">
        <Thead backgroundColor="#F8F9FB">
          <Tr>
            {table.getHeaderGroups().map((headerGroup) =>
              headerGroup.headers.map((header) => (
                <Th key={header.id} textTransform="none">
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </Th>
              ))
            )}
          </Tr>
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <Th
                  key={cell.id}
                  borderBottom="0"
                  textTransform="none"
                  verticalAlign="middle"
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Th>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default ClinicsTable;
