import {
  ChangeEvent,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Checkbox,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { IModal } from "../../../interfaces/IModal";
import { SearchIcon } from "evergreen-ui";
import { QuestionnairesFilter } from "./QuestionnairesFilter";
import { QuestionnairesTable } from "./QuestionnairesTable";
import React from "react";
import {
  IQuestionnaires,
  QuestionnaireRelatedToType,
} from "../../../interfaces/IQuestionnaires";
import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ChevronVerticalIcon } from "src/components/Icons/ChevronVerticalIcon";
import { type DocumentNode, useQuery } from "@apollo/client";
import { GET_QUESTIONNAIRES } from "src/graphql/queries/questionnaires.query";
import { useTranslation } from "react-i18next";

/**
 * Add props to:
 * 
 * - hide QuestionnairesFilter
 * - filter questionnaires for patient only
 * - dynamic GET_QUESTIONNAIRES query
 */
interface Props extends IModal {
  query?: DocumentNode
  queryKey?: 'questionnaires' | 'getQuestionnairesByAgency' | 'getDoctorQuestionnaires'
  filterRowsFn?: (row: IQuestionnaires) => boolean
  showQuestionnairesFilter?: boolean
}

const AssociateQuestionnairesModal = forwardRef(
  ({ 
    isOpen, 
    loading, 
    onClose, 
    onSave, 
    currentData,
    showQuestionnairesFilter = true, 
    filterRowsFn, 
    query = GET_QUESTIONNAIRES, 
    queryKey = 'questionnaires'
  }: Props, ref) => {
    const [selectedQuestionnaires, setSelectedQuestionnaires] = useState<
      IQuestionnaires[]
    >([]);
    const [globalFilter, setGlobalFilter] = useState("");

    const { t } = useTranslation();
    const { data, loading: questionnairesLoading } = useQuery(query);
    // console.log('xxx', data)

    const initialQuestionnaires = useMemo(() => {
      let filtered = data?.[queryKey].filter(
        (questionnaire: IQuestionnaires) =>
          !currentData
            .map((item: IQuestionnaires) => item.id)
            ?.includes(questionnaire.id)
      );
      if (filterRowsFn && filtered?.length) {
        filtered = filtered.filter(filterRowsFn)
      }

      return filtered ?? [];
    }, [currentData, data?.[queryKey], filterRowsFn]);

    const [filteredQuestionnaire, setFilteredQuestionnaire] = useState<
      IQuestionnaires[]
    >(initialQuestionnaires ?? []);

    useEffect(() => {
      setFilteredQuestionnaire(initialQuestionnaires);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentData, data]);

    const onToggleFilter = useCallback(
      (filters: QuestionnaireRelatedToType[]) => {
        const filtered = initialQuestionnaires.filter((questionnaire: IQuestionnaires) => {
          console.log(questionnaire.relatedTo);
          return (
            filters.length === 0 ||
            filters.includes(
              questionnaire.relatedTo
              //QuestionnaireRelatedToType[
              // questionnaire.relatedTo as string as keyof typeof QuestionnaireRelatedToType
              // ]
            )
          );
        });

        setFilteredQuestionnaire(filtered ?? []);
      },
      [initialQuestionnaires]
    );

    if (questionnairesLoading) {
      return (
        <Flex alignItems="center" justifyContent="center" height="100%">
          <Spinner size="xl" color="brand.500" thickness="4px" speed="0.65s" />
        </Flex>
      );
    }

    return (
      <Modal
        size="6xl"
        isOpen={isOpen}
        isCentered={true}
        onClose={onClose}
        scrollBehavior="inside"
        closeOnOverlayClick={false}
      >
        <ModalOverlay backgroundColor={"#0000007D"} />
        <ModalContent backgroundColor="brand.600">
          <ModalHeader pt="24px">
            <Text>{t("Associa Questionario")}</Text>
          </ModalHeader>
          <ModalCloseButton mt="8px" mr="8px" borderRadius="23px" />

          <ModalBody pb={6} mt="16px">
            <Flex
              height="76px"
              paddingInlineEnd="24px"
              alignItems="center"
              justifyContent="space-between"
            >
              <Flex alignItems="center">
                <InputGroup
                  border="2px"
                  borderRadius="10px"
                  borderColor="gray.200"
                >
                  <InputLeftElement
                    pointerEvents="none"
                    children={<SearchIcon color="gray" />}
                  />
                  <Input
                    width="360px"
                    placeholder={t("Cerca Questionari...")}
                    value={globalFilter ?? ""}
                    background="white"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      const filtered = initialQuestionnaires.filter(
                        (questionnaire: IQuestionnaires) =>
                          questionnaire.name
                            .toLowerCase()
                            .replaceAll(" ", "")
                            .includes(
                              e.target.value.toLowerCase().replaceAll(" ", "")
                            )
                      );

                      setFilteredQuestionnaire(filtered ?? []);
                      setGlobalFilter(e.target.value);
                    }}
                  />
                </InputGroup>
                {showQuestionnairesFilter ? <QuestionnairesFilter onToggleFilter={onToggleFilter} /> : null}
              </Flex>
            </Flex>
            <QuestionnairesList
              data={filteredQuestionnaire}
              searchText={globalFilter}
              selectedQuestionnaires={selectedQuestionnaires}
              setSelectedQuestionnaires={setSelectedQuestionnaires}
            />
          </ModalBody>

          <ModalFooter pb="24px" justifyContent="space-between">
            <Text
              style={{
                fontSize: 16,
                fontWeight: 600,
                color: "#667085",
                marginRight: 84,
              }}
            >
              {`${selectedQuestionnaires.length}`}{" "}
              {t("questionari selezionati")}
            </Text>
            <Button
              type="button"
              colorScheme="teal"
              variant="solid"
              backgroundColor="brand.500"
              isLoading={loading}
              isDisabled={selectedQuestionnaires.length === 0}
              onClick={() => {
                onSave(selectedQuestionnaires);
                onClose();
                setSelectedQuestionnaires([]);
                setGlobalFilter("");
                setFilteredQuestionnaire(initialQuestionnaires ?? []);
              }}
            >
              {t("Associa Questionario")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
);

type QuestionnairesListProps = {
  data: IQuestionnaires[];
  searchText?: string;
  selectedQuestionnaires?: IQuestionnaires[];
  setSelectedQuestionnaires?: (questionnaires: IQuestionnaires[]) => void;
};

const QuestionnairesList: React.FC<QuestionnairesListProps> = React.memo(
  ({ data, searchText, selectedQuestionnaires, setSelectedQuestionnaires }) => {
    
  const { t, i18n } = useTranslation();
    const columns: ColumnDef<IQuestionnaires>[] = useMemo(
      () => [
        {
          id: "name",
          header: () => {
            return (
              <Button
                variant="unstyled"
                textAlign="start"
                flexDirection={"row"}
                display={"flex"}
                height={"40px"}
              >
                <Checkbox
                  defaultChecked={
                    selectedQuestionnaires?.length === data.length &&
                    data.length > 0
                  }
                  onChange={() => {
                    console.log(selectedQuestionnaires?.length);
                    if (selectedQuestionnaires?.length === data.length) {
                      console.log("Clean");
                      setSelectedQuestionnaires?.([]);
                    } else {
                      console.log("Add all");

                      setSelectedQuestionnaires?.(data);
                    }
                  }}
                />
                <Text paddingX={"2"}>{t("Nome")}</Text>
                <ChevronVerticalIcon />
              </Button>
            );
          },
          accessorFn: (row) => row.name,
          cell: ({ row, getValue }) => {
            const id = row.original.id;
            const name = getValue() as string;

            return (
              <Button
                variant="unstyled"
                colorScheme="teal"
                fontSize="15px"
                fontWeight="bold"
                textAlign="start"
                flexDirection={"row"}
                display={"flex"}
              >
                <Checkbox
                  defaultChecked={selectedQuestionnaires
                    ?.map((q) => q.id)
                    .includes(id)}
                  onChange={() => {
                    if (selectedQuestionnaires?.map((q) => q.id).includes(id)) {
                      setSelectedQuestionnaires?.(
                        selectedQuestionnaires?.filter(
                          (questionnaire) => questionnaire.id !== id
                        )
                      );
                    } else {
                      setSelectedQuestionnaires?.([
                        ...(selectedQuestionnaires ?? []),
                        row.original,
                      ]);
                    }
                  }}
                />
                <Text paddingLeft={"2"}>{name}</Text>
              </Button>
            );
          },
        },
        {
          header: () => {
            return (
              <Flex alignItems="center">
                <Button
                  variant="unstyled"
                  colorScheme="teal"
                  fontSize="15px"
                  fontWeight="bold"
                  height={"40px"}
                  textAlign="start"
                  flexDirection={"row"}
                  display={"flex"}
                >
                  <Text paddingRight={"2"}>{t('Rivolto a')}</Text>
                  <ChevronVerticalIcon />
                </Button>
              </Flex>
            );
          },
          id: "questionnaire-destination",
          cell: ({ row }) => {
            return (
              <Text fontSize="15px" fontWeight="bold">
                {row.original.relatedTo
                  ? row.original.relatedTo === QuestionnaireRelatedToType.Doctor
                    ? t(QuestionnaireRelatedToType.Doctor)
                    : t(QuestionnaireRelatedToType.Patient)
                  : ""}
              </Text>
            );
          },
        },
      ],
      [data, t, selectedQuestionnaires, setSelectedQuestionnaires]
    );

    const table = useReactTable({
      data,
      columns,
      state: {
        globalFilter: searchText,
      },
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
    });

    return <QuestionnairesTable table={table} />;
  }
);

export { AssociateQuestionnairesModal };
