import { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Grid,
  useToast,
} from "@chakra-ui/react";
import { Hub } from "aws-amplify";
import { useNavigate, useSearchParams } from "react-router-dom";
import CodeSubmissionForm from "./Forms/CodeSubmissionForm";
import { text } from "../../utils/text";
import { User } from "../../types/User";
import { useAuthContext } from "../../providers/AuthProvider/context";
import EmailVerificationForm from "./Forms/EmailVerificationForm";
import NewPasswordCreatingForm from "./Forms/NewPasswordCreatingForm";
import PasswordUpdateForm from "./Forms/PasswordUpdateForm";

const ResetPasword = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const {
    forgotPassword,
    forgotPasswordSubmit,
    confirmSignUp,
    resendConfirmationCode,
    inProgress,
  } = useAuthContext();

  const [step, setStep] = useState(0);
  const [userEmail, setUserEmail] = useState("");
  const [code, setCode] = useState("");

  const type = (searchParams.get("type") as User) || "patient";
  // const { consentLinkText, consentText, features } = text[type];

  // useEffect(() => {
  //   const listenToAutoSignInEvent = () => {
  //     Hub.listen("auth", ({ payload }) => {
  //       const { event } = payload;
  //       console.log(payload);

  //       if (event === "autoSignIn") {
  //         const user = payload.data;
  //         console.log("AUTO SIGNIN");
  //         navigate("/profile");
  //       } else if (event === "autoSignIn_failure") {
  //         console.log("AUTOSIGNIN FAILED");
  //         navigate("/signin");
  //       }
  //     });
  //   };

  //   listenToAutoSignInEvent();

  //   return () => Hub.remove("auth", listenToAutoSignInEvent);
  // }, []);

  const handleEmailVerification = async ({ email }: any) => {
    try {
      await forgotPassword(email);
      setUserEmail(email);
      setStep(1);
    } catch (e) {
      toast({
        title: e,
        status: "error",
        isClosable: true,
      });
    }
  };

  const handleNewPasswordCreating = async ({ password }: any) => {
    try {
      console.log(code);
      await forgotPasswordSubmit(userEmail, code, password);
      setStep(3);
    } catch (e) {
      toast({
        title: e,
        status: "error",
        isClosable: true,
      });
    }
  };

  const renderForm = (step: number) => {
    switch (step) {
      case 0:
        return (
          <EmailVerificationForm
            isLoading={inProgress}
            onSubmit={handleEmailVerification}
          />
        );
      // case 1:
      //   return (
      //     <ConfirmIdentityPhoneForm
      //       onSubmit={(values) => {
      //         console.log(values);
      //         setStep(2);
      //       }}
      //     />
      //   );
      case 1:
        return (
          <CodeSubmissionForm
            isLoading={inProgress}
            onSubmit={({ code }: any) => {
              try {
                setCode(code);
                setStep(2);
              } catch (e) {
                toast({
                  title: e,
                  status: "error",
                  isClosable: true,
                });
              }
            }}
            onSendAgain={async () => {
              try {
                await resendConfirmationCode(userEmail);
              } catch (e) {
                toast({
                  title: e,
                  status: "error",
                  isClosable: true,
                });
              }
            }}
          />
        );
      case 2:
        return (
          <NewPasswordCreatingForm
            //consent={{ consentLinkText, consentText }}
            isLoading={inProgress}
            onSubmit={handleNewPasswordCreating}
          />
        );

      case 3:
        return <PasswordUpdateForm />;
    }
  };

  return (
    <Grid height="100vh" gridAutoColumns="1fr" gridAutoFlow="column">
      <Box backgroundColor="brand.600">
        <Flex justifyContent="center" flexDirection="column">
          {renderForm(step)}
        </Flex>
      </Box>
    </Grid>
  );
};

export default ResetPasword;
