import {
  Box,
  Button,
  CloseButton,
  Divider,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import { PlusIcon } from "evergreen-ui";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { AssociateHealthServiceModal } from "src/components/Modals/AssociateHealthServiceModal";
import { IServiceHealth } from "src/interfaces/IServiceHealth";

type HeatlhServicesPropType = {
  setMainHealthServices: (healthServices: number[]) => void;
};

const HealthServices: React.FC<HeatlhServicesPropType> = React.memo(
  ({ setMainHealthServices }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { t, i18n } = useTranslation();
    const [value, setValue] = useState("0");
    const [selectedHealthService, setSelectedHealthService] = useState<
      IServiceHealth[]
    >([]);

    const onRemoveHealthService = (healthService: IServiceHealth) => {
      setSelectedHealthService(
        selectedHealthService.filter((q) => q.id !== healthService.id)
      );
    };

    useEffect(() => {
      const heathServicesIds: number[] = [];
      selectedHealthService.map((healthService) => {
        heathServicesIds.push(parseInt(healthService.id));
        setMainHealthServices(heathServicesIds);
      });
    }, [selectedHealthService]);

    return (
      <>
        <AssociateHealthServiceModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          currentData={selectedHealthService}
          onSave={(services) =>
            setSelectedHealthService(
              (prev) =>
                [...prev, ...(services as IServiceHealth[])] as IServiceHealth[]
            )
          }
        />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            marginTop: 10,
            gap: "80px",
          }}
        >
          <Text
            style={{
              fontSize: 16,
              fontWeight: 500,
              marginRight: 84,
            }}
            color="brand.800"
          >
            {t("Associa le prestazioni al tuo percorso.")}
          </Text>

          <div>
            {selectedHealthService.length !== 0 && (
              <div>
                {selectedHealthService.map((healthService) => {
                  return (
                    <div>
                      <Box
                        borderRadius={"8px"}
                        border={"1px"}
                        padding="14px"
                        marginY={"4"}
                        style={{
                          borderColor: "#E2E8F0",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ width: 450 }}>
                            <Text
                              fontWeight="500"
                              fontSize={16}
                              color="brand.700"
                              paddingBottom="2"
                            >
                              {healthService.description}
                            </Text>
                            <Text
                              fontWeight="500"
                              fontSize={14}
                              color="brand.800"
                            >
                              {`${t("Listino")}: ${
                                healthService.priceList
                              } - ${t("Genere")}: ${healthService.type}`}
                            </Text>
                          </div>

                          <CloseButton
                            color={"ActiveBorder"}
                            onClick={() => onRemoveHealthService(healthService)}
                          />
                        </div>
                      </Box>

                      <Divider paddingY={"2"} color={"gray.200"} />
                    </div>
                  );
                })}
              </div>
            )}
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              pb="10px"
            >
              <RadioGroup
                w="201px"
                h="56px"
                onChange={(value) => setValue(value)}
              >
                <Stack fontWeight={600}>
                  <Radio
                    value="1"
                    colorScheme="teal"
                    h="20px"
                    borderColor=" grey"
                    variant="Vector"
                  >
                    {t("Attiva su invito medico")}
                  </Radio>
                  <Radio
                    value="2"
                    colorScheme="teal"
                    h="20px"
                    borderColor="grey"
                    isChecked={true}
                  >
                    {t("Attiva per prestazione")}
                  </Radio>
                </Stack>
              </RadioGroup>
            </Box>
            {value === "2" ? (
              <Button
                h="24px"
                backgroundColor="transparent"
                color="brand.500"
                variant="link"
                fontSize="16px"
                leftIcon={<PlusIcon marginTop="-1px" />}
                onClick={() => setIsModalOpen(true)}
                marginTop={selectedHealthService.length !== 0 ? "4" : "0"}
              >
                <Text>{t("Associa Prestazione")}</Text>
              </Button>
            ) : null}
          </div>
        </div>
      </>
    );
  }
);

export { HealthServices };
