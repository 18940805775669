import { useQuery } from "@apollo/client";
import { useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Avatar, Tablist, Tab } from "evergreen-ui";
import { Box, Flex, Text, Spinner } from "@chakra-ui/react";

import Review from "./Review";
import SanitaryData from "./SanitaryData";
import Appointments from "./Appointments";
import ClinicalHistory from "./ClinicalHistory";
import Questionnaires from "./Questionnaires";
// import Metrics from "./Metrics";
import { GET_PATIENT } from "../../../graphql/queries/patients.query";
// import { LargeEmailButton } from "../../../components/LargeEmailButton";
// import { LargeMobileBadge } from "../../../components/LargeMobileBadge";
import "./index.scss";
import Camera from "src/components/Icons/Camera";
import { ArrowLeftIcon } from "evergreen-ui";
import { useTranslation } from "react-i18next";
import { GET_PATIENT_BY_AGENCY } from "src/graphql/queries/get-patients-by-agency.query";
import { GET_USER } from "src/graphql/queries/user.query";

const Patient = () => {
  const navigate = useNavigate();
  const params = useParams();
  const {
    data: { me: user },
    loading: userLoading,
  } = useQuery(GET_USER);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { t } = useTranslation();

  const queryGetPatientByAgency = useQuery(GET_PATIENT_BY_AGENCY, {
    variables: { patientId: params.patientId },
  });
  const queryGetPatient = useQuery(GET_PATIENT, {
    variables: { patientId: params.patientId },
  });

  const data = useMemo(() => {
    if (user.role === "agency_admin") {
      return queryGetPatientByAgency.data?.getPatientByAgency || null;
    } else {
      return queryGetPatient.data?.patient || null;
    }
  }, [queryGetPatientByAgency, queryGetPatient, user.role]);

  const tabs = [
    {
      title: t("Panoramica"),
      view: (props: any = {}) => <Review {...props} />,
    },
    {
      title: t("Dati sanitari"),
      view: (props: any = {}) => <SanitaryData {...props} />,
    },
    {
      title: t("Appuntamenti"),
      view: () => <Appointments />,
    },
    {
      title: t("Storia clinica"),
      view: () => <ClinicalHistory />,
    },
    {
      title: t("Questionari"),
      view: () => <Questionnaires />,
    },
    // {
    //   title: t("Metriche"),
    //   view: () => <Metrics />,
    // },
    // {
    //   title: 'Familiarità',
    //   view: () => null,
    // },
  ];
  if (queryGetPatientByAgency.loading || queryGetPatient.loading) {
    return (
      <Flex alignItems="center" justifyContent="center" height="100%">
        <Spinner size="xl" color="brand.500" thickness="4px" speed="0.65s" />
      </Flex>
    );
  }

  return (
    <>
      <Box className="go-back-button" onClick={() => navigate(-1)}>
        <ArrowLeftIcon /> <Text ml={3}>{t("Ritorna a lista pazienti")}</Text>
      </Box>
      <Box className="patient-container">
        <Box className="patient-header">
          <Box className="patient-header__info">
            <Box display="flex">
              <Box position={"relative"}>
                <Avatar
                  src={data ? `${data.name}` : ""}
                  name={data ? `${data.name}` : ""}
                  size={80}
                />
                <Box className="upload-photo-icon">
                  <Camera />
                </Box>
              </Box>

              <Box
                marginLeft={6}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                alignItems="flex-start"
                lineHeight={"20px"}
              >
                <Box className="patient-name">
                  {data?.name || ""}&nbsp;{data?.surname || ""}
                </Box>
                <Box
                  h="32px"
                  p="0px"
                  display="flex"
                  alignItems="center"
                  color={"#F21B3F"}
                  fontWeight={600}
                >
                  <div
                    style={{
                      width: "8px",
                      height: "8px",
                      marginRight: "8px",
                      borderRadius: "100px",
                      background: "#F21B3F",
                    }}
                  ></div>
                  {data?.status === "invited"
                    ? t("Non registrato")
                    : t("Registrato")}
                </Box>
              </Box>
            </Box>
            {/* <Box>
            <LargeMobileBadge phone={data.phone} />
            <LargeEmailButton email={data.email} />
          </Box> */}
          </Box>
          <Box mt="24px" borderBottom={"solid 1px #D9DFE9"}>
            <Tablist mb="16px" flexBasis={240} mr="24px" h="100%">
              {tabs.map(({ title }, index) => (
                <Tab
                  className="nav-tab"
                  key={title}
                  id={title}
                  onSelect={() => setSelectedIndex(index)}
                  isSelected={index === selectedIndex}
                  aria-controls={`Boxl-${title}`}
                  height={48}
                  backgroundColor={
                    index === selectedIndex
                      ? "#F1F6F7 !important"
                      : "#FFF !important"
                  }
                  borderBottom={
                    index === selectedIndex ? " 2px solid #096B72" : ""
                  }
                  color={
                    index === selectedIndex
                      ? "#096B72 !important"
                      : "#667085 !important"
                  }
                  boxShadow="none !important"
                >
                  {title}
                </Tab>
              ))}
            </Tablist>
          </Box>
          {/* <Box border="1px solid #EFEFEF" w="100%" mt="40px" /> */}
        </Box>
        <Box>
          {tabs.map(({ title, view }, index) => (
            <Box
              key={title}
              id={`Boxl-${title}`}
              role="tabBoxl"
              aria-labelledby={title}
              aria-hidden={index !== selectedIndex}
              display={index === selectedIndex ? "block" : "none"}
            >
              {view({ patient: data })}
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default Patient;
