import { useEffect, useState } from "react";
import * as yup from "yup";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  VStack,
  Checkbox,
  InputRightElement,
  InputGroup,
  Link,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface IProps {
  consent: { consentText: string; consentLinkText: string };
  isLoading: boolean;
  onSubmit: (data: any) => void;
}

const CreateAccountForm = (props: IProps) => {
  const [show, setShow] = useState(false);
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const { t, i18n } = useTranslation();
  const schema = yup.object({
    email: yup
      .string()
      .email(t("L'indirizzo email inserito non è valido"))
      .required(t("Inserisci la tua email")),
    password: yup
      .string()
      .min(8, t("La password deve avere almeno 8 caratteri"))
      // .matches(
      //   /^(?=.*[A-Za-z])(?=.*d)(?=.*[@$!%*#?&_])[A-Za-zd@$!%*#?&_]{8,}$/,
      //   "La password deve contenere 8 caratteri, uno maiuscolo, uno minuscolo, un numero e un carattere speciale"
      // )
      .matches(/[a-z]+/, t("La password deve contenere uno minuscolo"))
      .matches(/[A-Z]+/, t("La password deve contenere uno maiuscolo"))
      .matches(
        /[@$!%*#?&_]+/,
        t("La password deve contenere un carattere speciale")
      )
      .matches(/\d+/, t("La password deve contenere un numero"))
      .required(t("Inserisci la tua password")),
    cpassword: yup
      .string()
      .min(8, t("La password deve avere almeno 8 caratteri"))
      .oneOf([yup.ref("password")], t("La password non corrisponde"))
      .required(t("Inserisci la tua password")),
    acceptTerms: yup
      .bool()
      .oneOf([true], t("È necessario accettare il trattamento dei dati"))
      .required(),
  });
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setValue("email", email);
  }, []);

  const handleClick = () => setShow(!show);

  return (
    <form onSubmit={handleSubmit(props.onSubmit)}>
      <Box>
        <Heading as="h1" size="2xl">
          {t("Crea un account")}
        </Heading>
        <VStack alignItems="flex-start" spacing="20px" mt="32px" w="327px">
          <FormControl isInvalid={!!errors.email}>
            <Input
              size="lg"
              id="email"
              type="email"
              placeholder={t("Email")}
              {...register("email")}
              w="327px"
              disabled={Boolean(email)}
              boxShadow="0px 1px 24px rgba(9, 107, 114, 0.08)"
            />
            <FormErrorMessage>
              {errors.email && errors.email.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.password}>
            <InputGroup size="lg">
              <Input
                size="lg"
                id="password"
                type={show ? "text" : "password"}
                placeholder={t("Password")}
                {...register("password")}
                w="327px"
                boxShadow="0px 1px 24px rgba(9, 107, 114, 0.08)"
              />
              <InputRightElement width="4.5rem">
                <Button
                  size="xs"
                  onClick={handleClick}
                  borderRadius="5px"
                  mr="5px"
                  height="30px"
                >
                  {show ? t("Nascondi") : t("Mostra")}
                </Button>
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>
              {errors.password && errors.password.message}
            </FormErrorMessage>
          </FormControl>

          <Box>
            <FormControl isInvalid={!!errors.cpassword}>
              <InputGroup size="lg">
                <Input
                  size="lg"
                  id="cpassword"
                  type={show ? "text" : "password"}
                  placeholder={t("Conferma password")}
                  {...register("cpassword")}
                  w="327px"
                  boxShadow="0px 1px 24px rgba(9, 107, 114, 0.08)"
                />
                <InputRightElement width="4.5rem">
                  <Button
                    size="xs"
                    onClick={handleClick}
                    borderRadius="5px"
                    mr="5px"
                    height="30px"
                  >
                    {show ? t("Nascondi") : t("Mostra")}
                  </Button>
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>
                {errors.cpassword && errors.cpassword.message}
              </FormErrorMessage>
            </FormControl>
          </Box>
          <FormControl isInvalid={!!errors.acceptTerms}>
            <Checkbox
              size="lg"
              colorScheme="teal"
              defaultChecked={false}
              borderColor="brand.500"
              {...register("acceptTerms")}
            >
              {t(props.consent.consentText)}
              <Link
                href="https://easydoctor.io/privacy-policy/"
                isExternal
                color="teal"
              >
                {t(props.consent.consentLinkText)}
              </Link>
            </Checkbox>
            <FormErrorMessage>
              {errors.acceptTerms && errors.acceptTerms.message}
            </FormErrorMessage>
          </FormControl>

          <Button
            size="lg"
            type="submit"
            variant="solid"
            colorScheme="teal"
            backgroundColor="brand.500"
            isLoading={props.isLoading}
          >
            {t("Avanti")}
          </Button>
        </VStack>
      </Box>
    </form>
  );
};

export default CreateAccountForm;
