import { Box, Button, CloseButton, Divider, Text } from "@chakra-ui/react";
import { PlusIcon } from "evergreen-ui";
import React, { useEffect, useState } from "react";
import { AssociateQuestionnairesModal } from "src/components/Modals/AssociateQuestionnairesModal";
import {
  ActivationDate,
  IQuestionnaires,
  QuestionnaireRelatedToType,
  Repetition,
} from "src/interfaces/IQuestionnaires";
import { QuestionnaireDeliveryChoice } from "./QuestionnaireDeliveryChoice";
import IPathQuestionnaire from "src/interfaces/IPathQuestionnaire";
import { useTranslation } from "react-i18next";

type QuestionnairePropType = {
  setMainQuestionnaires: (questionnaires: IPathQuestionnaire[]) => void;
};

const Questionnaires: React.FC<QuestionnairePropType> = React.memo(
  ({ setMainQuestionnaires }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedQuestionnaires, setSelectedQuestionnaires] = useState<
      IQuestionnaires[]
    >([]);

    const { t, i18n } = useTranslation();
    const onRemoveQuestionnaire = (questionnaire: IQuestionnaires) => {
      setSelectedQuestionnaires(
        selectedQuestionnaires.filter((q) => q.id !== questionnaire.id)
      );
    };

    useEffect(() => {
      const pathQuestionnaires: IPathQuestionnaire[] = [];
      selectedQuestionnaires.map((questionnaire) => {
        pathQuestionnaires.push({
          questionnaire_id: parseInt(questionnaire.id),
          repetition: questionnaire.repetition,
          activationDate: questionnaire.activationDate,
        });
        setMainQuestionnaires(pathQuestionnaires);
      });
    }, [selectedQuestionnaires]);

    return (
      <>
        <AssociateQuestionnairesModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          currentData={selectedQuestionnaires}
          onSave={(questionnaires) =>
            setSelectedQuestionnaires(
              (prev) =>
                [
                  ...prev,
                  ...(questionnaires as IQuestionnaires[]),
                ] as IQuestionnaires[]
            )
          }
        />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            marginTop: 10,
          }}
        >
          <Text
            style={{
              fontSize: 16,
              fontWeight: 500,
              marginRight: 84,
            }}
            color="brand.800"
          >
            {t("Associa i questionari al tuo percorso.")}
          </Text>

          <div>
            {selectedQuestionnaires.length !== 0 && (
              <div>
                {selectedQuestionnaires.map((questionnaire) => {
                  return (
                    <div>
                      <Box
                        borderRadius={"8px"}
                        border={"1px"}
                        padding="14px"
                        marginY={"4"}
                        style={{
                          borderColor: "#E2E8F0",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ width: 450 }}>
                            <Text
                              fontWeight="500"
                              fontSize={16}
                              color="brand.700"
                              paddingBottom="2"
                            >
                              {questionnaire.name}
                            </Text>
                            <Text
                              fontWeight="500"
                              fontSize={14}
                              color="brand.800"
                            >
                              {`${t("Rivolto a")}: ${
                                QuestionnaireRelatedToType[
                                  questionnaire.relatedTo as string as keyof typeof QuestionnaireRelatedToType
                                ]
                              }`}
                            </Text>
                          </div>

                          <CloseButton
                            color={"ActiveBorder"}
                            onClick={() => onRemoveQuestionnaire(questionnaire)}
                          />
                        </div>
                      </Box>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <QuestionnaireDeliveryChoice
                          choices={Object.values(ActivationDate)}
                          onChoose={(choice) =>
                            setSelectedQuestionnaires(
                              (currentQuestionnaire) => {
                                return currentQuestionnaire.map((q) => {
                                  return {
                                    ...q,
                                    activationDate:
                                      q.id === questionnaire.id
                                        ? Object.values(ActivationDate).find(
                                            (v) => v === choice
                                          )
                                        : q.activationDate,
                                  };
                                });
                              }
                            )
                          }
                          selectedChoice={
                            selectedQuestionnaires.find(
                              (q) => q.id === questionnaire.id
                            )?.activationDate
                          }
                          choicePlaceholder={t("Attivazione")}
                        />
                        <Box width={"4"} />
                        <QuestionnaireDeliveryChoice
                          choices={Object.values(Repetition)}
                          onChoose={(choice) =>
                            setSelectedQuestionnaires(
                              (currentQuestionnaire) => {
                                return currentQuestionnaire.map((q) => {
                                  return {
                                    ...q,
                                    repetition:
                                      q.id === questionnaire.id
                                        ? Object.values(Repetition).find(
                                            (v) => v === choice
                                          )
                                        : q.repetition,
                                  };
                                });
                              }
                            )
                          }
                          selectedChoice={
                            selectedQuestionnaires.find(
                              (q) => q.id === questionnaire.id
                            )?.repetition
                          }
                          choicePlaceholder={t("Ripetizione")}
                        />
                      </div>
                      <Divider paddingY={"2"} color={"gray.200"} />
                    </div>
                  );
                })}
              </div>
            )}
            <Button
              backgroundColor="transparent"
              color="brand.500"
              variant="link"
              fontSize="16px"
              leftIcon={<PlusIcon marginTop="-1px" />}
              onClick={() => setIsModalOpen(true)}
              marginTop={selectedQuestionnaires.length !== 0 ? "4" : "0"}
            >
              <Text>{t("Associa Questionario")}</Text>
            </Button>
          </div>
        </div>
      </>
    );
  }
);

export { Questionnaires };
