import * as yup from "yup";
import format from "date-fns/format";
import { GraphQLError } from "graphql";
import { useState, useEffect, useMemo } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  SimpleGrid,
  Spinner,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import itLocale from "@fullcalendar/core/locales/it";
import enLocale from "@fullcalendar/core/locales/en-gb";
import arLocale from "@fullcalendar/core/locales/ar-sa";
import frLocale from "@fullcalendar/core/locales/fr";
import deLocale from "@fullcalendar/core/locales/de";
import esLocale from "@fullcalendar/core/locales/es";
import ptLocale from "@fullcalendar/core/locales/pt";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Label from "../../components/Label";
import Title from "../../components/Title";
import TitleSmall from "../../components/TitleSmall";
import AnchorItem from "../../components/AnchorItem";
import GrayLine from "../../components/GrayLine";
import ToggleInput from "../../components/Inputs/ToggleInput";
import { GET_DOCTOR } from "../../graphql/queries/doctors.query";
import { UPDATE_DOCTOR } from "../../graphql/mutations/update-doctor.mutation";
import { GET_AVAILABILITY } from "../../graphql/queries/availability.query";
import { UPDATE_AVAILABILITY } from "../../graphql/mutations/update-availability.mutation";
import { validateTaxCode } from "../../components/Modals/AddPatientModal/validateTaxCode";
import { colors } from "../../colors";
import { useTranslation } from "react-i18next";

type Slot = {
  dow: number[];
  start: string;
  end: string;
};

const initialState = {
  services: {
    cardiology: false,
    electrogramma: false,
    holter: false,
    stressTest: false,
    tilting: false,
    ecocardiogramma: false,
    pacemaker: false,
    myocardialScintigraphy: false,
  },
};

const EditDoctorProfile = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const { t, i18n } = useTranslation();
  const { data, loading } = useQuery(GET_DOCTOR);
  const doctorId = data?.doctor?.id;
  const { data: availabilityData, loading: availabilityLoading } = useQuery(
    GET_AVAILABILITY,
    {
      skip: !doctorId,
      variables: { doctorId },
    }
  );

  const [calendarLanguage, setCalendarLanguage] = useState(enLocale);
  const schema = yup.object({
    name: yup.string().required(t("Inserisci il tuo nome e cognome")),
    surname: yup.string().required(t("Inserisci il tuo nome e cognome")),
    fiscalCode: yup
      .string()
      .test("isFiscalCodeValid", t("Il codice fiscale non è valido"), (value) =>
        validateTaxCode(value)
      )
      .required(t("Inserisci il tuo codice fiscale")),
    email: yup.string().email(t("L'indirizzo email inserito non è valido")),
    phone: yup
      .string()
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        t("Il numero di telefono non è valido")
      )
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr)),
    piva: yup.string().nullable(),
    pec: yup.string().nullable(),
    specialization: yup.string().nullable(),
    order: yup.string().nullable(),
    orderNumber: yup.string().nullable(),
  });

  const [updateDoctor, { loading: updateLoading }] = useMutation(
    UPDATE_DOCTOR,
    {
      refetchQueries: [GET_DOCTOR],
    }
  );
  const [updateAvailability, { loading: updateAvailabilityLoading }] =
    useMutation(UPDATE_AVAILABILITY, {
      refetchQueries: [GET_AVAILABILITY],
    });

  const slots = useMemo(
    () =>
      (availabilityData?.availability?.slots || []).map(
        ({ dow, start, end }: Slot) => ({
          dow,
          start,
          end,
        })
      ),
    [availabilityData]
  );

  const [state, setState] = useState<any>(initialState);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const nameError = errors.name?.message || errors.surname?.message;
  const languageToLocaleMap: any = {
    it: itLocale,
    ar: arLocale,
    fr: frLocale,
    de: deLocale,
    pt: ptLocale,
    es: esLocale,
  };
  useEffect(() => {
    const defaultLocale = enLocale;
    const selectedLocale = languageToLocaleMap[i18n.language] || defaultLocale;
    setCalendarLanguage(selectedLocale);
  }, [i18n.language]);
  useEffect(() => {
    if (data.doctor) {
      const { doctor } = data;
      setState({
        ...state,
        ...doctor,
      });
      reset({
        ...state,
        ...doctor,
      });
    }
  }, [data, reset, state]);

  const onSubmit = async (data: any) => {
    try {
      await updateDoctor({
        variables: {
          id: state.id,
          input: {
            name: data.name,
            surname: data.surname,
            vat: data.piva,
            phone: data.phone,
            pec: data.pec,
            order: data.order,
            orderNumber: data.orderNumber,
            fiscalCode: data.fiscalCode,
          },
        },
      });
      navigate("/profile");
    } catch (error) {
      toast({
        title: t("Errore"),
        description: (error as GraphQLError).message,
        status: "error",
        isClosable: true,
      });
    }
  };

  const handleDateClick = async ({ date }: any) => {
    const slot = {
      dow: [date.getDay()],
      start: format(date, "HH:mm"),
      end: format(new Date(date.setHours(date.getHours() + 1)), "HH:mm"),
    };

    const slotsAvailability = slots.some(
      (s: Slot) => s.dow[0] === slot.dow[0] && s.start === slot.start
    )
      ? slots.filter(
          (s: Slot) => !(s.dow[0] === slot.dow[0] && s.start === slot.start)
        )
      : [...slots, slot];

    try {
      await updateAvailability({
        variables: {
          input: {
            doctorId: +doctorId,
            slots: slotsAvailability,
          },
        },
      });
    } catch (error) {
      toast({
        title: t("Errore"),
        description: (error as GraphQLError).message,
        status: "error",
        isClosable: true,
      });
    }
  };

  if (loading) {
    return (
      <Flex alignItems="center" justifyContent="center" height="100%">
        <Spinner size="xl" color="brand.500" thickness="4px" speed="0.65s" />
      </Flex>
    );
  }

  return (
    <Box className="profile-container" h="100%" ml="91px" mr="91px">
      <Title text={t("Profilo")} style={{ paddingTop: 40 }} />
      <Box
        background="#FCFCFC"
        w="100%"
        p="24px"
        display="flex"
        justifyContent="center"
        alignItems="start"
      >
        <Box h="100%" w="100%" maxW="336px" display="flex">
          <Box
            h="240px"
            maxW="280px"
            w="100%"
            mr="32px"
            ml="24px"
            marginY="24px"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            fontSize="15px"
            fontWeight={600}
            color="#6F767E"
          >
            <AnchorItem text={t("Informazioni")} />
            <AnchorItem text={t("Account")} />
            <AnchorItem text={t("Notifiche")} />
            <AnchorItem text={t("Termini e condizioni")} />
          </Box>
        </Box>
        <Flex w="100%" h="100%" flexDirection="column">
          <TitleSmall
            text={t("Informazioni personali")}
            color="#B5E4CA"
            style={{ marginRight: 24 }}
          />
          <Flex
            h="96px"
            mt="32px"
            maxWidth="400px"
            alignItems="center"
            justifyContent="space-between"
          >
            <Avatar name={data.doctor.name} size="xl" />
            <Box>
              <Button
                mr="10px"
                variant="solid"
                colorScheme="teal"
                backgroundColor="brand.500"
                onClick={() => console.log("change image")}
              >
                {t("Cambia immagine")}
              </Button>
              <Button
                variant="outline"
                colorScheme="gray"
                onClick={() => console.log("remove")}
                backgroundColor="white"
              >
                {t("Rimuovi")}
              </Button>
            </Box>
          </Flex>
          <form id="doctor-edit-form" onSubmit={handleSubmit(onSubmit)}>
            <VStack alignItems="unset" mt="32px" spacing="32px">
              <Flex alignItems="flex-end">
                <FormControl
                  mr="16px"
                  isInvalid={!!errors.name || !!errors.surname}
                >
                  <FormLabel htmlFor="name" fontSize="14px">
                    {t("Nome completo")}*
                  </FormLabel>
                  <Input
                    id="name"
                    placeholder={t("Nome")}
                    {...register("name")}
                    backgroundColor="brand.400 !important"
                  />
                  <FormErrorMessage>{nameError}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!errors.name || !!errors.surname}>
                  <Input
                    id="surname"
                    placeholder={t("Cognome")}
                    backgroundColor="brand.400 !important"
                    {...register("surname")}
                  />
                  <FormErrorMessage>{nameError}</FormErrorMessage>
                </FormControl>
              </Flex>

              <FormControl isInvalid={!!errors.fiscalCode}>
                <FormLabel htmlFor="fiscalCode" fontSize="14px">
                  {t("Codice Fiscale")}*
                </FormLabel>
                <Input
                  id="fiscalCode"
                  placeholder={t("Codice Fiscale")}
                  backgroundColor="brand.400 !important"
                  {...register("fiscalCode")}
                />
                <FormErrorMessage>
                  {errors.fiscalCode?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.piva}>
                <FormLabel htmlFor="piva" fontSize="14px">
                  {t("P.IVA")}
                </FormLabel>
                <Input
                  id="piva"
                  placeholder={t("P.IVA")}
                  backgroundColor="brand.400 !important"
                  {...register("piva")}
                />
                <FormErrorMessage>{errors.piva?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.phone}>
                <FormLabel htmlFor="phone" fontSize="14px">
                  {t("Cellulare")}
                </FormLabel>
                <Input
                  id="phone"
                  placeholder={t("Cellulare")}
                  backgroundColor="brand.400 !important"
                  {...register("phone")}
                />
                <FormErrorMessage>{errors.phone?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.email}>
                <FormLabel htmlFor="email" fontSize="14px">
                  {t("Email")}
                </FormLabel>
                <Input
                  id="email"
                  type="email"
                  placeholder={t("Email")}
                  backgroundColor="brand.400 !important"
                  {...register("email")}
                  disabled={true}
                />
                <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.pec}>
                <FormLabel htmlFor="pec" fontSize="14px">
                  {t("PEC")}
                </FormLabel>
                <Input
                  id="pec"
                  placeholder={t("PEC")}
                  backgroundColor="brand.400 !important"
                  {...register("pec")}
                />
                <FormErrorMessage>{errors.pec?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.specialization}>
                <FormLabel htmlFor="specialization" fontSize="14px">
                  {t("Specializzazione")}
                </FormLabel>
                <Input
                  id="specialization"
                  placeholder={t("Specializzazione")}
                  backgroundColor="brand.400 !important"
                  {...register("specialization")}
                />
                <FormErrorMessage>
                  {errors.specialization?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.order}>
                <FormLabel htmlFor="order" fontSize="14px">
                  {t("Ordine")}
                </FormLabel>
                <Input
                  id="order"
                  placeholder={t("Ordine")}
                  backgroundColor="brand.400 !important"
                  {...register("order")}
                />
                <FormErrorMessage>{errors.order?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.orderNumber}>
                <FormLabel htmlFor="orderNumber" fontSize="14px">
                  {t("Numero ordine")}
                </FormLabel>
                <Input
                  id="orderNumber"
                  placeholder={t("Numero ordine")}
                  backgroundColor="brand.400 !important"
                  {...register("orderNumber")}
                />
                <FormErrorMessage>
                  {errors.orderNumber?.message}
                </FormErrorMessage>
              </FormControl>
              <Box>
                <FormLabel htmlFor="name" fontSize="14px">
                  {t("Servizi svolti")}
                </FormLabel>

                <SimpleGrid columns={[2, null, 3]} spacing="20px">
                  {/* FIXME: checkboxes can be reusable components. -> AddDoctorModal */}
                  <Checkbox
                    colorScheme="teal"
                    defaultChecked
                    onChange={(e) =>
                      setState({
                        ...state,
                        services: {
                          ...state.services,
                          cardiology: !state.services.cardiology,
                        },
                      })
                    }
                  >
                    {t("Visita Cardiologica")}
                  </Checkbox>
                  <Checkbox
                    colorScheme="teal"
                    defaultChecked
                    onChange={(e) =>
                      setState({
                        ...state,
                        services: {
                          ...state.services,
                          stressTest: !state.services.stressTest,
                        },
                      })
                    }
                  >
                    Prove da sforzo
                  </Checkbox>
                  <Checkbox
                    colorScheme="teal"
                    defaultChecked
                    onChange={(e) =>
                      setState({
                        ...state,
                        services: {
                          ...state.services,
                          electrogramma: !state.services.electrogramma,
                        },
                      })
                    }
                  >
                    Controlli pace-maker
                  </Checkbox>

                  <Checkbox
                    colorScheme="teal"
                    defaultChecked
                    onChange={(e) =>
                      setState({
                        ...state,
                        services: {
                          ...state.services,
                          pacemaker: !state.services.pacemaker,
                        },
                      })
                    }
                  >
                    Elettrocardiogramma
                  </Checkbox>
                  <Checkbox
                    colorScheme="teal"
                    defaultChecked
                    onChange={(e) =>
                      setState({
                        ...state,
                        services: {
                          ...state.services,
                          tilting: !state.services.tilting,
                        },
                      })
                    }
                  >
                    Tilting test
                  </Checkbox>
                  <Checkbox
                    colorScheme="teal"
                    defaultChecked
                    onChange={(e) =>
                      setState({
                        ...state,
                        services: {
                          ...state.services,
                          myocardialScintigraphy:
                            !state.services.myocardialScintigraphy,
                        },
                      })
                    }
                  >
                    Scintigrafie miocardiche
                  </Checkbox>

                  <Checkbox
                    colorScheme="teal"
                    defaultChecked
                    onChange={(e) =>
                      setState({
                        ...state,
                        services: {
                          ...state.services,
                          holter: !state.services.holter,
                        },
                      })
                    }
                  >
                    Holter
                  </Checkbox>
                  <Checkbox
                    colorScheme="teal"
                    defaultChecked
                    onChange={(e) =>
                      setState({
                        ...state,
                        services: {
                          ...state.services,
                          ecocardiogramma: !state.services.ecocardiogramma,
                        },
                      })
                    }
                  >
                    Ecocardiogramma
                  </Checkbox>
                </SimpleGrid>
              </Box>
            </VStack>
          </form>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Label
                label={t("Disponibilità generali")}
                style={{ marginTop: "32px" }}
              />
              <Label
                className="label__gray"
                style={{ marginTop: "12px" }}
                label={t(
                  "Fai tap su uno slot per switchare da disponibile a non disponbile"
                )}
              />
            </Box>
            <Box display="flex">
              <Box
                display="flex"
                alignItems="center"
                h="28px"
                p="8px"
                backgroundColor="#F4F4F4"
                borderRadius="8px"
                fontSize="12px"
                mr="4px"
              >
                <Box
                  w="12px"
                  h="12px"
                  background="#FFFFFF"
                  borderRadius="4px"
                />
                &nbsp;= Disponibile
              </Box>
              <Box
                display="flex"
                alignItems="center"
                h="28px"
                p="8px"
                borderRadius="8px"
                fontSize="12px"
              >
                <Box
                  w="12px"
                  h="12px"
                  background="#F4F4F4"
                  borderRadius="4px"
                />
                &nbsp;= Non disponibile
              </Box>
            </Box>
          </Box>

          <FullCalendar
            height="auto"
            events={[]}
            selectable={false}
            selectMirror={true}
            selectOverlap={false}
            locale={calendarLanguage}
            contentHeight={600}
            plugins={[timeGridPlugin, interactionPlugin]}
            initialView="timeGridWeek"
            businessHours={[
              ...(slots.length
                ? slots
                : [
                    {
                      dow: [1, 2, 3, 4, 5],
                      start: "23:00",
                      end: "23:00",
                    },
                  ]
              ).map((slot: { dow: number[]; start: string; end: string }) => ({
                daysOfWeek: slot.dow,
                startTime: slot.start,
                endTime: slot.end,
              })),
            ]}
            hiddenDays={[0, 6]}
            slotMinTime={"08:00:00"}
            slotMaxTime={"21:00:00"}
            selectConstraint="businessHours"
            eventConstraint="businessHours"
            headerToolbar={false}
            buttonText={{
              day: t("Giorno"),
              week: t("Settimana"),
              month: t("Mese"),
            }}
            slotDuration="01:00"
            slotLabelFormat={[
              {
                hour: "2-digit",
                minute: "2-digit",
              },
            ]}
            datesSet={(arg) => {
              // setState({
              //   from: arg.start.toISOString().substring(0, 10),
              //   to: arg.end.toISOString().substring(0, 10),
              // });
            }}
            dayHeaderFormat={{ weekday: "short" }}
            allDaySlot={false}
            dateClick={handleDateClick}
            select={(info) => {
              // setIsAddAppointment(true);
              console.log(info);
            }}
          />

          <GrayLine marginY={48} />

          <TitleSmall
            text={t("Cambia password")}
            color={colors[100]}
            style={{ marginRight: 24 }}
          />
          <VStack alignItems="unset" mt="32px" spacing="32px">
            <FormControl isInvalid={!!errors.password}>
              <FormLabel htmlFor="password" fontSize="14px">
                {t("Password corrente")}
              </FormLabel>
              <Input
                id="password"
                placeholder={t("Password corrente")}
                backgroundColor="brand.400 !important"
                {...register("password")}
              />
              <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
            </FormControl>

            <Flex alignItems="flex-end">
              <FormControl mr="16px" isInvalid={!!errors.newPassword}>
                <FormLabel htmlFor="newPassword" fontSize="14px">
                  {t("Nuova password")}
                </FormLabel>
                <Input
                  id="newPassword"
                  placeholder={t("Nuova password")}
                  {...register("newPassword")}
                  backgroundColor="brand.400 !important"
                />
                <FormErrorMessage>
                  {errors.newPassword?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.confirmNewPassword}>
                <FormLabel htmlFor="confirmNewPassword" fontSize="14px">
                  {t("Conferma nuova password")}
                </FormLabel>
                <Input
                  id="surname"
                  placeholder={t("Conferma nuova password")}
                  backgroundColor="brand.400 !important"
                  {...register("confirmNewPassword")}
                />
                <FormErrorMessage>
                  {errors.confirmNewPassword?.message}
                </FormErrorMessage>
              </FormControl>
            </Flex>

            <Button
              colorScheme="gray"
              variant="outline"
              onClick={() => {
                console.log("update");
              }}
              backgroundColor="white"
              width="174px"
            >
              {t("Aggiorna password")}
            </Button>
          </VStack>

          <GrayLine marginY={48} />

          <TitleSmall
            text={t("Notifiche")}
            color="#FFBC99"
            style={{ marginRight: 24 }}
          />
          <ToggleInput
            marginTop={32}
            title={t("Nuovi appuntamenti")}
            checked={state.isNewAppointment}
            onChange={(val: any) =>
              setState({ ...state, isNewAppointment: !state.isNewAppointment })
            }
          />
          <GrayLine marginY={16} />
          <ToggleInput
            title={t("Modifiche agli appuntamenti")}
            checked={state.isEditAppointment}
            onChange={(val: any) =>
              setState({
                ...state,
                isEditAppointment: !state.isEditAppointment,
              })
            }
          />
          <GrayLine marginY={16} />
          <ToggleInput
            title={t("Registrazione sull’app da parte dei pazienti invitati")}
            checked={state.patientRegistration}
            onChange={(val: any) =>
              setState({
                ...state,
                patientRegistration: !state.patientRegistration,
              })
            }
          />

          <GrayLine marginY={16} />

          <ToggleInput
            title={t("Meeting di equipe")}
            checked={state.commandMeeting}
            onChange={(val: any) =>
              setState({ ...state, commandMeeting: !state.commandMeeting })
            }
          />

          <Flex mt="32px" alignItems="center" justifyContent="space-between">
            <Button
              colorScheme="gray"
              variant="outline"
              onClick={() => navigate("/profile")}
              backgroundColor="white"
            >
              {t("Annulla")}
            </Button>
            <Button
              type="submit"
              form="doctor-edit-form"
              colorScheme="teal"
              variant="solid"
              backgroundColor="brand.500"
              isLoading={updateLoading}
            >
              {t("Salva")}
            </Button>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default EditDoctorProfile;
