import { gql } from '@apollo/client';

export const UPDATE_USER = gql`
  mutation updateUser($input: UserUpdateInput!) {
    updateUser( input: $input) {
      email
      id
      invitedAt
      name
      profilePhoto
      registeredAt
      role
      phone
      surname
      language
    }
  }
`;
