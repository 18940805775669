import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

type DefaultModalProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description?: string;
  confirmText?: string;
  cancelText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  children?: React.ReactNode;
  buttonType?: 'button' | 'submit' | 'reset' | undefined;
};

const DefaultModal: React.FC<DefaultModalProps> = ({
  isOpen,
  onClose,
  description,
  title,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
  children,
  buttonType,
}) => {
  return (
    <Modal
      blockScrollOnMount={false}
      isCentered
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {description && (
            <Text color={'GrayText'} mb="1rem">
              {description}
            </Text>
          )}
          {children}
        </ModalBody>

        <ModalFooter flexDir={'row'}>
          {cancelText && (
            <Button
              colorScheme="gray"
              variant="outline"
              backgroundColor="white"
              mr={3}
              onClick={() => {
                onClose();
                onCancel?.();
              }}
              flex={2}
            >
              {cancelText ?? 'No'}
            </Button>
          )}
          {cancelText && confirmText && <Flex flex={2} />}
          <Button
            colorScheme="brand"
            flex={4}
            mr={3}
            type={buttonType}
            onClick={() => {
              // TODO: if (buttonType === 'submit') return;
              onClose();
              onConfirm?.();
            }}
          >
            {confirmText ?? 'Si'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export { DefaultModal };
