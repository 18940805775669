import { useQuery } from "@apollo/client";
import { useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Avatar, Tablist, Tab, Button } from "evergreen-ui";
import { Box, Flex, Spinner } from "@chakra-ui/react";
import MailIcon from "../../../components/Icons/MailIcon";
import PhoneIcon from "../../../components/Icons/PhoneIcon";
import ClinicReview from "./ClinicReview";
import { GET_CLINIC } from "../../../graphql/queries/clinics.query";

import "./index.scss";
import { useTranslation } from "react-i18next";

const Clinic = () => {
  const params = useParams();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const { t, i18n } = useTranslation();
  const { data: clinic, loading } = useQuery(GET_CLINIC, {
    variables: { id: params.clinicId },
  });

  const data = useMemo(() => clinic?.clinic || null, [clinic]);
  const tabs = [
    {
      title: t("Panoramica"),
      view: (props: any = {}) => <ClinicReview {...props} />,
    },
    {
      title: t("Metriche"),
      view: () => null,
    },
  ];
  if (loading) {
    return (
      <Flex alignItems="center" justifyContent="center" height="100%">
        <Spinner size="xl" color="brand.500" thickness="4px" speed="0.65s" />
      </Flex>
    );
  }

  return (
    <Box className="clinic-container">
      <Box className="clinic-header">
        <Box className="clinic-header__info">
          <Box display="flex">
            <Avatar
              src={data ? `${data.name}` : ""}
              name={data ? `${data.name}` : ""}
              size={80}
            />
            <Box
              marginLeft={16}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Box className="clinic-name">
                {data?.name || ""}&nbsp;{data?.surname || ""}
              </Box>
              <Box
                h="32px"
                p="4px 8px"
                backgroundColor="#FFD88D"
                borderRadius={6}
                display="flex"
                alignItems="center"
              >
                {/* Fixed status */}
                {data?.status === "invited" ? t("Invitato") : t("Registrato")}
              </Box>
            </Box>
          </Box>
          <Box>
            <Button
              borderRadius="50%"
              height="64px !important"
              width={64}
              onClick={() => {
                console.log();
              }}
            >
              <PhoneIcon />
            </Button>
            <Button
              borderRadius="50%"
              height="64px !important"
              width={64}
              marginLeft={16}
              onClick={() => {
                console.log();
              }}
            >
              <MailIcon />
            </Button>
          </Box>
        </Box>
        <Box mt="40px">
          <Tablist mb="16px" flexBasis={240} mr="24px" h="100%">
            {tabs.map(({ title }, index) => (
              <Tab
                className="nav-tab"
                key={title}
                id={title}
                onSelect={() => setSelectedIndex(index)}
                isSelected={index === selectedIndex}
                aria-controls={`Boxl-${title}`}
                height={40}
                borderRadius={8}
                backgroundColor={
                  index === selectedIndex
                    ? "#EFEFEF !important"
                    : "#FCFCFC !important"
                }
                color={
                  index === selectedIndex
                    ? "#1A1D1F !important"
                    : "#6F767E !important"
                }
                boxShadow="none !important"
              >
                {title}
              </Tab>
            ))}
          </Tablist>
        </Box>
        <Box border="1px solid #EFEFEF" w="100%" mt="40px" />
      </Box>
      <Box>
        {tabs.map(({ title, view }, index) => (
          <Box
            key={title}
            id={`Boxl-${title}`}
            role="tabBoxl"
            aria-labelledby={title}
            aria-hidden={index !== selectedIndex}
            display={index === selectedIndex ? "block" : "none"}
          >
            {view({ clinic: data })}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Clinic;
