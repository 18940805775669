import { gql } from "@apollo/client";

export const GET_PATIENTS_BY_AGENCY = gql`
query GetPatientsByAgency {
    getPatientsByAgency {
      id
      name
      surname
      email
      fiscalCode
      phone
      sex
      tags
      status
    }
  }
`;


export const GET_PATIENT_BY_AGENCY = gql`
query GetPatientByAgency($patientId: ID!) {
  getPatientByAgency(patientId: $patientId) {
    id
    name
    surname
    email
    birthDate
    birthCity
    fiscalCode
    phone
    pec
    sex
    addressResidence
    addressDomicile
    gpName
    gpSurname
    gpPhone
    gpEmail
    gpPec
    anprId
    note
    tags
    status
    createdAt
    caregiver {
      id
      name
      surname
      relationship
      email
      phone
    }
    relatives {
      id
      name
    }
    pathologies {
      id
      name
    }
  }
}`;