import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { PlusIcon, SearchIcon } from "evergreen-ui";
import {
  Avatar,
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Tag,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  FilterFn,
} from "@tanstack/react-table";
import format from "date-fns/format";
import { useNavigate } from "react-router-dom";
import { EmailButton } from "../../../components/EmailButton";
import { MobileBadge } from "../../../components/MobileBadge";
import TitleSmall from "../../../components/TitleSmall";
import { IPatient } from "../../../interfaces/IPatient";
import AddPatientModal from "../../../components/Modals/AddPatientModal";
import AddAppointmentModal from "../../../components/Modals/AddAppointmentModal";
import { colors } from "../../../colors";
import PatientsTable from "./PatientsTable";
import { GET_PATIENTS } from "../../../graphql/queries/patients.query";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_PATIENT } from "../../../graphql/mutations/create-patient.mutation";
import { CREATE_APPOINTMENT } from "../../../graphql/mutations/create-appointment.mutation";
import { addMinutes } from "date-fns";
import { GET_USER } from "../../../graphql/queries/user.query";
import { GET_APPOINTMENTS } from "../../../graphql/queries/appointments.query";
import { GET_AVAILABILITY } from "../../../graphql/queries/availability.query";
import { GET_DOCTOR } from "../../../graphql/queries/doctors.query";
import { ISlot } from "../../../interfaces/ISlot";
import { useTranslation } from "react-i18next";
import { GET_PATIENTS_BY_AGENCY } from "src/graphql/queries/get-patients-by-agency.query";

const globalFilterFn: FilterFn<IPatient> = (row, columnId, filterValue) => {
  let rowOriginalLowercase = Object.fromEntries(
    Object.entries(row.original).map(([key, value]) => [
      key,
      typeof value == "string" ? value.toLowerCase() : value,
    ])
  );

  const isFound = Object.values(rowOriginalLowercase).some((item) =>
    item?.includes(filterValue.toLowerCase())
  );
  return isFound;
};

enum PatientStatus {
  REGISTERED = "registered",
  INVITED = "invited",
}

const Patients = () => {
  const patientModalRef = useRef<{ resetForm: () => void }>();

  const navigate = useNavigate();
  const toast = useToast();

  const [globalFilter, setGlobalFilter] = useState("");
  const [isAddPatient, setIsAddPatient] = useState(false);
  const [isAddAppointment, setIsAddAppointment] = useState<{
    openModal: boolean;
    patientId: number | null;
  }>({
    openModal: false,
    patientId: null,
  });
  const [filtered, setFiltered] = useState<string>(PatientStatus.REGISTERED);

  const userQuery = useQuery(GET_USER);
  const { role } = userQuery.data.me;

  const patientsByAgencyQuery = useQuery(GET_PATIENTS_BY_AGENCY);
  const patientsQuery = useQuery(GET_PATIENTS);

  const { data: appointments } = useQuery(GET_APPOINTMENTS);

  const [createPatient, { loading }] = useMutation(CREATE_PATIENT, {
    refetchQueries: [
      { query: GET_PATIENTS_BY_AGENCY },
      { query: GET_PATIENTS },
    ],
    // refetchQueries: [{ query: GET_PATIENTS }],
  });

  const [createAppointment] = useMutation(CREATE_APPOINTMENT, {
    refetchQueries: [{ query: GET_APPOINTMENTS }],
  });
  const { data: dataDoctor, loading: doctorLoading } = useQuery(GET_DOCTOR);
  const doctorId = dataDoctor?.doctor?.id;
  const { data: availabilityData, loading: availabilityLoading } = useQuery(
    GET_AVAILABILITY,
    {
      skip: !doctorId,
      variables: { doctorId },
    }
  );

  const handleSaveAppointment = async (event: any) => {
    try {
      const time = event.startTime.padStart(5, 0);
      const date = format(event.date, "yyyy-MM-dd");

      const startDateTime = new Date(`${date} ${time}`).toISOString();
      const endDateTime = addMinutes(
        new Date(`${date} ${time}`),
        event.service.duration
      ).toISOString();

      const { id, email, name, surname } = userQuery.data.me;

      await createAppointment({
        variables: {
          input: {
            serviceId: event.service.id,
            startDateTime,
            endDateTime,
            online: event.isOnline,
            note: event.note,
            patientId: parseInt(event.patientId),
            attendees: [
              {
                user: {
                  id: parseInt(id),
                  email,
                  displayName: `${name} ${surname}`,
                },
              },
              {
                user: {
                  id: parseInt(event.patientId),
                  email: event.email,
                  displayName: `${event.name} ${event.surname}`,
                },
              },
            ],
          },
        },
      });
      setIsAddAppointment({ openModal: false, patientId: null });
    } catch (error: any) {
      toast({
        title: t("Errore durante la creazione dell'appuntamento."),
        description: error.message,
        status: "error",
        isClosable: true,
      });
    }
  };

  const handleSavePatient = async (data: any) => {
    try {
      await createPatient({
        variables: {
          input: {
            name: data.name,
            surname: data.surname,
            email: data.email,
            phone: data.phone,
            fiscalCode: data.fiscalCode,
            tags: data.tags,
          },
        },
      });
      setIsAddPatient(false);
      patientModalRef.current?.resetForm();
    } catch (error: any) {
      toast({
        title: t("Errore durante la creazione del paziente."),
        description: error.message,
        status: "error",
        isClosable: true,
      });
    }
  };
  const { t, i18n } = useTranslation();

  const columnsDef: ColumnDef<IPatient>[] = [
    {
      id: "name",
      header: t("Nome"),
      accessorFn: (row) => `${row.name} ${row.surname}`,
      cell: ({ row, getValue }) => {
        const id = row.original.id;
        const name = getValue() as string;

        const handleClick = () => {
          navigate(`/patients/${id}`);
        };

        return (
          <Flex alignItems="center" gap="10px">
            <Avatar name={name} />
            <Button
              variant="link"
              colorScheme="teal"
              fontSize="15px"
              fontWeight="bold"
              color="brand.700"
              onClick={handleClick}
              whiteSpace="break-spaces"
              textAlign="start"
            >
              {name}
            </Button>
          </Flex>
        );
      },
    },
    {
      header: t("Contatto"),
      accessorFn: (row) => ({ email: row.email, mobile: row.phone }),
      id: "contact",
      cell: ({ getValue }) => {
        const values = getValue() as {
          email: string | undefined;
          mobile: string | undefined;
        };

        return (
          <Flex gap="10px" alignItems="center">
            {values.mobile && <MobileBadge mobile={values.mobile} />}
            {values.email && <EmailButton email={values.email} />}
          </Flex>
        );
      },
    },
    {
      header: t("Prenota una visita"),
      enableGlobalFilter: false,
      accessorKey: "id",
      cell: ({ getValue }) => (
        <Button
          marginRight={10}
          borderRadius={8}
          onClick={() => {
            setIsAddAppointment({
              openModal: true,
              patientId: getValue() as number,
            });
          }}
        >
          {t("Aggiungi appuntamento")}
        </Button>
      ),
    },
    {
      accessorKey: "id",
      id: "tag",
      header: t("Tag"),
      cell: ({ row }) => {
        const tags = row.original.tags ?? [];
        return tags.map((x, idx) => (
          <Tag
            key={idx}
            variant="solid"
            colorScheme="teal"
            marginRight="4px"
            size="sm"
          >
            {x}
          </Tag>
        ));
      },
    },
  ];

  const data = useMemo(() => {
    if (role == "agency_admin") {
      return (patientsByAgencyQuery.data?.getPatientsByAgency || []).filter(
        // (patientsQuery.data?.patients || []).filter(
        (patient: IPatient) => patient.status === filtered
      );
    } else {
      // (patientsQuery.data?.getPatientsByAgency || []).filter(
      return (patientsQuery.data?.patients || []).filter(
        (patient: IPatient) => patient.status === filtered
      );
    }
  }, [patientsQuery.data, patientsByAgencyQuery.data, role, filtered]);

  const slots = useMemo(
    () =>
      (availabilityData?.availability?.slots || []).map(
        ({ dow, start, end }: ISlot) => ({
          dow,
          start,
          end,
        })
      ),
    [availabilityData]
  );
  // const columns = useMemo(() => columnsDef, []);

  const columns = useMemo(() => {
    return columnsDef;
  }, []);

  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
    },
    globalFilterFn: globalFilterFn,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  if (patientsQuery.loading || patientsByAgencyQuery.loading) {
    return (
      <Flex alignItems="center" justifyContent="center" height="100%">
        <Spinner size="xl" color="brand.500" thickness="4px" speed="0.65s" />
      </Flex>
    );
  }

  return (
    <Box h="100%" mr="91px" ml="91px">
      <AddPatientModal
        ref={patientModalRef}
        loading={loading}
        isOpen={isAddPatient}
        onClose={() => setIsAddPatient(false)}
        onSave={handleSavePatient}
      />
      <AddAppointmentModal
        isOpen={isAddAppointment.openModal}
        onClose={() =>
          setIsAddAppointment({ openModal: false, patientId: null })
        }
        onSave={handleSaveAppointment}
        events={appointments?.appointments}
        patientId={isAddAppointment.patientId}
        slots={slots}
      />

      <Text fontSize="40px" fontWeight="semibold" pt="40px" pb="24px">
        {t("Lista pazienti")}
      </Text>

      <Box backgroundColor="brand.300">
        <Flex
          height="76px"
          paddingInlineStart="24px"
          paddingInlineEnd="24px"
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex alignItems="center">
            <TitleSmall
              text={t("Pazienti")}
              color={colors[100]}
              style={{ marginRight: 24 }}
            />
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<SearchIcon color="gray" />}
              />
              <Input
                placeholder={t("Cerca per nome o altro")}
                value={globalFilter ?? ""}
                background="brand.400 !important"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setGlobalFilter(e.target.value)
                }
              />
            </InputGroup>
          </Flex>
          <Box>
            <Button
              colorScheme="gray"
              variant="ghost"
              isActive={filtered === PatientStatus.REGISTERED}
              onClick={() => setFiltered(PatientStatus.REGISTERED)}
              marginRight={2}
            >
              {t("Registrato")}
            </Button>
            <Button
              colorScheme="gray"
              variant="ghost"
              isActive={filtered === PatientStatus.INVITED}
              onClick={() => setFiltered(PatientStatus.INVITED)}
            >
              {t("Invitato")}
            </Button>
          </Box>
          {/* <Button
            colorScheme="teal"
            variant="solid"
            onClick={() => setIsAddPatient(true)}
            leftIcon={<PlusIcon />}
            width="188px"
            height="48px"
            backgroundColor="brand.500"
          >
            {t("Aggiungi paziente")}
          </Button> */}
        </Flex>
      </Box>

      <PatientsTable table={table} />
    </Box>
  );
};

export default Patients;
