import { ReactNode, useEffect, useState } from 'react';
import { Auth, I18n } from 'aws-amplify';

import { AuthContext } from './context';

I18n.setLanguage('it'); // FIXME: check the localization

interface IProps {
  children: ReactNode;
}

// Errors: https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_SignUp.html#API_SignUp_Errors
enum CognitoErrors {
  USERNAME_EXISTS_EXCEPTION = 'UsernameExistsException',
  INVALID_PASSWORD_EXCEPTION = 'InvalidPasswordException',
  CODE_MISMATCH_EXCEPTION = 'CodeMismatchException',
  EXPIRED_CODE_EXCEPTION = 'ExpiredCodeException',
  TOO_MANY_FAILED_ATTEMPTS_EXCEPTION = 'TooManyFailedAttemptsException',
  USER_NOT_FOUND_EXCEPTION = 'UserNotFoundException',
  CODE_DELIVERY_FAILURE_EXCEPTION = 'CodeDeliveryFailureException',
}

const AuthProvider = ({ children }: IProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [inProgress, setInProgress] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    async function check() {
      await checkIsAuthenticated();
    }

    check();
  }, []);

  const checkIsAuthenticated = async () => {
    try {
      setInProgress(true);
      const user = await Auth.currentAuthenticatedUser();
      setInProgress(false);
      if (user) {
        setIsAuthenticated(true);
      }
    } catch (e) {
      setInProgress(false);
      setIsAuthenticated(false);
    }
  };

  const signUp = async (username: string, password: string) => {
    try {
      setInProgress(true);
      await Auth.signUp({ username, password, autoSignIn: { enabled: true } });
      setInProgress(false);
    } catch (e) {
      setInProgress(false);
      const { code } = e as { code: string };
      switch (code) {
        case CognitoErrors.USERNAME_EXISTS_EXCEPTION:
          throw `Esiste già un account con l'email specificata.`;
        case CognitoErrors.INVALID_PASSWORD_EXCEPTION:
          throw 'La password non è valida.';
        default:
          throw 'Errore interno del server.';
      }
    }
  };

  const confirmSignUp = async (username: string, code: string) => {
    try {
      setInProgress(true);
      await Auth.confirmSignUp(username, code);
      setInProgress(false);
    } catch (e) {
      setInProgress(false);
      const { code } = e as { code: string };
      switch (code) {
        case CognitoErrors.CODE_MISMATCH_EXCEPTION:
          throw 'Il codice fornito non corrisponde.';
        case CognitoErrors.EXPIRED_CODE_EXCEPTION:
          throw 'Il codice è scaduto.';
        case CognitoErrors.TOO_MANY_FAILED_ATTEMPTS_EXCEPTION:
          throw 'Hai fatto troppi tentativi falliti. Per favore riprova più tardi.';
        case CognitoErrors.USER_NOT_FOUND_EXCEPTION:
          throw 'Utente non trovato.';
        default:
          throw 'Errore interno del server.';
      }
    }
  };

  const resendConfirmationCode = async (username: string) => {
    try {
      await Auth.resendSignUp(username);
    } catch (e) {
      const { code } = e as { code: string };
      switch (code) {
        case CognitoErrors.CODE_DELIVERY_FAILURE_EXCEPTION:
          throw 'Il codice di verifica non viene consegnato';
        case CognitoErrors.TOO_MANY_FAILED_ATTEMPTS_EXCEPTION:
          throw 'Hai fatto troppi tentativi falliti. Per favore riprova più tardi.';
        case CognitoErrors.USER_NOT_FOUND_EXCEPTION:
          throw 'Utente non trovato.';
        default:
          throw 'Errore interno del server.';
      }
    }
  };

  const signIn = async (username: string, password: string) => {
    try {
      setInProgress(true);
      await Auth.signIn(username, password);
      setIsAuthenticated(true);
      setInProgress(false);
    } catch (e) {
      setInProgress(false);
      throw e;
    }
  };

  const signOut = async () => {
    try {
      setInProgress(true);
      await Auth.signOut();
      setIsAuthenticated(false);
      setInProgress(false);
    } catch (e) {
      setInProgress(false);
      setError(e as Error);
    }
  };

  const forgotPassword = async (username: string) => {
    try {
      setInProgress(true);
      await Auth.forgotPassword(username);
      setInProgress(false);
    } catch (e) {
      console.log(e);
      const { code } = e as { code: string };
      switch (code) {
        case CognitoErrors.USERNAME_EXISTS_EXCEPTION:
          throw `Esiste già un account con l'email specificata.`;
        case CognitoErrors.TOO_MANY_FAILED_ATTEMPTS_EXCEPTION:
          throw 'Hai fatto troppi tentativi falliti. Per favore riprova più tardi.';
        case CognitoErrors.USER_NOT_FOUND_EXCEPTION:
          throw 'Utente non trovato.';
        default:
          throw 'Errore interno del server.';
      }
    }
  };

  const forgotPasswordSubmit = async (
    username: string,
    code: string,
    newPassword: string
  ) => {
    try {
      setInProgress(true);
      await Auth.forgotPasswordSubmit(username, code, newPassword);
      setInProgress(false);
    } catch (e) {
      setInProgress(false);
      const { code } = e as { code: string };
      switch (code) {
        case CognitoErrors.CODE_MISMATCH_EXCEPTION:
          throw 'Il codice fornito non corrisponde.';
        case CognitoErrors.EXPIRED_CODE_EXCEPTION:
          throw 'il codice è scaduto.';
        case CognitoErrors.TOO_MANY_FAILED_ATTEMPTS_EXCEPTION:
          throw 'Hai fatto troppi tentativi falliti. Per favore riprova più tardi.';
        case CognitoErrors.USER_NOT_FOUND_EXCEPTION:
          throw 'Utente non trovato.';
        default:
          throw 'Errore interno del server.';
      }
    }
  };

  const changePassword = async (currPassword: string, newPassword: string) => {
    try {
      setInProgress(true);
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, currPassword, newPassword);
      setInProgress(false);
    } catch (err) {
      setInProgress(false);
      const { code } = err as { code: string };
      switch (code) {
        case CognitoErrors.INVALID_PASSWORD_EXCEPTION:
          throw 'La password non è valida.';
        default:
          throw 'Errore interno del server.';
      }
    }
  };  

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        signUp,
        confirmSignUp,
        checkIsAuthenticated,
        resendConfirmationCode,
        forgotPassword,
        forgotPasswordSubmit,
        changePassword,
        inProgress,
        isAuthenticated,
        error,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
