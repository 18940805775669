import {
  ChangeEvent,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Checkbox,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { IModal } from "../../../interfaces/IModal";
import { SearchIcon } from "evergreen-ui";
import React from "react";
import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ChevronVerticalIcon } from "src/components/Icons/ChevronVerticalIcon";
import {
  IServiceHealth,
  IServiceHealthBackEnd,
} from "src/interfaces/IServiceHealth";
import { ServiceHealthTable } from "./ServiceHealthTable";
import {
  ServiceHealthFilter,
  ServiceHealthFilters,
} from "./ServiceHealthFilters";
import { GET_SERVICES_HEALTH } from "src/graphql/queries/services-health.query";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

const AssociateHealthServiceModal = forwardRef(
  ({ isOpen, loading, onClose, onSave, currentData }: IModal, ref) => {
    const { data, loading: servicesLoading } = useQuery<{
      medicalServices: IServiceHealthBackEnd[];
    }>(GET_SERVICES_HEALTH);

    const healtServices = data?.medicalServices.map((medicalService) => {
      return {
        id: medicalService.id,
        description: medicalService.description,
        type: medicalService.type.name,
        acronym: medicalService.acronym.name,
        step: medicalService.step.name,
        priceList: medicalService.priceList.name,
      };
    });

    const [selectedServices, setSelectedServices] = useState<IServiceHealth[]>(
      []
    );

    const { t, i18n } = useTranslation();
    const [globalFilter, setGlobalFilter] = useState("");

    console.log(healtServices);

    const initialServices = useMemo(() => {
      const filtered = (healtServices ?? []).filter(
        (service) =>
          !currentData
            .map((item: IServiceHealth) => item.id)
            ?.includes(service.id)
      );

      return filtered ?? [];
    }, [currentData, healtServices]);

    console.log({
      initialServices,
    });

    const [filteredServices, setFilteredServices] = useState<IServiceHealth[]>(
      () => initialServices // Set initial state to initialServices
    );

    useEffect(() => {
      setFilteredServices(initialServices);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentData]);

    const onToggleFilter = useCallback(
      (filters: ServiceHealthFilters) => {
        const filtered = initialServices.filter((service) => {
          const listFilters = filters.priceList
            .filter((filter) => filter.checked)
            .map((filter) => filter.name);

          const tagFilters = filters.acronym
            .filter((filter) => filter.checked)
            .map((filter) => filter.name);

          const genreFilters = filters.type
            .filter((filter) => filter.checked)
            .map((filter) => filter.name);

          return (
            (listFilters.length === 0 ||
              listFilters.includes(service.priceList)) &&
            (tagFilters.length === 0 || tagFilters.includes(service.acronym)) &&
            (genreFilters.length === 0 || genreFilters.includes(service.type))
          );
        });

        setFilteredServices(filtered ?? []);
      },
      [initialServices]
    );

    if (servicesLoading) {
      return (
        <Flex alignItems="center" justifyContent="center" height="100%">
          <Spinner size="xl" color="brand.500" thickness="4px" speed="0.65s" />
        </Flex>
      );
    }

    return (
      <Modal
        size="6xl"
        isOpen={isOpen}
        isCentered={true}
        onClose={onClose}
        scrollBehavior="inside"
        closeOnOverlayClick={false}
      >
        <ModalOverlay backgroundColor={"#0000007D"} />
        <ModalContent backgroundColor="brand.600">
          <ModalHeader pt="24px">
            <Text>{t("Associa Prestazione")}</Text>
          </ModalHeader>
          <ModalCloseButton mt="8px" mr="8px" borderRadius="23px" />

          <ModalBody pb={6} mt="16px">
            <Flex
              height="76px"
              paddingInlineEnd="24px"
              alignItems="center"
              justifyContent="space-between"
            >
              <Flex alignItems="center">
                <InputGroup
                  border="2px"
                  borderRadius="10px"
                  borderColor="gray.200"
                >
                  <InputLeftElement
                    pointerEvents="none"
                    children={<SearchIcon color="gray" />}
                  />
                  <Input
                    width="360px"
                    placeholder={t("Cerca prestazione...")}
                    value={globalFilter ?? ""}
                    background="white"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      const filtered = initialServices.filter((service) =>
                        service.description
                          .toLowerCase()
                          .replaceAll(" ", "")
                          .includes(
                            e.target.value.toLowerCase().replaceAll(" ", "")
                          )
                      );

                      setFilteredServices(filtered ?? []);
                      setGlobalFilter(e.target.value);
                    }}
                  />
                </InputGroup>
                <ServiceHealthFilter
                  services={initialServices ?? []}
                  onToggleFilter={onToggleFilter}
                />
              </Flex>
            </Flex>
            <ServiceHealthList
              data={filteredServices}
              searchText={globalFilter}
              selectedServices={selectedServices}
              setSelectedServices={setSelectedServices}
            />
          </ModalBody>

          <ModalFooter pb="24px" justifyContent="space-between">
            <Text
              style={{
                fontSize: 16,
                fontWeight: 600,
                color: "#667085",
                marginRight: 84,
              }}
            >
              {`${selectedServices.length} ${t("prestazioni selezionate")}`}
            </Text>
            <Button
              type="button"
              colorScheme="teal"
              variant="solid"
              backgroundColor="brand.500"
              isLoading={loading}
              isDisabled={selectedServices.length === 0}
              onClick={() => {
                onSave(selectedServices);
                onClose();
                setSelectedServices([]);
                setGlobalFilter("");
                setFilteredServices(initialServices ?? []);
              }}
            >
              {t("Associa Prestazione")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
);

type ServiceHealthListProps = {
  data: IServiceHealth[];
  searchText?: string;
  selectedServices?: IServiceHealth[];
  setSelectedServices?: (services: IServiceHealth[]) => void;
};

const ServiceHealthList: React.FC<ServiceHealthListProps> = React.memo(
  ({ data, searchText, selectedServices, setSelectedServices }) => {
    
  const { t, i18n } = useTranslation();
    const renderHeader = useCallback(
      ({ key, title }: { key: string; title: string }) => {
        return {
          header: () => {
            return (
              <Flex alignItems="center">
                <Button
                  variant="unstyled"
                  colorScheme="teal"
                  fontSize="15px"
                  fontWeight="bold"
                  height={"40px"}
                  textAlign="start"
                  flexDirection={"row"}
                  display={"flex"}
                >
                  <Text paddingRight={"2"}>{title}</Text>
                  <ChevronVerticalIcon />
                </Button>
              </Flex>
            );
          },
          id: key,
          cell: (item: any) => {
            return (
              <Text fontSize="15px" fontWeight="bold">
                {item.row.original[key]}
              </Text>
            );
          },
        };
      },
      []
    );

    const columns: ColumnDef<IServiceHealth>[] = useMemo(
      () => [
        {
          id: "name",
          header: () => {
            return (
              <Button
                variant="unstyled"
                textAlign="start"
                flexDirection={"row"}
                display={"flex"}
                height={"40px"}
              >
                <Checkbox
                  defaultChecked={
                    selectedServices?.length === data.length && data.length > 0
                  }
                  onChange={() =>
                    setSelectedServices?.(
                      selectedServices?.length === data.length ? [] : data
                    )
                  }
                />
                <Text paddingX={"2"}>{t("Nome")}</Text>
                <ChevronVerticalIcon />
              </Button>
            );
          },
          accessorFn: (row) => row.description,
          cell: ({ row, getValue }) => {
            const id = row.original.id;
            const name = getValue() as string;

            return (
              <Button
                variant="unstyled"
                colorScheme="teal"
                fontSize="15px"
                fontWeight="bold"
                textAlign="start"
                flexDirection={"row"}
                display={"flex"}
              >
                <Checkbox
                  defaultChecked={selectedServices
                    ?.map((q) => q.id)
                    .includes(id)}
                  onChange={() => {
                    if (selectedServices?.map((q) => q.id).includes(id)) {
                      setSelectedServices?.(
                        selectedServices?.filter((service) => service.id !== id)
                      );

                      return;
                    }

                    setSelectedServices?.([
                      ...(selectedServices ?? []),
                      row.original,
                    ]);
                  }}
                />
                <Text paddingLeft={"2"}>{name}</Text>
              </Button>
            );
          },
        },
        ...[
          { key: "priceList", title: t("Listino") },
          { key: "acronym", title: t("Sigla") },
          { key: "type", title: t("Genere") },
        ].map((item) => renderHeader(item)),
      ],
      [data, renderHeader, selectedServices, setSelectedServices]
    );

    const table = useReactTable({
      data,
      columns,
      state: {
        globalFilter: searchText,
      },
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
    });

    return <ServiceHealthTable table={table} />;
  }
);

export { AssociateHealthServiceModal };
