import React from "react";
import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  Button,
  Flex,
  Text,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Divider,
  IconButton,
} from "@chakra-ui/react";
import { ChevronVerticalIcon } from "../../../components/Icons/ChevronVerticalIcon";
import { QuestionnairesMainTable } from "./QuestionnairesMainTable";
import { useMemo, useState } from "react";
import {
  IQuestionnaires,
  QuestionnaireRelatedToType,
} from "../../../interfaces/IQuestionnaires";
import { MoreIcon } from "evergreen-ui";
import "survey-core/defaultV2.min.css";
import SurveyComponent from "./SurveyComponent";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { GET_QUESTIONNAIRES } from "src/graphql/queries/questionnaires.query";
import { DELETE_QUESTIONNAIRE } from "src/graphql/mutations/delete-questionnaire.mutation";
import { useTranslation } from "react-i18next";

type QuestionnairesMainListProps = {
  data: IQuestionnaires[];
  searchText?: string;
  selectedQuestionnaires?: IQuestionnaires[];
  setSelectedQuestionnaires?: (questionnaires: IQuestionnaires[]) => void;
  handleQuestionnaireDelete: (id: any) => void;
};

const QuestionnairesMainList: React.FC<QuestionnairesMainListProps> =
  React.memo(
    ({
      data,
      searchText,
      selectedQuestionnaires,
      setSelectedQuestionnaires,
      handleQuestionnaireDelete,
    }) => {
      const navigate = useNavigate();
      const [surveyId, setSurveyId] = useState("");
      const [isOpen, setIsOpen] = useState(false);
      const [title, setTitle] = useState("");

      const { t, i18n } = useTranslation();
      const handleClick = () => {
        setIsOpen(true);
      };

      const columns: ColumnDef<IQuestionnaires>[] = useMemo(
        () => [
          {
            id: "name",
            header: () => (
              <Text fontSize="16px" fontFamily={"Nunito"} fontWeight="600">
                <Flex gap="6px">
                  {t("Titolo questionario")} <ChevronVerticalIcon />
                </Flex>
              </Text>
            ),
            accessorFn: (row) => row.name,
            cell: ({ row, getValue }) => {
              const name = getValue() as string;
              const myId = row.original.id;
              return (
                <Button
                  variant="unstyled"
                  colorScheme="teal"
                  fontSize="15px"
                  fontWeight="bold"
                  textAlign="start"
                  flexDirection={"row"}
                  display={"flex"}
                  onClick={() => {
                    setSurveyId(myId);
                    setTitle(name);
                    handleClick();
                  }}
                >
                  <Text paddingLeft={"2"}>{name}</Text>
                </Button>
              );
            },
          },
          {
            header: () => {
              return (
                <Flex alignItems="center">
                  <Button
                    variant="unstyled"
                    colorScheme="teal"
                    fontSize="15px"
                    fontWeight="bold"
                    height={"40px"}
                    textAlign="start"
                    flexDirection={"row"}
                    display={"flex"}
                  >
                    <Text
                      paddingRight={"2"}
                      fontFamily={"Nunito"}
                      fontWeight="600"
                    >
                      {t("Rivolto a")}
                    </Text>
                    <ChevronVerticalIcon />
                  </Button>
                </Flex>
              );
            },
            id: "questionnaire-destination",
            cell: ({ row }) => {
              return (
                <Text fontSize="15px" fontWeight="bold">
                  {row.original.relatedTo
                    ? row.original.relatedTo ===
                      QuestionnaireRelatedToType.Doctor
                      ? t(QuestionnaireRelatedToType.Doctor)
                      : t(QuestionnaireRelatedToType.Patient)
                    : ""}
                </Text>
              );
            },
          },
          {
            header: "",
            id: "more",
            cell: ({ row }) => {
              return (
                <>
                  <Box>
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        aria-label="Options"
                        icon={
                          <MoreIcon style={{ transform: "rotate(90deg)" }} />
                        }
                        variant="outline"
                        border="none"
                      />
                      <MenuList p={0} minW="0" w={"130px"} borderRadius="12px">
                        <MenuItem
                          borderRadius="12px"
                          h={"40px"}
                          onClick={() => {
                            setSurveyId(row.original.id);
                            setTitle(row.original.name);
                            handleClick();
                          }}
                        >
                          <Text
                            colorScheme="teal"
                            fontSize="15px"
                            fontWeight="500"
                            color={"GrayText"}
                            whiteSpace="break-spaces"
                          >
                            {t("Modifica")}
                          </Text>
                        </MenuItem>
                        <Divider color="#EDEFF5" />
                        <MenuItem
                          h={"40px"}
                          onClick={() =>
                            handleQuestionnaireDelete(row.original.id)
                          }
                        >
                          <Text
                            colorScheme="teal"
                            fontSize="15px"
                            fontWeight="500"
                            color={"GrayText"}
                            whiteSpace="break-spaces"
                          >
                            {t("Elimina")}
                          </Text>
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Box>
                </>
              );
            },
          },
        ],
        [t, handleQuestionnaireDelete]
      );

      const table = useReactTable({
        data,
        columns,
        state: {
          globalFilter: searchText,
        },
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
      });

      return (
        <>
          <SurveyComponent
            isOpen={isOpen}
            surveyId={surveyId}
            setTitle={setTitle}
            onClose={() => {
              navigate("/questionnaires");
              setIsOpen(false);
            }}
          />
          <QuestionnairesMainTable table={table} />
        </>
      );
    }
  );
export default QuestionnairesMainList;
