import { ICalendarEvent } from "../interfaces/ICalendarEvent";
import { ISlot } from "../interfaces/ISlot";

const BREAK_TIME = 15 * 60000;

const zeroPad = (num: number) => String(num).padStart(2, "0");

export const formatTime = (time: string): string => {
  const [hh, mm] = time.split(":");
  let formatted = "";

  if (hh.length === 1) {
    formatted = "0";
  }

  formatted = `${formatted}${hh}:${mm}:00`;

  return formatted;
};

// const getDaySlots = (slots: ISlot[], date: Date) =>
//   slots
//     .filter((slot) => slot.dow.includes(date.getDay()))
//     .map((x) => {
//       const [hhs, mms] = x.start.split(":");
//       const [hhe, mme] = x.end.split(":");

//       // Convert hours and minutes to a date.
//       return {
//         dow: x.dow,
//         start: new Date(date).setHours(parseInt(hhs), parseInt(mms)),
//         end: new Date(date).setHours(parseInt(hhe), parseInt(mme)),
//       };
//     });

export const getAvailableTime = (
  date: Date,
  slots: ISlot[],
  events: ICalendarEvent[],
  serviceDuration: number
) => {
  // const daySlots = getDaySlots(slots, date);

  const currentDate = new Date().setHours(0, 0, 0, 0);
  const selectedDate = date.setHours(0, 0, 0, 0);
  const startTime =
    currentDate === selectedDate
      ? new Date(date).setHours(
          new Date().getHours(),
          Math.ceil(new Date().getMinutes() / 5) * 5,
          0,
          0
        )
      : new Date(date).setHours(8, 0, 0, 0);
  const endTime = new Date(date).setHours(20, 0, 0, 0);
  const millisecondsRange = serviceDuration * 60000; // Convert minutes to milliseconds.

  // const dayEvents = events.filter(
  //   (event) =>
  //     Date.parse(event.start) >= startTime && Date.parse(event.end) < endTime
  // );

  const timeList = [];
  let start = startTime;

  while (start <= endTime - millisecondsRange) {
    // const firstPredicate = !dayEvents.some(
    //   (dayEvent) =>
    //     start + millisecondsRange > Date.parse(dayEvent.start) &&
    //     start < Date.parse(dayEvent.end) + BREAK_TIME
    // );
    // const secondPredicate = daySlots.some(
    //   (daySlot) =>
    //     start >= daySlot.start &&
    //     start < daySlot.end - millisecondsRange
    // );

    // if (firstPredicate && secondPredicate) {
      const startDate = new Date(start);
      timeList.push(
        `${startDate.getHours()}:${zeroPad(startDate.getMinutes())}`
      );
    // }

    start += BREAK_TIME;
  }
  return timeList;
};