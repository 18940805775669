import { useMutation, useQuery } from "@apollo/client";
import { useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Avatar, Tablist, Tab } from "evergreen-ui";
import { Box, Flex, Spinner, useToast } from "@chakra-ui/react";

import Review from "./Review";
import { GET_DOCTOR } from "../../../graphql/queries/doctors.query";
import { DELETE_DOCTOR } from "../../../graphql/mutations/delete-doctor.mutation";
import DeleteDoctorModal from "../../../components/Modals/DeleteDoctorModal";
import { LargeEmailButton } from "../../../components/LargeEmailButton";
import { LargeMobileBadge } from "../../../components/LargeMobileBadge";
import { LargeDeleteButton } from "../../../components/LargeDeleteButton";
import "./index.scss";
import { useTranslation } from "react-i18next";

const Doctor = () => {
  const { t, i18n } = useTranslation();
  const { doctorId } = useParams<{ doctorId: string }>();
  const toast = useToast();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const { data: doctor, loading } = useQuery(GET_DOCTOR, {
    variables: { id: doctorId },
  });

  const [deleteDoctor, { loading: deleteDoctorLoading }] = useMutation(
    DELETE_DOCTOR,
    {
      refetchQueries: [{ query: GET_DOCTOR }],
    }
  );

  const data = useMemo(() => doctor?.doctor || null, [doctor]);
  const tabs = [
    {
      title: t("Panoramica"),
      view: (props: any = {}) => <Review {...props} />,
    },
    {
      title: "Disponibilità",
      view: () => null,
    },
    {
      title: t("Metriche"),
      view: () => null,
    },
    {
      title: t("Pazienti associati"),
      view: () => null,
    },
  ];
  const handleSaveDoctor = async () => {
    try {
      if (doctorId) {
        await deleteDoctor({ variables: { id: parseInt(doctorId) } });
      }
      setIsOpenModal(false);
    } catch (error: any) {
      toast({
        title: t("Errore durante la cancellazione del dottore"),
        description: error.message,
        status: "error",
        isClosable: true,
      });
    }
  };

  if (loading) {
    return (
      <Flex alignItems="center" justifyContent="center" height="100%">
        <Spinner size="xl" color="brand.500" thickness="4px" speed="0.65s" />
      </Flex>
    );
  }

  return (
    <Box className="doctor-container">
      <DeleteDoctorModal
        doctor={doctor?.doctor}
        loading={loading && deleteDoctorLoading}
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        onSave={handleSaveDoctor}
      />
      <Box className="doctor-header">
        <Box className="doctor-header__info">
          <Box display="flex">
            <Avatar
              src={data ? `${data.name}` : ""}
              name={data ? `${data.name}` : ""}
              size={80}
            />
            <Box
              marginLeft={16}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Box className="doctor-name">
                {data?.name || ""}&nbsp;{data?.surname || ""}
              </Box>
              <Box
                h="32px"
                p="4px 8px"
                backgroundColor="#FFD88D"
                borderRadius={6}
                display="flex"
                alignItems="center"
              >
                {data?.status === "invited" ? t("Invitato") : t("Registrato")}
              </Box>
            </Box>
          </Box>
          <Box>
            <LargeMobileBadge phone={data.phone} />
            <LargeEmailButton email={data.email} />
            <LargeDeleteButton onClick={() => setIsOpenModal(true)} />
          </Box>
        </Box>
        <Box mt="40px">
          <Tablist mb="16px" flexBasis={240} mr="24px" h="100%">
            {tabs.map(({ title }, index) => (
              <Tab
                className="nav-tab"
                key={title}
                id={title}
                onSelect={() => setSelectedIndex(index)}
                isSelected={index === selectedIndex}
                aria-controls={`Boxl-${title}`}
                height={40}
                borderRadius={8}
                backgroundColor={
                  index === selectedIndex
                    ? "#EFEFEF !important"
                    : "#FCFCFC !important"
                }
                color={
                  index === selectedIndex
                    ? "#1A1D1F !important"
                    : "#6F767E !important"
                }
                boxShadow="none !important"
              >
                {title}
              </Tab>
            ))}
          </Tablist>
        </Box>
        <Box border="1px solid #EFEFEF" w="100%" mt="40px" />
      </Box>
      <Box>
        {tabs.map(({ title, view }, index) => (
          <Box
            key={title}
            id={`Boxl-${title}`}
            role="tabBoxl"
            aria-labelledby={title}
            aria-hidden={index !== selectedIndex}
            display={index === selectedIndex ? "block" : "none"}
          >
            {view({ doctor: data })}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Doctor;
