import { useRef, useState } from "react";
import { useMutation } from "@apollo/client";
import {
  Button,
  Box,
  Flex,
  FormControl,
  FormLabel,
  Textarea,
  useToast,
  Tag,
} from "@chakra-ui/react";
import { parseISO } from "date-fns";
import format from "date-fns/format";
import it from "date-fns/locale/it";

import InfoRow from "../../../../components/InfoRow";
import TitleSmall from "../../../../components/TitleSmall";
import EditIcon from "../../../../components/Icons/EditIcon";
import EditDoctorModal from "../../../../components/Modals/EditDoctorModal";
import { GET_DOCTORS } from "../../../../graphql/queries/doctors.query";
import { GET_AVAILABILITY } from "../../../../graphql/queries/availability.query";
import { UPDATE_DOCTOR } from "../../../../graphql/mutations/update-doctor.mutation";
import { UPDATE_AVAILABILITY } from "../../../../graphql/mutations/update-availability.mutation";
import { IDoctor } from "../../../../interfaces/IDoctor";
import { colors } from "../../../../colors";
import "./index.scss";
import { useTranslation } from "react-i18next";

const Review = ({ doctor }: { doctor: IDoctor }) => {
  const DoctorModalRef = useRef<{ resetForm: () => void }>();
  const { t, i18n } = useTranslation();
  const toast = useToast();

  const [note, setNote] = useState<string | null>(null);
  const [isEditDoctor, setIsEditDoctor] = useState(false);
  const [updateDoctor, { loading }] = useMutation(UPDATE_DOCTOR, {
    refetchQueries: [{ query: GET_DOCTORS }],
  });
  const [updateAvailability, { loading: updateAvailabilityLoading }] =
    useMutation(UPDATE_AVAILABILITY, {
      refetchQueries: [GET_AVAILABILITY],
    });

  const handleUpdateNote = async () => {
    try {
      await updateDoctor({
        variables: {
          id: parseInt(doctor.id),
          input: {
            note,
          },
        },
      });
    } catch (error: any) {
      toast({
        title: t("Errore durante la creazione del dottore."),
        description: error.message,
        status: "error",
        isClosable: true,
      });
    }
  };

  const handleSaveDoctor = async (data: any) => {
    try {
      await updateAvailability({
        variables: {
          input: {
            doctorId: parseInt(doctor.id),
            slots: data.slots,
          },
        },
      });
      await updateDoctor({
        variables: {
          id: parseInt(doctor.id),
          input: {
            name: data.name,
            specializations: [
              {
                name: data.specializations,
              },
            ],
            tags: data.tags,
            phone: data.phone,
            email: data.email,
            note: data.note,
          },
        },
      });
      setIsEditDoctor(false);
      DoctorModalRef.current?.resetForm();
    } catch (error: any) {
      toast({
        title: t("Errore durante la creazione del dottore."),
        description: error.message,
        status: "error",
        isClosable: true,
      });
    }
  };

  const getDoctorTags = (tags: string[]) => (
    <Flex gap="16px">
      {tags.map((x: string, i: number) => (
        <Tag key={i} variant="subtle" colorScheme="cyan">
          {x}
        </Tag>
      ))}
    </Flex>
  );

  return (
    <Box p="24px" borderRadius="8px" bg="#FCFCFC">
      <EditDoctorModal
        ref={DoctorModalRef}
        loading={loading && updateAvailabilityLoading}
        isOpen={isEditDoctor}
        onClose={() => setIsEditDoctor(false)}
        onSave={handleSaveDoctor}
        doctor={doctor}
      />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <TitleSmall
          text={t("Panoramica")}
          color={colors[100]}
          style={{ marginRight: 24 }}
        />
        <Button
          colorScheme="teal"
          variant="solid"
          onClick={() => setIsEditDoctor(true)}
          leftIcon={<EditIcon />}
          width="114px"
          height="48px"
          backgroundColor="brand.500"
        >
          {t("Modifica")}
        </Button>
      </Box>
      <Box p="16px" bg="rgba(244, 244, 244, 0.5)" borderRadius={12} mt="24px">
        <InfoRow
          label={t("Tags")}
          placeholder={t("Non definito")}
          element={doctor?.tags ? getDoctorTags(doctor.tags) : null}
        />
        <InfoRow
          label={t("Titolo")}
          placeholder={t("Non definito")}
          element={
            doctor?.specializations?.length
              ? doctor.specializations[0]?.name
              : null
          } // specializations: Array<string>
        />
        <InfoRow
          label={t("Telefono")}
          placeholder={t("Non definito")}
          element={doctor?.phone}
        />
        <InfoRow
          label={t("Mail")}
          placeholder={t("Non definito")}
          element={doctor?.email}
        />
        <InfoRow
          label={t("Invitato il")}
          placeholder={t("Non definito")}
          element={
            doctor?.invitedAt
              ? format(parseISO(doctor.invitedAt), "dd MMMM yyyy", {
                  locale: it,
                })
              : null
          }
        />
        <InfoRow
          label={t("Iscritta il")}
          placeholder={t("Non definito")}
          element={
            doctor?.createdAt
              ? format(parseISO(doctor.createdAt), "dd MMMM yyyy", {
                  locale: it,
                })
              : null
          }
        />
        <FormControl mt="32px">
          <FormLabel htmlFor="note" fontSize="14px">
            {t("Note")}
          </FormLabel>
          <Textarea
            id="note"
            resize="vertical"
            backgroundColor="white"
            border="none"
            onChange={(e) => setNote(e.target.value)}
          />
        </FormControl>
        <Flex mt="32px" alignItems="center" justifyContent="space-between">
          <Button
            colorScheme="teal"
            variant="solid"
            onClick={handleUpdateNote}
            backgroundColor="brand.500"
            disabled={!note}
          >
            {t("Salva")}
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};

export default Review;
