import { InputGroup, InputLeftElement, Input } from "@chakra-ui/react";
import { SearchIcon } from "evergreen-ui";
import {
  ChangeEvent,
  SetStateAction,
  useCallback,
  useState,
  useEffect,
} from "react";

type FilterPathProps = {
  text: string;
  filteredItems: any[];
  setFilteredItems: React.Dispatch<SetStateAction<any[]>>;
  globalFilter: string;
  setGlobalFilter: React.Dispatch<SetStateAction<string>>;
  defaultItems: any[];
};

export const PathSearchbar: React.FC<FilterPathProps> = ({
  text,
  filteredItems,
  setFilteredItems,
  globalFilter,
  setGlobalFilter,
  defaultItems,
}) => {
  let target;
  const handleSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      target = e.target.value;
      setGlobalFilter(e.target.value);
      if (target.length >= 1) {
        setFilteredItems(
          filteredItems.filter((i) =>
            i?.questionnaire?.name
              ?.toLowerCase()
              .replaceAll(" ", "")
              .includes(e.target.value.toLowerCase().replaceAll(" ", ""))
          )
        );
      } else {
        setFilteredItems(defaultItems);
      }
    },
    [target]
  );

  return (
    <>
      <InputGroup border="2px" borderRadius="10px" borderColor="gray.200">
        <InputLeftElement
          pointerEvents="none"
          children={<SearchIcon color="gray" />}
        />
        <Input
          width="352px"
          placeholder={text}
          background="white"
          value={globalFilter || ""}
          onChange={(e) => handleSearch(e)}
        />
      </InputGroup>
    </>
  );
};
