import { IconButton, Tooltip, useClipboard } from '@chakra-ui/react';
  import PhoneIcon from '../Icons/PhoneIcon';
import { useTranslation } from 'react-i18next';
  
  export const LargeMobileBadge = ({ phone }: { phone: string }) => {
    const clipboard = useClipboard(phone);
    const { t, i18n } = useTranslation();
    return (
      <Tooltip
        backgroundColor="brand.500"
        label={t("Copiato negli appunti")}
        fontSize="md"
        isOpen={clipboard.hasCopied}
      >
        <IconButton
          border="1px"
          borderColor="#6F767E"
          background="transparent"
          width="64px !important"
          height="64px !important"
          borderRadius="50%"
          marginLeft={16}
          aria-label="Copy email"
          icon={<PhoneIcon />}
          isRound={true}
          onClick={clipboard.onCopy}
        />
      </Tooltip>
    );
  };
  