import { gql } from "@apollo/client";

export const CREATE_PATIENT = gql`
  mutation createPatient($input: PatientInput!) {
    createPatient(input: $input) {
      name
      surname
      email
      phone
      fiscalCode
      tags
    }
  }
`;
