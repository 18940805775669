import "./App.scss";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import SignIn from "./views/SignIn";
import Patients from "./views/Dashboard/Patients";
import Patient from "./views/Dashboard/Patient";
import Clinics from "./views/Dashboard/Clinics";
import Clinic from "./views/Dashboard/Clinic";
import Doctors from "./views/Dashboard/Doctors";
import Doctor from "./views/Dashboard/Doctor";
import Services from "./views/Dashboard/Services";
import Questionnaires from "./views/Dashboard/Questionnaires";
import EditDoctorProfile from "./views/EditDoctorProfile";
import DoctorProfile from "./views/DoctorProfile";
import Settings from "./views/Settings";
import { PrivateRoute } from "./views/Layout/PrivateRoute";
import { BaseLayout } from "./views/Layout/BaseLayout";
import Calendar from "./views/Dashboard/Calendar";
import SignUp from "./views/SignUp";
import ResetPassword from "./views/ResetPassword";
import Meeting from "./views/Meeting";
import {
  lightTheme,
  GlobalStyles,
  MeetingProvider,
} from "amazon-chime-sdk-component-library-react";
import { ThemeProvider } from "styled-components";
import { ChakraProvider } from "@chakra-ui/react";
import { Amplify } from "aws-amplify";
import { theme } from "./theme";
import EditClinicProfile from "./views/Dashboard/Clinics/EditClinicProfile";
import { CreateNewPath } from "./views/Dashboard/Path/CreateNewPath";
import Paths from "./views/Dashboard/Path";
import PathDetails from "./views/Dashboard/PathDetails";
import CreateNewQuestionnaire from "./views/Dashboard/Questionnaires/CreateNewQuestionnaire";
import EditPath from "./views/Dashboard/Path/edithPath/EditPath";

Amplify.configure({
  Auth: {
    identityPoolId: "eu-central-1:fcc4bc87-78d4-44cd-beef-eeea9b61c2b7",
    region: "eu-central-1",
    userPoolId: "eu-central-1_Mht8RF4TU",
    userPoolWebClientId: "3g1ar9p8d1ho505q2bgogm7t3b",

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    // mandatorySignIn: false,

    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    // cookieStorage: {
    //   // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    //   domain: '.yourdomain.com',
    //   // OPTIONAL - Cookie path
    //   path: '/',
    //   // OPTIONAL - Cookie expiration in days
    //   expires: 365,
    //   // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
    //   sameSite: 'strict' | 'lax',
    //   // OPTIONAL - Cookie secure flag
    //   // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    //   secure: true,
    // },

    // OPTIONAL - customized storage object
    // storage: MyStorage,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    // authenticationFlowType: 'USER_PASSWORD_AUTH',

    // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    // clientMetadata: { myCustomKey: 'myCustomValue' },

    // OPTIONAL - Hosted UI configuration
    oauth: {
      scope: [
        // 'phone',
        "email",
        "profile",
        "openid",
        // 'aws.cognito.signin.user.admin',
      ],
      redirectSignIn: "http://localhost:3000/",
      redirectSignOut: "http://localhost:3000/",
      responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
});

function App() {
  
  return (
    <Router basename="/">
      <ChakraProvider theme={theme}>
        <ThemeProvider theme={lightTheme}>
          <GlobalStyles />
          <MeetingProvider>
            <Routes>
              <Route path="/signin" element={<SignIn />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/resetpassword" element={<ResetPassword />} />
              <Route
                path="/meeting"
                element={<PrivateRoute layout={Meeting} />}
              />
              <Route path="/" element={<PrivateRoute layout={BaseLayout} />}>
                <Route path="/" element={<Calendar />} />
              </Route>
              <Route
                path="calendar"
                element={<PrivateRoute layout={BaseLayout} />}
              >
                <Route path="/calendar" element={<Calendar />} />
              </Route>
              <Route
                path="services"
                element={<PrivateRoute layout={BaseLayout} />}
              >
                <Route path="/services" element={<Services />} />
              </Route>
              <Route
                path="patients"
                element={<PrivateRoute layout={BaseLayout} />}
              >
                {/* <Route
                  path="/patients/:patientId/edit"
                  element={<EditPatient />}
                /> */}
                <Route path="/patients/:patientId" element={<Patient />} />
                <Route path="/patients" element={<Patients />} />
              </Route>
              <Route
                path="clinics"
                element={<PrivateRoute layout={BaseLayout} />}
              >
                <Route
                  path="/clinics/:patientId/edit"
                  element={<EditClinicProfile />}
                />
                <Route path="/clinics/:clinicId" element={<Clinic />} />
                <Route path="/clinics" element={<Clinics />} />
              </Route>
              <Route
                path="/paths"
                element={<PrivateRoute layout={BaseLayout} />}
              >
                <Route
                  path="/paths/create-new-path"
                  element={<CreateNewPath />}
                />
                <Route path="/paths" element={<Paths />} />
                <Route path="/paths/:pathId" element={<PathDetails />} />
                <Route path="/paths/:pathId/edit" element={<EditPath />} />
              </Route>
              {/* <Route
                path="profile"
                element={<PrivateRoute layout={BaseLayout} />}
              >
                <Route path="/profile/edit" element={<EditDoctorProfile />} />
                <Route path="/profile" element={<DoctorProfile />} />
              </Route> */}
              <Route
                path="doctors"
                element={<PrivateRoute layout={BaseLayout} />}
              >
                {/* <Route
                  path="/doctors/:doctorId/edit"
                  element={<EditDoctorProfile />}
                /> */}
                <Route path="/doctors/:doctorId" element={<Doctor />} />
                <Route path="/doctors" element={<Doctors />} />
              </Route>
              <Route
                path="profile"
                element={<PrivateRoute layout={BaseLayout} />}
              >
                <Route path="/profile/edit" element={<EditDoctorProfile />} />
                <Route path="/profile" element={<DoctorProfile />} />
              </Route>
              <Route
                path="settings"
                element={<PrivateRoute layout={BaseLayout} />}
              >
                <Route path="/settings" element={<Settings/>} />
              </Route>
              <Route
                path="questionnaires"
                element={<PrivateRoute layout={BaseLayout} />}
              >
                <Route
                  path="/questionnaires/create-new-questionnaire"
                  element={<CreateNewQuestionnaire />}
                />
                <Route
                  path="/questionnaires/:questionnairesId"
                  element={<Questionnaires />}
                />
                <Route path="/questionnaires" element={<Questionnaires />} />
              </Route>
            </Routes>
          </MeetingProvider>
        </ThemeProvider>
      </ChakraProvider>
    </Router>
  );
}

export default App;
