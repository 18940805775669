export interface IQuestionnaires {
  id: string;
  name: string;
  description: string;
  icon: string;
  landBotUrl: string;
  activationDate?: ActivationDate;
  repetition?: Repetition;
  relatedTo: QuestionnaireRelatedToType;
}

enum QuestionnaireRelatedToType {
  Patient = "Paziente",
  Doctor = "Dottore",
}

enum ActivationDate {
  BEFORE = "Prima della prestazione",
  AFTER = "Dopo la prestazione",
  NONE = "Nessuna",
}

enum Repetition {
  NONE = "Nessuna ricorrenza",
  DAILY = "Ogni giorno",
  WEEKLY = "Ogni settimana",
  MONTHLY = "Ogni mese",
  YEARLY = "Ogni anno",
}

export { ActivationDate, Repetition, QuestionnaireRelatedToType };
