import { useCallback, useState, useMemo, useEffect } from "react";
import { addMinutes } from "date-fns";
import { Tablist, Tab, PlusIcon } from "evergreen-ui";
import { Box, Button, Flex, Spinner, useToast } from "@chakra-ui/react";
import TitleSmall from "../../../../components/TitleSmall";
import { colors } from "../../../../colors";
import { useQuery, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { IAppointment } from "../../../../interfaces/IAppointment";
import format from "date-fns/format";
import AddAppointmentModal from "../../../../components/Modals/AddAppointmentModal";
import EditAppointmentModal from "../../../../components/Modals/EditAppointmentModal";
import { GET_APPOINTMENTS } from "../../../../graphql/queries/appointments.query";
import { UPDATE_APPOINTMENT } from "../../../../graphql/mutations/update-appointment.mutation";
import { ISlot } from "../../../../interfaces/ISlot";
import { GET_AVAILABILITY } from "../../../../graphql/queries/availability.query";
import { GET_DOCTOR } from "../../../../graphql/queries/doctors.query";
import { ICalendarEvent } from "../../../../interfaces/ICalendarEvent";
import { CREATE_APPOINTMENT } from "../../../../graphql/mutations/create-appointment.mutation";
import { GET_USER } from "../../../../graphql/queries/user.query";
import { Row } from "./Row";
import { useTranslation } from "react-i18next";



const Appointments = () => {
  const { t, i18n } = useTranslation();
const serviceList: any = [
  {
    id: 1,
    hospital_id: 1,
    name: t("Visita introduttiva"),
    price: "20.00",
    duration: 30,
  },
  {
    id: 2,
    hospital_id: 1,
    name: t("Visita di follow-up"),
    price: "10.00",
    duration: 20,
  },
];

const tabs = [
  {
    title: t("In programma"),
  },
  {
    title: t("Passate"),
  },
  {
    title: t("Cancellate"),
  },
]; 

const navigate = useNavigate();
  const toast = useToast();
  const [events, setEvents] = useState<ICalendarEvent[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  
  const [isAddAppointment, setIsAddAppointment] = useState({
    isOpen: false,
  });
  const [isEditAppointment, setIsEditAppointment] = useState<{
    isOpen: boolean;
    event: any;
  }>({
    isOpen: false,
    event: null,
  });

  const userQuery = useQuery(GET_USER);
  const { data, loading } = useQuery(GET_APPOINTMENTS);
  console.log(data);

  const [createAppointment] = useMutation(CREATE_APPOINTMENT, {
    refetchQueries: [{ query: GET_APPOINTMENTS }],
  });
  const [updateAppointment, { loading: updateAppointmentLoading }] =
    useMutation(UPDATE_APPOINTMENT, {
      refetchQueries: [{ query: GET_APPOINTMENTS }],
    });
  const { data: dataDoctor, loading: doctorLoading } = useQuery(GET_DOCTOR);
  const doctorId = dataDoctor?.doctor?.id;
  const { data: availabilityData, loading: availabilityLoading } = useQuery(
    GET_AVAILABILITY,
    {
      skip: !doctorId,
      variables: { doctorId },
    }
  );

  const slots = useMemo(
    () =>
      (availabilityData?.availability?.slots || []).map(
        ({ dow, start, end }: ISlot) => ({
          dow,
          start,
          end,
        })
      ),
    [availabilityData]
  );

  useEffect(() => {
    if (data?.appointments) {
      setEvents(
        data.appointments
          .filter((x: IAppointment) => x.status !== "cancelled")
          .map((x: IAppointment) => ({
            id: x.id,
            title: `${x.patient?.name} ${x.patient?.surname}`,
            start: x.startDateTime,
            end: x.endDateTime,
            patient: x.patient,
            serviceId: x.serviceId,
            online: x.online,
            note: x.note,
          }))
      );
    }
  }, [data]);

  const handleSave = useCallback(
    async (event: any) => {
      try {
        console.log(event);

        const time = event.startTime.padStart(5, 0);
        const date = format(event.date, "yyyy-MM-dd");

        const startDateTime = new Date(`${date} ${time}`).toISOString();
        const endDateTime = addMinutes(
          new Date(`${date} ${time}`),
          event.service.duration
        ).toISOString();

        const { id, email, name, surname } = userQuery.data.me;

        await createAppointment({
          variables: {
            input: {
              serviceId: event.service.id,
              startDateTime,
              endDateTime,
              note: event.note,
              online: Boolean(event.isOnline),
              patientId: parseInt(event.patientId),
              attendees: [
                {
                  user: {
                    id: parseInt(id),
                    email,
                    displayName: `${name} ${surname}`,
                  },
                },
                {
                  user: {
                    id: parseInt(event.patientId),
                    email: event.email,
                    displayName: `${event.name} ${event.surname}`,
                  },
                },
              ],
            },
          },
        });
        setIsAddAppointment({
          isOpen: false,
        });
      } catch (error: any) {
        toast({
          title: t("Errore durante la creazione dell'appuntamento."),
          description: error.message,
          status: "error",
          isClosable: true,
        });
      }
    },
    [userQuery]
  );

  // TODO refactor this
  const appointments = useMemo(() => {
    if (selectedIndex === 0) {
      return data?.appointments
        .filter((appointment: IAppointment) => {
          return (
            new Date(appointment.startDateTime).getTime() > new Date().getTime()
          );
        })
        .sort((a: any, b: any) =>
          new Date(a.start).getTime() < new Date(b.start).getTime() ? 1 : -1
        )
        .map((x: IAppointment) => ({
          id: x.id,
          title: `${x.patient?.name} ${x.patient?.surname}`,
          start: x.startDateTime,
          end: x.endDateTime,
          patient: x.patient,
          serviceId: x.serviceId,
          online: x.online,
          meetingId: x.meetingId,
        }));
    }

    if (selectedIndex === 1) {
      return data?.appointments
        .filter((appointment: IAppointment) => {
          return (
            new Date(appointment.startDateTime).getTime() < new Date().getTime()
          );
        })
        .sort((a: any, b: any) =>
          new Date(a.start).getTime() < new Date(b.start).getTime() ? 1 : -1
        )
        .map((x: IAppointment) => ({
          id: x.id,
          title: `${x.patient?.name} ${x.patient?.surname}`,
          start: x.startDateTime,
          end: x.endDateTime,
          patient: x.patient,
          serviceId: x.serviceId,
          online: x.online,
          meetingId: x.meetingId,
        }));
    }

    if (selectedIndex === 2) {
      return data?.appointments
        .filter(
          (appointment: IAppointment) => appointment.status === "cancelled"
        )
        .map((x: IAppointment) => ({
          id: x.id,
          title: `${x.patient?.name} ${x.patient?.surname}`,
          start: x.startDateTime,
          end: x.endDateTime,
          patient: x.patient,
          serviceId: x.serviceId,
          online: x.online,
          meetingId: x.meetingId,
        }));
    }
  }, [data, selectedIndex]);

  if (loading) {
    return (
      <Flex alignItems="center" justifyContent="center" height="150px">
        <Spinner size="xl" color="brand.500" thickness="4px" speed="0.65s" />
      </Flex>
    );
  }

  const handleUpdate = async (event: any) => {
    try {
      const time = event.startTime.padStart(5, 0);
      const date = format(event.date, "yyyy-MM-dd");

      const startDateTime = new Date(`${date} ${time}`).toISOString();
      const endDateTime = addMinutes(
        new Date(`${date} ${time}`),
        event.service.duration
      ).toISOString();

      await updateAppointment({
        variables: {
          id: event.id,
          input: {
            startDateTime,
            endDateTime,
            note: event.note,
            serviceId: event.service.id,
            online: Boolean(event.isOnline),
          },
        },
      });

      setIsEditAppointment({ event: null, isOpen: false });
    } catch (error: any) {
      toast({
        title: t("Errore durante l'aggiornamento dell'appuntamento."),
        description: error.message,
        status: "error",
        isClosable: true,
      });
    }
  };

  const editEvent = (event: any) => {
    setIsEditAppointment({
      isOpen: true,
      event,
    });
  };

  return (
    <Box p="24px" borderRadius="8px" bg="#FCFCFC">
      <AddAppointmentModal
        isOpen={isAddAppointment.isOpen}
        onClose={() =>
          setIsAddAppointment({
            isOpen: false,
          })
        }
        onSave={handleSave}
        events={events}
        slots={slots}
      />

      {isEditAppointment.event && (
        <EditAppointmentModal
          isOpen={isEditAppointment.isOpen}
          loading={updateAppointmentLoading}
          onClose={() => {
            setIsEditAppointment({
              isOpen: false,
              event: null,
            });
          }}
          onSave={handleUpdate}
          event={isEditAppointment.event}
          events={appointments}
          slots={slots}
        />
      )}

      <Box display="flex" justifyContent="space-between" alignItems="center">
        <TitleSmall
          text={t("Appuntamenti")}
          color={colors[1100]}
          style={{ marginRight: 24 }}
        />
        <Box>
          <Button
            variant="outline"
            onClick={() => navigate("/calendar")}
            height="46px"
            mr="10px"
          >
            {t('Vai al Calendario')}
          </Button>
          <Button
            colorScheme="teal"
            variant="solid"
            onClick={() =>
              setIsAddAppointment({
                isOpen: true,
              })
            }
            leftIcon={<PlusIcon />}
            height="48px"
            backgroundColor="brand.500"
          >
          {t('Aggiungi appuntamento')}
          </Button>
        </Box>
      </Box>
      <Box mt="40px" mb="32px">
        <Tablist mb="16px" flexBasis={240} mr="24px" h="100%">
          {tabs.map(({ title }, index) => (
            <Tab
              className="nav-tab"
              key={title}
              id={title}
              onSelect={() => setSelectedIndex(index)}
              isSelected={index === selectedIndex}
              aria-controls={`Boxl-${title}`}
              height={40}
              borderRadius={8}
              backgroundColor={
                index === selectedIndex
                  ? "#EFEFEF !important"
                  : "#FCFCFC !important"
              }
              color={
                index === selectedIndex
                  ? "#1A1D1F !important"
                  : "#6F767E !important"
              }
              boxShadow="none !important"
            >
              {title}
            </Tab>
          ))}
        </Tablist>
      </Box>
      {appointments.map((x: any) => (
        <Row
          key={x.id}
          date={x.start}
          title={`${serviceList.find((s: any) => s.id === parseInt(x.serviceId)).name}`}
          duration={45}
          isOnline={x.online}
          meetingId={x.meetingId}
          event={x}
          editEvent={editEvent}
          selectedIndex={selectedIndex}
        />
      ))}
    </Box>
  );
};

export default Appointments;
