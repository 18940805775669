import { gql } from "@apollo/client";

export const GET_DOCTORS_BY_AGENCY = gql`
query getDoctorsByAgency {
    getDoctorsByAgency {
      id
      name
      surname
      email
      fiscalCode
      phone
    
      
      
    }
  }
`;
