import { useEffect, useState } from "react";
import * as yup from "yup";
import {
  Grid,
  Box,
  Text,
  Flex,
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  VStack,
  Checkbox,
  InputRightElement,
  InputGroup,
  Link,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSearchParams } from "react-router-dom";
import LogoIcon from "../../../components/Icons/LogoIcon";
import { useTranslation } from "react-i18next";

interface IProps {
  //consent: { consentText: string; consentLinkText: string };
  isLoading: boolean;
  onSubmit: (data: any) => void;
}

const NewPasswordCreatingForm = (props: IProps) => {
  const [show, setShow] = useState(false);
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");

  const { t, i18n } = useTranslation();
  const schema = yup.object({
    password: yup
      .string()
      .min(8, t("La password deve avere almeno 8 caratteri"))
      // .matches(
      //   /^(?=.*[A-Za-z])(?=.*d)(?=.*[@$!%*#?&_])[A-Za-zd@$!%*#?&_]{8,}$/,
      //   "La password deve contenere 8 caratteri, uno maiuscolo, uno minuscolo, un numero e un carattere speciale"
      // )
      .matches(/[a-z]+/, t("La password deve contenere uno minuscolo"))
      .matches(/[A-Z]+/, t("La password deve contenere uno maiuscolo"))
      .matches(
        /[@$!%*#?&_]+/,
        t("La password deve contenere un carattere speciale")
      )
      .matches(/\d+/, t("La password deve contenere un numero"))
      .required(t("Inserisci la tua password")),
    cpassword: yup
      .string()
      .min(8, t("La password deve avere almeno 8 caratteri"))
      .oneOf([yup.ref("password")], t("La password non corrisponde"))
      .required(t("Inserisci la tua password")),
  });
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleClick = () => setShow(!show);

  return (
    <Grid height="100vh" gridAutoColumns="1fr" gridAutoFlow="column">
      <Box backgroundColor="brand.600">
        <Flex alignItems="center" justifyContent="space-between" p="24px">
          <LogoIcon />
        </Flex>
        <Flex
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          mt="10%"
        >
          <form onSubmit={handleSubmit(props.onSubmit)}>
            <Box>
              <Heading as="h1" size="2xl">
                {t("Crea nuova password")}
              </Heading>
              <Text
                mt="24px"
                fontSize="15px"
                fontWeight={400}
                color="brand.800"
              >
                {t('Deve contenere almeno...')}
              </Text>
              <VStack
                alignItems="flex-start"
                spacing="20px"
                mt="32px"
                w="327px"
              >
                <FormControl isInvalid={!!errors.password}>
                  <InputGroup size="lg">
                    <Input
                      size="lg"
                      id="password"
                      type={show ? "text" : "password"}
                      placeholder={t("Password")}
                      {...register("password")}
                      w="327px"
                      boxShadow="0px 1px 24px rgba(9, 107, 114, 0.08)"
                    />
                    <InputRightElement width="4.5rem">
                      <Button
                        size="xs"
                        onClick={handleClick}
                        borderRadius="5px"
                        mr="5px"
                        height="30px"
                      >
                        {show ? t("Nascondi") : t("Mostra")}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>
                    {errors.password && errors.password.message}
                  </FormErrorMessage>
                </FormControl>

                <Box>
                  <FormControl isInvalid={!!errors.cpassword}>
                    <InputGroup size="lg">
                      <Input
                        size="lg"
                        id="cpassword"
                        type={show ? "text" : "password"}
                        placeholder={t("Conferma password")}
                        {...register("cpassword")}
                        w="327px"
                        boxShadow="0px 1px 24px rgba(9, 107, 114, 0.08)"
                      />
                      <InputRightElement width="4.5rem">
                        <Button
                          size="xs"
                          onClick={handleClick}
                          borderRadius="5px"
                          mr="5px"
                          height="30px"
                        >
                          {show ? t("Nascondi") : t("Mostra")}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>
                      {errors.cpassword && errors.cpassword.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
                <Button
                  size="lg"
                  type="submit"
                  variant="solid"
                  colorScheme="teal"
                  backgroundColor="brand.500"
                  isLoading={props.isLoading}
                >
                  {t('Aggiorna')}
                </Button>
              </VStack>
            </Box>
          </form>
        </Flex>
      </Box>
    </Grid>
  );
};

export default NewPasswordCreatingForm;
