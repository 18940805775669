import {
  Button,
  Box,
  Flex,
  Textarea,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { parseISO } from "date-fns";
import format from "date-fns/format";
import it from "date-fns/locale/it";
import { useNavigate } from "react-router-dom";
import TitleSmall from "../../../../components/TitleSmall";
import EditIcon from "../../../../components/Icons/EditIcon";
import InfoRow from "../../../../components/InfoRow";
import { colors } from "../../../../colors";

import "./index.scss";
import { useTranslation } from "react-i18next";

const ClinicReview = ({ clinic }: any) => {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  return (
    <Box p="24px" borderRadius="8px" bg="#FCFCFC">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <TitleSmall
          text={t("Panoramica")}
          color={colors[100]}
          style={{ marginRight: 24 }}
        />
        <Button
          colorScheme="teal"
          variant="solid"
          onClick={() => navigate("edit")}
          leftIcon={<EditIcon />}
          width="114px"
          height="48px"
          backgroundColor="brand.500"
        >
          {t('Modifica')}
        </Button>
      </Box>
      <Box p="16px" bg="rgba(244, 244, 244, 0.5)" borderRadius={12} mt="24px">
        <InfoRow
          label={t("Tags")}
          placeholder={t("Non definito")}
          element={clinic?.tags}
        />
        <InfoRow
          label={t("Indirizzo")}
          placeholder={t("Non definito")}
          element={clinic?.address}
        />
        <InfoRow
          label={t("Dottori iscritti")}
          placeholder={t("Non definito")}
          element={clinic?.doctorsQuantity}
        />
        <InfoRow
          label={t("Telefono")}
          placeholder={t("Non definito")}
          element={clinic?.phone}
        />
        <InfoRow
          label={t("Email")}
          placeholder={t("Non definito")}
          element={clinic?.email}
        />
        <InfoRow
          label={t("Invitato il")}
          placeholder={t("Non definito")}
          element={
            clinic?.createdAt
              ? format(parseISO(clinic.createdAt), "dd MMMM yyyy", {
                  locale: it,
                })
              : null
          }
        />
        <InfoRow
          label={t("Iscritta il")}
          placeholder={t("Non definito")}
          element={
            clinic?.createdAt
              ? format(parseISO(clinic.createdAt), "dd MMMM yyyy", {
                  locale: it,
                })
              : null
          }
        />
        <FormControl mt="32px">
          <FormLabel htmlFor="note" fontSize="14px">
            {t("Note")}
          </FormLabel>
          <Textarea
            id="note"
            resize="vertical"
            backgroundColor="white"
            border="none"
            // {...register("note")}
          />
        </FormControl>
        <Flex mt="32px" alignItems="center" justifyContent="space-between">
          <Button
            colorScheme="teal"
            variant="solid"
            onClick={() => navigate("/profile")}
            backgroundColor="brand.500"
          >
            {t("Salva")}
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};

export default ClinicReview;
