import { gql } from "@apollo/client";

export const GET_PATH_MODELS = gql`
query getPathModels {
    getPathModels {
      id
      image
      name
      status
      questionnaires {
        id
        activationDate
        repetition
        questionnaire {
          id
          name
          icon
        }
      }
      description
      category {
        id
        name
      }
    }
  }
  `;