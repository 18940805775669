import { SVGProps } from "react";

const CalendarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.286.643v7.143M16.714.643v7.143M4.571 11.357h4.286m8.572 0h-4.286M4.57 15.643h4.286m4.286 0h4.286m-15-11.428H19.57c.79 0 1.429.64 1.429 1.428V19.93c0 .789-.64 1.428-1.429 1.428H2.43c-.79 0-1.429-.64-1.429-1.428V5.643c0-.789.64-1.428 1.429-1.428Z"
      stroke={props.color ?? "#096b72"}
      strokeWidth={1.2}
    />
  </svg>
);

export default CalendarIcon;
