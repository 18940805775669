import { SurveyCreatorComponent, SurveyCreator } from "survey-creator-react";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import { SEND_SURVEY } from "src/graphql/mutations/send-survey.mutation";
import { useMutation, useQuery } from "@apollo/client";
import "survey-core/survey.i18n.js";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Button,
} from "@chakra-ui/react";
import { GET_QUESTIONNAIRES_BY_AGENCY } from "src/graphql/queries/questionnaires-byAgency.query";
import { GET_SURVEY } from "src/graphql/queries/survey.query";

import { useTranslation } from "react-i18next";
const ModifyQuestionnaire = ({
  isOpen,
  onClose,
  surveyId,
  surveyJson,
  setTitle,
}) => {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  const [sendSurvey] = useMutation(SEND_SURVEY, {
    refetchQueries: [
      {
        query: GET_SURVEY,
        variables: {
          getSurveyId: surveyId,
        },
      },
    ],
  });

  const modCreator = useMemo(() => {
    const options = {
      showLogicTab: false,
    };
    const surveyCreator = new SurveyCreator(options);
    surveyCreator.text = JSON.stringify(surveyJson);

    return surveyCreator;
  }, [isOpen]);
  modCreator.isAutoSave = true;
  modCreator.showState = true;

  modCreator.saveSurveyFunc = async function (saveNo, callback) {
    try {
      const modifiedSurvey = await sendSurvey({
        variables: {
          sendSurveyId: surveyId,
          json: modCreator.JSON,
          title: modCreator.JSON.title,
        },
      });
      setTitle(modifiedSurvey.data.title);
      console.log( modCreator.JSON)
    } catch (error) {
      console.log(error);
    } finally {
      console.log(modCreator.JSON);
      callback(saveNo, true);
    }
  };
  return (
    <Modal
      size="full"
      isOpen={isOpen}
      isCentered={true}
      onClose={onClose}
      scrollBehavior="inside"
      closeOnOverlayClick={false}
    >
      <ModalOverlay backgroundColor={"#0000007D"} />
      <ModalContent backgroundColor="brand.600">
        <ModalHeader
          pt="24px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap="10px"
        >
          <Text fontSize="24px" fontWeight="bold" color="brand.700">
            {t("Modifica Questionario:")} {modCreator.JSON.title}
          </Text>

          <Button
            colorScheme="teal"
            variant="solid"
            width="200px"
            height="48px"
            backgroundColor="brand.500"
            onClick={() => {
              onClose();
              modCreator.saveSurveyFunc();
              
            }}
          >
            {t("Modifica")}
          </Button>
        </ModalHeader>
        <ModalBody pb={6} mt="16px" scrollBehavior="inside">
          {isOpen && <SurveyCreatorComponent creator={modCreator} />}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export { ModifyQuestionnaire };
