import { SVGProps } from "react";

const ModifyIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke="#667085"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.8"
        d="M1.917 12.077c.03-.276.046-.413.088-.542.037-.114.09-.223.155-.324.075-.113.173-.21.369-.407L11.333 2A1.886 1.886 0 0114 4.667L5.196 13.47c-.196.196-.295.294-.408.369-.1.066-.209.118-.323.155-.129.042-.267.057-.542.088l-2.256.25.25-2.256z"
      ></path>
    </svg>
  );
};

export default ModifyIcon;
