import { SurveyCreatorComponent, SurveyCreator } from "survey-creator-react";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import { SEND_SURVEY } from "src/graphql/mutations/send-survey.mutation";
import { useMutation, useQuery } from "@apollo/client";
import "survey-core/survey.i18n.js";
import { useMemo } from "react";
import { PlusIcon } from "evergreen-ui";
import "survey-creator-core/survey-creator-core.i18n";
import { localization } from "survey-creator-core";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Button,
} from "@chakra-ui/react";
import { GET_SURVEY } from "src/graphql/queries/survey.query";
import { CREATE_QUESTIONNAIRES_BY_AGENCY } from "src/graphql/mutations/create-questionnaires-byAgency.mutation";
import { FormFieldLabel } from "evergreen-ui";
import { GET_QUESTIONNAIRES_BY_AGENCY } from "src/graphql/queries/questionnaires-byAgency.query";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import { Serializer } from "survey-core";
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
const defaultJson = {
  title: "titolo",
  description: "Descrizione",
  logoPosition: "right",
  pages: [
    {
      name: "page1",
      elements: [
        {
          type: "text",
          name: "question1",
        },
      ],
    },
  ],
};

const propertiesToShow = [
  "name",
  "title",
  "description",
  "visible",
  "readOnly",
  "validation",
  "data",
  "imageFit",
  "logic",
  "choices",
  "columns",
  "rows",
  "rateValues",
];

const SurveyCreatorRenderComponent = ({
  isOpen,
  onClose,
  formik,
  myData,
  isAdvanced,
}) => {
  
  localization.currentLocale = i18next.language ;
  const navigate = useNavigate();
  const { data, loading } = useQuery(GET_QUESTIONNAIRES_BY_AGENCY);
  const [sendSurvey] = useMutation(SEND_SURVEY, {});

  const { t, i18n } = useTranslation();
  // EASY TO USE OPTIONS
  const easyToUseCreator = useMemo(() => {
    const options = {
      showLogicTab: false,
      showPreviewTab: true,
      showJSONEditorTab: false,
      showTranslationTab: false,
      questionTypes: [
        "text",
        "radiogroup",
        "rating",
        "checkbox",
        "dropdown",
        "file",
        "ranking",
        "panel",
        "image",
        "imagepicker",
        "signaturepad",
        "tagbox",
        "boolean",
        "comment",
        "matrix",
        "matrixdropdown",
      ],
    };
    const easyToUseSurveyCreator = new SurveyCreator(options);

    easyToUseSurveyCreator.onShowingProperty.add((sender, options) => {
      options.canShow = propertiesToShow.indexOf(options.property.name) > -1;
      if (
        options.obj.getType() === "ranking" ||
        options.obj.getType() === "imagepicker"
      ) {
        const f = propertiesToShow.filter((i) => i !== "choices");
        options.canShow = f.indexOf(options.property.name) > -1;
      }
      if (options.obj.getType() === "text") {
        const f = propertiesToShow.filter((i) => i !== "rows");
        options.canShow = f.indexOf(options.property.name) > -1;
      }
    });

    defaultJson.title = formik.values.title;
    defaultJson.description = formik.values.description;
    easyToUseSurveyCreator.text = JSON.stringify(defaultJson);

    return easyToUseSurveyCreator;
  }, [isOpen]);

  easyToUseCreator.isAutoSave = true;
  easyToUseCreator.showState = true;

  easyToUseCreator.saveSurveyFunc = async function (saveNo, callback) {
    try {
      await sendSurvey({
        variables: {
          sendSurveyId: myData?.createQuestionnaireByAgency?.id,
          json: easyToUseCreator.JSON,
          title: easyToUseCreator.JSON.title,
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      console.log("etu");
      callback(saveNo, true);
    }
  };

  // ADVANCED OPTIONS
  const AdvancedCreator = useMemo(() => {
    const options = {
      showLogicTab: true,
      questionTypes: [
        "text",
        "radiogroup",
        "rating",
        "checkbox",
        "dropdown",
        "file",
        "imagepicker",
        "ranking",
        "panel",
        "paneldynamic",
        "matrix",
        "matrixdynamic",
        "expression",
        "image",
        "signaturepad",
        "tagbox",
        "boolean",
        "comment",
      ],
    };
    const advancedSurveyCreator = new SurveyCreator(options);
    defaultJson.title = formik.values.title;
    defaultJson.description = formik.values.description;

    advancedSurveyCreator.onShowingProperty.add((sender, options) => {
      options.canShow = propertiesToShow.indexOf(options.property.name) > -1;
      if (options.obj.getType() === "matrix") {
        const f = [...propertiesToShow, "cells"];
        options.canShow = f.indexOf(options.property.name) > -1;
      }
    });

    advancedSurveyCreator.text = JSON.stringify(defaultJson);

    return advancedSurveyCreator;
  }, [isOpen]);

  AdvancedCreator.isAutoSave = true;
  AdvancedCreator.showState = true;

  AdvancedCreator.saveSurveyFunc = async function (saveNo, callback) {
    try {
      await sendSurvey({
        variables: {
          sendSurveyId: myData?.createQuestionnaireByAgency?.id,
          json: AdvancedCreator.JSON,
          title:AdvancedCreator.JSON.title
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      console.log("advanced");
      callback(saveNo, true);
    }
  };

  return (
    <Modal
      size="full"
      isOpen={isOpen}
      isCentered={true}
      onClose={onClose}
      scrollBehavior="inside"
      closeOnOverlayClick={false}
    >
      <ModalOverlay backgroundColor={"#0000007D"} />
      <ModalContent backgroundColor="brand.600">
        <ModalHeader
          pt="24px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap="10px"
        >
          {isAdvanced === false ? (
            <Text fontSize="24px" fontWeight="bold" color="brand.700">
              {t('Crea Questionario (Modalità Easy To Use)')}
            </Text>
          ) : (
            <Text fontSize="24px" fontWeight="bold" color="brand.700">
              {t('Crea Questionario (Modalità Advanced)')}
            </Text>
          )}
          <Button
            colorScheme="teal"
            variant="solid"
            leftIcon={<PlusIcon />}
            width="200px"
            height="48px"
            backgroundColor="brand.500"
            onClick={() => {
              isAdvanced === true ? AdvancedCreator.saveSurveyFunc() : easyToUseCreator.saveSurveyFunc();
              navigate("/questionnaires");
            }}
          >
            {t('Pubblica')}
          </Button>
        </ModalHeader>

        <ModalBody pb={6} mt="16px">
          {isOpen && isAdvanced === false ? (
            <div className="easyToUse">
              <SurveyCreatorComponent creator={easyToUseCreator} />
            </div>
          ) : isOpen && isAdvanced === true ? (
            <SurveyCreatorComponent creator={AdvancedCreator} />
          ) : null}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export { SurveyCreatorRenderComponent };
