import { SVGProps } from "react";

const BlackCopyIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke="#667085"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.8"
        d="M5.333 5.333V3.467c0-.747 0-1.12.146-1.406.128-.25.332-.455.583-.582.285-.146.658-.146 1.405-.146h5.067c.746 0 1.12 0 1.405.146.25.127.455.331.582.582.146.286.146.659.146 1.406v5.066c0 .747 0 1.12-.146 1.406-.127.25-.331.454-.582.582-.285.146-.659.146-1.405.146h-1.867m-7.2 4h5.066c.747 0 1.12 0 1.406-.146.25-.128.455-.332.582-.582.146-.286.146-.659.146-1.406V7.467c0-.747 0-1.12-.146-1.406a1.333 1.333 0 00-.582-.582c-.285-.146-.659-.146-1.405-.146H3.466c-.747 0-1.12 0-1.405.146-.251.127-.455.331-.583.582-.146.286-.146.659-.146 1.406v5.066c0 .747 0 1.12.146 1.406.128.25.332.454.583.582.285.146.658.146 1.405.146z"
      ></path>
    </svg>
  );
};

export default BlackCopyIcon;
