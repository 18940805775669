import { IconButton, Tooltip, useClipboard } from '@chakra-ui/react';
import MailIcon from '../Icons/MailIcon';
import { useTranslation } from 'react-i18next';


export const EmailButton = ({ email }: { email: string; }) => {
  const clipboard = useClipboard(email);
  const { t, i18n } = useTranslation();
  return (
    <Tooltip
      backgroundColor="brand.500" // TODO: move to theme
      label={t("Copiato negli appunti")}
      fontSize="md"
      isOpen={clipboard.hasCopied}
    >
      <IconButton
        aria-label="Copy email"
        icon={<MailIcon />}
        isRound={true}
        onClick={clipboard.onCopy} />
    </Tooltip>
  );
};
