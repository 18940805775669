import { Box } from "@chakra-ui/react";
import { TagInput, TagInputOwnProps } from "evergreen-ui";
import Label from "../../Label";
import { useState } from "react";
import "./index.scss";

interface IProps extends TagInputOwnProps {
  values: string[];
  onChange: (values: string[]) => void;
  className?: string;
  styles?: any;
  placeholder?: string;
  label: string;
  maxTags: number;
}

const GreenTagInput = ({
  label,
  values,
  onChange,
  placeholder = "",
  maxTags,
  ...props
}: IProps) => {
  const [error, setError] = useState("");
  const handleChange = (values: string[]) => {
    console.log(values);
    if (values.length <= maxTags) {
      if (values[values.length - 1].length < 50) {
        onChange(values);
        setError("");
      } else {
        setError("Ogni tag può avere al massimo 50 caratteri");
      }
    }
  };

  return (
    <Box
      mt="16px"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      className="box-tags"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb="2px"
      >
        {values.length > 0 && (
          <Label style={{ marginTop: "0px" }} label={label} />
        )}
        {/* {maxTags < 100 && (
          <div className="tag-count">
            <div>{values.length}</div>
            <div className="tag-count__max">/{maxTags} tags</div>
          </div>
        )} */}
      </Box>
      <TagInput
        className="tag-input-box"
        values={values}
        height={39}
        onChange={handleChange}
        inputProps={{ placeholder }}
        {...props}
      />
      {error.length > 0 && (
        <div style={{ color: "#E53E3E", fontSize: "14px", marginTop: "8px" }}>
          {error}
        </div>
      )}
    </Box>
  );
};

export default GreenTagInput;
