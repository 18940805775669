import { useCallback, useState, useEffect, useMemo } from "react";
import { useMutation, useQuery } from "@apollo/client";
import format from "date-fns/format";
import { addMinutes } from "date-fns";
import { PlusIcon } from "evergreen-ui";
import { Box, Button, Flex, Spinner, useToast } from "@chakra-ui/react";
import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import itLocale from "@fullcalendar/core/locales/it";
import enLocale from "@fullcalendar/core/locales/en-gb";
import arLocale from "@fullcalendar/core/locales/ar-sa";
import frLocale from "@fullcalendar/core/locales/fr";
import deLocale from "@fullcalendar/core/locales/de";
import esLocale from "@fullcalendar/core/locales/es";
import ptLocale from "@fullcalendar/core/locales/pt";
import AddAppointmentModal from "../../../components/Modals/AddAppointmentModal";
import ViewAppointmentModal from "../../../components/Modals/ViewAppointmentModal";
import EditAppointmentModal from "../../../components/Modals/EditAppointmentModal";
import { IAppointment } from "../../../interfaces/IAppointment";
import { ICalendarEvent } from "../../../interfaces/ICalendarEvent";
import { GET_USER } from "../../../graphql/queries/user.query";
import { GET_APPOINTMENTS } from "../../../graphql/queries/appointments.query";
import { CREATE_APPOINTMENT } from "../../../graphql/mutations/create-appointment.mutation";
import { UPDATE_APPOINTMENT } from "../../../graphql/mutations/update-appointment.mutation";
// import { useJoinMeeting } from "../../../hooks/useJoinMeeting";
// import { JOIN_MEETING } from "../../../graphql/mutations/join-meeting.mutation";
import { DELETE_APPOINTMENT } from "../../../graphql/mutations/delete-appointment.mutation";
import { GET_AVAILABILITY } from "../../../graphql/queries/availability.query";
import { GET_DOCTOR } from "../../../graphql/queries/doctors.query";
import { ISlot } from "../../../interfaces/ISlot";
import VideoSmallBlackIcon from "../../../components/Icons/VideoSmallBlackIcon";
import "./index.scss";
import { useTranslation } from "react-i18next";
// import i18next from 'i18next';

const Calendar = () => {
  const { t, i18n } = useTranslation();
  const toast = useToast();
  const [state, setState] = useState({
    from: "",
    to: "",
  });
  const [lang, setLang] = useState<string>(i18n.language);
  const [events, setEvents] = useState<ICalendarEvent[]>([]);
  const [isAddAppointment, setIsAddAppointment] = useState({
    isOpen: false,
    initialValues: {},
  });
  const [isViewAppointment, setIsViewAppointment] = useState<{
    isOpen: boolean;
    event: any;
  }>({
    isOpen: false,
    event: null,
  });
  const [isEditAppointment, setIsEditAppointment] = useState<{
    isOpen: boolean;
    event: any;
  }>({
    isOpen: false,
    event: null,
  });

  // const { joinMeeting } = useJoinMeeting();

  const userQuery = useQuery(GET_USER);
  // FIXME: add from, to
  const { data, loading } = useQuery(GET_APPOINTMENTS);
  const { data: dataDoctor, loading: doctorLoading } = useQuery(GET_DOCTOR);
  const doctorId = dataDoctor?.doctor?.id;
  const { data: availabilityData, loading: availabilityLoading } = useQuery(
    GET_AVAILABILITY,
    {
      skip: !doctorId,
      variables: { doctorId },
    }
  );
  const [calendarLanguage, setCalendarLanguage] = useState(enLocale);
  const [createAppointment] = useMutation(CREATE_APPOINTMENT, {
    refetchQueries: [{ query: GET_APPOINTMENTS }],
  });
  const [updateAppointment, { loading: updateAppointmentLoading }] =
    useMutation(UPDATE_APPOINTMENT, {
      refetchQueries: [{ query: GET_APPOINTMENTS }],
    });
  const [deleteAppointment, { loading: deleteAppointmentLoading }] =
    useMutation(DELETE_APPOINTMENT, {
      refetchQueries: [{ query: GET_APPOINTMENTS }],
    });
  // const [joinMeetingMutation, { loading: joinMeetingLoading }] =
  //   useMutation(JOIN_MEETING);
    const languageToLocaleMap: any = {
      it: itLocale,
      ar: arLocale,
      fr: frLocale,
      de: deLocale,
      pt: ptLocale,
      es: esLocale,
    };
    useEffect(() => {
      const defaultLocale = enLocale;
      const selectedLocale = languageToLocaleMap[i18n.language] || defaultLocale;
      setCalendarLanguage(selectedLocale);
    }, [i18n.language]);
  const slots = useMemo(
    () =>
      (availabilityData?.availability?.slots || []).map(
        ({ dow, start, end }: ISlot) => ({
          dow,
          start,
          end,
        })
      ),
    [availabilityData]
  );

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (data?.appointments) {
      setEvents(
        data.appointments
          .filter((x: IAppointment) => x.status !== "cancelled")
          .map((x: IAppointment) => ({
            id: x.id,
            title: `${x.patient?.name} ${x.patient?.surname}`,
            start: x.startDateTime,
            end: x.endDateTime,
            patient: x.patient,
            serviceId: x.serviceId,
            online: x.online,
            note: x.note,
            roomId: x.roomId,
            roomName: x.roomName,
            roomUrl: x.roomUrl,
          }))
      );
    }
  }, [data]);

  const handleSave = useCallback(
    async (event: any) => {
      try {
        console.log(event);

        const time = event.startTime.padStart(5, 0);
        const date = format(event.date, "yyyy-MM-dd");

        const startDateTime = new Date(`${date} ${time}`).toISOString();
        const endDateTime = addMinutes(
          new Date(`${date} ${time}`),
          event.service.duration
        ).toISOString();

        const { id, email, name, surname } = userQuery.data.me;

        await createAppointment({
          variables: {
            input: {
              serviceId: event.service.id,
              startDateTime,
              endDateTime,
              note: event.note,
              online: Boolean(event.isOnline),
              patientId: parseInt(event.patientId),
              attendees: [
                {
                  user: {
                    id: parseInt(id),
                    email,
                    displayName: `${name} ${surname}`,
                  },
                },
                {
                  user: {
                    id: parseInt(event.patientId),
                    email: event.email,
                    displayName: `${event.name} ${event.surname}`,
                  },
                },
              ],
            },
          },
        });
        setIsAddAppointment({
          isOpen: false,
          initialValues: {},
        });
      } catch (error: any) {
        toast({
          title: t("Errore durante la creazione dell'appuntamento."),
          description: error.message,
          status: "error",
          isClosable: true,
        });
      }
    },
    [userQuery]
  );

  const handleUpdate = async (event: any) => {
    try {
      const time = event.startTime.padStart(5, 0);
      const date = format(event.date, "yyyy-MM-dd");

      const startDateTime = new Date(`${date} ${time}`).toISOString();
      const endDateTime = addMinutes(
        new Date(`${date} ${time}`),
        event.service.duration
      ).toISOString();

      await updateAppointment({
        variables: {
          id: event.id,
          input: {
            startDateTime,
            endDateTime,
            note: event.note,
            serviceId: event.service.id,
            online: Boolean(event.isOnline),
          },
        },
      });

      setIsEditAppointment({ event: null, isOpen: false });
    } catch (error: any) {
      toast({
        title: t("Errore durante l'aggiornamento dell'appuntamento."),
        description: error.message,
        status: "error",
        isClosable: true,
      });
    }
  };

  // const handleJoin = async (id: string) => {
  //   const { data } = await joinMeetingMutation({
  //     variables: { id },
  //   });

  //   if (data?.joinMeeting) {
  //     joinMeeting(data.joinMeeting);
  //   }
  // };

  const editEvent = (event: any) => {
    setIsViewAppointment({ event, isOpen: false });
    setIsEditAppointment({
      isOpen: true,
      event,
    });
  };

  const deleteEvent = async (id: string) => {
    try {
      await deleteAppointment({ variables: { id } });
    } catch (error: any) {
      toast({
        title: t("Errore durante la cancellazione dell'appuntamento."),
        description: error.message,
        status: "error",
        isClosable: true,
      });
    }
  };

  const renderEventContent = (eventInfo: any) => {
    const data = eventInfo.event.extendedProps;
    console.log(data);

    return (
      <>
        <Box display="flex" alignItems="center" mr="2px" color="#1E1F25">
          {eventInfo.timeText}
        </Box>
        <Flex alignItems="center" gap="4px">
          {data.online && <VideoSmallBlackIcon />}
          <Box fontWeight="bold" color="#1E1F25">
            {eventInfo.event.title}
          </Box>
        </Flex>
      </>
    );
  };

  if (loading || doctorLoading || availabilityLoading) {
    return (
      <Flex alignItems="center" justifyContent="center" height="100%">
        <Spinner size="xl" color="brand.500" thickness="4px" speed="0.65s" />
      </Flex>
    );
  }

  return (
    <Box pl="50px" pr="50px" pt="20px">
      <Box p="12px" backgroundColor="brand.300" position="relative">
        <AddAppointmentModal
          isOpen={isAddAppointment.isOpen}
          values={isAddAppointment.initialValues}
          onClose={() =>
            setIsAddAppointment({
              isOpen: false,
              initialValues: {},
            })
          }
          onSave={handleSave}
          events={events}
          slots={slots}
        />
        <ViewAppointmentModal
          event={isViewAppointment.event}
          isOpen={isViewAppointment.isOpen}
          // isJoining={joinMeetingLoading}
          onClose={() =>
            setIsViewAppointment({
              isOpen: false,
              event: null,
            })
          }
          editEvent={editEvent}
          deleteEvent={deleteEvent}
        />
        {isEditAppointment.event && (
          <EditAppointmentModal
            isOpen={isEditAppointment.isOpen}
            loading={updateAppointmentLoading}
            onClose={() => {
              setIsViewAppointment({
                event: isEditAppointment.event,
                isOpen: true,
              });
              setIsEditAppointment({
                isOpen: false,
                event: null,
              });
            }}
            onSave={handleUpdate}
            event={isEditAppointment.event}
            events={events}
            slots={slots}
          />
        )}

        <Button
          colorScheme="teal"
          variant="solid"
          onClick={() =>
            setIsAddAppointment({
              isOpen: true,
              initialValues: {},
            })
          }
          leftIcon={<PlusIcon />}
          height="48px"
          backgroundColor="brand.500"
          position="absolute"
          right="28px"
          top="22px"
        >
          {t("Aggiungi appuntamento")}
        </Button>
        <FullCalendar
          events={events}
          selectable={true}
          selectMirror={true}
          selectOverlap={false}
          locale={calendarLanguage}
          contentHeight={600}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          businessHours={[
            ...(slots.length
              ? slots
              : [
                  {
                    dow: [1, 2, 3, 4, 5],
                    start: "23:00",
                    end: "23:00",
                  },
                ]
            ).map((slot: { dow: number[]; start: string; end: string }) => ({
              daysOfWeek: slot.dow,
              startTime: slot.start,
              endTime: slot.end,
            })),
          ]}
          eventContent={renderEventContent}
          selectAllow={(info) => {
            return info.start >= new Date();
          }}
          slotMinTime={"08:00:00"}
          slotMaxTime={"21:00:00"}
          selectConstraint="businessHours"
          eventConstraint="businessHours"
          headerToolbar={{
            start: "prev,title,next",
            center: "timeGridDay,timeGridWeek,dayGridMonth",
            end: "",
          }}
          buttonText={{
            day: t("Giorno"),
            week: t("Settimana"),
            month: t("Mese"),
          }}
          slotDuration="01:00"
          slotLabelFormat={[
            {
              hour: "2-digit",
              minute: "2-digit",
            },
          ]}
          datesSet={(arg) => {
            setState({
              from: arg.start.toISOString().substring(0, 10),
              to: arg.end.toISOString().substring(0, 10),
            });
          }}
          dayHeaderFormat={{ weekday: "short" }}
          allDaySlot={false}
          select={(info) => {
            if (info.start < new Date()) {
              return;
            }

            setIsAddAppointment({
              isOpen: true,
              initialValues: {
                date: info.start,
              },
            });
          }}
          eventClick={({ event }) => {
            setIsViewAppointment({
              event: events.find((ev) => ev.id == event.id),
              isOpen: true,
            });
          }}
        />
      </Box>
    </Box>
  );
};

export default Calendar;
