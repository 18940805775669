import {
  Box,
  Button,
  Checkbox,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "evergreen-ui";
import React, { useCallback, useState } from "react";
import { QuestionnaireRelatedToType } from "src/interfaces/IQuestionnaires";
import { useTranslation } from "react-i18next";
type QuestionnairesMainFilterProps = {
  onToggleFilter: (filters: QuestionnaireRelatedToType[]) => void;
};

const QuestionnairesMainFilter: React.FC<QuestionnairesMainFilterProps> =
  React.memo(({ onToggleFilter }) => {
    const [currentFilter, setCurrentFilter] = useState<
      QuestionnaireRelatedToType[]
    >([]);
    const [isOpen, setIsOpen] = useState(false);

    const { t, i18n } = useTranslation();
    const toggleFilter = useCallback(
      (filter: QuestionnaireRelatedToType) => {
        if (currentFilter.includes(filter)) {
          const newFilters = currentFilter.filter((f) => f !== filter);
          onToggleFilter(newFilters);
          setCurrentFilter(newFilters);
          return;
        }

        const newFilters = [...currentFilter, filter];
        onToggleFilter(newFilters);
        setCurrentFilter(newFilters);
      },
      [currentFilter, onToggleFilter]
    );

    return (
      <Popover onClose={() => setIsOpen(false)} isOpen={isOpen}>
        <PopoverTrigger>
          <Button
            backgroundColor="white"
            height="44px"
            rightIcon={<ChevronDownIcon />}
            width="100%"
            marginLeft="4"
            border="2px"
            borderRadius="10px"
            borderColor="gray.200"
            onClick={() => setIsOpen(true)}
          >
            <Text display="flex" marginRight="1">
              {t("Filtra per")}
            </Text>
          </Button>
        </PopoverTrigger>
        <PopoverContent
          borderRadius="14px"
          left="0"
          w="fit-content"
          maxH="fit-content"
          border="2px solid #D0D5DD"
        >
          <PopoverBody>
            {Object.values(QuestionnaireRelatedToType).map(
              (filter: any, index: number) => {
                return (
                  <Box
                    key={index}
                    display="flex"
                    w="fit-content"
                    h="fit-content"
                    paddingY="2"
                    paddingX="1"
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Checkbox
                        colorScheme="gray"
                        checked={currentFilter.includes(filter)}
                        onChange={() => toggleFilter(filter)}
                      />
                    </Box>
                    <Box
                      marginLeft={2}
                      fontWeight="500"
                      fontSize="16px"
                      fontFamily="Nunito"
                    >
                      {t(filter)}
                    </Box>
                  </Box>
                );
              }
            )}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  });

export { QuestionnairesMainFilter };
