import { SVGProps } from "react";

const DeleteIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={21}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.9 4.214V2.286C6.9 1.576 7.437 1 8.1 1h4.8c.663 0 1.2.576 1.2 1.286v1.928m-12.6.643h18m-16.2 0v12.857c0 .71.537 1.286 1.2 1.286h12c.663 0 1.2-.576 1.2-1.286V4.857m-7.2 4.5v6.429m-3.6-3.857v3.857m7.2-3.857v3.857"
      stroke="#3F3F3F"
      strokeWidth={1.8}
      strokeLinecap="round"
    />
  </svg>
);

export default DeleteIcon;
