import { gql } from "@apollo/client";

export const UPDATE_PATHINFO_BYID = gql`
mutation UpdatePathInfoById($updatePathInfoByIdId: ID!, $input: PathByAgencyInput!) {
  updatePathInfoById(id: $updatePathInfoByIdId, input: $input) {
      id
      name
      status
      description
      category {
        id
        name
        type
      }
      questionnaires {
        id
        questionnaire {
          id
          name
          description
          shortName
          relatedTo
          icon
          landBotUrl
          patientQuestionnaire {
            id
            status
          }
        }
        activationDate
        repetition
      }
      image
      agency {
        id
      }
    }
  }
`;
