import {
  Button,
  Box,
  Flex,
  Text,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  useToast,
  Spinner,
  Select,
} from "@chakra-ui/react";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import "./index.scss";
import { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";

import { GET_PATIENT } from "src/graphql/queries/patients.query";
import { UPDATE_PATIENT_PROFILE } from "src/graphql/mutations/update-patient-profile.mutation";
import { emailRegex, phoneRegex } from "src/regex";
import "./index.scss";
import { validateTaxCode } from "src/components/Modals/AddPatientModal/validateTaxCode";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { GET_PATIENT_BY_AGENCY } from "src/graphql/queries/get-patients-by-agency.query";
import { GET_USER } from "src/graphql/queries/user.query";

const Review = () => {
  const navigate = useNavigate();
  const params = useParams();
  const toast = useToast();
  const {
    data: { me: user },
  } = useQuery(GET_USER);
  const [state, setState] = useState<any>({});
  const [currentBirthDate, setCurrentBirthDate] = useState<any>(null);
  const [birthDateChanged, setBirthDateChanged] = useState(false);
  const [newState, setNewState] = useState<any>({});
  const { t, i18n } = useTranslation();
  const queryGetPatientByAgency = useQuery(GET_PATIENT_BY_AGENCY, {
    variables: { patientId: params.patientId },
  });
  const queryGetPatient = useQuery(GET_PATIENT, {
    variables: { patientId: params.patientId },
  });
  const [updatePatientProfile, mutation] = useMutation(UPDATE_PATIENT_PROFILE);
  const schema = yup.object({
    name: yup
      .string()
      .test(
        "isNameValid",
        t("Il nome non è valido"),
        (value: any) => value.trim().length !== 0
      )
      .required(t("Inserisci un nome")),
    surname: yup
      .string()
      .test(
        "isSurnameValid",
        t("Il cognome non è valido"),
        (value: any) => value.trim().length !== 0
      )
      .required(t("Inserisci un cognome")),
    fiscalCode: yup
      .string()
      .test("isFiscalCodeValid", t("Il codice fiscale non è valido"), (value) =>
        validateTaxCode(value)
      )
      .required(t("Inserisci il tuo codice fiscale")),

    email: yup
      .string()
      .transform((value) => (!value ? null : value))
      .email(t("L'email non è valida"))
      .matches(emailRegex, t("L'email non è valida"))
      .nullable(),
    phone: yup
      .string()
      .transform((value) => (!value ? null : value))
      .matches(phoneRegex, t("Il numero di telefono non è valido"))
      .nullable(),
    pec: yup
      .string()
      .transform((value) => (!value ? null : value))
      .email(t("L'email non è valida"))
      .matches(emailRegex, t("L'email non è valida"))
      .nullable(),
    birthCity: yup
      .string()
      .transform((value) => (!value ? null : value))
      .nullable(),
    birthDate: yup.string().nullable(),
    sex: yup
      .string()
      .transform((value) => (!value ? null : value))
      .nullable(),
    addressResidence: yup
      .string()
      .transform((value) => (!value ? null : value))
      .nullable(),
    addressDomicile: yup
      .string()
      .transform((value) => (!value ? null : value))
      .nullable(),
    gpName: yup
      .string()
      .transform((value) => (!value ? null : value))
      .nullable(),
    gpSurname: yup
      .string()
      .transform((value) => (!value ? null : value))
      .nullable(),
    gpPhone: yup
      .string()
      .transform((value) => (!value ? null : value))
      .matches(phoneRegex, t("Il numero di telefono non è valido"))
      .nullable(),
    gpEmail: yup
      .string()
      .transform((value) => (!value ? null : value))
      .email(t("L'email non è valida"))
      .matches(emailRegex, t("L'email non è valida"))
      .nullable(),
    gpPec: yup
      .string()
      .transform((value) => (!value ? null : value))
      .email(t("L'email non è valida"))
      .matches(emailRegex, t("L'email non è valida"))
      .nullable(),
    anprId: yup
      .string()
      .transform((value) => (!value ? null : value))
      .nullable(),
    caregiverName: yup.string().nullable(),
    caregiverSurname: yup.string().nullable(),
    caregiverPhone: yup
      .string()
      .transform((value) => (!value ? null : value))
      .matches(phoneRegex, t("Il numero di telefono non è valido"))
      .nullable(),
    caregiverEmail: yup
      .string()
      .transform((value) => (!value ? null : value))
      .email(t("L'email non è valida"))
      .matches(emailRegex, t("L'email non è valida"))
      .nullable(),
    caregiverRelationship: yup
      .string()
      .transform((value) => (!value ? null : value))
      .nullable(),
  });
  const data = useMemo(() => {
    if (user.role == "agency_admin") {
      return queryGetPatientByAgency.data?.getPatientByAgency || null;
    } else {
      return queryGetPatient.data?.patient || null;
    }
  }, [queryGetPatientByAgency, queryGetPatient, user.role]);

  useEffect(() => {
    if (data) {
      if (data.birthDate) {
        const date = new Date(data.birthDate);
        const yyyyMMdd = date.toISOString().slice(0, 10);
        setCurrentBirthDate(yyyyMMdd);
      }
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      setState({
        ...state,
        ...data,
        birthDate: currentBirthDate,
      });
      setNewState({
        ...state,
        ...data,
        birthDate: currentBirthDate,
      });
      reset({
        ...state,
        ...data,
        birthDate: currentBirthDate,
      });
    }
  }, [currentBirthDate]);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: any) => {
    try {
      await updatePatientProfile({
        variables: {
          id: data.id,
          input: {
            name: data.name,
            surname: data.surname,
            fiscalCode: data.fiscalCode,
            birthDate: data.birthDate,
            birthCity: data.birthCity,
            phone: data.phone,
            // TO FIX when backend ready
            // email: data.email,
            pec: data.pec,
            sex: data.sex,
            addressResidence: data.addressResidence,
            addressDomicile: data.addressDomicile,
            gpName: data.gpName,
            gpSurname: data.gpSurname,
            gpPhone: data.gpPhone,
            gpEmail: data.gpEmail,
            gpPec: data.gpPec,
            anprId: data.anprId,
            caregiver: {
              name: data.caregiverName,
              surname: data.caregiverSurname,
              email: data.caregiverEmail,
              relationship: data.caregiverRelationship,
              phone: data.caregiverPhone,
            },
          },
        },
      });
      setBirthDateChanged(false);
      setState({ ...newState });
      toast({
        title: t("Il paziente è stato aggiornato"),
        status: "success",
        isClosable: true,
      });
    } catch (error: any) {
      toast({
        title: t("Errore"),
        description: error.message,
        status: "error",
        isClosable: true,
      });
    }
  };

  if (queryGetPatientByAgency.loading || queryGetPatient.loading) {
    return (
      <Flex alignItems="center" justifyContent="center" height="100%">
        <Spinner size="xl" color="brand.500" thickness="4px" speed="0.65s" />
      </Flex>
    );
  }
  return (
    <>
      <Box pb="24px" px="32px" bg="#FFF" className="main-section-patient">
        <Box borderBottom={"solid 1px #EAECF0"}>
          <Flex>
            <Box w="360px" paddingRight={"24px"}>
              <Text
                fontWeight="700"
                fontSize="18px"
                lineHeight={"30px"}
                mb={"4px"}
              >
                {t("Informazioni di contatto")}
              </Text>
              <Text
                fontWeight="500"
                fontSize="16px"
                color={"brand.800"}
                lineHeight={"24px"}
              >
                {t(
                  "Qui puoi visualizzare e modificare le informazioni del paziente"
                )}
              </Text>
            </Box>
            <Box w="596px">
              <form id="patient-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-pat">
                  {/* name and surname */}
                  <Flex justifyContent="space-between" alignItems="flex-start">
                    <FormControl mr="16px" isInvalid={!!errors.name}>
                      {newState.name?.length > 0 && (
                        <FormLabel
                          marginTop={"4px"}
                          htmlFor="name"
                          fontSize="14px"
                          fontWeight={"bold"}
                          color="brand.700"
                        >
                          {t("Nome")}
                        </FormLabel>
                      )}
                      {newState.surname?.length > 0 &&
                        (newState.name?.length <= 0 || !newState.name) && (
                          <Box height={"22px"} marginTop={"4px"} />
                        )}
                      <Input
                        id="name"
                        variant="outline"
                        placeholder={t("Nome")}
                        {...register("name", {
                          onChange: (e) => {
                            setNewState({ ...newState, name: e.target.value });
                          },
                        })}
                      />
                      <FormErrorMessage>
                        {errors.name?.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!errors.surname}>
                      {newState.surname?.length > 0 && (
                        <FormLabel
                          marginTop={"4px"}
                          htmlFor="surname"
                          fontSize="14px"
                          fontWeight={"bold"}
                          color="brand.700"
                        >
                          {t("Cognome")}
                        </FormLabel>
                      )}
                      {newState.name?.length > 0 &&
                        (newState.surname?.length <= 0 ||
                          !newState.surname) && (
                          <Box height={"22px"} marginTop={"4px"} />
                        )}
                      <Input
                        id="surname"
                        placeholder={t("Cognome")}
                        {...register("surname", {
                          onChange: (e) =>
                            setNewState({
                              ...newState,
                              surname: e.target.value,
                            }),
                        })}
                      />
                      <FormErrorMessage>
                        {errors.surname?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>
                  {/* fiscal code and sex */}
                  <Flex
                    justifyContent="space-between"
                    alignItems="flex-start"
                    mt={"16px"}
                  >
                    <FormControl mr="16px" isInvalid={!!errors.fiscalCode}>
                      {newState.fiscalCode?.length > 0 && (
                        <FormLabel
                          marginTop={"4px"}
                          htmlFor="fiscalCode"
                          fontSize="14px"
                          fontWeight={"bold"}
                          color="brand.700"
                        >
                          {t("Codice Fiscale")}
                        </FormLabel>
                      )}
                      {newState.sex?.length > 0 &&
                        (newState.fiscalCode?.length <= 0 ||
                          !newState.fiscalCode) && (
                          <Box height={"22px"} marginTop={"4px"} />
                        )}
                      <Input
                        id="fiscalCode"
                        placeholder={t("Codice Fiscale")}
                        {...register("fiscalCode", {
                          onChange: (e) =>
                            setNewState({
                              ...newState,
                              fiscalCode: e.target.value,
                            }),
                        })}
                      />
                      <FormErrorMessage>
                        {errors.fiscalCode?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.sex}>
                      {newState.sex?.length > 0 && (
                        <FormLabel
                          marginTop={"4px"}
                          htmlFor="sex"
                          fontSize="14px"
                          fontWeight={"bold"}
                          color="brand.700"
                        >
                          {t("Sesso assegnato alla nascita")}
                        </FormLabel>
                      )}
                      {newState.fiscalCode?.length > 0 &&
                        (newState.sex?.length <= 0 || !newState.sex) && (
                          <Box height={"22px"} marginTop={"4px"} />
                        )}
                      <Select
                        {...register("sex", {
                          onChange: (e) =>
                            setNewState({ ...newState, sex: e.target.value }),
                        })}
                        id="sex"
                        placeholder={t("Sesso assegnato alla nascita")}
                      >
                        <option value="M">{t("M")}</option>
                        <option value="F">{t("F")}</option>
                      </Select>
                      <FormErrorMessage>{errors.sex?.message}</FormErrorMessage>
                    </FormControl>
                  </Flex>
                  {/* birth date and city */}
                  <Flex
                    justifyContent="space-between"
                    alignItems="flex-start"
                    mt={"16px"}
                  >
                    <FormControl mr="16px" isInvalid={!!errors.birthDate}>
                      {(newState.birthDate?.length > 0 || birthDateChanged) && (
                        <FormLabel
                          marginTop={"4px"}
                          htmlFor="birthDate"
                          fontSize="14px"
                          fontWeight={"bold"}
                          color="brand.700"
                        >
                          {t("Data di nascita")}
                        </FormLabel>
                      )}
                      {newState.birthCity?.length > 0 &&
                        (newState.birthDate?.length === 0 ||
                          !newState.birthDate) && (
                          <Box height={"22px"} marginTop={"4px"} />
                        )}
                      <Input
                        id="birthDate"
                        type="date"
                        placeholder={t("Data di nascita")}
                        {...register("birthDate", {
                          onChange: (e) => {
                            setBirthDateChanged(true);
                            // setCurrentBirthDate(e.target.value);
                            setNewState({
                              ...newState,
                              birthDate: e.target.value,
                            });
                          },
                        })}
                      />
                      <FormErrorMessage>
                        {errors.birthDate?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.birthCity}>
                      {newState.birthCity?.length > 0 && (
                        <FormLabel
                          marginTop={"4px"}
                          htmlFor="birthCity"
                          fontSize="14px"
                          fontWeight={"bold"}
                          color="brand.700"
                        >
                          {t("Comune di nascita")}
                        </FormLabel>
                      )}
                      {(newState.birthDate?.length > 0 || birthDateChanged) &&
                        (newState.birthCity?.length <= 0 ||
                          !newState.birthCity) && (
                          <Box height={"22px"} marginTop={"4px"} />
                        )}
                      <Input
                        id="birthCity"
                        placeholder={t("Comune di nascita")}
                        {...register("birthCity", {
                          onChange: (e) =>
                            setNewState({
                              ...newState,
                              birthCity: e.target.value,
                            }),
                        })}
                      />
                      <FormErrorMessage>
                        {errors.birthCity?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>
                  {/* email e telefono */}
                  <Flex justifyContent="space-between" alignItems="flex-start">
                    <FormControl mr="16px" mt="16px" isInvalid={!!errors.email}>
                      {newState.email?.length > 0 && (
                        <FormLabel
                          marginTop={"4px"}
                          htmlFor="email"
                          fontSize="14px"
                          fontWeight={"bold"}
                          color="brand.700"
                        >
                          {t("Email")}
                        </FormLabel>
                      )}
                      {newState.phone?.length > 0 &&
                        (newState.email?.length <= 0 || !newState.email) && (
                          <Box height={"22px"} marginTop={"4px"} />
                        )}
                      <Input
                        id="email"
                        placeholder={t("Email")}
                        {...register("email", {
                          onChange: (e) =>
                            setNewState({ ...newState, email: e.target.value }),
                        })}
                      />
                      <FormErrorMessage>
                        {errors.email?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl mt="16px" isInvalid={!!errors.phone}>
                      {newState.phone?.length > 0 && (
                        <FormLabel
                          marginTop={"4px"}
                          htmlFor="phone"
                          fontSize="14px"
                          fontWeight={"bold"}
                          color="brand.700"
                        >
                          {t("Telefono")}
                        </FormLabel>
                      )}
                      {newState.email?.length > 0 &&
                        (newState.phone?.length <= 0 || !newState.phone) && (
                          <Box height={"22px"} marginTop={"4px"} />
                        )}
                      <Input
                        id="phone"
                        type="phone"
                        placeholder={t("Telefono")}
                        {...register("phone", {
                          onChange: (e) =>
                            setNewState({ ...newState, phone: e.target.value }),
                        })}
                      />
                      <FormErrorMessage>
                        {errors.phone?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>
                  {/* residenza e domicilio */}
                  <Flex justifyContent="space-between" alignItems="flex-start">
                    <FormControl
                      mr="16px"
                      mt="16px"
                      isInvalid={!!errors.addressResidence}
                    >
                      {newState.addressResidence?.length > 0 && (
                        <FormLabel
                          marginTop={"4px"}
                          htmlFor="addressResidence"
                          fontSize="14px"
                          fontWeight={"bold"}
                          color="brand.700"
                        >
                          {t("Luogo di residenza")}
                        </FormLabel>
                      )}
                      {newState.addressDomicile?.length > 0 &&
                        (newState.addressResidence?.length <= 0 ||
                          !newState.addressResidence) && (
                          <Box height={"22px"} marginTop={"4px"} />
                        )}
                      <Input
                        id="addressResidence"
                        placeholder={t("Luogo di residenza")}
                        {...register("addressResidence", {
                          onChange: (e) =>
                            setNewState({
                              ...newState,
                              addressResidence: e.target.value,
                            }),
                        })}
                      />
                      <FormErrorMessage>
                        {errors.addressResidence?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl mt="16px" isInvalid={!!errors.addressDomicile}>
                      {newState.addressDomicile?.length > 0 && (
                        <FormLabel
                          marginTop={"4px"}
                          htmlFor="addressDomicile"
                          fontSize="14px"
                          fontWeight={"bold"}
                          color="brand.700"
                        >
                          {t("Luogo di domicilio")}
                        </FormLabel>
                      )}
                      {newState.addressResidence?.length > 0 &&
                        (newState.addressDomicile?.length <= 0 ||
                          !newState.addressDomicile) && (
                          <Box height={"22px"} marginTop={"4px"} />
                        )}
                      <Input
                        id="addressDomicile"
                        type="addressDomicile"
                        placeholder={t("Luogo di domicilio")}
                        {...register("addressDomicile", {
                          onChange: (e) =>
                            setNewState({
                              ...newState,
                              addressDomicile: e.target.value,
                            }),
                        })}
                      />
                      <FormErrorMessage>
                        {errors.addressDomicile?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>
                  {/* pec */}
                  <Flex
                    justifyContent="space-between"
                    mb={"24px"}
                    width={"50%"}
                    alignItems="flex-start"
                  >
                    <FormControl
                      mt="16px"
                      marginRight={"6px"}
                      isInvalid={!!errors.pec}
                    >
                      {newState.pec?.length > 0 && (
                        <FormLabel
                          marginTop={"4px"}
                          htmlFor="pec"
                          fontSize="14px"
                          fontWeight={"bold"}
                          color="brand.700"
                        >
                          {t("PEC")}
                        </FormLabel>
                      )}
                      <Input
                        id="pec"
                        placeholder={t("PEC")}
                        {...register("pec", {
                          onChange: (e) =>
                            setNewState({ ...newState, pec: e.target.value }),
                        })}
                      />
                      <FormErrorMessage>{errors.pec?.message}</FormErrorMessage>
                    </FormControl>
                  </Flex>
                </div>
              </form>
            </Box>
          </Flex>
        </Box>
        <Box borderBottom={"solid 1px #EAECF0"} mt={"24px"}>
          <Flex>
            <Box w="360px" paddingRight={"24px"}>
              <Text
                fontWeight="700"
                fontSize="18px"
                lineHeight={"30px"}
                mb={"4px"}
              >
                {t("Informazioni del medico di base")}
              </Text>
              <Text
                fontWeight="500"
                fontSize="16px"
                color={"brand.800"}
                lineHeight={"24px"}
              >
                {t(
                  "Qui puoi visualizzare e modificare le informazioni del medico"
                )}
              </Text>
            </Box>
            <Box w="596px">
              <form id="patient-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-pat">
                  {/* name and surname medico*/}
                  <Flex justifyContent="space-between" alignItems="flex-start">
                    <FormControl mr="16px" isInvalid={!!errors.gpName}>
                      {newState.gpName?.length > 0 && (
                        <FormLabel
                          marginTop={"4px"}
                          htmlFor="gpName"
                          fontSize="14px"
                          fontWeight={"bold"}
                          color="brand.700"
                        >
                          {t("Nome del medico")}
                        </FormLabel>
                      )}
                      {newState.gpSurname?.length > 0 &&
                        (newState.gpName?.length <= 0 || !newState.gpName) && (
                          <Box height={"22px"} marginTop={"4px"} />
                        )}
                      <Input
                        id="gpName"
                        variant="outline"
                        placeholder={t("Nome del medico")}
                        {...register("gpName", {
                          onChange: (e) =>
                            setNewState({
                              ...newState,
                              gpName: e.target.value,
                            }),
                        })}
                      />
                      <FormErrorMessage>
                        {errors.gpName?.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!errors.gpSurname}>
                      {newState.gpSurname?.length > 0 && (
                        <FormLabel
                          marginTop={"4px"}
                          htmlFor="gpSurname"
                          fontSize="14px"
                          fontWeight={"bold"}
                          color="brand.700"
                        >
                          {t("Cognome del medico")}
                        </FormLabel>
                      )}
                      {newState.gpName?.length > 0 &&
                        (newState.gpSurname?.length <= 0 ||
                          !newState.gpSurname) && (
                          <Box height={"22px"} marginTop={"4px"} />
                        )}
                      <Input
                        id="gpSurname"
                        placeholder={t("Cognome del medico")}
                        {...register("gpSurname", {
                          onChange: (e) =>
                            setNewState({
                              ...newState,
                              gpSurname: e.target.value,
                            }),
                        })}
                      />
                      <FormErrorMessage>
                        {errors.gpSurname?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>
                  {/* email e telefono medico*/}
                  <Flex justifyContent="space-between" alignItems="flex-start">
                    <FormControl
                      mr="16px"
                      mt="16px"
                      isInvalid={!!errors.gpEmail}
                    >
                      {newState.gpEmail?.length > 0 && (
                        <FormLabel
                          marginTop={"4px"}
                          htmlFor="gpEmail"
                          fontSize="14px"
                          fontWeight={"bold"}
                          color="brand.700"
                        >
                          {t("Email del medico")}
                        </FormLabel>
                      )}
                      {newState.gpPhone?.length > 0 &&
                        (newState.gpEmail?.length <= 0 ||
                          !newState.gpEmail) && (
                          <Box height={"22px"} marginTop={"4px"} />
                        )}
                      <Input
                        id="gpEmail"
                        placeholder={t("Email del medico")}
                        {...register("gpEmail", {
                          onChange: (e) =>
                            setNewState({
                              ...newState,
                              gpEmail: e.target.value,
                            }),
                        })}
                      />
                      <FormErrorMessage>
                        {errors.gpEmail?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl mt="16px" isInvalid={!!errors.gpPhone}>
                      {newState.gpPhone?.length > 0 && (
                        <FormLabel
                          marginTop={"4px"}
                          htmlFor="gpPhone"
                          fontSize="14px"
                          fontWeight={"bold"}
                          color="brand.700"
                        >
                          {t("Telefono del medico")}
                        </FormLabel>
                      )}
                      {newState.gpEmail?.length > 0 &&
                        (newState.gpPhone?.length <= 0 ||
                          !newState.gpPhone) && (
                          <Box height={"22px"} marginTop={"4px"} />
                        )}
                      <Input
                        id="gpPhone"
                        type="gpPhone"
                        placeholder={t("Telefono del medico")}
                        {...register("gpPhone", {
                          onChange: (e) =>
                            setNewState({
                              ...newState,
                              gpPhone: e.target.value,
                            }),
                        })}
                      />
                      <FormErrorMessage>
                        {errors.gpPhone?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>
                  {/* pec idanpr medico */}
                  <Flex
                    justifyContent="space-between"
                    alignItems="flex-start"
                    mb={"24px"}
                  >
                    <FormControl mr="16px" mt="16px" isInvalid={!!errors.gpPec}>
                      {newState.gpPec?.length > 0 && (
                        <FormLabel
                          marginTop={"4px"}
                          htmlFor="gpPec"
                          fontSize="14px"
                          fontWeight={"bold"}
                          color="brand.700"
                        >
                          {t("PEC del medico")}
                        </FormLabel>
                      )}
                      {newState.anprId?.length > 0 &&
                        (newState.gpPec?.length <= 0 || !newState.gpPec) && (
                          <Box height={"22px"} marginTop={"4px"} />
                        )}
                      <Input
                        id="gpPec"
                        placeholder={t("PEC del medico")}
                        {...register("gpPec", {
                          onChange: (e) =>
                            setNewState({ ...newState, gpPec: e.target.value }),
                        })}
                      />

                      <FormErrorMessage>
                        {errors.gpPec?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl mt="16px" isInvalid={!!errors.anprId}>
                      {newState.anprId?.length > 0 && (
                        <FormLabel
                          marginTop={"4px"}
                          htmlFor="anprId"
                          fontSize="14px"
                          fontWeight={"bold"}
                          color="brand.700"
                        >
                          {t("ID ANPR del medic")}o
                        </FormLabel>
                      )}
                      {newState.gpPec?.length > 0 &&
                        (newState.anprId?.length <= 0 || !newState.anprId) && (
                          <Box height={"22px"} marginTop={"4px"} />
                        )}
                      <Input
                        id="anprId"
                        type="anprId"
                        placeholder={t("ID ANPR del medico")}
                        {...register("anprId", {
                          onChange: (e) =>
                            setNewState({
                              ...newState,
                              anprId: e.target.value,
                            }),
                        })}
                      />
                      <FormErrorMessage>
                        {errors.anprId?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>
                </div>
              </form>
            </Box>
          </Flex>
        </Box>
        <Box borderBottom={"solid 1px #EAECF0"} mt={"24px"}>
          <Flex>
            <Box w="360px" paddingRight={"24px"}>
              <Text
                fontWeight="700"
                fontSize="18px"
                lineHeight={"30px"}
                mb={"4px"}
              >
                {t("Caregiver")}
              </Text>
              <Text
                fontWeight="500"
                fontSize="16px"
                color={"brand.800"}
                lineHeight={"24px"}
              >
                {t(
                  "Qui puoi visualizzare e modificare le informazioni del caregiver"
                )}
              </Text>
            </Box>
            <Box w="596px">
              <form id="patient-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-pat">
                  {/* name and phone e parentela caregiver*/}
                  <Flex justifyContent="space-between" alignItems="flex-start">
                    <FormControl mr="16px" isInvalid={!!errors.caregiverName}>
                      {newState.caregiverName?.length > 0 && (
                        <FormLabel
                          marginTop={"4px"}
                          htmlFor="caregiverName"
                          fontSize="14px"
                          fontWeight={"bold"}
                          color="brand.700"
                        >
                          {t("Nome del caregiver")}
                        </FormLabel>
                      )}
                      {newState.caregiverSurname?.length > 0 &&
                        (newState.caregiverName?.length <= 0 ||
                          !newState.caregiverName) && (
                          <Box height={"22px"} marginTop={"4px"} />
                        )}
                      <Input
                        id="caregiverName"
                        variant="outline"
                        placeholder={t("Nome del caregiver")}
                        {...register("caregiverName", {
                          onChange: (e) =>
                            setNewState({
                              ...newState,
                              caregiverName: e.target.value,
                            }),
                        })}
                      />
                      <FormErrorMessage>
                        {errors.caregiverName?.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!errors.caregiverSurname}>
                      {newState.caregiverSurname?.length > 0 && (
                        <FormLabel
                          marginTop={"4px"}
                          htmlFor="caregiverSurname"
                          fontSize="14px"
                          fontWeight={"bold"}
                          color="brand.700"
                        >
                          {t("Cognome del caregiver")}
                        </FormLabel>
                      )}
                      {newState.caregiverName?.length > 0 &&
                        (newState.caregiverSurname?.length <= 0 ||
                          !newState.caregiverSurname) && (
                          <Box height={"22px"} marginTop={"4px"} />
                        )}
                      <Input
                        id="caregiverSurname"
                        placeholder={t("Cognome del caregiver")}
                        {...register("caregiverSurname", {
                          onChange: (e) =>
                            setNewState({
                              ...newState,
                              caregiverSurname: e.target.value,
                            }),
                        })}
                      />
                      <FormErrorMessage>
                        {errors.caregiverSurname?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>
                  {/* phone e mail caregiver */}
                  <Flex justifyContent="space-between" alignItems="flex-start">
                    <FormControl
                      mr="16px"
                      mt="16px"
                      isInvalid={!!errors.caregiverEmail}
                    >
                      {newState.caregiverEmail?.length > 0 && (
                        <FormLabel
                          marginTop={"4px"}
                          htmlFor="caregiverEmail"
                          fontSize="14px"
                          fontWeight={"bold"}
                          color="brand.700"
                        >
                          {t("Email del caregiver")}
                        </FormLabel>
                      )}
                      {newState.caregiverPhone?.length > 0 &&
                        (newState.caregiverEmail?.length <= 0 ||
                          !newState.caregiverEmail) && (
                          <Box height={"22px"} marginTop={"4px"} />
                        )}
                      <Input
                        id="caregiverEmail"
                        placeholder={t("Email del caregiver")}
                        {...register("caregiverEmail", {
                          onChange: (e) =>
                            setNewState({
                              ...newState,
                              caregiverEmail: e.target.value,
                            }),
                        })}
                      />
                      <FormErrorMessage>
                        {errors.caregiverEmail?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl mt="16px" isInvalid={!!errors.caregiverPhone}>
                      {newState.caregiverPhone?.length > 0 && (
                        <FormLabel
                          marginTop={"4px"}
                          htmlFor="caregiverPhone"
                          fontSize="14px"
                          fontWeight={"bold"}
                          color="brand.700"
                        >
                          {t("Telefono del caregiver")}
                        </FormLabel>
                      )}
                      {newState.caregiverEmail?.length > 0 &&
                        (newState.caregiverPhone?.length <= 0 ||
                          !newState.caregiverPhone) && (
                          <Box height={"22px"} marginTop={"4px"} />
                        )}
                      <Input
                        id="caregiverPhone"
                        type="caregiverPhone"
                        placeholder={t("Telefono del caregiver")}
                        {...register("caregiverPhone", {
                          onChange: (e) =>
                            setNewState({
                              ...newState,
                              caregiverPhone: e.target.value,
                            }),
                        })}
                      />
                      <FormErrorMessage>
                        {errors.caregiverPhone?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>
                  {/* parentela caregiver */}
                  <Flex
                    justifyContent="space-between"
                    mb={"24px"}
                    width={"50%"}
                    alignItems="flex-start"
                  >
                    <FormControl
                      mt="16px"
                      marginRight={"6px"}
                      isInvalid={!!errors.caregiverRelationship}
                    >
                      {newState.caregiverRelationship?.length > 0 && (
                        <FormLabel
                          marginTop={"4px"}
                          htmlFor="caregiverRelationship"
                          fontSize="14px"
                          fontWeight={"bold"}
                          color="brand.700"
                        >
                          {t("Parentela / rapporto con il caregiver")}
                        </FormLabel>
                      )}
                      <Input
                        id="caregiverRelationship"
                        placeholder={t("Parentela / rapporto con il caregiver")}
                        {...register("caregiverRelationship", {
                          onChange: (e) =>
                            setNewState({
                              ...newState,
                              caregiverRelationship: e.target.value,
                            }),
                        })}
                      />
                      <FormErrorMessage>
                        {errors.caregiverRelationship?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>
                </div>
              </form>
            </Box>
          </Flex>
        </Box>
        <Box mt={"24px"}>
          <Flex justifyContent={"space-between"} alignItems={"center"}>
            <Button
              style={{ color: "gray" }}
              colorScheme="gray"
              variant="solid"
              onClick={() => {
                navigate(-1);
                setBirthDateChanged(false);
              }}
              backgroundColor="white"
              isLoading={mutation.loading}
            >
              {t("Annulla")}
            </Button>
            <Button
              type="submit"
              form="patient-form"
              colorScheme="teal"
              variant="solid"
              backgroundColor="brand.500"
              isLoading={mutation.loading}
              isDisabled={_.isEqual(state, newState)}
            >
              {t("Salva modifiche")}
            </Button>
          </Flex>
        </Box>
      </Box>
      <Box pb="24px" px="32px" bg="none" h={"46px"}></Box>
    </>
  );
};

export default Review;
