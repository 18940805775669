import { SVGProps } from 'react'

const VideoSmallBlackIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={14}
    height={10}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.334 7.497v.381a2 2 0 0 1-2 2h-6a2 2 0 0 1-2-2V2.545a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v.38l1.225-.874c.882-.63 2.108 0 2.108 1.085v4.151c0 1.085-1.226 1.716-2.108 1.085l-1.225-.875Zm-8-5.619h6c.368 0 .666.299.666.667v5.333a.667.667 0 0 1-.666.667h-6a.667.667 0 0 1-.667-.667V2.545c0-.368.298-.667.666-.667Zm8 3.98 2 1.43V3.135l-2 1.428V5.86Z"
      fill="#1A1D1F"
    />
  </svg>
)

export default VideoSmallBlackIcon