import { Box, Button, Flex } from "@chakra-ui/react";
import VideoWhiteIcon from "../../../../components/Icons/VideoWhiteIcon";
import { IAppointment } from "../../../../interfaces/IAppointment";
import { Card } from "./Card";
import { useTranslation } from "react-i18next";
import { useMeeting } from "src/hooks/useMeeting";

interface IRowProps {
  title: string;
  duration: number;
  date: string;
  meetingId?: string;
  isOnline?: boolean;
  event: IAppointment;
  editEvent: (event: IAppointment) => void;
  selectedIndex: number;
}
export const Row = (props: IRowProps) => {
  const { joinHandler } = useMeeting(props.event);
  const { t } = useTranslation();
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      pt="12px"
      pb="12px"
    >
      <Card
        date={props.date}
        title={props.title}
        duration={props.duration}
        isOnline={props.isOnline}
      />
      {props.selectedIndex === 0 ? (
        <Box>
          {Boolean(props.isOnline) ? <Button
            onClick={joinHandler}
            colorScheme="teal"
            variant="solid"
            leftIcon={<VideoWhiteIcon />}
            height="48px"
            bg="brand.500"
            mr="16px"
          >
            {t("Partecipa")}
          </Button> : null}
          <Button
            onClick={() => props.editEvent(props.event)}
            variant="outline"
            height="48px"
          >
            {t("Modifica")}
          </Button>
        </Box>
      ) : null}
    </Flex>
  );
};
