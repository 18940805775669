import React, { useEffect, useState } from "react";
import { Box, Flex, FormErrorMessage, Input, Text } from "@chakra-ui/react";
import { RelatedToSelector } from "./RelatedToSelector";
import { useTranslation } from "react-i18next";

const GeneralQuestionnairesInformationsField = {
  title: "title",
  description: "description",
  relatedTo: "relatedTo",
};

type GeneralQuestionnairesInformationsProps = {
  formik: any;
};

const GeneralQuestionnairesInformations: React.FC<GeneralQuestionnairesInformationsProps> =
  React.memo(({ formik }) => {
    const [selectedRelatedTo, setSelectedRelatedTo] = useState("");

    const { t, i18n } = useTranslation();
    useEffect(() => {
      formik.setFieldValue(
        GeneralQuestionnairesInformationsField.relatedTo,
        selectedRelatedTo
      );
    }, [selectedRelatedTo]);

    return (
      <Flex p={0} flexDir={"column"}>
        <Flex>
          <Box flex={2} marginTop={"15px"}>
            <Text color={"gray.600"}>
              {t("Aggiungi un titolo e una descrizione per il tuo percorso.")}
            </Text>
          </Box>
          <Box flexDir={"column"} flex={3}>
            <Box flex={1}>
              <Input
                boxShadow="0px 2px 24px rgba(9, 107, 114, 0.08)"
                marginTop={"15px"}
                variant="outline"
                placeholder={t("Titolo")}
                errorBorderColor="red.300"
                id={GeneralQuestionnairesInformationsField.title}
                name={GeneralQuestionnairesInformationsField.title}
                onChange={formik.handleChange}
                value={formik.values.title}
              />
              {Boolean(formik?.errors?.title) && (
                <FormErrorMessage>{formik.errors.title}</FormErrorMessage>
              )}
              <Input
                boxShadow="0px 2px 24px rgba(9, 107, 114, 0.08)"
                marginTop={"15px"}
                marginBottom={"15px"}
                height={"100px"}
                variant="outline"
                placeholder={t("Descrizione")}
                textAlign={"left"}
                id={GeneralQuestionnairesInformationsField.description}
                name={GeneralQuestionnairesInformationsField.description}
                onChange={formik.handleChange}
                value={formik.values.description}
                maxLength={40}
              />
              <Text
                display="flex"
                gap="4px"
                marginBottom={"15px"}
                fontSize="14px"
              >
                <span>
                  {40 - Number(formik.values.description.split("").length)}
                </span>
                {t("Caratteri rimasti")}
              </Text>
              <RelatedToSelector setSelectedRelatedTo={setSelectedRelatedTo} />
              {Boolean(formik?.errors?.description) && (
                <FormErrorMessage>{formik.errors.description}</FormErrorMessage>
              )}
            </Box>
          </Box>
        </Flex>
      </Flex>
    );
  });

export {
  GeneralQuestionnairesInformations,
  GeneralQuestionnairesInformationsField,
};
