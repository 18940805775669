import { Button, Select, Text } from "@chakra-ui/react";
import AddIcon from "src/components/Icons/AddIcon";
import { useQuery } from "@apollo/client";
import { GET_PATH_CATEGORIES } from "src/graphql/queries/get-path-categories.query";
import { useEffect, useMemo, useState } from "react";
import IPathCategory from "src/interfaces/IPathCategory";
import { SetStateAction, Dispatch } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";

type CategorySelectorProps = {
  onCreateCategory?: () => void;
  GeneralInformationsField: any;
  singlePath: any;
};

const EditCategory: React.FC<CategorySelectorProps> = ({
  onCreateCategory,
  GeneralInformationsField,
  singlePath,
}) => {
  const { data } = useQuery(GET_PATH_CATEGORIES);

  const { t, i18n } = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState<number | undefined>(
    undefined
  );

  const categories = useMemo(
    () =>
      (data?.pathCategories?.map((category: IPathCategory) => {
        return {
          id: category.id,
          name: category.name,
        };
      }) ?? []) as IPathCategory[],
    [data?.pathCategories]
  );

  useEffect(() => {
    GeneralInformationsField.category = selectedCategory;
  }, [selectedCategory]);

  return (
    <>
      <Select
        defaultValue={singlePath?.category?.name}
        border="gray.600"
        color="GrayText"
        boxShadow="0px 2px 24px rgba(9, 107, 114, 0.08)"
        onChange={(e) => setSelectedCategory(parseInt(e.target.value))}
      >
        {categories?.map((category: IPathCategory, index: number) => {
          return (
            <option key={index} value={category.id}>
              {category.name}
            </option>
          );
        })}
      </Select>
      <Button
        flexDir="row"
        marginTop={"15px"}
        onClick={onCreateCategory}
        variant={"outline"}
        border={"0px"}
      >
        <AddIcon />
        <Text
          marginLeft={"1.5"}
          color={"brand.500"}
          size={"l"}
          fontWeight={"bold"}
        >
          {t('Crea Categoria')}
        </Text>
      </Button>
    </>
  );
};

export { EditCategory };
