import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  VStack,
  Text,
  HStack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface IProps {
  isLoading: boolean;
  onSubmit: (data: any) => void;
  onSendAgain: () => void;
}

const ConfirmIdentityCodeForm = (props: IProps) => {
  const { t, i18n } = useTranslation();
  const schema = yup.object({
    code: yup
      .string()
      .typeError(t("Il codice non è valido"))
      .required(t("Inserisci il tuo codice")),
  });
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    resolver: yupResolver(schema),
  });
  return (
    <form onSubmit={handleSubmit(props.onSubmit)}>
      <Box>
        <Heading as="h1" size="2xl">
          {t("Conferma identità")}
        </Heading>
        <Text mt="24px" fontSize="15px" fontWeight={400} color="brand.800">
          {t("Ti abbiamo mandato per SMS un codice di 6 cifre.")}
        </Text>
        <HStack spacing="5px">
          <Text fontSize="15px" fontWeight={400} color="brand.800">
            {t("Inseriscilo qui per confermare la tua identità.")}
          </Text>
          <Button
            variant="link"
            colorScheme="teal"
            fontSize="15px"
            fontWeight={400}
            disabled={!isSubmitSuccessful}
            onClick={props.onSendAgain}
          >
            {t("Invia di nuovo")}
          </Button>
        </HStack>
        <VStack alignItems="flex-start" spacing="20px" mt="32px">
          <FormControl isInvalid={!!errors.code}>
            <Input
              size="lg"
              id="code"
              placeholder={t("Codice")}
              {...register("code")}
              w="327px"
              boxShadow="0px 1px 24px rgba(9, 107, 114, 0.08)"
            />
            <FormErrorMessage>
              {errors.code && errors.code.message}
            </FormErrorMessage>
          </FormControl>
          <Button
            size="lg"
            type="submit"
            variant="solid"
            colorScheme="teal"
            backgroundColor="brand.500"
            isLoading={props.isLoading}
          >
            {t("Conferma")}
          </Button>
        </VStack>
      </Box>
    </form>
  );
};

export default ConfirmIdentityCodeForm;
