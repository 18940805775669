import { Flex, Text, Box, Image } from "@chakra-ui/react";
import { ICard } from "./ICard";
import { useTranslation } from "react-i18next";

const Card = (props: ICard & { onClick: (id: string) => void }) => {
  const { t, i18n } = useTranslation();
  return (
    <Flex
      backgroundColor="brand.300"
      borderRadius="12px"
      w="254px"
      h="245px"
      flexDirection="column"
    >
      <Flex flex={3} flexDirection="column" cursor="pointer">
        <Box>
          <Image
            src={props.image}
            width="254px"
            height="175px"
            alt="icon"
            borderRadius="lg"
            onClick={() => props.onClick(props.id)}
          />
        </Box>
        <Text
          fontWeight="bold"
          fontSize="18px"
          pt="8px"
          onClick={() => props.onClick(props.id)}
        >
          {props.name}
        </Text>

        <Text color="#667085" fontSize="16px" pt="8px">
          {props.questionnaires?.length ?? 0} {t("Questionari")}
        </Text>
      </Flex>
    </Flex>
  );
};

export default Card;
