import { SVGProps } from "react";

const PatientIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.62 8.952v4.572a9.143 9.143 0 0 1-1.83 5.486l-.914 1.219a6.095 6.095 0 0 1-9.752 0l-.915-1.22a9.143 9.143 0 0 1-1.828-5.485V8.952m15.238 0a7.62 7.62 0 0 0-15.238 0m15.238 0L16.91 7.244a4.571 4.571 0 0 0-3.232-1.34H8.322a4.572 4.572 0 0 0-3.233 1.34L3.381 8.952m3.81 4.572h1.523m4.572 0h1.524m-6.096-3.048H3.381a2.38 2.38 0 0 0-2.309 2.957l.23.921a2.928 2.928 0 0 0 2.84 2.218m9.144-6.096h5.333a2.38 2.38 0 0 1 2.309 2.957l-.23.921a2.928 2.928 0 0 1-2.84 2.218m-13.715.761 1.276.766a10.667 10.667 0 0 0 5.488 1.52h.186c1.933 0 3.83-.525 5.488-1.52l1.276-.766m-9.905 1.524 1.524-1.524h3.048l1.524 1.524"
      stroke={props.color ?? "#096b72"}
      strokeWidth={1.2}
    />
  </svg>
);

export default PatientIcon;
