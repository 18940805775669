import { gql } from "@apollo/client";

export const GET_SERVICES_HEALTH = gql`
  query Query {
    medicalServices {
      id
      description
      type {
        id
        name
      }
      step {
        id
        name
      }
      acronym {
        id
        name
      }
      priceList {
        id
        name
      }
    }
  }
`;
