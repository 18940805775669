import { SVGProps } from "react"

const ArchiveIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14"
      fill="none"
      viewBox="0 0 16 14"
    >
      <path
        stroke="#667085"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.8"
        d="M2.333 4.331a1.53 1.53 0 01-.26-.023A1.333 1.333 0 011.026 3.26C1 3.131 1 2.976 1 2.667c0-.31 0-.465.026-.594a1.333 1.333 0 011.047-1.047C2.202 1 2.357 1 2.667 1h10c.31 0 .464 0 .593.026.529.105.943.518 1.048 1.047.025.129.025.284.025.594s0 .464-.025.593a1.333 1.333 0 01-1.048 1.048 1.53 1.53 0 01-.26.023M6.333 7.667H9M2.333 4.333H13V9.8c0 1.12 0 1.68-.218 2.108a2 2 0 01-.874.874C11.48 13 10.92 13 9.8 13H5.533c-1.12 0-1.68 0-2.108-.218a2 2 0 01-.874-.874c-.218-.428-.218-.988-.218-2.108V4.333z"
      ></path>
    </svg>
  );
}

export default ArchiveIcon;
