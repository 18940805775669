import {
  Box,
  Button,
  Checkbox,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "evergreen-ui";
import React, { useCallback, useState } from "react";
import { PathFilters } from "../../../interfaces/IPath";
import { useTranslation } from "react-i18next";
type PathsFilterProps = {
  onToggleFilter: (filters: PathFilters[]) => void;
};

const PathsFilter: React.FC<PathsFilterProps> = React.memo(
  ({ onToggleFilter }) => {
    const [currentFilter, setCurrentFilter] = useState<PathFilters[]>([]);
    const [isOpen, setIsOpen] = useState(false);

    const { t, i18n } = useTranslation();
    const toggleFilter = useCallback(
      (filter: PathFilters) => {
        if (currentFilter.includes(filter)) {
          const newFilters = currentFilter.filter((f) => f !== filter);
          onToggleFilter(newFilters);
          setCurrentFilter(newFilters);
          return;
        }
        const newFilters = [...currentFilter, filter];
        onToggleFilter(newFilters);
        setCurrentFilter(newFilters);
      },
      [currentFilter, onToggleFilter]
    );

    return (
      <Popover onClose={() => setIsOpen(false)} isOpen={isOpen}>
        <PopoverTrigger>
          <Button
            backgroundColor="white"
            height="44px"
            rightIcon={<ChevronDownIcon />}
            width="100%"
            marginLeft="4"
            border="2px"
            borderRadius="10px"
            borderColor="gray.200"
            onClick={() => setIsOpen(true)}
          >
            <Box flexDirection="row" display="flex">
              <Text display="flex" marginRight="1">
                {t("Filtra per")}:
              </Text>
            </Box>
          </Button>
        </PopoverTrigger>
        <PopoverContent
          border="2px solid #D0D5DD"
          borderRadius="8px"
          left="-8"
          w={180}
          h="233px"
          overflow="scroll"
        >
          <PopoverHeader
            fontSize="16px"
            fontWeight="700"
            color="#2B292D"
            pt="12px"
          >
            {t('Categoria')}
          </PopoverHeader>
          <PopoverBody>
            {Object.values(PathFilters).map((filter: any, index: number) => {
              return (
                <Box
                  key={index}
                  display="flex"
                  w="100%"
                  paddingY="10px"
                  paddingX="1"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Checkbox
                      colorScheme="gray"
                      checked={currentFilter.includes(filter)}
                      onChange={() => toggleFilter(filter)}
                    />
                  </Box>
                  <Box
                    marginLeft={2}
                    fontWeight="500"
                    fontSize="16px"
                    fontFamily="Nunito"
                  >
                    {t(filter)}
                  </Box>
                </Box>
              );
            })}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  }
);

export { PathsFilter };
