import IPathCategory from "./IPathCategory";

export default  interface IPath {
  id: string;
  description: string;
  name: string;
  status: string;
  category: IPathCategory
  questionnaires: string[]
}

enum PathFilters {
  ORAL_HYGIENE = "Igiene orale",
  ONCOLOGY = "Oncologia",
  CARDIOVASCULAR = "Cardiovascolare",
  DENTAL_CARE = "Igiene dentale",
  MENTAL_HEALTH = "Salute mentale",
}

enum PathStatus {
  ACTIVE = 'active',
  ARCHIVED = 'archived',
  DRAFT = 'draft',
}

export { PathFilters, PathStatus }