import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  fonts: {
    body: "Nunito",
    heading: "Nunito",
  },
  // styles: {
  //   global: {
  //     body: {
  //       fontFamily: 'Nunito'
  //     }
  //   }
  // },
  colors: {
    brand: {
      50: "#F1F6F7",
      100: "#CABDFF",
      200: "#B5E4CA",
      300: "#FCFCFC",
      400: "#F4F4F4",
      500: "#096B72",
      600: "#F1F4F9",
      700: "#101828",
      800: "#667085",
      900: "#3F3F3F",
      1000: "#FFBC99",
      1100: "rgba(0, 0, 0, 0.50);",
    },
  },
  components: {
    Heading: {
      baseStyle: {
        fontFamily: "Nunito", // check this
      },
    },
    Button: {
      variants: {
        solid: {
          borderRadius: "8px",
          padding: "10px 32px",
          minHeight: "44px",
        },
        outline: {
          borderRadius: "8px",
          // height: '48px',
        },
        link: {
          fontFamily: "Nunito",
        },
        circle: {
          borderRadius: "50%",
          height: "36px",
          width: "36px",
          minWidth: "36px",
        },
      },
    },
    Input: {
      variants: {
        solid: {
          field: {
            height: "44px",
          },
        },
      },
    },
    Modal: {
      baseStyle: {
        overlay: {
          bg: "brand.1100",
        },
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          borderRadius: "4px",
          borderColor: "gray.200",

          _checked: {
            bg: "#66E3C3",
            borderColor: "#66E3C3",
          },
        },
      },
    },
  },
});
