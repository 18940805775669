import './index.scss';

interface IProps {
  label: string;
  style?: any;
  className?: string;
}

const Label = ({ className = '', label, style = {} }: IProps) => {
  return (
    <div className="label-container" style={style}>
      <div className={`label ${className}`} style={{ marginRight: '4px', fontWeight : "700", color: "#101828"}}>
        {label}
      </div>
    </div>
  );
};

export default Label;
