import { gql } from '@apollo/client';

export const ASSOCIATE_QUESTIONNAIRES_TO_PATIENT = gql`
  mutation associateQuestionnairesToPatient($patientId: ID!, $questionnairesIds: [ID!]) {
    associateQuestionnairesToPatient(patientId: $patientId, questionnairesIds: $questionnairesIds) {
      id
      status
    }
  }
`;
