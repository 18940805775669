import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/react";

import "./index.scss";
import { useTranslation } from "react-i18next";

interface IModal {
  isOpen: boolean;
  setIsOpen: () => void;
}

const SuccessModal = ({ isOpen, setIsOpen }: IModal) => {
  
  const { t, i18n } = useTranslation();
  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      onClose={setIsOpen}
      isCentered={true}
      scrollBehavior="inside"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent backgroundColor="brand.600">
        <ModalBody pb={6} mt="16px">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            padding={0}
            textAlign="center"
          >
            {t(
              "Il profilo è stato creato correttamente. Affinchè l’utente sia attivo, è necessario che il dottore confermi la registrazione tramite il link ricevuto via email."
            )}
          </Box>
        </ModalBody>

        <ModalFooter pb="24px" justifyContent="center">
          <Button
            type="submit"
            form="doctor-form"
            colorScheme="teal"
            variant="solid"
            backgroundColor="brand.500"
            onClick={setIsOpen}
          >
            {t('Fine')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SuccessModal;
