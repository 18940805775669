import { useLazyQuery } from "@apollo/client";
import { useState } from "react";
import { GET_DAILY_APPOINTMENT_TOKEN } from "src/graphql/queries/getDailyAppointmentToken";

export const useMeeting = (event: any) => {
  const [isJoining, setIsJoining] = useState(false);
  const [ getToken, { data = {} } ] = useLazyQuery(GET_DAILY_APPOINTMENT_TOKEN);

  const joinHandler = async () => {
    console.log('joinHandler', event)
    if (!Boolean(event?.online) || !event?.roomUrl) {
      return console.error('Appointment not online or missing "roomUrl"!')
    }
    setIsJoining(true)
    const { data } = await getToken({ variables: { roomName: event?.roomName } })
    console.log('getToken response', data)
    // MOCK STUFF
    // event.roomUrl = 'https://easydoctorapp.daily.co/DQlVmfFgyWvdxbxFmLjJ'
    if (data?.getDailyAppointmentToken) {
      const url = `${event?.roomUrl}?t=${data.getDailyAppointmentToken}`
      window.open(url, '_blank')
    }
    setIsJoining(false)
  }

  return { isJoining, joinHandler }
};


// MOCK STUFF
// const data: any = await new Promise((resolve) => {
//   setTimeout(() => {
//     resolve({
//       token: 'foo'
//     })
//   }, 1000)
// })