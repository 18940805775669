import { useQuery } from "@apollo/client";
import {
  Box,
  Flex,
  Text,
  Button,
  InputGroup,
  InputLeftElement,
  Input,
  Tabs,
  TabList,
  Tab,
  Divider,
  TabIndicator,
  TabPanels,
  TabPanel,
  filter,
  Spinner,
} from "@chakra-ui/react";
import { PlusIcon, SearchIcon } from "evergreen-ui";
import { PathCard } from "./PathCard";
import { useNavigate } from "react-router-dom";
import IPath, { PathStatus } from "../../../interfaces/IPath";
import { ChangeEvent, useCallback, useState } from "react";
import React from "react";
import { useEffect } from "react";
import ModellliIcon from "../../../components/Icons/ModelliIcon";
import PathModelsModal from "../../../components/Modals/PathModelsModal";
import { PathsFilter } from "./PathsFilter";
import { GET_PATHS } from "../../../graphql/queries/paths.query";
import { useTranslation } from "react-i18next";
import { GET_USER } from "src/graphql/queries/user.query";

const Paths = () => {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  const SEARCH_PATH_TABS = [
    {
      label: t("Attivi"),
      status: PathStatus.ACTIVE,
    },
    {
      label: t("Archiviati"),
      status: PathStatus.ARCHIVED,
    },
    {
      label: t("In bozza"),
      status: PathStatus.DRAFT,
    },
  ];

  const { data, loading } = useQuery<{ paths: IPath[] }>(GET_PATHS);

  const [tabIndex, setTabIndex] = useState(0);
  const [tabData, setTabData] = useState<IPath[] | null>(null);
  const [isPathModelsModal, setIsPathModelsModal] = useState(false);
  const [globalFilter, setGlobalFilter] = useState<{
    toggleValue: string[];
    inputValue: string;
  }>({ toggleValue: [], inputValue: "" });
  const [filteredData, setFilteredData] = useState<IPath[] | null>(null);
  const {
    data: { me: user },
    loading: userLoading,
  } = useQuery(GET_USER);
  useEffect(() => {
    const status = SEARCH_PATH_TABS[tabIndex].status;
    const tabData = data?.paths.filter((x) => x.status === status);
    setFilteredData(tabData ?? []);
    setTabData(tabData ?? []);
    // console.log(tabIndex)
  }, [tabIndex, data]);

  useEffect(() => {
    const { toggleValue, inputValue } = globalFilter;
    const filtered = tabData?.filter((elem) => {
      return (
        (toggleValue.length === 0 ||
          toggleValue.includes(elem.category?.name)) &&
        elem.name
          .toLowerCase()
          .replaceAll(" ", "")
          .includes(inputValue.toLowerCase().replaceAll(" ", ""))
      );
    });
    setFilteredData(filtered || []);
  }, [globalFilter, tabData]);

  const onToggleFilter = useCallback(
    (filters: string[]) => {
      setGlobalFilter({ ...globalFilter, toggleValue: filters });
    },
    [setGlobalFilter, globalFilter]
  );

  const handleClick = () => {
    navigate("/paths/create-new-path");
  };

  if (loading) {
    return (
      <Flex alignItems="center" justifyContent="center" height="100%">
        <Spinner size="xl" color="brand.500" thickness="4px" speed="0.65s" />
      </Flex>
    );
  }

  return (
    <Box h="100%" mr="32px" ml="32px">
      <PathModelsModal
        isOpen={isPathModelsModal}
        onClose={() => setIsPathModelsModal(false)}
        loading={loading}
        onSave={() => {}}
      />
      <Flex alignItems="center" justifyContent="space-between">
        <Flex flexDirection="column">
          <Text fontSize="40px" fontWeight="semibold" pt="40px" pb="8px">
            {t("Percorsi")}
          </Text>

          <Text fontSize="16px" fontWeight="500" pb="30px" color={"GrayText"}>
            {`${t("Vedi la lista dei percorsi gestiti da ")}`}
            {userLoading ? "..." : " " + user.name + " " + user.surname}
          </Text>
        </Flex>
        <Box display="flex" gap="20px">
          <Button
            backgroundColor="brand.300"
            variant="outline"
            height="48px"
            leftIcon={<ModellliIcon />}
            onClick={() => setIsPathModelsModal(true)}
          >
            {t("Modelli")}
          </Button>
          <Button
            colorScheme="teal"
            variant="solid"
            //onClick={() => setIsAddClinic(true)}
            onClick={handleClick}
            leftIcon={<PlusIcon />}
            minWidth="200px"
            height="48px"
            backgroundColor="brand.500"
          >
            {t("Crea nuovo percorso")}
          </Button>
        </Box>
      </Flex>

      <Tabs
        marginTop="24px"
        variant="unstyled"
        position="relative"
        onChange={(index) => setTabIndex(index)}
      >
        <TabList>
          {SEARCH_PATH_TABS.map((tab, index) => (
            <Tab
              key={index}
              fontWeight="semibold"
              _selected={{ color: "brand.500", bg: "#E4EDF2" }}
            >
              {tab.label}
            </Tab>
          ))}
        </TabList>
        <Divider
          mt="-1px"
          height="0.5px"
          borderRadius="1px"
          backgroundColor="#D9DFE9"
          color="#D9DFE9"
        />
        <TabIndicator
          mt="-1.5px"
          height="2px"
          bg="brand.500"
          borderRadius="1px"
        />
        <Flex
          mt="20px"
          height="76px"
          paddingInlineEnd="24px"
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex alignItems="center">
            <InputGroup border="2px" borderRadius="10px" borderColor="gray.200">
              <InputLeftElement
                pointerEvents="none"
                children={<SearchIcon color="gray" />}
              />
              <Input
                width="435px"
                placeholder={t("Cerca per nome o tag")}
                value={globalFilter.inputValue ?? ""}
                background="white"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setGlobalFilter({
                    ...globalFilter,
                    inputValue: e.target.value,
                  });
                }}
              />
            </InputGroup>
            <PathsFilter onToggleFilter={onToggleFilter} />
          </Flex>
        </Flex>
        <TabPanels>
          {SEARCH_PATH_TABS.map((tab, index) => (
            <TabPanel pl="0" pr="0" key={index}>
              <Flex flexWrap="wrap" gap="24px">
                {filteredData?.map((x: IPath) => (
                  <PathCard
                    activeTabStatus={tab.status}
                    key={x.id}
                    category={x.category}
                    name={x.name}
                    questionnaires={x.questionnaires}
                    description={x.description}
                    id={x.id}
                    status={x.status}
                  />
                ))}
              </Flex>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default Paths;
