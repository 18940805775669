import {
  Box,
  Button,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  ModalFooter,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

type removeQuestionnaireModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const DeleteWarningModal: React.FC<removeQuestionnaireModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { t, i18n } = useTranslation();
  return (
    <Modal
      size="sm"
      isOpen={isOpen}
      isCentered={true}
      onClose={onClose}
      scrollBehavior="inside"
      closeOnOverlayClick={false}
    >
      <ModalOverlay backgroundColor={"#0000007D"} />
      <ModalContent backgroundColor="white">
        <ModalHeader pt="24px" fontWeight="700">
          <Text>{t("Elimina questionario")}</Text>
        </ModalHeader>
        <ModalBody pb={6} mt="16px" paddingInlineEnd="24px" alignItems="center">
          <Text color={"GrayText"} mb="1rem">
            {t(
              "Non è possibile rimuovere un questionario che è associato a un percorso."
            )}
          </Text>
          <Text color={"GrayText"} mb="1rem">
            {t(
              "È necessario dissociare il questionario prima di poterlo eliminare."
            )}
          </Text>
          <ModalFooter display="flex" justifyContent="center">
            <Button
              colorScheme="teal"
              height="44px"
              variant="solid"
              backgroundColor="brand.500"
              borderRadius="10px"
              borderColor="gray.200"
              onClick={() => {
                onClose();
              }}
            >
              {t("Chiudi")}
            </Button>
          </ModalFooter>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DeleteWarningModal;
