import { SVGProps } from "react";

const Camera = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Camera">
      <path
        id="Vector"
        d="M17.0625 17.0625H3.9375C3.5894 17.0625 3.25556 16.9242 3.00942 16.6781C2.76328 16.4319 2.625 16.0981 2.625 15.75V6.5625C2.625 6.2144 2.76328 5.88056 3.00942 5.63442C3.25556 5.38828 3.5894 5.25 3.9375 5.25H6.5625L7.875 3.28125H13.125L14.4375 5.25H17.0625C17.4106 5.25 17.7444 5.38828 17.9906 5.63442C18.2367 5.88056 18.375 6.2144 18.375 6.5625V15.75C18.375 16.0981 18.2367 16.4319 17.9906 16.6781C17.7444 16.9242 17.4106 17.0625 17.0625 17.0625Z"
        stroke="#1D2939"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M10.5 13.7812C12.131 13.7812 13.4531 12.4591 13.4531 10.8281C13.4531 9.19716 12.131 7.875 10.5 7.875C8.86903 7.875 7.54688 9.19716 7.54688 10.8281C7.54688 12.4591 8.86903 13.7812 10.5 13.7812Z"
        stroke="#1D2939"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default Camera;
