import { useQuery } from "@apollo/client";
import { useState, useMemo, useEffect } from "react";
import { CrossIcon, Dialog, Textarea } from "evergreen-ui";
import { Box, IconButton } from "@chakra-ui/react";
import { IModal } from "../../../interfaces/IModal";
import InputDatePicker from "../../Inputs/InputDatePicker";
import InputBlock from "../../Inputs/InputBlock";
import ToggleInput from "../../Inputs/ToggleInput";
import DefaultButton from "../../Buttons/DefaultButton";
import TitleSmall from "../../TitleSmall";
import InvitationSubModal from "../SubModals/InvitationSubModal";
import TypeInvitationModal from "../SubModals/TypeInvitationModal";
import TimeSubModal from "../SubModals/TimeSubModal";
import GreenCalendarInput from "../../Inputs/GreenCalendarInput";
import { IPatient } from "../../../interfaces/IPatient";
import { getAvailableTime } from "../../../utils";
import GreenButton from "../../Buttons/GreenButton";
import { GET_PATIENTS } from "../../../graphql/queries/patients.query";
import { ICalendarEvent } from "../../../interfaces/ICalendarEvent";
import { ISlot } from "../../../interfaces/ISlot";
import { useTranslation } from "react-i18next";

const initialState = {
  isOpenInvitation: false,
  isOpenType: false,
  isOpenTime: false,
};

const initialFormData = {
  patientId: "",
  email: "",
  service: null,
  isOnline: false,
  date: new Date(),
  startTime: "",
  note: "",
  name: "",
  surname: "",
};

const AddAppointmentModal = ({
  onClose,
  isOpen,
  onSave,
  events,
  patientId,
  values,
  slots,
}: IModal & {
  events: ICalendarEvent[];
  patientId?: number | null;
  values?: { date?: Date };
  slots: ISlot[];
}) => {
  const [state, setState] = useState<any>(initialState);
  const [availableTime, setAvailableTime] = useState<string[]>([]);
  const [formData, setFormData] = useState<any>(initialFormData);
  const patientsQuery = useQuery(GET_PATIENTS);

  const { t, i18n } = useTranslation();
  const data: IPatient[] = useMemo(
    () => patientsQuery.data?.patients || [],
    [patientsQuery.data]
  );

  // const serviceList: IClinicServices[] = useMemo(
  //   () => clinicServiceList.data?.data || [],
  //   [clinicServiceList]
  // );

  const serviceList: any = [
    {
      id: 1,
      hospital_id: 1,
      name: "Visita introduttiva",
      price: "20.00",
      duration: 30,
    },
    {
      id: 2,
      hospital_id: 1,
      name: "Visita di follow-up",
      price: "10.00",
      duration: 20,
    },
  ];

  const currentPatient = useMemo(() => {
    console.log(formData);

    if (formData.patientId) {
      const patient = data.find((p) => p.id === formData.patientId);
      return patient ? `${patient.name} ${patient.surname}` : "";
    }

    return "";
  }, [formData, data]);

  useEffect(() => {
    if (values && values.date) {
      setFormData({
        ...formData,
        date: values.date,
      });
    }
  }, [values]);

  useEffect(() => {
    if (!isOpen) {
      setState({
        ...initialState,
      });
      setFormData((prevState: any) => ({
        ...prevState,
        patientId: patientId || prevState.patientId,
      }));
    } else {
      setFormData((prevState: any) => ({ ...prevState, patientId }));
    }
  }, [isOpen, patientId]);

  useEffect(() => {
    if (formData.service) {
      setAvailableTime(
        getAvailableTime(
          formData.date,
          slots,
          events,
          +formData.service.duration
        )
      );
    }
  }, [formData.date, formData.service, slots, events]);

  const handleChange = (key: string, value: any) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const isWeekday = (date: Date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  const ModalHeader = () => {
    return (
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        w="100%"
      >
        <TitleSmall text={t("Crea appuntamento")} color="#FFBC99" />
        <IconButton
          border="none"
          aria-label="close"
          icon={<CrossIcon style={{ height: "20px", width: "20px" }} />}
          onClick={onClose}
          background="#EFEFEF"
          variant="circle"
        />
      </Box>
    );
  };

  return (
    <Dialog
      isShown={isOpen}
      hasHeader={false}
      hasFooter={false}
      header={ModalHeader}
      containerProps={{ background: "#F2F5FA !important" }}
      width={376}
      onCloseComplete={onClose}
    >
      <InvitationSubModal
        isOpen={state.isOpenInvitation}
        onSelect={({ id, email, name, surname }) => {
          setFormData({
            ...formData,
            patientId: id,
            email,
            name,
            surname,
          });
          setState({
            ...state,
            isOpenInvitation: false,
          });
        }}
        onClose={() =>
          setState({
            ...state,
            isOpenInvitation: false,
          })
        }
        patients={data || []}
      />
      <TypeInvitationModal
        isOpen={state.isOpenType}
        onSelect={(service) => {
          handleChange("service", service);
          setState({
            ...state,
            isOpenType: false,
          });
        }}
        onClose={() =>
          setState({
            ...state,
            isOpenType: false,
          })
        }
        values={serviceList}
      />
      <TimeSubModal
        title={t("Ora")}
        timeList={availableTime}
        isOpen={state.isOpenTime}
        onSelect={(time) => {
          handleChange("startTime", time);
          setState({
            ...state,
            isOpenTime: false,
          });
        }}
        onClose={() =>
          setState({
            ...state,
            isOpenTime: false,
          })
        }
      />
      <Box>
        <div className="modal-text">
          {t("Scegli una data e un'ora per la visita. Vedrai solo gli slot coerenti con le tue disponibilità.")}
        </div>
        <Box mt="24px">
          <InputBlock
            className="input-box_green"
            placeholder={t("Invitati")}
            greenLabel={t("Invitati")}
            value={currentPatient}
            onChange={() => console.log("onChange")}
            marginTop={24}
            onClick={() =>
              setState({
                ...initialState,
                isOpenInvitation: true,
              })
            }
            disabled={patientId}
          />
          <InputBlock
            className="input-box_green"
            placeholder={t("Tipo di appuntamento")}
            greenLabel={t("Tipo di appuntamento")}
            value={
              formData.service
                ? `${formData.service.name} - ${formData.service.duration} ${t(
                    "minuti"
                  )}`
                : ""
            }
            onChange={() => console.log("onChange")}
            marginTop={24}
            onClick={() =>
              setState({
                ...initialState,
                isOpenType: true,
              })
            }
          />
          <ToggleInput
            marginTop={24}
            marginLeft={20}
            title={t("Appuntamento online")}
            checked={formData.isOnline}
            onChange={(checked) => handleChange("isOnline", checked)}
          />
          <InputDatePicker
            icon="green"
            greenLabel={t("Data")}
            dateFormat="EEEE d MMMM yyyy"
            placeholder={t("Data")}
            value={formData.date}
            iconBackgroundColor="#FFFFFF"
            onChange={(val) => handleChange("date", val)}
            filterDate={isWeekday}
            minDate={new Date()} 
          />
          <GreenCalendarInput
            placeholder={t("Ora")}
            greenLabel={t("Ora")}
            value={formData.startTime}
            iconBackgroundColor="#FFFFFF"
            onChange={() => {
              console.log("onChange");
            }}
            onClick={() =>
              setState({
                ...initialState,
                isOpenTime: true,
              })
            }
          />
          <Box mt="24px">
            <Textarea
              id="textarea-patient-note"
              className="textarea-base"
              required={false}
              value={formData.note}
              height="112px !important"
              onChange={(event: any) =>
                handleChange("note", event.target.value)
              }
            />
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt="16px"
        >
          <DefaultButton title={t("Annulla")} onClick={onClose} />
          <GreenButton
            title={t("Crea visita")}
            borderRadius={12}
            onClick={() => {
              onSave(formData);
              setFormData(initialFormData);
            }}
          />
        </Box>
      </Box>
    </Dialog>
  );
};

export default AddAppointmentModal;
