import { Box, Button, Divider, Text, Flex } from "@chakra-ui/react";
import { ChevronRightIcon } from "evergreen-ui";
import { useNavigate } from "react-router-dom";
import MenuButtonPath from "../../../components/Buttons/MenuButtonPath";
import { PathStatus } from "../../../interfaces/IPath";
import IPathCategory from "../../../interfaces/IPathCategory";
import { useTranslation } from "react-i18next";

export default interface IPathCardProps {
  id: string;
  description: string;
  name: string;
  status: string;
  category: IPathCategory;
  questionnaires: string[];
  activeTabStatus: PathStatus;
}

export const PathCard = (props: IPathCardProps) => {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  const handleClick = () => {
    navigate(`/paths/${props.id}`);
  };

  return (
    <Flex
      backgroundColor="brand.300"
      borderRadius="12px"
      w="349px"
      h="298px"
      p="16px 0px"
      gap="28px"
      alignItems="flex-start"
      flexDirection="row"
    >
      <Flex flex={4} flexDirection="column" w="349px" h="201px" p="0px 24px">
        <Flex justifyContent="space-between" alignItems="center">
          <Text
            color="#667085"
            fontWeight="600"
            fontFamily="Nunito"
            lineHeight="21px"
            fontSize="14px"
            background="#EDEFF5"
            p="4px 12px 4px 12px"
            borderRadius="6px"
          >
            {props?.category?.name}
          </Text>
          <Box>
            <MenuButtonPath
              activeTabStatus={props.activeTabStatus}
              cardId={props.id}
            />
          </Box>
        </Flex>
        <Flex flexDirection="column">
          <Text
            w="308px"
            h="60px"
            fontWeight="700"
            fontSize="20px"
            fontStyle="normal"
            fontFamily="Nunito"
            color="#2B292D"
            lineHeight="30px"
            mt="12px"
          >
            {props?.name}
          </Text>
          <Text
            w="301px"
            h="24px"
            fontStyle="normal"
            fontFamily="Nunito"
            fontWeight="600"
            lineHeight="24px"
            color="#101828"
            mt="8px"
          >
            {`${
              props?.questionnaires.length !== 1
                ? props?.questionnaires.length + " Questionari"
                : props?.questionnaires.length + " Questionario"
            }`}
          </Text>
          <Text
            w="301px"
            h="48px"
            fontStyle="normal"
            fontFamily="Nunito"
            fontSize="16px"
            fontWeight="500"
            lineHeight="24px"
            color="#667085"
            mt="16px"
          >
            {props?.description}
          </Text>
        </Flex>
        <Flex flexDirection="column">
          <Divider
            mt="18px"
            height="1px"
            borderRadius="1px"
            backgroundColor="#EDEFF5"
            color="#EDEFF5"
            position="relative"
            right="24px"
            w="349px"
          />
          <Flex
            alignItems="center"
            flexDirection="row"
            justifyContent="flex-start"
            gap="2px"
          >
            <Button
              w="157px"
              h="24px"
              mt="12px"
              rightIcon={<ChevronRightIcon />}
              fontStyle="normal"
              fontFamily="Nunito"
              fontSize="16px"
              fontWeight="700"
              lineHeight="24px"
              color="#096B72"
              border="none"
              variant="link"
              onClick={handleClick}
            >
              {t("Dettaglio percorso")}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
