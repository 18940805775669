import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { GET_QUESTIONNAIRES } from "../../../graphql/queries/questionnaires.query";
import {
  Box,
  Text,
  Flex,
  Spinner,
  Button,
  InputGroup,
  InputLeftElement,
  Input,
} from "@chakra-ui/react";
import { IQuestionnaires } from "../../../interfaces/IQuestionnaires";
import { PlusIcon, SearchIcon } from "evergreen-ui";
import { useNavigate } from "react-router-dom";
import QuestionnairesMainList from "./QuestionnairesMainList";
import { QuestionnairesMainFilter } from "./QuestionnairesMainFilter";
import { GET_QUESTIONNAIRES_BY_AGENCY } from "src/graphql/queries/questionnaires-byAgency.query";
import { DELETE_QUESTIONNAIRE } from "src/graphql/mutations/delete-questionnaire.mutation";
import DeleteWarningModal from "src/components/Modals/DeleteWarningModal";
import { GET_SURVEY } from "src/graphql/queries/survey.query";

import { useTranslation } from "react-i18next";
import { GET_USER } from "src/graphql/queries/user.query";
const Questionnaires = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [globalFilter, setGlobalFilter] = useState<{
    toggleValue: string[];
    inputValue: string;
  }>({ toggleValue: [], inputValue: "" });
  const [filteredQuestionnaire, setFilteredQuestionnaire] = useState<
    IQuestionnaires[]
  >(null ?? []);
  const [selectedQuestionnaires, setSelectedQuestionnaires] = useState<
    IQuestionnaires[]
  >([]);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const {
    data: { me: user },
    loading: userLoading,
  } = useQuery(GET_USER);
  const [deleteQuestionnaire, { loading: loading2 }] = useMutation(
    DELETE_QUESTIONNAIRE,
    {
      refetchQueries: [
        {
          query: GET_QUESTIONNAIRES_BY_AGENCY,
        },
      ],
    }
  );

  const handleQuestionnaireDelete = async (id: any) => {
    try {
      await deleteQuestionnaire({
        variables: {
          deleteQuestionnaireByIdId: id,
        },
      });
    } catch (err) {
      console.error(err);
      setIsOpenModal(true);
    }
  };

  const { data, loading } = useQuery(GET_QUESTIONNAIRES_BY_AGENCY);
  useEffect(() => {
    const { toggleValue, inputValue } = globalFilter;
    const filtered = data?.getQuestionnairesByAgency.filter((elem: any) => {
      return (
        (toggleValue.length === 0 || toggleValue.includes(elem.relatedTo)) &&
        elem.name
          .toLowerCase()
          .replaceAll(" ", "")
          .includes(inputValue.toLowerCase().replaceAll(" ", ""))
      );
    });
    setFilteredQuestionnaire(filtered || []);
  }, [globalFilter, data]);

  const onToggleFilter = useCallback(
    (filters: string[]) => {
      setGlobalFilter({ ...globalFilter, toggleValue: filters });
    },
    [setGlobalFilter]
  );

  const handleClick = () => {
    navigate("/questionnaires/create-new-questionnaire");
  };

  if (loading) {
    return (
      <Flex alignItems="center" justifyContent="center" height="100%">
        <Spinner size="xl" color="brand.500" thickness="4px" speed="0.65s" />
      </Flex>
    );
  }

  return (
    <>
      <Box h="100%" mr="91px" ml="91px">
        <Flex alignItems="center" justifyContent="space-between">
          <Flex flexDirection="column">
            <Text fontSize="40px" fontWeight="semibold" pt="40px" pb="24px">
              {t('Questionari')}
            </Text>
            <Text fontSize="16px" fontWeight="500" pb="30px" color={"GrayText"}>
              {`${t('Vedi la lista dei questionari gestiti da')}` }
              {userLoading ? "..." : " "+user.name + " " + user.surname}
            </Text>
          </Flex>
          <Button
            colorScheme="teal"
            variant="solid"
            onClick={handleClick}
            leftIcon={<PlusIcon />}
            width="fitContent"
            height="48px"
            backgroundColor="brand.500"
          >
            {t('Crea nuovo questionario')}
          </Button>
        </Flex>
        <Flex
          height="76px"
          paddingInlineEnd="24px"
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex alignItems="center">
            <InputGroup border="2px" borderRadius="10px" borderColor="gray.200">
              <InputLeftElement
                pointerEvents="none"
                children={<SearchIcon color="gray" />}
              />
              <Input
                width="435px"
                placeholder={t("Cerca questionario...")}
                value={globalFilter.inputValue ?? ""}
                background="white"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setGlobalFilter({
                    ...globalFilter,
                    inputValue: e.target.value,
                  });
                }}
              />
            </InputGroup>
            <QuestionnairesMainFilter onToggleFilter={onToggleFilter} />
          </Flex>
        </Flex>
        <QuestionnairesMainList
          data={filteredQuestionnaire}
          searchText={globalFilter.inputValue ?? ""}
          selectedQuestionnaires={selectedQuestionnaires}
          setSelectedQuestionnaires={setSelectedQuestionnaires}
          handleQuestionnaireDelete={handleQuestionnaireDelete}
        />
      </Box>
      <DeleteWarningModal
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
      />
    </>
  );
};

export default Questionnaires;
