import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "evergreen-ui";
import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  SetStateAction,
} from "react";
import {
  ActivationDate,
  IQuestionnaires,
  QuestionnaireRelatedToType,
  Repetition,
} from "src/interfaces/IQuestionnaires";
import { useTranslation } from "react-i18next";
enum QuestionnairesFiltersType {
  relatedTo = "Rivolto a",
  activationDate = "Attivazione",
  repetition = "Ricorrenza",
}

type FilterType = {
  name:
    | string
    | ActivationDate
    | QuestionnaireRelatedToType
    | Repetition
    | undefined;
  checked: boolean;
};

type QuestionnairesFilters = {
  relatedTo: FilterType[];
  activationDate: FilterType[];
  repetition: FilterType[];
};

type QuestionnairesFIlterProps = {
  filteredItems: any[];
  setFilteredItems: React.Dispatch<SetStateAction<any[]>>;

  onToggleFilter: (filters: any[]) => void;
};

const PathQuestionnairesFilter: React.FC<QuestionnairesFIlterProps> =
  React.memo(({ filteredItems, setFilteredItems, onToggleFilter }) => {
    const [currentFilter, setCurrentFilter] = useState<any[]>(filteredItems);
    const [isOpen, setIsOpen] = useState(false);
    const [isChecked, setIsChecked] = useState<boolean>(true);

    const { t, i18n } = useTranslation();
    const toggleFilter = useCallback(
      (filter: any) => {
        if (isChecked === true) {
          const newFilter = currentFilter?.filter(
            (f) =>
              f?.questionnaire?.relatedTo === filter ||
              f?.activationDate === filter ||
              f?.repetition === filter
          );
          setFilteredItems(newFilter);
        }
        if (isChecked === false) {
          const newFilter = currentFilter;
          setFilteredItems(newFilter);
        }
      },
      [isChecked]
    );

    return (
      <Popover onClose={() => setIsOpen(false)} isOpen={isOpen}>
        <PopoverTrigger>
          <Button
            backgroundColor="white"
            height="44px"
            rightIcon={<ChevronDownIcon />}
            width="100%"
            marginLeft="4"
            border="2px"
            borderRadius="10px"
            borderColor="gray.200"
            onClick={() => setIsOpen(true)}
          >
            <Text display="flex" marginRight="1">
              {t("Filtra per")}
            </Text>
          </Button>
        </PopoverTrigger>
        <PopoverContent
          border="2px"
          borderRadius="14px"
          maxH="fit-content"
          style={{
            borderColor: "#E2E8F0",
          }}
        >
          <PopoverBody>
            <Accordion allowToggle>
              {Object.entries(QuestionnairesFiltersType).map(
                ([key, values]) => {
                  return (
                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box as="span" flex="1" textAlign="left">
                            {
                              QuestionnairesFiltersType[
                                key as keyof typeof QuestionnairesFiltersType
                              ]
                            }
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        {key === "relatedTo"
                          ? Object.values(QuestionnaireRelatedToType).map(
                              (
                                filter: QuestionnaireRelatedToType,
                                index: number
                              ) => {
                                return (
                                  <Box
                                    key={index}
                                    display="flex"
                                    w="100%"
                                    paddingY="2"
                                    paddingX="1"
                                  >
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="center"
                                    >
                                      <Checkbox
                                        onChange={() => {
                                          toggleFilter(filter);
                                          setIsChecked(!isChecked);
                                        }}
                                      />
                                    </Box>
                                    <Box
                                      marginLeft={2}
                                      fontWeight="500"
                                      fontSize="16px"
                                      fontFamily="Nunito"
                                    >
                                      {t(filter)}
                                    </Box>
                                  </Box>
                                );
                              }
                            )
                          : key === "Repetition"
                          ? Object.values(Repetition).map(
                              (filter: Repetition, index: number) => {
                                return (
                                  <Box
                                    key={index}
                                    display="flex"
                                    w="100%"
                                    paddingY="2"
                                    paddingX="1"
                                  >
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="center"
                                    >
                                      <Checkbox
                                        onChange={() => {
                                          toggleFilter(filter);
                                          setIsChecked(!isChecked);
                                        }}
                                      />
                                    </Box>
                                    <Box
                                      marginLeft={2}
                                      fontWeight="500"
                                      fontSize="16px"
                                      fontFamily="Nunito"
                                    >
                                      {t(filter)}
                                    </Box>
                                  </Box>
                                );
                              }
                            )
                          : Object.values(ActivationDate).map(
                              (filter: ActivationDate, index: number) => {
                                return (
                                  <Box
                                    key={index}
                                    display="flex"
                                    w="100%"
                                    paddingY="2"
                                    paddingX="1"
                                  >
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="center"
                                    >
                                      <Checkbox
                                        onChange={() => {
                                          toggleFilter(filter);
                                          setIsChecked(!isChecked);
                                        }}
                                      />
                                    </Box>
                                    <Box
                                      marginLeft={2}
                                      fontWeight="500"
                                      fontSize="16px"
                                      fontFamily="Nunito"
                                    >
                                      {t(filter)}
                                    </Box>
                                  </Box>
                                );
                              }
                            )}
                      </AccordionPanel>
                    </AccordionItem>
                  );
                }
              )}
            </Accordion>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  });

export { PathQuestionnairesFilter };
export type { QuestionnairesFilters };
