import { useFormik } from "formik";
import { DefaultModal } from "../DefaultModal";
import * as Yup from "yup";
import { FormControl, FormErrorMessage, Input } from "@chakra-ui/react";
import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { ADD_PATH_CATEGORY } from "src/graphql/mutations/add-path-category.mutation";
import { GET_PATH_CATEGORIES } from "src/graphql/queries/get-path-categories.query";

import { useTranslation } from "react-i18next";

type CreateCategoryModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
};

const CategorySelectorField = {
  category: "category",
};
const CreateCategoryModal: React.FC<CreateCategoryModalProps> = ({
  isOpen,
  onClose,
  onCancel,
}) => {
  const [addPathCategory] = useMutation(ADD_PATH_CATEGORY, {
    // refetchQueries: [{ query: GET_CLINICS }],
  });
  const { t, i18n } = useTranslation();
  const formik = useFormik({
    initialValues: {
      [CategorySelectorField.category]: "",
    },
    validationSchema: Yup.object({
      [CategorySelectorField.category]: Yup.string()
        .required(t("Aggiungi una categoria"))
        .max(40, t("Sono ammessi al massimo 40 caratteri")),
    }),
    validateOnChange: true,
    onSubmit: (values) => {
      onClose();
    },
  });

  const onConfirm = useCallback(() => {
    addPathCategory({
      variables: {
        name: formik.values.category,
      },
      refetchQueries: [GET_PATH_CATEGORIES],
    });
  }, [addPathCategory, formik.values.category]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <DefaultModal
        isOpen={isOpen}
        onClose={onClose}
        title={t("Crea Categoria")}
        confirmText={t("Crea Categoria")}
        onConfirm={onConfirm}
        onCancel={onCancel}
        buttonType="submit"
      >
        <FormControl isInvalid={!!formik.errors.category}>
          <Input
            boxShadow="0px 2px 24px rgba(9, 107, 114, 0.08)"
            height={"45px"}
            variant="outline"
            placeholder={t("Titolo categoria")}
            textAlign={"left"}
            id={CategorySelectorField.category}
            name={CategorySelectorField.category}
            onChange={formik.handleChange}
            value={formik.values.category}
          />
          <FormErrorMessage>{formik.errors.category}</FormErrorMessage>
        </FormControl>
      </DefaultModal>
    </form>
  );
};

export { CreateCategoryModal };
