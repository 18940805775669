import { SVGProps } from "react"

const AddIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={12}
    height={12}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 1.333v9.334M1.333 6h9.333"
      stroke="#096B72"
      strokeWidth={1.5}
    />
  </svg>
)

export default AddIcon;