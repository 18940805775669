import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { Box, Button, Flex, useToast } from "@chakra-ui/react";
import TitleSmall from "../../../../components/TitleSmall";

import { colors } from "../../../../colors";
import { PlusIcon } from "evergreen-ui";
import { useTranslation } from "react-i18next";
import { GET_PATIENT_QUESTIONNAIRES } from "src/graphql/queries/getPatientQuestionnaires";
import { useParams } from "react-router-dom";
import { useCallback, useState } from "react";
import { ASSOCIATE_QUESTIONNAIRES_TO_PATIENT } from "src/graphql/mutations/associate-questionnaires-to-patient.mutation";
import { AssociateQuestionnairesModal } from "src/components/Modals/AssociateQuestionnairesModal";
import { REMOVE_PATIENT_QUESTIONNAIRE } from "src/graphql/mutations/remove-patient-questionnaire";
import { GET_USER } from "src/graphql/queries/user.query";
import { GET_QUESTIONNAIRES_BY_DOCTOR } from "src/graphql/queries/questionnaires-byDoctor.query";
import { GET_QUESTIONNAIRES_BY_AGENCY } from "src/graphql/queries/questionnaires-byAgency.query";
import SurveyComponent from "../../Questionnaires/SurveyComponent";
import { GET_PATIENT_QUESTIONNAIRE_RESPONSE } from "src/graphql/queries/getPatientQuestionnaireResponse";

const PatientQuestionnaires = () => {
  const toast = useToast();
  const [surveyId, setSurveyId] = useState("");
  const [title, setTitle] = useState("");
  const [associateModalOpened, setAssociateModalOpened] = useState(false);
  const { t } = useTranslation();
  const params = useParams()
  const {
    data: { me: user },
  } = useQuery(GET_USER);

  const { data } = useQuery(GET_PATIENT_QUESTIONNAIRES, {
    variables: {
      patientId: params.patientId,
    },
  });

  const [getPatientQuestionnaireResponse] = useLazyQuery(GET_PATIENT_QUESTIONNAIRE_RESPONSE);
  const loadPatientQuestionnaireResponse = async (row: any) => {
    const { data } = await getPatientQuestionnaireResponse({ variables: { patientQuestionnaireId: row.id } })
    console.log('Survey Responses', data)
    setSurveyId(row.questionnaire.id)
  }

  const [createAssociation] = useMutation(ASSOCIATE_QUESTIONNAIRES_TO_PATIENT, {
    refetchQueries: [
      { 
        query: GET_PATIENT_QUESTIONNAIRES, variables: {
          patientId: params.patientId,
        } 
      }
    ],
  });
  const associateQuestionnairesToPatient = async (questionnaires: any) => {
    try {
      await createAssociation({
        variables: {
          patientId: params.patientId,
          questionnairesIds: questionnaires.map((row: any) => row.id)
        },
      });
      setAssociateModalOpened(false);
    } catch (error: any) {
      toast({
        title:t("Errore durante l'associazione dei questionari."),
        description: error.message,
        status: "error",
        isClosable: true,
      });
    }
  };

  const [removeAssociation] = useMutation(REMOVE_PATIENT_QUESTIONNAIRE, {
    refetchQueries: [
      { 
        query: GET_PATIENT_QUESTIONNAIRES, variables: {
          patientId: params.patientId,
        } 
      }
    ],
  });
  const removeQuestionnaireFromPatient = async (id: string) => {
    try {
      await removeAssociation({
        variables: {
          patientQuestionnaireId: id,
        },
      });
      // setAssociateModalOpened(false);
    } catch (error: any) {
      toast({
        title:t("Errore durante la dissociazione del questionario."),
        description: error.message,
        status: "error",
        isClosable: true,
      });
    }
  };

  // console.log('data, loading', data)
  const hasValidData = () => {
    return data?.getPatientQuestionnaires && data?.getPatientQuestionnaires.length
  }

  const currentData = data?.getPatientQuestionnaires?.map((row: any) => {
    if (row?.questionnaire) {
      return { id: row.questionnaire.id }
    }
    return null
  }).filter(Boolean)

  const query = user.role === "doctor" ? GET_QUESTIONNAIRES_BY_DOCTOR : GET_QUESTIONNAIRES_BY_AGENCY
  const queryKey = user.role === "doctor" ? 'getDoctorQuestionnaires' : 'getQuestionnairesByAgency'
  const filterRowsFn = useCallback((row: any) => row.relatedTo.toLowerCase() === 'paziente', [])
  return (
    <>
      <SurveyComponent
        isOpen={Boolean(surveyId)}
        surveyId={surveyId}
        setTitle={setTitle}
        onClose={() => { setSurveyId('') }}
        closeBtnOnly={true}
        randomizeResponses={true}
      />
      <AssociateQuestionnairesModal
        isOpen={associateModalOpened}
        onClose={() => setAssociateModalOpened(false)}
        currentData={currentData ?? []}
        onSave={associateQuestionnairesToPatient}
        query={query}
        queryKey={queryKey}
        filterRowsFn={filterRowsFn}
        showQuestionnairesFilter={false}
      />
      <Flex justifyContent="space-between" alignItems="center">
        <TitleSmall
          text={t("Questionari")}
          color={colors[100]}
          style={{ marginRight: 24 }}
        />
        <Button
          colorScheme="teal"
          variant="solid"
          onClick={() => setAssociateModalOpened(true)}
          leftIcon={<PlusIcon />}
          height="48px"
          backgroundColor="brand.500"
        >
          {t("Associa Questionario")}
        </Button>
      </Flex>
      <Box p="10px" bg="#FCFCFC" borderRadius={12} mt="4px">
        { !hasValidData() ? 'Non ci sono questionari associati al momento.' : 
          data?.getPatientQuestionnaires?.map((row: any) => {
          return (
            <Flex
              key={row.id}
              justifyContent="space-between"
              alignItems="center"
              pt="24px"
              pb="24px"
            >
              <Box onClick={() => loadPatientQuestionnaireResponse(row)} cursor={'pointer'}>
                <Box fontWeight="semibold" pb="10px">{row.questionnaire.name}</Box>
                <Box bg="brand.200" borderRadius="8px" display='inline-block' fontWeight="bold" p="3px">
                  {t(row.status)}
                </Box>
              </Box>
              <Box>
                <Button variant="outline" height="48px" marginRight={5} onClick={() => loadPatientQuestionnaireResponse(row)}>
                  {t('Visualizza questionario')}
                </Button>
                <Button variant="outline" height="48px" onClick={async () => {
                  if (window.confirm(t('Sei proprio sicuro di voler eliminare')+'?')) {
                    await removeQuestionnaireFromPatient(row.id)
                  }
                }}>
                  {t('Rimuovi')}
                </Button>
              </Box>
            </Flex>
          )
        })}
      </Box>
    </>
  )
}

const Questionnaires = () => {  
  // const { t, i18n } = useTranslation();
  return (
    <Box p="24px" borderRadius="8px" bg="#FCFCFC">
      <PatientQuestionnaires />
    </Box>
  );
};

export default Questionnaires;
