import {
  Box,
  Button,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  ModalFooter,
} from "@chakra-ui/react";
import { useMutation } from "@apollo/client";
import { REMOVE_ASSOCIATED_QUESTIONNAIRE } from "src/graphql/mutations/removeAssociatedQuestionnaire.mutation";
import { GET_PATH_QUESTIONNAIRES } from "src/graphql/queries/get-path-questionnaires.query";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

type removeQuestionnaireModalProps = {
  isOpen: boolean;
  onClose: () => void;
  questionnaireId: number;
  pathId: string | undefined;
  removeAssociatedQuestionnaire: (questionnaireId: number) => Promise<void>;
};

const RemovePathQuestionnaireModal: React.FC<removeQuestionnaireModalProps> = ({
  isOpen,
  onClose,
  questionnaireId,
  pathId,
  removeAssociatedQuestionnaire,
}) => {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  return (
    <Modal
      size="sm"
      isOpen={isOpen}
      isCentered={true}
      onClose={onClose}
      scrollBehavior="inside"
      closeOnOverlayClick={false}
    >
      <ModalOverlay backgroundColor={"#0000007D"} />
      <ModalContent backgroundColor="white">
        <ModalHeader pt="24px" fontWeight="700">
          <Text>{t("Elimina Questionario")}</Text>
        </ModalHeader>
        <ModalBody pb={6} mt="16px" paddingInlineEnd="24px" alignItems="center">
          <Text color={"GrayText"} mb="1rem">
            {t(
              "Sei sicuro di voler eliminare il questionario? L'eliminazione di questo questionario comporterà la sua rimozione dalla lista."
            )}
          </Text>
          <ModalFooter display="flex" justifyContent="space-between">
            <Button
              backgroundColor="white"
              border="2px"
              height="44px"
              borderRadius="10px"
              borderColor="gray.200"
              onClick={() => {
                onClose();
                navigate(`/paths/${pathId}`);
              }}
            >
              {t("No")}, {t("annulla")}
            </Button>
            <Button
              colorScheme="teal"
              height="44px"
              variant="solid"
              backgroundColor="brand.500"
              onClick={() => {
                removeAssociatedQuestionnaire(questionnaireId);
              }}
            >
              {t("Sì")}, {t("conferma")}
            </Button>
          </ModalFooter>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default RemovePathQuestionnaireModal;
