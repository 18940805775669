import { gql } from '@apollo/client';

export const GET_PATIENTS = gql`
  query Patients {
    patients {
      id
      email
      name
      surname
      phone
      status
      tags
      fiscalCode
    }
  }
`;

export const GET_PATIENT = gql`
query Patient($patientId: ID!) {
  patient(id: $patientId) {
    id
    name
    surname
    email
    birthDate
    birthCity
    fiscalCode
    phone
    pec
    sex
    addressResidence
    addressDomicile
    gpName
    gpSurname
    gpPhone
    gpEmail
    gpPec
    anprId
    note
    tags
    status
    createdAt
    pathologies {
      id
      name
    }
    relatives {
      id
      name
    }
    caregiver {
      id
      name
      surname
      relationship
      email
      phone
    }
  }
}
`;
