import DatePicker from "react-datepicker";
import CalendarInput from "../CalendarInput";
import GreenCalendarInput from "../GreenCalendarInput";
import it from "date-fns/locale/it";
import ar from "date-fns/locale/ar";
import en from "date-fns/locale/en-GB";
import fr from "date-fns/locale/fr";
import es from "date-fns/locale/es";
import pt from "date-fns/locale/pt";
import de from "date-fns/locale/de";
import "react-datepicker/dist/react-datepicker.css";
import "./index.scss";
import i18next from "i18next";
import { useEffect, useState } from "react";

// const monthsIT = [
//   'Gennaio',
//   'Febbraio',
//   'Marzo',
//   'Aprile',
//   'Maggio',
//   'Giugno',
//   'Luglio',
//   'Agosto',
//   'Settembre',
//   'Ottobre',
//   'Novembre',
//   'Dicembre',
// ];
// const daysIT = ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'];

// registerLocale('it', {
//   localize: {
//     ...it.localize,
//     month: (n: any) => monthsIT[n],
//     day: (n: any) => daysIT[n],
//   },
//   formatLong: {
//     ...it.formatLong,
//     day: []
//   },
// } as any);

interface IProps {
  value: Date | null;
  color?: "white" | "gray";
  icon?: "green" | "black";
  iconBackgroundColor?: string;
  placeholder?: string;
  dateFormat?: string;
  greenLabel?: string;
  filterDate?: (date: Date) => boolean;
  onClick?: () => void;
  onChange: (val: Date | null) => void;
  minDate?: Date;
}

const InputDatePicker = ({
  value,
  onChange,
  onClick,
  iconBackgroundColor,
  placeholder,
  color = "gray",
  icon = "black",
  dateFormat = "d MMMM yyyy",
  greenLabel,
  filterDate = () => true,
  ...props
}: IProps) => {
  const [locale, setLocale] = useState<any>();
  const languageToLocaleMap: any = {
    it: it,
    ar: ar,
    fr: fr,
    de: de,
    es: es,
    pt: pt,
  };

  const defaultLocale = en;

  useEffect(() => {
    const selectedLocale =
      languageToLocaleMap[i18next.language] || defaultLocale;
    setLocale(selectedLocale);
  }, [i18next.language]);
  return (
    <DatePicker
      {...props}
      locale={locale}
      selected={value}
      onChange={onChange}
      dateFormat={dateFormat}
      className={`datepicker-${color}`}
      filterDate={filterDate}
      customInput={
        icon === "black" ? (
          <CalendarInput
            placeholder={placeholder}
            onChange={onChange}
            onClick={onClick}
            value={value}
            iconBackgroundColor={iconBackgroundColor}
          />
        ) : (
          <GreenCalendarInput
            greenLabel={greenLabel}
            placeholder={placeholder}
            onChange={onChange}
            onClick={onClick}
            value={value}
            iconBackgroundColor={iconBackgroundColor}
          />
        )
      }
    />
  );
};

export default InputDatePicker;
