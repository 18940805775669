import { IconButton } from "@chakra-ui/react";
import DeleteIcon from "../Icons/DeleteIcon";

interface IProps {
  onClick: () => void;
}

export const LargeDeleteButton = ({ onClick }: IProps) => {
  return (
    <IconButton
      border="1px"
      borderColor="#6F767E"
      background="transparent"
      width="64px !important"
      height="64px !important"
      borderRadius="50%"
      marginLeft={4}
      aria-label="Delete Doctor"
      icon={<DeleteIcon />}
      isRound={true}
      onClick={onClick}
    />
  );
};
