import { SVGProps } from "react";

export const ChevronVerticalIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.66699 10L8.00033 13.3333L11.3337 10M4.66699 6.00001L8.00033 2.66667L11.3337 6.00001"
      stroke="#475467"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
