import it from './languages/it.json'
import en from './languages/en.json'
import pt from './languages/pt.json'
import de from './languages/de.json'
import fr from './languages/fr.json'
import ar from './languages/ar.json'
import es from './languages/es.json'
export const resources = {
  en: {
    translation: en
  },
  it: {
    translation: it
  },
  es: {
    translation: es
  },
  ar: {
    translation: ar
  },
  de: {
    translation: de
  },
  fr: {
    translation: fr
  },
  pt: {
    translation: pt
  }
};