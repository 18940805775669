import { useQuery } from "@apollo/client";
import {
  Avatar,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { GET_USER } from "../../graphql/queries/user.query";
import RightSelectorIcon from "../Icons/RightSelectorIcon";
import { useTranslation } from "react-i18next";

const SwitchSelector = () => {
  const { data } = useQuery(GET_USER);
  useEffect(() => setState(data.me), [data]);

  const { t, i18n } = useTranslation();
  const [state, setState] = useState<any | null>(null);
  const displayName = `${state?.name} ${state?.surname}`;
  const users = [
    {
      id: 1,
      name: state?.name,
      surname: state?.surname,
      role: t("Dirigente Medico"),
    },
    { id: 2, name: state?.name, surname: state?.surname, role: t("Dottore") },
  ];
  return (
    <Menu>
      <MenuButton
        as={Button}
        background="transparent"
        // rightIcon={<RightSelectorIcon />}
        h="60px"
        w="238px"
        disabled={true}
      >
        <Flex alignItems="center">
          <Avatar
            src={state?.profilePhoto}
            name={displayName}
            boxSize="3rem"
            borderRadius="full"
            mr="12px"
          />
          <Box flexDirection="column" textAlign="left">
            <Box
              overflow="hidden"
              minWidth={0}
              text-overflow="ellipsis"
              white-space="nowrap"
            >
              {displayName}
            </Box>
            <Box
              overflow="hidden"
              minWidth={0}
              text-overflow="ellipsis"
              white-space="nowrap"
              color={"GrayText"}
            >
              {state?.role}
            </Box>
          </Box>
        </Flex>
      </MenuButton>
      {/* <MenuList>
        {users
          .filter((x) => x.id !== state?.id)
          .map((x, i) => (
            <MenuItem
              key={i}
              w="238px"
              overflow="hidden"
              minWidth={0}
              text-overflow="ellipsis"
              white-space="nowrap"
              minH="48px"
              onClick={() => setState(x)}
            >
              
              <span>{x.role}</span>
            </MenuItem>
          ))}
      </MenuList> */}
    </Menu>
  );
};

export default SwitchSelector;
