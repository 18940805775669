import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Badge,
  Box,
  Divider,
  Flex,
  Spinner,
  Tabs,
  TabList,
  Tab,
  Button,
  Select,
  useToast,
} from "@chakra-ui/react";
import { useApolloClient } from "@apollo/client/react";
import { useAuthContext } from "../../providers/AuthProvider/context";
import SwitchSelector from "../SwitchSelector";
import Logotipe from "../Icons/Logotipe";
import LogOutIcon from "../Icons/LogOutIcon";
import NotificationsIcon from "../Icons/NotificationIcon";
import CompassIcon from "../Icons/CompassIcon";
import PatientIcon from "../Icons/PatientIcon";
import CalendarIcon from "../Icons/CalendarIcon";
import DoctorsIcon from "../Icons/DoctorsIcon";
import ClinicIcon from "../Icons/ClinicIcon";
import ServicesIcon from "../Icons/ServicesIcon";
import AgencyIcon from "../Icons/AgencyIcon";
import QuestionnairesIcon from "../Icons/QuestionnairesIcon";
import "./index.css";
import { SettingsIcon } from "../Icons/SettingsIcon";

import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { GET_USER } from "src/graphql/queries/user.query";
import { UPDATE_USER } from "src/graphql/mutations/update-user.mutation";
type Role =
  | "patient"
  | "doctor"
  | "clinic_admin"
  | "agency_admin"
  | "super_admin";

type SidebarProps = {
  role: Role;
  isLoading: boolean;
};
interface TabInfo {
  key: number;
  title: string;
  route: string;
  Icon: any;
}

interface Tabs {
  patient: TabInfo[];
  doctor: TabInfo[];
  clinic_admin: TabInfo[];
  agency_admin: TabInfo[];
  super_admin: TabInfo[];
}

const Sidebar = ({ role, isLoading }: SidebarProps) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const client = useApolloClient();
  const toast = useToast();
  const { signOut } = useAuthContext();
  const {
    data: { me: user },
    loading: userLoading,
  } = useQuery(GET_USER);
  const [updateUser, { loading }] = useMutation(UPDATE_USER, {
    refetchQueries: [{ query: GET_USER }],
  });
  console.log("user", user);
  const [selectedIndex, setSelectedIndex] = useState<null | number>(0);
  const tabs: Tabs = {
    patient: [
      {
        key: 0,
        title: t("Calendario"),
        route: "/calendar",
        Icon: CalendarIcon,
      },
    ],
    doctor: [
      {
        key: 0,
        title: t("Calendario"),
        route: "/calendar",
        Icon: CalendarIcon,
      },
      {
        key: 1,
        title: t("Pazienti"),
        route: "/patients",
        Icon: PatientIcon,
      },
      // {
      //   key: 2,
      //   title: 'Cliniche',
      //   route: '/clinics',
      //   Icon: ClinicIcon,
      // },
      // {
      //   key: 2,
      //   title: 'Percorsi',
      //   route: '',
      //   Icon: CompassIcon,
      // },
    ],
    clinic_admin: [
      {
        key: 0,
        title: t("Servizi"),
        route: "/services",
        Icon: ServicesIcon,
      },
      {
        key: 1,
        title: t("Dottori"),
        route: "/doctors",
        Icon: DoctorsIcon,
      },
      {
        key: 2,
        title: t("Pazienti"),
        route: "/patients",
        Icon: PatientIcon,
      },
      // {
      //   key: 3,
      //   title: 'Questionari',
      //   route: '/Questionnaires',
      //   Icon: QuestionnairesIcon,
      // },
    ],
    agency_admin: [
      {
        key: 0,
        title: t("Cliniche"),
        route: "/clinics",
        Icon: ClinicIcon,
      },
      {
        key: 1,
        title: t("Dottori"),
        route: "/doctors",
        Icon: DoctorsIcon,
      },
      {
        key: 2,
        title: t("Pazienti"),
        route: "/patients",
        Icon: PatientIcon,
      },
      {
        key: 3,
        title: t("Questionari"),
        route: "/questionnaires",
        Icon: QuestionnairesIcon,
      },
      {
        key: 4,
        title: t("Percorsi"),
        route: "/paths",
        Icon: CompassIcon,
      },
    ],
    super_admin: [
      {
        key: 0,
        title: t("Calendario"),
        route: "/calendar",
        Icon: CalendarIcon,
      },
      // {
      //   key: 1,
      //   title: "Agencies",
      //   route: "/agencies",
      //   Icon: AgencyIcon,
      // },
      {
        key: 2,
        title: t("Cliniche"),
        route: "/clinics",
        Icon: ClinicIcon,
      },
      // {
      //   key: 3,
      //   title: "Dottori",
      //   route: "/doctors",
      //   Icon: DoctorsIcon,
      // },
      {
        key: 4,
        title: t("Pazienti"),
        route: "/patients",
        Icon: PatientIcon,
      },
      // {
      //   key: 5,
      //   title: t("Questionari"),
      //   route: "/questionnaires",
      //   Icon: QuestionnairesIcon,
      // },
    ],
  };

  useEffect(() => {
    const route = location.pathname;
    const tab = tabs[role].find((x) => route.includes(x.route));

    if (tab) {
      setSelectedIndex(tab.key);
    }
  }, [location.pathname, role, setSelectedIndex]);

  useEffect(() => {
    i18n.changeLanguage(user.language);
    console.log("lang", user.language);
  }, [user]);

  const handleLogout = async () => {
    await client.clearStore();
    await signOut();
    navigate("/signin");
  };

  // const handleClick = () => {
  //   navigate(`/notifications`);
  // };
  const handleClickSettings = () => {
    navigate(`/settings`);
  };
  const updateLanguage = (lang: string) => {
    handleLanguageUpdate(lang);
  };

  const handleLanguageUpdate = async (lang: any) => {
    try {
      await updateUser({
        variables: {
          input: {
            language: lang,
          },
        },
      });
      i18n.changeLanguage(lang);
    } catch (error: any) {
      toast({
        title: t("Errore"),
        description: error.message,
        status: "error",
        isClosable: true,
      });
    }
  };

  return (
    <aside className="sidebar">
      <Box ml="8px">
        <Logotipe />
      </Box>
      <Box mt="20px" mb="70px">
        <SwitchSelector />
      </Box>
      <p className="menu-title">{t("MENU")}</p>

      <Tabs
        h="100%"
        orientation="vertical"
        variant="soft-rounded"
        colorScheme="gray"
        onChange={(index) => {
          setSelectedIndex(index);
          navigate(tabs[role][index].route);
        }}
      >
        <TabList flexBasis={240}>
          <Flex justifyContent="space-between" flexDirection="column" h="100%">
            <Flex flexDirection="column">
              {role
                ? tabs[role].map(({ key, title, Icon }) => (
                    <Tab
                      key={key}
                      id={title}
                      height="40px"
                      borderRadius="5px"
                      // isSelected={key === selectedIndex}
                      aria-controls={`panel-${title}`}
                    >
                      <Flex alignItems="center" width="100%">
                        <Flex justifyContent="center" mr="10px" w="25px">
                          <Icon
                            color={
                              key === selectedIndex ? "#096b72" : "#2B292D"
                            }
                          />
                        </Flex>
                        <Box
                          className="tab-text"
                          color={key === selectedIndex ? "#096b72" : "#2B292D"}
                          fontWeight={500}
                        >
                          {title}
                        </Box>
                      </Flex>
                    </Tab>
                  ))
                : null}
            </Flex>
            {/* <Flex flexDirection={"column"}>
              <Box mb={"10px"}>{t("Lingua")}</Box>
              <div>
                <Button onClick={() => updateLanguage("it")}>it</Button>
                <Button onClick={() => updateLanguage("en")}>en</Button>
                <Button onClick={() => updateLanguage("pt")}>pt</Button>
                <Button onClick={() => updateLanguage("de")}>de</Button>
                <Button onClick={() => updateLanguage("fr")}>fr</Button>
                <Button onClick={() => updateLanguage("ar")}>ar</Button>
                <Button onClick={() => updateLanguage("es")}>es</Button>
              </div>
            </Flex> */}
            <Flex flexDirection="column">
              {/* <Tab
                mb="8px"
                height="40px"
                borderRadius="5px"
                onClick={handleClick}
              >
                <Flex
                  width="100%"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Flex justifyContent="flex-start" alignItems="center">
                    <Flex justifyContent="center" mr="10px" w="25px">
                      <NotificationsIcon />
                    </Flex>
                    <Box color="#2B292D" fontWeight={500}>
                      {t('Notifiche')}
                    </Box>
                  </Flex>
                  <Badge variant="subtle" colorScheme="grey">
                    6
                  </Badge>
                </Flex>
              </Tab> */}

              <Divider color="#D0D5DD" />

              <Tab
                mt="8px"
                mb="8px"
                height="40px"
                borderRadius="5px"
                onClick={handleClickSettings}
              >
                <Flex
                  width="100%"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Flex justifyContent="flex-start" alignItems="center">
                    <Flex justifyContent="center" mr="10px" w="25px">
                      <SettingsIcon />
                    </Flex>
                    <Box
                      color="#2B292D"
                      fontWeight={500}
                      style={{ textAlign: "left" }}
                    >
                      {t("Impostazioni account")}
                    </Box>
                  </Flex>
                </Flex>
              </Tab>


              <Divider color="#D0D5DD" />
              <Tab
                mt="8px"
                height="40px"
                borderRadius="5px"
                isDisabled={isLoading}
                onClick={handleLogout}
              >
                {isLoading ? (
                  <Spinner />
                ) : (
                  <Flex
                    w="100%"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Flex justifyContent="center" mr="10px" w="25px">
                      <LogOutIcon />
                    </Flex>
                    <Box color="#2B292D" fontWeight={500}>
                      {t("Log Out")}
                    </Box>
                  </Flex>
                )}
              </Tab>
            </Flex>
          </Flex>
        </TabList>
      </Tabs>
    </aside>
  );
};
export default Sidebar;
