import { gql } from "@apollo/client";

export const CREATE_CLINIC = gql`
  mutation createClinic($input: ClinicInput!) {
    createClinic(input: $input) {
      name
      doctorsQuantity
      address
      pec
      clinicManager {
        email
        name
        surname
      }
    }
  }
`;
