import { ChangeEvent, useMemo, useRef, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  FilterFn,
} from "@tanstack/react-table";
// import { useNavigate } from 'react-router-dom';
import { PlusIcon, SearchIcon } from "evergreen-ui";
import TitleSmall from "../../../components/TitleSmall";
import SuccessModal from "../../../components/Modals/SuccessModal";
import { IClinicServices } from "../../../interfaces/IClinicServices";

import ServicesTable from "./ServicesTable";

import { colors } from "../../../colors";
import { useMutation, useQuery } from "@apollo/client";
import { GET_SERVICES } from "../../../graphql/queries/services.query";
import { UPDATE_SERVICE } from "../../../graphql/mutations/update-services.mutation";
import { CREATE_SERVICE } from "../../../graphql/mutations/create-service-mutation";
import AddServicesModal from "../../../components/Modals/AddServicesModal";
import ServiceEditIcon from "../../../components/Icons/ServiceEditIcon";
import EditServicesModal from "../../../components/Modals/EditServicesModal";
import { useTranslation } from "react-i18next";

const globalFilterFn: FilterFn<IClinicServices> = (
  row,
  columnId,
  filterValue
) => {
  const data: string = row.getValue(columnId);
  return data.toLowerCase().includes(filterValue.toLowerCase());
};

const Services = () => {
  const serviceModalRef = useRef<{ resetForm: () => void }>();
  const toast = useToast();

  //   const navigate = useNavigate();

  const [status, setStatus] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [isAddService, setIsAddService] = useState(false);
  const [editService, setEditService] = useState<Record<string, any>>({
    isOpen: false,
    service: null,
  });

  const { t, i18n } = useTranslation();
  const servicesQuery = useQuery(GET_SERVICES);

  const [createService, { loading }] = useMutation(CREATE_SERVICE, {
    refetchQueries: [{ query: GET_SERVICES }],
  });
  const [updateService, { loading: updateLoading }] = useMutation(
    UPDATE_SERVICE,
    {
      refetchQueries: [{ query: GET_SERVICES }],
    }
  );

  // const handleStatusChanged = (value: DoctorStatus) => {
  //   setStatus(value);
  // };

  const handleSaveService = async (data: any) => {
    try {
      await createService({
        variables: {
          input: {
            name: data.name,
            cost: parseInt(data.cost),
            duration: data.duration,
          },
        },
      });
      setIsAddService(false);
      serviceModalRef.current?.resetForm();
    } catch (error: any) {
      toast({
        title: t("Errore durante la creazione del servizi."),
        description: error.message,
        status: "error",
        isClosable: true,
      });
    }
  };

  const handleEditService = async (data: any) => {
    try {
      await updateService({
        variables: {
          id: data.id,
          input: {
            name: data.name,
            cost: parseInt(data.cost),
            duration: data.duration,
          },
        },
      });
      setEditService({ isOpen: false, service: null });
    } catch (error: any) {
      toast({
        title:t( "Errore durante la creazione del servizi."),
        description: error.message,
        status: "error",
        isClosable: true,
      });
    }
  };

  const columnsDef: ColumnDef<IClinicServices>[] = [
    {
      id: "name",
      header: "Visita",
      accessorFn: (row) => row.name,
      cell: ({ row, getValue }) => {
        const id = row.original.id;
        const name = getValue() as string;

        return (
          <Flex gap="10px" alignItems="center">
            <Avatar name={name} />
            {name}
          </Flex>
        );
      },
    },
    {
      header: "Durata",
      accessorFn: (row) => ({ duration: row.duration }),
      id: "duration",
      cell: ({ getValue }) => {
        const values = getValue() as {
          duration: number | undefined;
        };

        return (
          <Flex gap="10px" alignItems="center">
            {values.duration}min installazione + {values.duration}min rimozione
          </Flex>
        );
      },
    },
    {
      header: "Costa",
      accessorFn: (row) => ({ cost: row.cost }),
      id: "cost",
      cell: ({ getValue }) => {
        const values = getValue() as {
          cost: string | undefined;
        };

        return (
          <Flex gap="10px" alignItems="center">
            {values.cost}€
          </Flex>
        );
      },
    },
    {
      header: "",
      enableGlobalFilter: false,
      accessorKey: "id",
      cell: ({ row }) => (
        <Button
          border="1px"
          variant="outline"
          backgroundColor="transparent"
          onClick={() =>
            setEditService({ isOpen: true, service: row.original })
          }
          leftIcon={<ServiceEditIcon />}
          width="114px"
          height="48px"
        >
          {t("Modifica")}
        </Button>
      ),
    },
  ];

  const data = useMemo(
    () => servicesQuery.data?.services || [],
    [servicesQuery.data]
  );
  const columns = useMemo(() => columnsDef, []);

  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
    },
    globalFilterFn: globalFilterFn,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  if (servicesQuery.loading) {
    return (
      <Flex alignItems="center" justifyContent="center" height="100%">
        <Spinner size="xl" color="brand.500" thickness="4px" speed="0.65s" />
      </Flex>
    );
  }
  return (
    <Box h="100%" mr="91px" ml="91px">
      <AddServicesModal
        ref={serviceModalRef}
        isOpen={isAddService}
        onClose={() => setIsAddService(false)}
        loading={loading}
        onSave={handleSaveService}
      />
      <EditServicesModal
        isOpen={editService.isOpen}
        onClose={() => setEditService({ isOpen: false, service: null })}
        loading={updateLoading}
        onSave={handleEditService}
        data={editService.service}
      />
      <SuccessModal isOpen={isSuccess} setIsOpen={() => setIsSuccess(false)} />

      <Text fontSize="40px" fontWeight="semibold" pt="40px" pb="24px">
        {t("Servizi")}
      </Text>

      <Box backgroundColor="brand.300">
        <Flex
          height="76px"
          paddingInlineStart="24px"
          paddingInlineEnd="24px"
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex alignItems="center">
            <TitleSmall
              text={t("Servizi")}
              color={colors[100]}
              style={{ marginRight: 24 }}
            />
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<SearchIcon color="gray" />}
              />
              <Input
                width="360px"
                placeholder={t("Cerca servizio")}
                // value={globalFilter ?? ''}
                background="brand.400 !important"
                // onChange={(e: ChangeEvent<HTMLInputElement>) =>
                //   setGlobalFilter(e.target.value)
                // }
              />
            </InputGroup>
          </Flex>

          {/* <Box>
                <Button
                  colorScheme="gray"
                  variant="ghost"
                  isActive={status === DoctorStatus.REGISTERED}
                  onClick={() => handleStatusChanged(DoctorStatus.REGISTERED)}
                  marginRight={2}
                >
                  Registrato
                </Button>
                <Button
                  colorScheme="gray"
                  variant="ghost"
                  isActive={status === DoctorStatus.INVITED}
                  onClick={() => handleStatusChanged(DoctorStatus.INVITED)}
                >
                  Invitato
                </Button>
              </Box> */}

          <Button
            colorScheme="teal"
            variant="solid"
            onClick={() => setIsAddService(true)}
            leftIcon={<PlusIcon />}
            width="188px"
            height="48px"
            backgroundColor="brand.500"
          >
            {t("Aggiungi Servizio")}
          </Button>
        </Flex>
      </Box>

      <ServicesTable table={table} />
    </Box>
  );
};

export default Services;
