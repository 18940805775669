import { useState } from 'react';
import {
  ControlBar,
  ControlBarButton,
  useLocalVideo,
  useToggleLocalMute,
  useContentShareState,
  useContentShareControls,
} from 'amazon-chime-sdk-component-library-react';
import EndMeetingControl from '../EndMeetingControl';
import { MutedMicrophoneIcon } from '../../components/Icons/MutedMicrophoneIcon';
import { UnmutedMicrophoneIcon } from '../../components/Icons/UnmutedMicrophoneIcon';
import { ActiveCameraIcon } from '../../components/Icons/ActiveCameraIcon';
import { DisabledCameraIcon } from '../../components/Icons/DisabledCameraIcon';
import { ShareScreenIcon } from '../../components/Icons/ShareScreenIcon';
import { SharedScreenIcon } from '../../components/Icons/SharedScreenIcon';
import './index.scss';

const MeetingControls = () => {
  const { muted, toggleMute } = useToggleLocalMute();
  const { isVideoEnabled, toggleVideo } = useLocalVideo();
  const { toggleContentShare } = useContentShareControls();
  const { isLocalUserSharing } = useContentShareState();

  const microphoneButtonProps = {
    icon: !muted ? <UnmutedMicrophoneIcon /> : <MutedMicrophoneIcon />,
    onClick: () => toggleMute(),
    label: 'Mute',
    className: 'base-control-button',
  };

  const cameraButtonProps = {
    icon: isVideoEnabled ? <ActiveCameraIcon /> : <DisabledCameraIcon />,
    onClick: () => toggleVideo(),
    label: 'Camera',
    className: 'base-control-button',
  };

  const shareButtonProps = {
    icon: isLocalUserSharing ? <SharedScreenIcon /> : <ShareScreenIcon />,
    onClick: () => toggleContentShare(),
    label: 'Screen',
    className: 'base-control-button share-button-padding',
  };

  return (
    <div className="controls">
      <ControlBar
        className="controls-menu"
        layout="undocked-horizontal"
        showLabels={false}
      >
        <ControlBarButton {...microphoneButtonProps} />
        <ControlBarButton {...cameraButtonProps} />
        <ControlBarButton {...shareButtonProps} />
        <EndMeetingControl />
      </ControlBar>
    </div>
  );
};

export default MeetingControls;
