import { IconButton, Tooltip, useClipboard } from '@chakra-ui/react';
import MailIcon from '../Icons/MailIcon';
import { useTranslation } from 'react-i18next';


export const LargeEmailButton = ({ email }: { email: string; }) => {
  const clipboard = useClipboard(email);
  const { t, i18n } = useTranslation();
  return (
    <Tooltip
      backgroundColor="brand.500" // TODO: move to theme
      label={t("Copiato negli appunti")}
      fontSize="md"
      isOpen={clipboard.hasCopied}
    >
      <IconButton
        border="1px"
        borderColor="#6F767E"
        background="transparent"
        width="64px !important"
        height="64px !important"
        borderRadius="50%"
        marginLeft={4}
        aria-label="Copy email"
        icon={<MailIcon />}
        isRound={true}
        onClick={clipboard.onCopy}
      />
    </Tooltip>
  );
};
