import React, { useEffect } from "react";
import {
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";
import { Avatar, Button, Flex, Text, Checkbox } from "@chakra-ui/react";
import { TrashIcon, ExpandAllIcon } from "evergreen-ui";
import { QuestionnairesPathTable } from "../QuestionnairesPath/QuestionnairesPathTable";
import { useMemo, useState } from "react";
import { ChevronVerticalIcon } from "src/components/Icons/ChevronVerticalIcon";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { REMOVE_ASSOCIATED_QUESTIONNAIRE } from "src/graphql/mutations/removeAssociatedQuestionnaire.mutation";
import { GET_PATH_QUESTIONNAIRES } from "src/graphql/queries/get-path-questionnaires.query";

import RemovePathQuestionnaireModal from "src/components/Modals/RemovePathQuestionnaireModal";
import { ActivationDate, Repetition } from "src/interfaces/IQuestionnaires";
import { GET_PATHS } from "src/graphql/queries/paths.query";
import { useTranslation } from "react-i18next";

type QuestionnairesPathTableProps = {
  data: any[];
  searchText?: string;
  selectedQuestionnaires: any[];
  setSelectedQuestionnaires: (questionnaires: any[]) => void;
  pathId: string | undefined;
};

export const QuestionnairesPathList: React.FC<QuestionnairesPathTableProps> =
  React.memo(
    ({
      data,
      searchText,
      selectedQuestionnaires,
      setSelectedQuestionnaires,
      pathId,
    }) => {
      const navigate = useNavigate();
      const [isOpenModal, setIsOpenModal] = useState(false);
      const [questionnaireId, setQuestionnaireId] = useState(0);
      const [newData, setNewData] = useState(data);

      const { t, i18n } = useTranslation();
      const [
        removeQuestionnaire,
        { loading: removeAssociatedQuestionnaireLoading },
      ] = useMutation(REMOVE_ASSOCIATED_QUESTIONNAIRE, {
        refetchQueries: [
          { query: GET_PATH_QUESTIONNAIRES },
          { query: GET_PATHS },
        ],
      });

      const removeAssociatedQuestionnaire = async (questionnaireId: number) => {
        try {
          if (questionnaireId) {
            await removeQuestionnaire({
              variables: { removeAssociatedQuestionnaireId: questionnaireId },
            });
          }
        } catch (error: any) {
          console.log(error);
        }
        setIsOpenModal(false);
        navigate(`/paths/${pathId}`);
        return setSelectedQuestionnaires(
          data.filter((item) => item.id !== questionnaireId)
        );
      };

      const columns: ColumnDef<any>[] = useMemo(
        () => [
          {
            id: "name",
            header: () => (
              <Text fontSize="16px">
                <Flex gap="6px">
                  {t("Titolo questionario")} <ExpandAllIcon />
                </Flex>
              </Text>
            ),
            accessorFn: (row) => row?.questionnaire?.name,
            cell: ({ row, getValue }) => {
              const name = getValue() as string;
              const relatedTo = row?.original?.questionnaire?.relatedTo;
              const otherRelatedTo = row?.original?.relatedTo;
              const icon = row?.original?.questionnaire?.icon;
              const otherName = row.original?.name;

              return (
                <Flex alignItems="center" gap="10px">
                  <Avatar src={icon} />
                  <Flex alignItems="flexStart" gap="5px" flexDir="column">
                    <Button
                      variant="link"
                      colorScheme="teal"
                      fontSize="15px"
                      fontWeight="bold"
                      color="brand.700"
                      whiteSpace="break-spaces"
                      textAlign="start"
                    >
                      {name ? name : otherName}
                    </Button>
                    <Text fontWeight="500" color={"GrayText"}>
                      {relatedTo
                        ? `${t("Rivolto a")}: ${relatedTo}`
                        : otherRelatedTo
                        ? `${t("Rivolto a")}: ${otherRelatedTo}`
                        : ""}
                    </Text>
                  </Flex>
                </Flex>
              );
            },
          },
          {
            header: () => {
              return (
                <Flex alignItems="center">
                  <Button
                    variant="unstyled"
                    colorScheme="teal"
                    fontSize="15px"
                    fontWeight="bold"
                    height={"40px"}
                    textAlign="start"
                    flexDirection={"row"}
                    display={"flex"}
                  >
                    <Text paddingRight={"2"}>{t("Attivazione")}</Text>
                    <ChevronVerticalIcon />
                  </Button>
                </Flex>
              );
            },
            id: "activation",
            accessorFn: (row) => row?.activationDate,
            cell: ({ row, getValue }) => {
              const activationDate = getValue() as string;
              return (
                <Flex gap="10px" alignItems="center">
                  <Text
                    colorScheme="teal"
                    fontSize="15px"
                    fontWeight="500"
                    color={"GrayText"}
                    whiteSpace="break-spaces"
                    textAlign="start"
                  >
                    {activationDate ? activationDate : ActivationDate.NONE}
                  </Text>
                </Flex>
              );
            },
          },
          {
            header: () => (
              <Text fontSize="16px">
                <Flex gap="6px">
                  {t("Ricorrenza")} <ExpandAllIcon />
                </Flex>
              </Text>
            ),
            id: "recurrence",
            accessorFn: (row) => row?.repetition,
            cell: ({ row, getValue }) => {
              const repetition = getValue() as string;
              return (
                <Flex gap="10px" alignItems="center">
                  <Text
                    colorScheme="teal"
                    fontSize="15px"
                    fontWeight="500"
                    color={"GrayText"}
                    whiteSpace="break-spaces"
                    textAlign="start"
                  >
                    {repetition ? repetition : Repetition.NONE}
                  </Text>
                </Flex>
              );
            },
          },
          {
            header: "",
            id: "delete",
            accessorFn: (row) => row?.id,
            cell: ({ row, getValue }) => {
              const id = getValue() as number;
              return (
                <Flex justifyContent={"flex-end"}>
                  <Button
                    backgroundColor="brand.300"
                    color="gray.300"
                    justifyContent={"flex-end"}
                    onClick={() => {
                      setQuestionnaireId(id);
                      setIsOpenModal(true);
                    }}
                  >
                    <TrashIcon />
                  </Button>
                </Flex>
              );
            },
          },
        ],
        [
          data,
          selectedQuestionnaires,
          /* questionnaireId,
        setQuestionnaireId,
        isOpenModal,
        setIsOpenModal, */
          setSelectedQuestionnaires,
          t,
        ]
      );

      const table = useReactTable({
        data,
        columns,
        state: {
          globalFilter: searchText,
        },
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
      });

      return (
        <>
          <RemovePathQuestionnaireModal
            isOpen={isOpenModal}
            onClose={() => setIsOpenModal(false)}
            questionnaireId={questionnaireId}
            pathId={pathId}
            removeAssociatedQuestionnaire={removeAssociatedQuestionnaire}
          />
          <QuestionnairesPathTable table={table} />
        </>
      );
    }
  );
export default QuestionnairesPathList;
