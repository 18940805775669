import { Box, Flex } from "@chakra-ui/react";
import { colors } from "../../../../colors";
import format from "date-fns/format";
import VideoBlackIcon from "../../../../components/Icons/VideoBlackIcon";
import UsersBlackIcon from "../../../../components/Icons/UsersBlackIcon";

interface ICardProps {
  title: string;
  duration: number;
  date: string;
  meetingId?: string;
  isOnline?: boolean;
}
export const Card = (props: ICardProps) => {
  const color = props.isOnline ? colors[100] : colors[1200];

  return (
    <Flex gap="20px">
      <Box
        w="80px"
        h="80px"
        bg={color}
        borderRadius="8px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {props.isOnline ? <VideoBlackIcon /> : <UsersBlackIcon />}
      </Box>
      <Flex flexDir="column" justifyContent="space-between">
        <Flex alignItems="center" gap="8px" h="32px">
          <Box fontSize="15px" fontWeight="semibold">
            {props.title}
          </Box>
          {props.isOnline && (
            <Box
              h="32px"
              bg="#CABDFF"
              padding="4px 8px"
              borderRadius="6px"
              fontWeight="semibold"
              display="flex"
              alignItems="center"
            >
              Online
            </Box>
          )}
        </Flex>
        <Box fontSize="13px" color="brand.800">
          {props.duration} minuti
        </Box>
        <Box
          h="32px"
          fontSize="14px"
          color="brand.800"
          fontWeight="semibold"
          display="flex"
          alignItems="center"
        >
          {format(new Date(props.date), "MMM dd, yyyy")} at{" "}
          {format(new Date(props.date), "HH:mm")}
        </Box>
      </Flex>
    </Flex>
  );
};
