import React, { SetStateAction } from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Button,
  Box,
} from "@chakra-ui/react";
import "survey-core/defaultV2.min.css";
import { GET_SURVEY } from "src/graphql/queries/survey.query";
import { useQuery } from "@apollo/client";
import { useState } from "react";
import { ModifyQuestionnaire } from "./ModifyQuestionnaire";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

type SurveyComponentProps = {
  isOpen: boolean;
  onClose: any;
  surveyId: string;
  setTitle: React.Dispatch<SetStateAction<string>>;
  closeBtnOnly?: boolean
  randomizeResponses?: boolean
};

const SurveyComponent: React.FC<SurveyComponentProps> = ({
  isOpen,
  onClose,
  surveyId,
  setTitle,
  closeBtnOnly = false,
  randomizeResponses = false,
}) => {
  const navigate = useNavigate();
  const { data: singleSurvey, loading } = useQuery(GET_SURVEY, {
    variables: { getSurveyId: surveyId },
  });

  const { t } = useTranslation();
  const [isModifyOpen, setIsModifyOpen] = useState(false);

  const survey = new Model(singleSurvey?.getSurvey?.json);
  if (randomizeResponses) {
    // survey.mode = "display"
    try {
      console.log('singleSurvey?.getSurvey?.json', survey.getAllQuestions())
      const data: Record<string, unknown> = {}
      survey.getAllQuestions().forEach((q: any) => {
        const choices = q?.jsonObj?.choices
        if (choices?.length) {
          const value = choices[Math.floor(Math.random()*choices.length)]
          data[q?.jsonObj.name] = value
        }
      })
      survey.data = data
    } catch (error) {
      console.error('Error while randomise responses', error)
    }
  }

  return (
    <>
      <ModifyQuestionnaire
        isOpen={isModifyOpen}
        onClose={() => {
          onClose();
          navigate("/questionnaires");
          // setIsModifyOpen(false);
          window.location.reload()
        }}
        surveyId={surveyId}
        surveyJson={singleSurvey?.getSurvey?.json}
        setTitle={setTitle}
      />
      <Modal
        size="4xl"
        isOpen={isOpen}
        isCentered={true}
        onClose={onClose}
        scrollBehavior="inside"
        closeOnOverlayClick={true}
      >
        <ModalOverlay backgroundColor={"#0000007D"} />
        <ModalContent backgroundColor="brand.600">
          <ModalHeader pt="24px" display="flex" justifyContent="space-between">
            <Text fontSize="24px" fontWeight="bold" color="brand.700">
              {singleSurvey?.getSurvey?.json?.title}
            </Text>
            <Box display="flex" gap="10px">
              {closeBtnOnly ? (
                <Button
                  colorScheme="teal"
                  height="44px"
                  variant="solid"
                  backgroundColor="brand.500"
                  onClick={() => onClose()}
                >
                  {t("Chiudi")}
                </Button>
              ) : 
              <>
                <Button
                  colorScheme="teal"
                  height="44px"
                  variant="solid"
                  backgroundColor="brand.500"
                  onClick={() => {
                    onClose();
                    navigate("/questionnaires");
                    setIsModifyOpen(true);
                  }}
                >
                  {t("Modifica")}
                </Button>
                <Button
                  backgroundColor="white"
                  border="2px"
                  height="44px"
                  borderRadius="10px"
                  borderColor="gray.200"
                  onClick={() => {
                    onClose();
                    navigate("/questionnaires");
                  }}
                >
                  {t("Annulla")}
                </Button>
              </>}
            </Box>
          </ModalHeader>
          <ModalBody pb={6} mt="16px">
            {isOpen && <Survey model={survey} />}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SurveyComponent;
