import * as yup from "yup";
import { useState, useMemo, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { ArrowLeftIcon, TagInput, TagInputOwnProps } from "evergreen-ui";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Flex,
  FormErrorMessage,
  Input,
  useToast,
  Spinner,
  Textarea,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import Title from "../../../../components/Title";
import TitleSmall from "../../../../components/TitleSmall";
import Label from "../../../../components/Label";
import { GET_CLINIC } from "../../../../graphql/queries/clinics.query";
import { UPDATE_CLINIC } from "../../../../graphql/mutations/update-clinic-mutation";

import { phoneRegex, emailRegex } from "../../../../regex";

import "./index.scss";
import GreenTagInput from "../../../../components/Inputs/GreenTagInput";
import { useTranslation } from "react-i18next";

const EditClinicProfile = () => {
  const navigate = useNavigate();
  const params = useParams();
  const toast = useToast();
  const [tags, setTags] = useState<string[]>([]);
  const [state, setState] = useState<any>({});

  const { t, i18n } = useTranslation();
  const { data: clinic, loading } = useQuery(GET_CLINIC, {
    variables: { id: params.clinicId },
  });
  const [updateClinic, mutation] = useMutation(UPDATE_CLINIC);
  const schema = yup.object({
    name: yup.string().required(t("Inserisci il tuo nome")),
    managerSurname: yup.string().required(t("Inserisci il tuo cognome")),
    doctorsQuantity: yup.number().required(t("Inserisci il numero dei medici")),
    address: yup.string().required(t("Inserisci l'indirizzo")),
    managerName: yup.string().required(t("Inserisci il tuo nome")),
    email: yup.string().email(t("L'indirizzo email inserito non è valido")),
    phone: yup
      .string()
      .transform((value) => (!value ? null : value))
      .matches(phoneRegex, t("Il numero di telefono non è valido"))
      .nullable(),
    note: yup.string(),
    dateOfInvitation: yup.string().nullable(),
    registrationDate: yup.string().nullable(),
  });
  const data = useMemo(() => clinic?.clinic || null, [clinic]);

  useEffect(() => {
    if (data) {
      setState({
        ...data,
      });
    }
  }, [data]);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  console.log(errors);
  const onSubmit = async (data: any) => {
    try {
      await updateClinic({
        variables: {
          input: {
            name: data.name,
            doctorsQuantity: parseInt(data.doctorsQuantity),
            address: data.address,
            clinicManager: {
              name: data.managerName,
              surname: data.managerSurname,
              email: data.email,
              pec: data.pec,
            },
          },
        },
      });
      navigate(-1);
      toast({
        title: t("La clinica è stata aggiornata"),
        status: "success",
        isClosable: true,
      });
    } catch (error: any) {
      toast({
        title: t("Errore"),
        description: error.message,
        status: "error",
        isClosable: true,
      });
    }
  };

  if (loading) {
    return (
      <Flex alignItems="center" justifyContent="center" height="100%">
        <Spinner size="xl" color="brand.500" thickness="4px" speed="0.65s" />
      </Flex>
    );
  }

  return (
    <Box className="clinic-container">
      <Title text={t("Modifica cliniche")} style={{ paddingTop: 40 }} />
      <Box className="clinic-edit-body">
        <form id="clinic-form" onSubmit={handleSubmit(onSubmit)}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <TitleSmall
              text={t("Panoramica")}
              color="#B5E4CA"
              style={{ marginRight: 24 }}
            />
            <Button
              colorScheme="gray"
              variant="outline"
              leftIcon={<ArrowLeftIcon />}
              onClick={() => navigate(`/clinics/${params.clinicId}`)}
              backgroundColor="#F2F5FA"
              borderRadius={12}
              height="48px"
            >
              {t("Indietro")}
            </Button>
          </Box>
          <Flex justifyContent="space-between" alignItems="flex-end">
            <FormControl isInvalid={!!errors.address} mt="32px">
              <FormLabel htmlFor="address" fontSize="14px">
                {t("Indrizzo")}
              </FormLabel>
              <Input
                id="address"
                h="48px"
                placeholder={t("Indrizzo")}
                {...register("address")}
              />
              <FormErrorMessage>
                {errors.address?.message || null}
              </FormErrorMessage>
            </FormControl>
          </Flex>
          <Flex justifyContent="space-between" alignItems="flex-end">
            <FormControl
              mr="16px"
              mt="32px"
              isInvalid={!!errors.doctorsQuantity}
            >
              <FormLabel htmlFor="doctorsQuantity" fontSize="14px">
                {t("Dottori iscritti")}
              </FormLabel>
              <Input
                id="doctorsQuantity"
                h="48px"
                placeholder={t("Dottori iscritti")}
                {...register("doctorsQuantity")}
              />
              <FormErrorMessage>
                {errors.doctorsQuantity?.message || null}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.phone}>
              <FormLabel htmlFor="phone" fontSize="14px">
                {t("Cellulare")}
              </FormLabel>
              <Input
                id="phone"
                h="48px"
                placeholder={t("Cellulare")}
                {...register("phone")}
              />
              <FormErrorMessage>
                {errors.phone?.message || null}
              </FormErrorMessage>
            </FormControl>
          </Flex>
          <Flex justifyContent="space-between" alignItems="flex-end">
            <FormControl
              mr="16px"
              mt="32px"
              isInvalid={!!errors.dateOfInvitation}
            >
              <FormLabel htmlFor="dateOfInvitation" fontSize="14px">
                {t("Invitato il")}
              </FormLabel>
              <Input
                id="dateOfInvitation"
                h="48px"
                placeholder="dd MMMM yyyy"
                {...register("dateOfInvitation")}
              />
              <FormErrorMessage>
                {errors.dateOfInvitation?.message || null}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.registrationDate}>
              <FormLabel htmlFor="registrationDate" fontSize="14px">
                {t("Iscritta il")}
              </FormLabel>
              <Input
                id="registrationDate"
                h="48px"
                placeholder="dd MMMM yyyy"
                {...register("registrationDate")}
              />
              <FormErrorMessage>
                {errors.registrationDate?.message || null}
              </FormErrorMessage>
            </FormControl>
          </Flex>
          <Flex justifyContent="space-between" alignItems="flex-end">
            <FormControl mr="16px" mt="32px" isInvalid={!!errors.email}>
              <FormLabel htmlFor="email" fontSize="14px">
                {t("Email")}
              </FormLabel>
              <Input
                id="email"
                h="48px"
                placeholder={t("Email")}
                {...register("email")}
              />
              <FormErrorMessage>
                {errors.email?.message || null}
              </FormErrorMessage>
            </FormControl>
          </Flex>
          <FormControl mt="32px">
            <FormLabel htmlFor="note" fontSize="14px">
              {t("Note")}
            </FormLabel>
            <Textarea
              id="note"
              resize="vertical"
              backgroundColor="white"
              border="none"
              {...register("note")}
            />
          </FormControl>
          <GreenTagInput
            maxTags={3}
            label={t("Tags")}
            values={tags}
            placeholder={t(
              "Inserisci i tag per poter cercare una clinica per parole chiave"
            )}
            onChange={(values) => {
              setTags(values);
            }}
          />

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt="32px"
          >
            <Button
              colorScheme="gray"
              variant="outline"
              leftIcon={<ArrowLeftIcon />}
              onClick={() => navigate(`/clinics/${params.clinicId}`)}
              height="48px"
              backgroundColor="white"
              borderRadius={12}
              disabled={mutation.loading}
            >
              {t("Annulla")}
            </Button>
            <Button
              type="submit"
              colorScheme="teal"
              variant="solid"
              width="188px"
              height="48px"
              backgroundColor="brand.500"
              isLoading={mutation.loading}
            >
              {t("Salva")}
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default EditClinicProfile;
