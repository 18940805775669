import React, { useCallback, useEffect } from "react";
import {
  Button,
  Text,
  Tabs,
  TabPanels,
  TabIndicator,
  Divider,
  Flex,
  Switch,
} from "@chakra-ui/react";
import { ArrowLeftIcon } from "evergreen-ui";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  GeneralQuestionnairesInformations,
  GeneralQuestionnairesInformationsField,
} from "./GeneralQuestionnairesInformations";
import "./index.scss";
import { SurveyCreatorRenderComponent } from "./surveyjs";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { GET_QUESTIONNAIRES_BY_AGENCY } from "src/graphql/queries/questionnaires-byAgency.query";
import { CREATE_QUESTIONNAIRES_BY_AGENCY } from "src/graphql/mutations/create-questionnaires-byAgency.mutation";
import { QuestionnaireRelatedToType } from "src/interfaces/IQuestionnaires";
import { useTranslation } from "react-i18next";

interface myData {
  id: any;
  name: string;
  description: string;
}

const CreateNewQuestionnaire = () => {
  const [mutationQuestionnaire] = useMutation(CREATE_QUESTIONNAIRES_BY_AGENCY, {
    refetchQueries: [{ query: GET_QUESTIONNAIRES_BY_AGENCY }],
  });

  const [isAdvanced, setIsAdvanced] = useState(false);

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [myData, setMyData] = useState<myData | undefined>();

  const formik = useFormik({
    initialValues: {
      [GeneralQuestionnairesInformationsField.title]: "",
      [GeneralQuestionnairesInformationsField.description]: "",
      [GeneralQuestionnairesInformationsField.relatedTo]:
        QuestionnaireRelatedToType.Patient,
    },
    validationSchema: Yup.object({
      [GeneralQuestionnairesInformationsField.title]: Yup.string().required(
        t("Aggiungi un titolo")
      ),
      [GeneralQuestionnairesInformationsField.description]: Yup.string()
        .max(40, t("Sono ammessi al più 40 caratteri"))
        .required(t("Aggiungi una descrizione")),
    }),
    validateOnChange: true,
    onSubmit: (values, actions) => {
      console.log({
        values,
      });
    },
  });

  const createQuestionnaireByAgency = async () => {
    try {
      const res = await mutationQuestionnaire({
        variables: {
          input: {
            name: formik.values.title,
            description: formik.values.description,
            relatedTo: formik.values.relatedTo,
          },
        },
      });
      setMyData(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      console.log("done");
    }
  };

  return (
    <div className="main-container">
      <SurveyCreatorRenderComponent
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        formik={formik}
        myData={myData}
        isAdvanced={isAdvanced}
      />
      <Button
        backgroundColor="transparent"
        color="brand.500"
        fontSize="16px"
        leftIcon={<ArrowLeftIcon />}
        onClick={() => {
          navigate("/questionnaires");
        }}
      >
        <Text>{t("Ritorna a questionari")}</Text>
      </Button>
      <div className="container">
        <Text fontSize="24px" fontWeight="bold" color="brand.700">
          {t("Crea un nuovo Questionario")}
        </Text>
        <Text fontSize="16px" color="brand.800" marginTop="5">
          {t(
            "Compila i campi e inserisci tutte le informazioni necessarie per creare il tuo nuovo questionario."
          )}
        </Text>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            createQuestionnaireByAgency();
          }}
        >
          <Tabs marginTop="24px" variant="unstyled" position="relative">
            <Divider
              mt="-1px"
              height="0.5px"
              borderRadius="1px"
              backgroundColor="#D9DFE9"
              color="#D9DFE9"
            />
            <TabIndicator
              mt="-1.5px"
              height="2px"
              bg="brand.500"
              borderRadius="1px"
            />
            <TabPanels>
              <GeneralQuestionnairesInformations formik={formik} />
            </TabPanels>
          </Tabs>
          <Flex marginTop={"20px"}>
            <Flex flex={2}>
              <Text color="gray.600">
                {t(
                  "Decidi tu se creare il questionario in modalità Easy to Use o Advanced"
                )}
              </Text>
            </Flex>
            <Flex flex={3} flexDir={"row"}>
              <Switch
                size="md"
                colorScheme="brand"
                marginRight={"10px"}
                onChange={() => setIsAdvanced(!isAdvanced)}
              />
              <Flex flexDir={"column"}>
                <Text color="gray.800">{t("Attiva Modalità Advanced")}</Text>
                <Text color="gray.600">
                  {t(
                    "Questa opzione consente una personalizzazione maggiore del Questionario."
                  )}
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Divider
            mt="24px"
            mb="24px"
            height="1.5px"
            borderRadius="1px"
            backgroundColor="#D9DFE9"
            color="#D9DFE9"
          />
          <div
            style={{
              justifyContent: "space-between",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Button
              backgroundColor="transparent"
              color="#667085"
              fontSize="16px"
              onClick={() => {
                navigate("/questionnaires");
              }}
            >
              <Text>{t("Annulla")}</Text>
            </Button>

            <div>
              <Button
                colorScheme="teal"
                variant="solid"
                width="188px"
                height="48px"
                backgroundColor="brand.500"
                type="submit"
                onClick={() => setIsModalOpen(true)}
                isDisabled={formik.values.title.length == 0}
              >
                {t("Inizia questionario")}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateNewQuestionnaire;
