import { SVGProps } from 'react'

export const UnmutedMicrophoneIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15 5a3 3 0 1 0-6 0v6a3 3 0 1 0 6 0V5Z"
      stroke="#fff"
      strokeWidth={1.5}
    />
    <path
      d="M5 10v1a7 7 0 1 0 14 0v-1m-7 8v4m0 0H9m3 0h3"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
