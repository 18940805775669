import { gql } from "@apollo/client";

export const GET_SERVICES = gql`
query getServices {
    services {
      id
      name
      cost
      duration
    }
  }
`;