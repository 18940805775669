import { gql } from '@apollo/client';

export const GET_PATH_CATEGORIES = gql`
  query gerPathCategories {
    pathCategories {
      id
      name
    }
  }
`;
