import {
  Box,
  Button,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import TitleSmall from "../../TitleSmall";
import { colors } from "../../../colors";
import { IModal } from "../../../interfaces/IModal";
import { IDoctor } from "../../../interfaces/IDoctor";
import { useTranslation } from "react-i18next";

const DeleteDoctorModal = ({
  isOpen,
  onClose,
  loading,
  doctor,
  onSave,
}: IModal & {
  doctor: IDoctor;
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
}) => {
  
  const { t, i18n } = useTranslation();
  return (
    <Modal
      size="sm"
      isOpen={isOpen}
      isCentered={true}
      onClose={onClose}
      scrollBehavior="inside"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent backgroundColor="brand.600" borderRadius="16px">
        <ModalHeader pt="24px">
          <TitleSmall text={t("Elimina dottore")} color={colors[1000]} />
        </ModalHeader>
        <ModalCloseButton mt="8px" mr="8px" borderRadius="23px" />
        <ModalBody pb={6} mt="16px">
          <Box className="modal-text" mb="24px">
            {t("Sei proprio sicuro di voler eliminare")} {doctor.name}{" "}
            {doctor.surname} {t("dall'elenco dei dottori?")}
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt="16px"
          >
            <Button
              colorScheme="gray"
              variant="outline"
              onClick={onClose}
              backgroundColor="white"
              disabled={loading}
            >
              {t("Annulla")}
            </Button>
            <Button
              colorScheme="red"
              variant="solid"
              borderRadius={12}
              onClick={onSave}
            >
              {t("Elimina")}
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DeleteDoctorModal;
