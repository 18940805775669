import { ChangeEvent, useMemo, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  FilterFn,
} from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { PlusIcon, SearchIcon } from "evergreen-ui";
import TitleSmall from "../../../components/TitleSmall";
import AddDoctorModal from "../../../components/Modals/AddDoctorModal";
import SuccessModal from "../../../components/Modals/SuccessModal";
import { IDoctor } from "../../../interfaces/IDoctor";
import DoctorsTable from "./DoctorsTable";
import { colors } from "../../../colors";
import { useMutation, useQuery } from "@apollo/client";
import { GET_DOCTORS } from "../../../graphql/queries/doctors.query";
import { GET_DOCTORS_BY_AGENCY } from "../../../graphql/queries/get-doctors-by-agency.query";
import { MobileBadge } from "../../../components/MobileBadge";
import { EmailButton } from "../../../components/EmailButton";
import { CREATE_DOCTOR } from "../../../graphql/mutations/create-doctor.mutation";
import { useTranslation } from "react-i18next";
import { GET_USER } from "src/graphql/queries/user.query";

// enum DoctorStatus {
//   REGISTERED = 'registered',
//   INVITED = 'invited',
// };

const globalFilterFn: FilterFn<IDoctor> = (row, columnId, filterValue) => {
  // if (columnId === 'tag') {
  //   const tags = row.original.tags ?? [];
  //   const tag = tags.find((x: string) =>
  //     x.toLowerCase().includes(filterValue.toLowerCase())
  //   );
  //   return tag ? true : false;
  // }

  const data: string = row.getValue(columnId);
  return data.toLowerCase().includes(filterValue.toLowerCase());
};

const Dashboard = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  // const [status, setStatus] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [isAddDoctor, setIsAddDoctor] = useState(false);
  const userQuery = useQuery(GET_USER);
  const { role } = userQuery.data.me;
  const doctorsQuery = useQuery(GET_DOCTORS);
  const doctorsQueryByAgency = useQuery(GET_DOCTORS_BY_AGENCY);
  const [createDoctor, { loading }] = useMutation(CREATE_DOCTOR, {
    refetchQueries: [{ query: GET_DOCTORS }, { query: GET_DOCTORS_BY_AGENCY }],
  });

  // const handleStatusChanged = (value: DoctorStatus) => {
  //   setStatus(value);
  // };

  const handleSaveDoctor = async (data: any) => {
    try {
      await createDoctor({
        variables: {
          input: {
            name: data.name,
            surname: data.surname,
            email: data.email,
            specialization: data.specialization,
          },
        },
      });
      setIsAddDoctor(false);
    } catch (error: any) {
      toast({
        title: t("Errore durante la creazione del dottore."),
        description: error.message,
        status: "error",
        isClosable: true,
      });
    }
  };

  const columnsDef: ColumnDef<IDoctor>[] = [
    {
      id: "name",
      header: t("Nome"),
      accessorFn: (row) => `${row.name} ${row.surname}`,
      cell: ({ row, getValue }) => {
        const id = row.original.id;
        const name = getValue() as string;

        const handleClick = () => {
          navigate(`/doctors/${id}`);
        };

        return (
          <Flex alignItems="center" gap="10px">
            <Avatar name={name} />
            <Button
              variant="link"
              colorScheme="teal"
              fontSize="15px"
              fontWeight="bold"
              color="brand.700"
              onClick={handleClick}
              whiteSpace="break-spaces"
              textAlign="start"
            >
              {name}
            </Button>
          </Flex>
        );
      },
    },
    {
      header: t("Contatto"),
      accessorFn: (row) => ({ email: row.email, mobile: row.phone }),
      id: "contact",
      cell: ({ getValue }) => {
        const values = getValue() as {
          email: string | undefined;
          phone: string | undefined;
        };

        return (
          <Flex gap="10px" alignItems="center">
            {values.phone && <MobileBadge mobile={values.phone} />}
            {values.email && <EmailButton email={values.email} />}
          </Flex>
        );
      },
    },
    {
      header: t("Tag"),
      accessorKey: "",
    },
  ];

  const data = useMemo(() => {
    if (role == "agency_admin") {
      return doctorsQueryByAgency.data?.getDoctorsByAgency || [];
    } else {
      return doctorsQuery.data?.doctors || [];
    }
  }, [doctorsQuery.data,role, doctorsQueryByAgency.data]);

  const columns = useMemo(() => columnsDef, []);

  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
    },
    globalFilterFn: globalFilterFn,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  if (doctorsQuery.loading) {
    return (
      <Flex alignItems="center" justifyContent="center" height="100%">
        <Spinner size="xl" color="brand.500" thickness="4px" speed="0.65s" />
      </Flex>
    );
  }

  return (
    <Box h="100%" mr="91px" ml="91px">
      <AddDoctorModal
        isOpen={isAddDoctor}
        onClose={() => setIsAddDoctor(false)}
        loading={loading}
        onSave={handleSaveDoctor}
      />
      <SuccessModal isOpen={isSuccess} setIsOpen={() => setIsSuccess(false)} />

      <Text fontSize="40px" fontWeight="semibold" pt="40px" pb="24px">
        {t("Lista dottori")}
      </Text>

      <Box backgroundColor="brand.300">
        <Flex
          height="76px"
          paddingInlineStart="24px"
          paddingInlineEnd="24px"
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex alignItems="center">
            <TitleSmall
              text={t("Dottori")}
              color={colors[100]}
              style={{ marginRight: 24 }}
            />
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<SearchIcon color="gray" />}
              />
              <Input
                placeholder={t("Cerca per nome o altro")}
                value={globalFilter ?? ""}
                background="brand.400 !important"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setGlobalFilter(e.target.value)
                }
              />
            </InputGroup>
          </Flex>

          {/* <Box>
            <Button
              colorScheme="gray"
              variant="ghost"
              isActive={status === DoctorStatus.REGISTERED}
              onClick={() => handleStatusChanged(DoctorStatus.REGISTERED)}
              marginRight={2}
            >
              Registrato
            </Button>
            <Button
              colorScheme="gray"
              variant="ghost"
              isActive={status === DoctorStatus.INVITED}
              onClick={() => handleStatusChanged(DoctorStatus.INVITED)}
            >
              Invitato
            </Button>
          </Box> */}

          <Button
            colorScheme="teal"
            variant="solid"
            onClick={() => setIsAddDoctor(true)}
            leftIcon={<PlusIcon />}
            width="188px"
            height="48px"
            backgroundColor="brand.500"
          >
            {t("Aggiungi dottore")}
          </Button>
        </Flex>
      </Box>

      <DoctorsTable table={table} />
    </Box>
  );
};

export default Dashboard;
