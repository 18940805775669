import { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Tab,
  TabList,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { useQuery } from "@apollo/client";
import PathModelsContent from "./PathModelsContent";
import { ICard } from "./PathModelsContent/ICard";
import { IModal } from "../../../interfaces/IModal";
import { IPathModels } from "../../../interfaces/IPathModels";
import { Paths } from "./Paths";
import { GET_PATH_MODELS } from "../../../graphql/queries/get-path-models.query";
import "./index.scss";
import { useTranslation } from "react-i18next";

const tabs = [
  {
    title: Paths.CARDIOMETABOLIC,
  },
  {
    title: Paths.ONCOLOGY,
  },
  {
    title: Paths.LIFE_COURSE,
  },
  {
    title: Paths.NEUROLOGY,
  },
  {
    title: Paths.MENTAL_HEALTH,
  },
];

interface ICards {
  [key: string]: ICard[];
}

const PathModelsModal = ({ isOpen, loading, onClose }: IModal) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [cards, setCards] = useState<ICards>({});

  const { t, i18n } = useTranslation();
  const { data } = useQuery<{
    getPathModels: IPathModels[];
  }>(GET_PATH_MODELS);

  useEffect(() => {
    if (data?.getPathModels) {
      const cards = data.getPathModels.reduce((prev: ICards, curr) => {
        const card: ICard = {
          id: curr.id,
          name: curr.name,
          questionnaires: curr.questionnaires,
          image: curr.image,
        };

        if (curr.category?.name) {
          prev[curr.category.name]
            ? prev[curr.category.name].push(card)
            : (prev[curr.category.name] = [card]);
        }

        return prev;
      }, {});

      setCards(cards);
    }
  }, [data]);

  if (loading) {
    return (
      <Flex alignItems="center" justifyContent="center" height="100%">
        <Spinner size="xl" color="brand.500" thickness="4px" speed="0.65s" />
      </Flex>
    );
  }

  const selectedCategory = tabs[selectedIndex].title;

  return (
    <Modal
      isOpen={isOpen}
      isCentered={true}
      onClose={onClose}
      scrollBehavior="inside"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent
        backgroundColor="brand.300"
        maxWidth="1179px"
        h="855px"
        borderRadius="12px"
      >
        <ModalHeader pt="32px" alignItems="center">
          <Text fontSize="24px" fontWeight="semibold">
            {t('Modelli')}
          </Text>
        </ModalHeader>

        <ModalCloseButton borderRadius="23px" mt="20px" />

        <Divider color="#EAECF0" />

        <ModalBody>
          <Flex padding="24px">
            <Tabs
              variant="soft-rounded"
              colorScheme="gray"
              orientation="vertical"
              onChange={(index) => setSelectedIndex(index)}
            >
              <TabList w="187px">
                <Box mb="24px">
                  <p className="menu-title" color="brand.700">
                    {t('SFOGLIA PER CATEGORIA')}
                  </p>
                </Box>
                {tabs.map(({ title }, index) => (
                  <Tab
                    justifyContent="flex-start"
                    borderRadius="5px"
                    mb="10px"
                    key={title}
                    id={title}
                    aria-controls={`Boxl-${title}`}
                    style={{
                      color: index === selectedIndex ? "#096b72" : "#2B292D",
                    }}
                  >
                    {t(title)}
                  </Tab>
                ))}
              </TabList>
            </Tabs>

            <Flex ml="79px" flexDirection="column">
              {tabs.map(({ title }, index) => (
                <Box
                  key={title}
                  id={`Boxl-${title}`}
                  role="tabBoxl"
                  aria-labelledby={title}
                  aria-hidden={index !== selectedIndex}
                  display={index === selectedIndex ? "block" : "none"}
                >
                  <PathModelsContent
                    category={selectedCategory}
                    cards={cards[selectedCategory]}
                  />
                </Box>
              ))}
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PathModelsModal;
