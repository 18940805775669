import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "evergreen-ui";
import { useTranslation } from "react-i18next";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { IServiceHealth } from "src/interfaces/IServiceHealth";

enum ServiceHealthFiltersType {
  priceList = "Listino",
  acronym = "Sigla",
  type = "Genere",
}

type FilterType = {
  name: string;
  checked: boolean;
};

type ServiceHealthFilters = {
  priceList: FilterType[];
  acronym: FilterType[];
  type: FilterType[];
};

type ServiceHealthFilterProps = {
  services: IServiceHealth[];
  onToggleFilter: (filter: ServiceHealthFilters) => void;
};

const ServiceHealthFilter: React.FC<ServiceHealthFilterProps> = React.memo(
  ({ services, onToggleFilter }) => {
    const availableFilters = useMemo(() => {
      const filters = services.reduce<ServiceHealthFilters>(
        (acc, service) => {
          const { priceList, acronym, type } = service;
          return {
            priceList: [...acc.priceList, { name: priceList, checked: false }],
            acronym: [...acc.acronym, { name: acronym, checked: false }],
            type: [...acc.type, { name: type, checked: false }],
          };
        },
        {
          priceList: [],
          acronym: [],
          type: [],
        }
      );

      //remove duplicates
      return Object.entries(filters).reduce<ServiceHealthFilters>(
        (acc, [key, values]) => {
          const uniqueValues = values.reduce<FilterType[]>((acc, filter) => {
            if (acc.find((f) => f.name === filter.name)) {
              return acc;
            }
            return [...acc, filter];
          }, []);
          return {
            ...acc,
            [key]: uniqueValues,
          };
        },
        {
          priceList: [],
          acronym: [],
          type: [],
        }
      );
    }, [services]);

    const { t, i18n } = useTranslation();
    const currentFilter = useRef<ServiceHealthFilters>(availableFilters);

    const toggleFilter = useCallback(
      (filter: FilterType) => {
        const newFilters = Object.entries(
          currentFilter.current
        ).reduce<ServiceHealthFilters>((acc, [key, values]) => {
          const newValues = values.map((value) => {
            if (value.name === filter.name) {
              return {
                ...value,
                checked: !value.checked,
              };
            }
            return value;
          });
          return {
            ...acc,
            [key]: newValues,
          };
        }, currentFilter.current);

        onToggleFilter(newFilters);

        currentFilter.current = newFilters;
      },
      [onToggleFilter]
    );

    const [isOpen, setIsOpen] = useState(false);

    return (
      <Popover onClose={() => setIsOpen(false)} isOpen={isOpen}>
        <PopoverTrigger>
          <Button
            backgroundColor="white"
            height="44px"
            rightIcon={<ChevronDownIcon />}
            width="100%"
            marginLeft="4"
            border="2px"
            borderRadius="10px"
            borderColor="gray.200"
            onClick={() => setIsOpen(true)}
          >
            <Text display="flex" marginRight="1">
              {t("Filtra per")}
            </Text>
          </Button>
        </PopoverTrigger>
        <PopoverContent
          border="2px"
          borderRadius="14px"
          maxH="600px"
          overflow="scroll"
          style={{
            borderColor: "#E2E8F0",
          }}
        >
          <PopoverBody>
            <Accordion allowToggle>
              {Object.entries(availableFilters).map(([key, values]) => {
                return (
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                          {t(
                            ServiceHealthFiltersType[
                              key as keyof typeof ServiceHealthFiltersType
                            ]
                          )}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      {values.map((filter: FilterType, index: number) => {
                        return (
                          <Box
                            key={index}
                            display="flex"
                            w="100%"
                            paddingY="2"
                            paddingX="1"
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Checkbox onChange={() => toggleFilter(filter)} />
                            </Box>
                            <Box
                              marginLeft={2}
                              fontWeight="500"
                              fontSize="16px"
                              fontFamily="Nunito"
                            >
                              {filter.name}
                            </Box>
                          </Box>
                        );
                      })}
                    </AccordionPanel>
                  </AccordionItem>
                );
              })}
            </Accordion>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  }
);

export { ServiceHealthFilter };
export type { ServiceHealthFilters };
